import { makeStyles } from '@material-ui/core';
import { colors } from 'material';
import { ICogniflowTheme } from 'material/theme';

export default makeStyles<ICogniflowTheme>(() => ({
  title: {
    fontWeight: 'bold',
    fontSize: 24,
    fontFamily: 'Poppins',
    textAlign: 'center',
  },

  stepTitle: {
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'center',
  },

  stepsCard: {
    backgroundColor: '#f7f9ff',
    padding: '32px',
    borderRadius: '12px',
    width: '100%',
    boxShadow: '0px 16px 24px 0px rgba(72, 79, 121, 0.15)',
  },

  guideTextContainer: {
    backgroundColor: colors.blue50,
    textAlign: 'left',
    '& p': {
      color: 'white',
      fontSize: '12px',
    },
    padding: '8px',
    borderRadius: '4px',
  },
  guideLink: {
    color: colors.orange,
    textDecoration: 'underlined',
  },
}));
