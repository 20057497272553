import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles((theme) => ({
  running: {
    width: 75,
    backgroundColor: 'transparent',
    '& > span': {
      zIndex: 99,
    },
  },
  pending: {
    backgroundColor: colors.blue10,
    color: theme.palette.primary.dark,
  },
  error: {
    width: 75,
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  progressContainer: {
    borderRadius: 16,
    overflow: 'hidden',
    width: 75,
    minWidth: 75,
    display: 'inline-block',
    position: 'relative',
  },
  progressbar: {
    background: theme.palette.grey[300],
    height: '100%',
    width: 0,
    display: 'inline-block',
    position: 'absolute',
    left: 0,
    top: 0,
  },
}));
