import React, { useCallback, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from 'hooks';
import { useAuthenticationContext } from 'context';
import useStyle from './signup.styles';
import { Button, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { LoginWrapper } from 'components/LoginWrapper/LoginWrapper';
import { LoginForm } from 'components/AuthFormCard/LoginForm/LoginForm';
import { signUpValidationSchema } from './validation.login';

interface QueryParams {
  email: string;
  token: string;
  setpwd: string;
}

export const ActivateAccount = () => {
  const classes = useStyle();
  const { activateAccount, authenticateUser } = useAuthenticationContext();
  const [error, setError] = useState('');
  const { push } = useHistory();
  const [open, setOpen] = React.useState(false);
  const { email, token, setpwd } = useQueryParams<QueryParams>();

  if (!email || !token) {
    push('/login');
  }

  const handleClose = useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    },
    []
  );

  const handleSignUp = useCallback(
    ({ password }) => {
      setError('');
      setOpen(false);
      activateAccount?.({ email, token, password })
        .then(() => push('/'))
        .catch((err) => {
          console.error(err);
          setOpen(true);
          setError('Ooops something went wrong!. Try again later');
        });
    },
    [activateAccount, email, token, push]
  );

  // eslint-disable-next-line
  const allowSetPassword = setpwd != 'false';

  if (!allowSetPassword) {
    activateAccount?.({ email, token })
      .then(({ access_token: accessToken }) => {
        authenticateUser?.({
          email,
          accessToken,
        });
        localStorage.setItem('profile-data', JSON.stringify({ email } || ''));

        push(`/signup/profile`, { from: 'activation' });
      })
      .catch((err) => {
        console.log('Something went wrong while activating the user', err);
      });
  }

  if (allowSetPassword) {
    return (
      <LoginWrapper>
        <LoginForm>
          <Formik
            onSubmit={handleSignUp}
            validationSchema={signUpValidationSchema}
            initialValues={{
              password: '',
              rePassword: '',
            }}
          >
            {({ isValid, touched }) => (
              <Form className={classes.container}>
                <span className={classes.userFormTitle}>Create password</span>
                <Field
                  className={classes.fieldContainer}
                  component={TextField}
                  name="password"
                  label="Password"
                  type="password"
                />
                <Field
                  className={classes.fieldContainer}
                  component={TextField}
                  name="rePassword"
                  label="Confirm password"
                  type="password"
                />

                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  disabled={!isValid || !touched.password}
                  type="submit"
                >
                  Activate account
                </Button>
              </Form>
            )}
          </Formik>
        </LoginForm>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          onClose={handleClose}
        >
          <Alert
            classes={{ filledError: classes.filledError }}
            variant="filled"
            color="error"
            severity="error"
            onClose={handleClose}
          >
            {error}
          </Alert>
        </Snackbar>
      </LoginWrapper>
    );
  }
  return null;
};
