import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles((theme) => ({
  imageCover: {
    margin: 0,
  },
  dropzone: {
    height: 300,
    width: 300,
    maxWidth: 360,
    borderRadius: 5,
    border: `2px dashed ${colors.orange}`,
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    '&:focus': {
      outline: 'none',
    },
    cursor: 'pointer',
    backgroundColor: colors.orange,
  },
  loadedImage: {
    height: 300,
    width: 300,
    borderRadius: 5,
    objectFit: 'contain',
  },
}));
