import React, { FC, useEffect } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import { useRecoilState } from 'recoil';

import { ActionButton, SubtitleHeaderMisc } from 'components';

import { ExperimentLabel, EXPERIMENT_TASK, PROJECT_TYPE } from 'types';

import { algo_mla_state } from '../state';

import useStyles from '../accordionadvancedconfig.styles';

const vectorizersTextMap: ExperimentLabel = {
  [EXPERIMENT_TASK.IMAGE_OBJECT_DETECTION]: 'Backbones',
};

const classifiersTextMap: ExperimentLabel = {
  [EXPERIMENT_TASK.IMAGE_OBJECT_DETECTION]: 'Detectors',
};

export interface AudioConfigProps {
  config: any;
  type: number;
  task: number;
  configChangeHandler: any;
  applyDefault(): void;
}

export const AudioConfig: FC<AudioConfigProps> = ({
  configChangeHandler,
  config,
  type,
  task,
  applyDefault,
}) => {
  const classes = useStyles();
  const [algostatu, setAlgoStatus] = useRecoilState(algo_mla_state);

  useEffect(() => {
    setAlgoStatus(
      !config['vectorization_vggish'] && !config['vectorization_l3net']
    );
  }, [config, setAlgoStatus]);

  return (
    <>
      <Grid item container direction="column">
        <Box className={classes.subTitleHeader}>
          <SubtitleHeaderMisc title="Audio Source Type" showHelpIcon />
        </Box>
        <Grid className={classes.itemContainer}>
          <FormControl>
            <RadioGroup
              aria-label="audio_type"
              name="audio_type"
              onChange={configChangeHandler}
              value={config.audio_type}
              row
            >
              <FormControlLabel
                value="env"
                control={<Radio />}
                label="Environmental"
              />
              <FormControlLabel
                value="music"
                control={<Radio />}
                label="Music"
              />
              <FormControlLabel
                value="speech"
                control={<Radio />}
                label="Speech"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item container direction="column">
        <Box className={classes.subTitleHeader}>
          <SubtitleHeaderMisc title="Preprocessing" showHelpIcon />
        </Box>
        <Grid className={classes.itemContainer}>
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.daug_rotation}
                  value={config.daug_rotation}
                  onChange={configChangeHandler}
                  name="data_augementation"
                />
              }
              label="Data Augmentation"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start"></Grid>
          <Grid item container md={6} alignItems="flex-start"></Grid>
          <Grid item container md={6} alignItems="flex-start"></Grid>
          <Grid item container md={6} alignItems="flex-start"></Grid>
        </Grid>
      </Grid>
      <Grid item container direction="column">
        <Box className={classes.subTitleHeader}>
          <SubtitleHeaderMisc
            title={vectorizersTextMap[task] || 'Vectorizer'}
            showHelpIcon
          />
        </Box>
        <Grid className={classes.itemContainer}>
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.cnn_small}
                  name="cnn_small"
                  onChange={configChangeHandler}
                />
              }
              label="Small CNN"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.cnn_inceptionv3}
                  name="cnn_inceptionv3"
                  onChange={configChangeHandler}
                />
              }
              label="InceptionV3"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.cnn_inception_resnetv2}
                  name="cnn_inception_resnetv2"
                  onChange={configChangeHandler}
                />
              }
              label="InceptionResnetV2"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.cnn_mobilenetv2}
                  name="cnn_mobilenetv2"
                  onChange={configChangeHandler}
                />
              }
              label="MobileNetV2"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            {type === PROJECT_TYPE.AUDIO && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={config.cnn_xception}
                    name="cnn_xception"
                    onChange={configChangeHandler}
                  />
                }
                label="Xception"
              />
            )}
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.vectorization_l3net}
                  name="vectorization_l3net"
                  onChange={configChangeHandler}
                />
              }
              label="L3Net"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.vectorization_vggish}
                  name="vectorization_vggish"
                  onChange={configChangeHandler}
                />
              }
              label="Vggish"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container direction="column">
        <Box className={classes.subTitleHeader}>
          <SubtitleHeaderMisc
            title={classifiersTextMap[task] || 'Classifiers'}
            showHelpIcon
          />
        </Box>
        <Grid className={classes.itemContainer}>
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.algo_logistic_regression}
                  name="algo_logistic_regression"
                  onChange={configChangeHandler}
                />
              }
              label="Logistic Regression"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.algo_linear_sgd}
                  name="algo_linear_sgd"
                  onChange={configChangeHandler}
                />
              }
              label="Linear Classifier with SGD training"
            />
          </Grid>

          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.algo_ffnn}
                  name="algo_ffnn"
                  onChange={configChangeHandler}
                />
              }
              label="Feed Forward Neural Network (FFNN)"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.algo_svc_rbf}
                  name="algo_svc_rbf"
                  onChange={configChangeHandler}
                />
              }
              label="SVC (with RBF kernel)"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.algo_mla}
                  name="algo_mla"
                  onChange={configChangeHandler}
                  disabled={algostatu}
                />
              }
              label="Multi Level Attention"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item container direction="column">
        <Box className={classes.subTitleHeader}>
          <SubtitleHeaderMisc title="Metric to optimize" showHelpIcon />
        </Box>
        <Grid className={classes.itemContainer}>
          <FormControl>
            <RadioGroup
              aria-label="metric"
              name="metric_to_optimize"
              onChange={configChangeHandler}
              value={config.metric_to_optimize}
              row
            >
              <FormControlLabel value="auto" control={<Radio />} label="Auto" />
              <FormControlLabel value="f1" control={<Radio />} label="F1" />
              <FormControlLabel
                value="accuracy"
                control={<Radio />}
                label="Accuracy"
              />
              <FormControlLabel
                value="precision"
                control={<Radio />}
                label="Precision"
              />
              <FormControlLabel
                value="recall"
                control={<Radio />}
                label="Recall"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>

      <Grid className={classes.actionGroup} item container justify="center">
        <ActionButton
          onClick={applyDefault}
          size="large"
          variant="outlined"
          color="secondary"
        >
          Revert to Default
        </ActionButton>
      </Grid>
    </>
  );
};
