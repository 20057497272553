import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  root: {
    padding: 20,
    maxWidth: 640,
    margin: '40px auto',
  },
  title: {
    fontSize: 20,
    paddingLeft: 20,
  },
  description: {
    marginTop: 36,
    fontSize: 16,
  },
  url: {
    display: 'inline-block',
    marginTop: 6,
    fontSize: 16,
  },
  buttons: {
    marginTop: 36,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    gap: 8,
  },
  info: {
    marginTop: 32,
    opacity: 0.5,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 6,
  },
  // Embed Modal
  modalWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 16,
  },
  modalClose: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
  customWrapper: {
    position: 'relative',
    padding: '20px 0',
    height: 763,
    width: 475,
    maxHeight: '100vh',
    maxWidth: '100vw',
    background: '#fff',
    borderRadius: 6,
    overflow: 'hidden',

    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
  },
  embedWrapper: {
    position: 'relative',
    padding: 20,
    width: 475,
    maxHeight: '100vh',
    maxWidth: '100vw',
    background: '#fff',
    borderRadius: 6,
    overflow: 'hidden',

    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
  },
  embedCodeWrapper: {
    padding: 12,
    background: '#f4f7ff',
    borderRadius: 4,
  },
  embedCode: {
    fontSize: 14,
    color: '#6c6e89',
    lineBreak: 'anywhere',
    lineHeight: '20px',
  },
  embedButtons: {
    display: 'flex',
    justifyContent: 'end',
    gap: 16,
  },
  // Customization Modal
  customizationForm: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: 22,

    flexGrow: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: 20,
  },
  customizationLocked: {
    position: 'absolute',
    top: 0,
    bottom: 4,
    left: 0,
    right: 0,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 16,

    padding: 40,
    margin: 10,
    background: '#e7ebf8d6',
    border: '2px solid #d7d9e1',
    textAlign: 'center',
    borderRadius: 8,
    zIndex: 2,
  },
  customizationInputText: {
    fontFamily: 'Poppins',
    marginBottom: 6,
    fontWeight: 'bold',
  },
  customizationInputColor: {
    marginBottom: 6,
    padding: 8,
    paddingLeft: 14,
    borderRadius: 6,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 6,
  },
}));
