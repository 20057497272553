import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(({ breakpoints }) => ({
  card: {
    backgroundColor: '#FFFF',
    boxShadow: '0 8px 16px 0 rgb(72 79 121 / 15%)',
    borderRadius: '10px',
  },
  testWrapper: {
    padding: 0,
    display: 'grid',
    gap: 32,
    [breakpoints.down('sm')]: {
      gridTemplateColumns: 'auto !important',
    },
  },
  inputWrapper: {
    boxSizing: 'border-box',
    width: '100%',
    padding: 24,
  },
  background: {
    height: 266,
    width: '100%',
    maxWidth: 728,
    margin: '20px 0',
    borderRadius: 5,
    border: `2px dashed ${colors.blue15}`,
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    '&:focus': {
      outline: 'none',
    },
  },
  dragDropTitle: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    color: colors.blue20,
  },
  dragDropWarn: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    color: colors.blue20,
  },
  title: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: colors.blue70,
  },
  subtitle: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.blue70,
  },
  underlined: {
    textDecoration: 'underline',
    color: colors.orange,
    cursor: 'pointer',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  messageContainer: {
    boxSizing: 'border-box',
    width: '100%',
    margin: '20px 0',
    padding: 20,
    backgroundColor: '#eceffa',
    borderRadius: 10,
  },
  alertWrapper: {
    marginTop: '16px',
  },
  longTranscriptionText: {
    marginBottom: '12px',
  },
  longTranscriptionLink: {
    color: colors.orange,
    cursor: 'pointer',
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
}));
