import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';
export default makeStyles(() => ({
  experimentrunningContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imageContainer: {
    height: '350px',
  },
  robotImage: {
    marginTop: '40px',
    marginBottom: '32px',
  },
  titleHeader: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '1.33',
    color: colors.blue70,
    textAlign: 'center',
  },
  subtitleHeader: {
    fontFamily: 'PTSerif',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.5',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: colors.blue70,
    marginTop: '16px',
  },
  mailMeContainer: {
    marginTop: '48px',
  },
}));
