import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  modalContainer: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
    },
    '&: .MuiDialog-paperScrollPaper': {
      maxWidth: 'none',
    },
    margin: '0 auto',
  },
}));
