import React, { useMemo, useState, useEffect } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import { Button, Grid, Typography } from '@material-ui/core';
import { ResponseBox } from './../ResponseBox';
import { PredictResponse, QandAResponse } from 'types';

import useStyles from './qandaresponsebox.styles';
import { IconClose } from 'assets';
import {
  getStartEndIdxOfSubstring,
  splitTextByIndexes,
  twoDecimalsFixed,
} from 'utils';

interface QandAResponseBoxProps {
  closeModalResultHandler(): void;
  textResponse: PredictResponse;
  text: string;
}

const positions: any = {
  0: ' ',
  1: 'second',
  // 2: 'third',
};

type FragmentItem = {
  text: string;
  highlighted: boolean;
  strong?: boolean | undefined;
};

export const QandAResponseBox = ({
  textResponse,
  closeModalResultHandler,
  text,
}: QandAResponseBoxProps) => {
  const classes = useStyles();
  const [responseIndex, setResponseIndex] = useState(0);

  const fragments = useMemo(() => {
    if (textResponse.result.length) {
      const { answer, context, short_answer } = textResponse.result[
        responseIndex
      ] as QandAResponse;
      const { start, end } = getStartEndIdxOfSubstring(context, answer);
      const longAnswerFragments = splitTextByIndexes(
        context,
        start,
        end,
        false
      );
      const { start: shortStart, end: shortEnd } = getStartEndIdxOfSubstring(
        longAnswerFragments[1].text,
        short_answer
      );

      const result = [
        longAnswerFragments[0],
        ...splitTextByIndexes(
          longAnswerFragments[1].text,
          shortStart,
          shortEnd,
          true
        ),
        longAnswerFragments[2],
      ];

      return result;
    }
    return [
      {
        text: 'Sorry, I could not find any relevant answer for your question',
        highlighted: false,
        strong: false,
      },
    ];
  }, [textResponse, responseIndex]);

  useEffect(() => {
    setResponseIndex(0);
  }, [textResponse]);

  return (
    <ResponseBox
      textResponse={textResponse}
      renderHeader={() => (
        <Grid className={classes.resultTitleWrapper}>
          <Grid></Grid>
          {textResponse.result.length ? (
            textResponse.result.length === 1 ? (
              <Typography variant="body1" className={classes.subtitle}>
                <b>Reference: </b>
                {(textResponse.result[0] as QandAResponse).reference}
              </Typography>
            ) : (
              <Typography variant="body1" className={classes.subtitle}>
                <>
                  I think this answer extracted from{' '}
                  <span className={classes.titleLink}>
                    {
                      (textResponse.result[responseIndex] as QandAResponse)
                        .reference
                    }
                  </span>{' '}
                  is the
                  {` `}
                  <span style={{ fontWeight: 'bolder', margin: '0 0' }}>
                    {positions[responseIndex]
                      ? positions[responseIndex]
                      : `${responseIndex + 1}th `}
                  </span>
                  {` `}
                  most probable answer (score{' '}
                  {twoDecimalsFixed(
                    (textResponse.result[responseIndex] as QandAResponse)
                      .confidence
                  )}
                  )
                </>
              </Typography>
            )
          ) : (
            <Typography
              variant="body1"
              className={classes.subtitle}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              I could not find any relevant answer for your question
            </Typography>
          )}
          <Grid
            className={classes.closeResultModal}
            onClick={closeModalResultHandler}
          >
            <IconClose />
          </Grid>
        </Grid>
      )}
      renderBody={() => (
        <>
          {!!textResponse.result.length && (
            <Typography
              variant="button"
              className={classes.badge}
              style={{ maxWidth: '100%', overflowX: 'auto', marginTop: '12px' }}
            >
              {(fragments as FragmentItem[]).map((el, idx) => {
                if (!textResponse.result.length) return '';
                const context = (textResponse.result[
                  responseIndex
                ] as QandAResponse).context;
                const isTextHighlighted = el.highlighted && context !== el.text;
                return (
                  <ReactMarkdown
                    key={idx}
                    remarkPlugins={[remarkGfm, remarkBreaks]}
                    linkTarget="_blank"
                    className={
                      el.strong
                        ? classes.strongText
                        : isTextHighlighted
                        ? classes.highlighted
                        : ''
                    }
                  >
                    {el.text}
                  </ReactMarkdown>
                );
              })}
              {!!textResponse.result.length && textResponse.result.length > 1 && (
                <>
                  <br />
                  <Button
                    onClick={() => setResponseIndex((s) => (s > 0 ? s - 1 : 0))}
                    style={{
                      color: '#ff6a61',
                    }}
                  >
                    {'<< previous answer'}
                  </Button>
                  <Button>{responseIndex + 1}</Button>
                  <Button
                    onClick={() =>
                      setResponseIndex((s) =>
                        s < textResponse.result.length - 1
                          ? s + 1
                          : textResponse.result.length - 1
                      )
                    }
                    style={{
                      color: '#ff6a61',
                    }}
                  >
                    {'next answer >>'}
                  </Button>
                </>
              )}
            </Typography>
          )}
        </>
      )}
    />
  );
};
