import React, { useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { Doc } from './Doc';
import { ActionButton, Text } from 'components';

// import vid from './vid.webm';

import useStyles from './Documents.style';
import { ACCEPTED_FILES, useDocuments } from './useDocuments';
import { usePrompt } from 'hooks';
import DragDropContainer from 'components/DragDropContainer/DragDropContainer';

export const Documents = ({
  idModel,
  idExperiment,
}: {
  idModel: string;
  idExperiment: string;
}) => {
  const classes = useStyles();

  const {
    canSave,
    confirmChanges,
    deleteDoc,
    docs,
    hasChanges,
    changesSaved,
    inputRef,
    loading,
    saveLoading,
    uploadDocsChange,
  } = useDocuments({
    idModel,
    idExperiment,
  });
  const [readingDocs, setReadingDocs] = useState(false);
  const Prompt = usePrompt({
    when: !hasChanges && changesSaved,
    message: 'You have unsaved changes, are you sure you want to leave?',
  });

  const clickUpload = () => {
    if (!inputRef.current) return;

    inputRef.current.value = null;
    inputRef.current.click();
  };

  if (loading) return null;

  return (
    <DragDropContainer
      onDrop={(files) => uploadDocsChange(files)}
      DraggingElement={() => (
        <div
          style={{
            position: 'absolute',
            pointerEvents: 'none',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: '#fff',
            opacity: 0.5,
            zIndex: 10,
          }}
        ></div>
      )}
      className={classes.wrapper}
    >
      <Prompt />
      {readingDocs ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <video autoPlay loop muted>
            {/* <source src={vid} type="video/webm" /> */}
          </video>
        </Box>
      ) : (
        <>
          <input
            type="file"
            multiple
            ref={inputRef}
            onChange={() => uploadDocsChange()}
            accept={ACCEPTED_FILES.map((i) => `.${i}`).join(', ')}
            hidden
          />

          <Box>
            <Box className={classes.header}>
              <Text variant="h3">Documents</Text>
              <Box mt="16px" display="flex" justifyContent="flex-end">
                {docs.length > 0 && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.uploadButton}
                    onClick={clickUpload}
                  >
                    Upload document
                  </Button>
                )}
              </Box>
            </Box>

            {docs.length === 0 && (
              <Box
                my="20px"
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Text
                  variant="h4"
                  align="center"
                  style={{ color: '#989fb5', marginBottom: '20px' }}
                >
                  No documents yet... 😢. Try to uploading a new document.
                </Text>
                <Button
                  variant="outlined"
                  color="secondary"
                  className={classes.uploadButton}
                  onClick={clickUpload}
                >
                  Upload document
                </Button>
              </Box>
            )}

            <Box className={classes.docsWrapper}>
              {docs.map((props) => (
                <Doc {...props} onDelete={deleteDoc} key={props.id} />
              ))}
            </Box>
          </Box>

          <Box mt="30px">
            <ActionButton
              variant="contained"
              color="secondary"
              onClick={confirmChanges}
              className={classes.uploadButton}
              disabled={!canSave}
              loading={saveLoading}
            >
              Confirm your changes
            </ActionButton>
          </Box>
        </>
      )}
    </DragDropContainer>
  );
};
