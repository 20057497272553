import React, { FC, ReactElement } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import { ActionButton, SubtitleHeaderMisc } from 'components';
import { ExperimentLabel, EXPERIMENT_TASK } from 'types';

import useStyles from '../accordionadvancedconfig.styles';
import { ExtractorConfig } from './ExtractorConfig';

export interface AudioConfigProps {}

export interface TextConfigProps {
  config: any;
  type: string;
  task: string;
  configChangeHandler: any;
  applyDefault(): void;
}

const classifiersTextMap: ExperimentLabel = {
  [EXPERIMENT_TASK.IMAGE_OBJECT_DETECTION]: 'Detectors',
};

const vectorizersTextMap: ExperimentLabel = {
  [EXPERIMENT_TASK.IMAGE_OBJECT_DETECTION]: 'Backbones',
};

export const TextConfig: FC<TextConfigProps> = ({
  configChangeHandler,
  config,
  task,
  applyDefault,
}) => {
  const classes = useStyles();

  const renderConfig: { [key: string]: ReactElement } = {
    [EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR]: <ExtractorConfig />,
  };

  return renderConfig[task] ? (
    renderConfig[task]
  ) : (
    <>
      <Grid item container direction="column">
        <Box className={classes.subTitleHeader}>
          <SubtitleHeaderMisc title="Preprocessing" showHelpIcon />
        </Box>
        <Grid
          className={classes.itemContainer}
          item
          container
          md={12}
          wrap="wrap"
        >
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.only_ascii}
                  onChange={configChangeHandler}
                  name="only_ascii"
                />
              }
              label="Convert to ASCII"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.do_lower}
                  onChange={configChangeHandler}
                  name="do_lower"
                />
              }
              label="Convert to Lowercase"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            <Grid
              item
              container
              alignItems="flex-start"
              style={{ marginTop: 10 }}
            >
              <FormLabel component="legend">Remove Stop Words</FormLabel>
              <RadioGroup
                aria-label="remove"
                name="do_stopwords_removal"
                onChange={configChangeHandler}
                row
                value={config.do_stopwords_removal}
              >
                <FormControlLabel
                  value="auto"
                  control={<Radio />}
                  label="Auto"
                />
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="True"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="False"
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            <FormControl style={{ marginTop: 10 }}>
              <FormLabel component="legend">
                Normalize words to their root form
              </FormLabel>
              <RadioGroup
                aria-label="gender"
                name="do_lemmatization"
                onChange={configChangeHandler}
                value={config.do_lemmatization}
                row
              >
                <FormControlLabel
                  value="auto"
                  control={<Radio />}
                  label="Auto"
                />
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="True"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="False"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item container md={6} alignItems="flex-start"></Grid>
        </Grid>
      </Grid>
      <Grid item container direction="column">
        <Box className={classes.subTitleHeader}>
          <SubtitleHeaderMisc
            title={vectorizersTextMap[task] || 'Vectorizer'}
            showHelpIcon
          />
        </Box>
        <Grid
          className={classes.itemContainer}
          item
          container
          md={12}
          wrap="wrap"
        >
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.vectorization_bow}
                  onChange={configChangeHandler}
                  name="vectorization_bow"
                />
              }
              label="BOW"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.vectorization_tfidf}
                  onChange={configChangeHandler}
                  name="vectorization_tfidf"
                />
              }
              label="TFIDF"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            {/* {type === "text" && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={config.vectorization_fasttext}
                      onChange={configChangeHandler}
                      name="vectorization_fasttext"
                    />
                  }
                  label="FastText"
                />
              )} */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.vectorization_bert}
                  onChange={configChangeHandler}
                  name="vectorization_bert"
                />
              }
              label="BERT"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.vectorization_distilbert}
                  onChange={configChangeHandler}
                  name="vectorization_distilbert"
                />
              }
              label="DistilBERT"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.vectorization_xlmroberta}
                  onChange={configChangeHandler}
                  name="vectorization_xlmroberta"
                />
              }
              label="XLM-RoBERTa"
            />
            {/* {type === "text" && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={config.vectorization_bert}
                      onChange={configChangeHandler}
                      name="vectorization_bert"
                    />
                  }
                  label="BERT"
                />
              )} */}
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            {/* {type === "text" && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={config.vectorization_xlmroberta}
                      onChange={configChangeHandler}
                      name="vectorization_xlmroberta"
                    />
                  }
                  label="XLM-RoBERTa"
                />
              )} */}
          </Grid>
          <Grid item container md={6} alignItems="flex-start"></Grid>
        </Grid>
      </Grid>
      <Grid item container direction="column">
        <Box className={classes.subTitleHeader}>
          <SubtitleHeaderMisc
            title={classifiersTextMap[task] || 'Classifiers'}
            showHelpIcon
          />
        </Box>
        <Grid
          className={classes.itemContainer}
          item
          container
          md={12}
          wrap="wrap"
        >
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.algo_logistic_regression}
                  name="algo_logistic_regression"
                  onChange={configChangeHandler}
                />
              }
              label="Logistic Regression"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.algo_linear_sgd}
                  name="algo_linear_sgd"
                  onChange={configChangeHandler}
                />
              }
              label="Linear Classifier with SGD training"
            />
          </Grid>

          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.algo_ffnn}
                  name="algo_ffnn"
                  onChange={configChangeHandler}
                />
              }
              label="Feed Forward Neural Network (FFNN)"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.algo_svc_rbf}
                  name="algo_svc_rbf"
                  onChange={configChangeHandler}
                />
              }
              label="SVC (with RBF kernel)"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start"></Grid>
        </Grid>
      </Grid>

      <Grid item container direction="column">
        <Box className={classes.subTitleHeader}>
          <SubtitleHeaderMisc title="Metric to optimize" showHelpIcon />
        </Box>
        <Grid
          className={classes.itemContainer}
          item
          container
          md={12}
          wrap="wrap"
        >
          <FormControl>
            <RadioGroup
              aria-label="metric"
              name="metric_to_optimize"
              onChange={configChangeHandler}
              value={config.metric_to_optimize}
              row
            >
              <FormControlLabel value="auto" control={<Radio />} label="Auto" />
              <FormControlLabel value="f1" control={<Radio />} label="F1" />
              <FormControlLabel
                value="accuracy"
                control={<Radio />}
                label="Accuracy"
              />
              <FormControlLabel
                value="precision"
                control={<Radio />}
                label="Precision"
              />
              <FormControlLabel
                value="recall"
                control={<Radio />}
                label="Recall"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>

      <Grid className={classes.actionGroup} item container justify="center">
        <ActionButton
          onClick={applyDefault}
          size="large"
          variant="outlined"
          color="secondary"
        >
          Revert to Default
        </ActionButton>
        {/**
						 * <ActionButton size="large" variant="contained" color="secondary" className={classes.applyBtn}>
						Apply
					</ActionButton>
						 */}
      </Grid>
    </>
  );
};
