import React, { useCallback, useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { Formik, Form, Field, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import { useHistory, useParams } from 'react-router-dom';

import { validationSchema } from './validation';
import { useUploadProgress } from 'hooks/useUploadProgress';
import { ActionButton, SmallDropzone } from 'components';
import { useExpCreationContext } from 'context';
import { ExperimentValues } from 'types';

import useStyles from './step3.styles';

interface Params {
  type: string;
  task: string;
}

export const Step3 = () => {
  const classes = useStyles();
  const { type, task } = useParams<Params>();
  const { expValues, setExpProps } = useExpCreationContext();

  const { push } = useHistory();
  const { progress, uploadFile, presignedResponse } = useUploadProgress(task);

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setExpProps({
          preview: presignedResponse?.get_url,
        });
      }, 500);
    }
  }, [presignedResponse, progress, setExpProps]);

  const handleSubmit = useCallback(
    (values: ExperimentValues) => {
      setExpProps({
        ...values,
        logo: values.logo || presignedResponse?.filename,
        preview: presignedResponse?.get_url,
      });
      push(`/create/${type}/${task}/upload`);
    },
    [setExpProps, push, type, presignedResponse, task]
  );

  const imgSrc = useMemo(() => {
    if (expValues.preview) {
      return expValues.preview;
    }
    if (expValues.logo) {
      return expValues.logo;
    }
  }, [expValues]);

  const handleChange = useCallback(
    (setFieldValue, e): void => {
      setFieldValue('title', e.target.value);
      setExpProps({
        title: e.target.value,
      });
    },
    [setExpProps]
  );

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.container}
    >
      <Grid container justify="center" alignItems="center">
        <SmallDropzone
          imgSrc={imgSrc}
          dropzoneOptions={{ accept: 'image/*' }}
          title="Representative image"
          subtitle="Choose or drag an image to upload"
          onDrop={uploadFile}
          percent={progress}
        />
      </Grid>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ ...expValues, task_type: '' }}
        validationSchema={validationSchema}
      >
        {({
          values,
          isValid,
          isSubmitting,
          dirty,
          setFieldValue,
        }: FormikProps<ExperimentValues>) => (
          <Form className={classes.form}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="flex-start"
            >
              <Field
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(setFieldValue, event)
                }
                className={classes.field}
                fullWidth
                component={TextField}
                name="title"
                label="Experiment name"
                variant="outlined"
                placeholder="Name your experiment"
              />
              <Field
                className={classes.field}
                fullWidth
                component={TextField}
                name="description"
                label="Description"
                multiline
                rows={6}
                variant="outlined"
              />

              <ActionButton
                disabled={expValues.title === ''}
                variant="contained"
                type="submit"
                color="secondary"
                size="large"
                className={classes.button}
              >
                Next step
              </ActionButton>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};
