import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import useStyles from './ocrresultspanel.styles';
import { Grid } from '@material-ui/core';

export const TextResultItem = ({ text, configText }: any) => {
  const classes = useStyles();
  const elementRef = useRef<any>();

  useEffect(() => {
    if (!elementRef.current) return;

    configText === text &&
      elementRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [configText, text]);

  return (
    <Grid
      className={classnames({
        [classes.textContainer]: true,
        [classes.textHighlight]: configText === text,
      })}
      ref={elementRef}
    >
      {text}
    </Grid>
  );
};
