import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  optionTitle: {
    fontSize: '14px',
  },
  subtitle: {
    fontWeight: 'bold',
  },
  descriptionTitle: {
    marginTop: 6,
    fontSize: '12px',
    color: colors.blue20,
    textAlign: 'justify',
  },
  link: {
    margin: '4px 0',
    '& a': {
      color: colors.orange,
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },

  dropdown: {
    display: 'flex',
    gridGap: '16px',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  dropdownWrapper: {
    cursor: 'pointer',
    border: '1px solid #D8DDEB',
    borderRadius: '8px',
    maxWidth: '400px',
    padding: '10px 16px',
    position: 'relative',
    minHeight: '56px',
    display: 'flex',
    '&:hover': {
      background: '#f7f9fd',
    },
  },

  icon: {
    '& svg': {
      fill: `${colors.orange} !important`,
    },
  },

  itemsWrapper: {
    overflow: 'hidden',
    position: 'absolute',
    top: '110%',
    left: '0',
    width: '100%',
    borderRadius: '8px',
    boxShadow: '0px 16px 24px 0px rgba(72, 79, 121, 0.15)',
    border: `1px solid ${colors.blue10}`,
    background: 'white',
    zIndex: 10,
  },

  item: {
    display: 'flex',
    gridGap: '16px',
    alignItems: 'center',
    borderTop: `1px solid ${colors.blue10}`,
    padding: '18px',
    '&:hover': {
      background: '#f4f6fb',
    },
  },

  disabled: {
    '& p': {
      color: colors.blue20,
    },
  },

  dropdownDisabled: {
    opacity: 0.8,
    pointerEvents: 'none',
    background: colors.blue5,
  },
}));
