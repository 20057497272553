import * as Yup from 'yup';

export const loginValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required('You must specify a password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Must contain at least 8 characters including  upper/lowercase letters and numbers'
    )
    .max(100),
  passwordConfirm: Yup.string()
    .required('You must confirm above password')
    .oneOf([Yup.ref('password')], 'Password must match')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Must contain at least 8 characters including  upper/lowercase letters and numbers'
    )
    .max(100),
});

export const forgotPasswdvalidation = Yup.object().shape({
  email: Yup.string()
    .required('You must specify an email')
    .email('This is not a valid email')
    .max(254),
});
