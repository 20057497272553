import { Grid } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { getDatasetsByExpId, TRAINING_DATASET } from 'services/datasets';
import { Partition, PartitionQAndA } from './partitions';
import { DatasetClass, DatasetResponse, EXPERIMENT_TASK } from 'types';

interface Props {
  experimentId: string;
  task: number;
}
export const Dataset = ({ experimentId, task }: Props) => {
  const [dataset, setDataset] = useState<DatasetResponse>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setLoading(true);
    getDatasetsByExpId(experimentId)
      .then((res) => {
        if (!res.length) {
          throw new Error('There is no available dataset for this experiment');
        }
        setDataset(res[0]);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [experimentId, setLoading, setDataset]);

  const { validation, training } = useMemo(() => {
    if (dataset?.classes) {
      const validation: DatasetClass[] = [];
      const training = dataset.classes.reduce<DatasetClass[]>(
        (train, dsClass) => {
          if (dsClass && dsClass.partition === TRAINING_DATASET) {
            train.push(dsClass);
          } else {
            validation.push(dsClass);
          }
          return train;
        },
        []
      );
      return {
        validation,
        training,
      };
    }
    return {};
  }, [dataset]);

  if (loading) {
    return <>Loading</>;
  }

  if (error) {
    return <Grid container>{error}</Grid>;
  }

  if (dataset && training && validation) {
    return (
      <>
        {task === EXPERIMENT_TASK.TEXT_QANDA ? (
          <Grid container justify="center">
            <PartitionQAndA
              title="Knowledge base"
              dataset={dataset}
              partitions={training}
              type="training"
            />
          </Grid>
        ) : (
          <Grid container spacing={4}>
            <Partition
              title="Training examples"
              dataset={dataset}
              partitions={training}
              type="training"
            />
            <Partition
              title="Validation examples"
              dataset={dataset}
              partitions={validation}
              type="validation"
            />
          </Grid>
        )}
      </>
    );
  }
  return null;
};
