import React, {
  FC,
  createContext,
  useState,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { userUsage as userUsageService } from 'services/user';
import { planList, PlAN_IDS } from 'types';
import { useAuthenticationContext } from './AuthenticationContext';

type PlanModalDataProps = {
  title: string;
  description: string;
  buttonText: string;
  confirmCb(): void;
};

type UserPlanContextProps = {
  showPlanExceededModal: boolean;
  showUpgradePlanModal: boolean;
  exceedPlanModalData: PlanModalDataProps;
  canDoUpgrade: boolean;
  isKnownPlan: boolean | undefined;
  closeUpgradePlanModal(): void;
  closePlanExceededModal(): void;
  validateTrainingLimitReached(): Promise<boolean>;
  validatePredictingLimitReached(): Promise<boolean>;
  openPlanPredictionExceededModal(params: any): void;
  openPlanHasNoPermissionsModal(): void;
};

const UserPlanContext = createContext<UserPlanContextProps>({
  showPlanExceededModal: false,
  showUpgradePlanModal: false,
  exceedPlanModalData: {} as PlanModalDataProps,
  canDoUpgrade: false,
  isKnownPlan: true,
  closeUpgradePlanModal: () => {},
  closePlanExceededModal: () => {},
  validateTrainingLimitReached: () => Promise.resolve(true),
  validatePredictingLimitReached: () => Promise.resolve(true),
  openPlanPredictionExceededModal: () => {},
  openPlanHasNoPermissionsModal: () => {},
});

export const useUserPlanContext = () => useContext(UserPlanContext);

export const UserPlanContextProvider: FC<{}> = ({ children }) => {
  const { user } = useAuthenticationContext();

  const [showPlanExceededModal, setShowPlanExceededModal] = useState(false);
  const [showUpgradePlanModal, setShowUpgradePlanModal] = useState(false);
  const [exceedPlanModalData, setExceedPlanModalData] = useState<
    PlanModalDataProps
  >({
    title: '',
    description: '',
    buttonText: '',
    confirmCb: () => {},
  });

  const periodLimit = useMemo(
    () => (user ? user.commercial_plan?.limit_prediction_period : ''),
    [user]
  );

  const canDoUpgrade = useMemo(() => {
    if (!user) return false;

    const newPlans: string[] = [
      PlAN_IDS.FREE,
      PlAN_IDS.FREE_TRIAL,
      PlAN_IDS.STARTER,
      PlAN_IDS.TEAM,
      PlAN_IDS.BUSINESS,
    ];
    const isGuest = user.is_company_commercial_plan;
    return newPlans.includes(user.commercial_plan.id || '') && !isGuest;
  }, [user]);

  const isAppSumoPlan = useMemo(
    () => user?.commercial_plan?.name.startsWith('AppSumo'),
    [user]
  );

  const isKnownPlan = useMemo(
    () => planList.includes(user?.commercial_plan.id || '') || isAppSumoPlan,
    [isAppSumoPlan, user]
  );

  const validateTrainingLimitReached = async () => {
    if (!user) return true;

    const userUsage = await userUsageService();

    const trainingLimitReached =
      user.commercial_plan.limit_training_count >= 0 &&
      userUsage.trainings_in_current_period >=
        user.commercial_plan.limit_training_count;

    const trainingHoursLimitReached =
      user.commercial_plan.limit_training_hours >= 0 &&
      userUsage.training_seconds_in_period >=
        user.commercial_plan.limit_training_hours * 3600;

    if (trainingHoursLimitReached || trainingLimitReached) {
      openPlanExceededModal({
        title: 'Sorry, you have exceeded your training quota',
        description: trainingHoursLimitReached
          ? `You have reached the maximum number of training hours in the current ${periodLimit}`
          : `You have reached the maximum number of training requests in the current ${periodLimit}`,
      });
      return true;
    }

    return false;
  };

  const validatePredictingLimitReached = useCallback(async () => {
    if (!user) return false;

    const userUsage = await userUsageService();

    const isPersonalPlan = user.commercial_plan.id === PlAN_IDS.STARTER;
    const noPaidPlan = user.commercial_plan.monthly_cost <= 0;

    if (
      (isPersonalPlan || noPaidPlan) &&
      userUsage.predictions_in_current_period >=
        user.commercial_plan.limit_prediction_count
    ) {
      return true;
    }

    return false;
  }, [user]);

  const openPlanExceededModal = useCallback(
    ({ title, description, buttonText = 'Upgrade your plan' }) => {
      setExceedPlanModalData({
        title,
        description,
        buttonText,
        confirmCb: () => {
          window.location.href = '/settings/subscription';
          setShowPlanExceededModal(false);
        },
      });
      setShowPlanExceededModal(true);
    },
    []
  );

  const openPlanPredictionExceededModal = useCallback(
    (isApplication: boolean = false) => {
      if (!isApplication)
        openPlanExceededModal({
          title: 'Sorry, you have exceeded your prediction quota',
          description: `You have reached the maximum number of prediction requests in the current ${periodLimit}`,
        });
      else {
        openPlanExceededModal({
          title: '',
          description: 'Sorry, this AI model has exceeded its prediction quota',
          buttonText: '',
        });
      }
    },
    [openPlanExceededModal, periodLimit]
  );

  const openPlanHasNoPermissionsModal = useCallback(() => {
    openPlanExceededModal({
      title: '',
      description: `This model is not available in your current plan.`,
      ...(isAppSumoPlan ? { buttonText: '' } : {}),
    });
  }, [openPlanExceededModal, isAppSumoPlan]);

  const closePlanExceededModal = () => {
    setShowPlanExceededModal(false);
  };

  const closeUpgradePlanModal = () => {
    setShowUpgradePlanModal(false);
  };

  return (
    <UserPlanContext.Provider
      value={{
        showPlanExceededModal,
        showUpgradePlanModal,
        exceedPlanModalData,
        canDoUpgrade,
        isKnownPlan,
        closeUpgradePlanModal,
        closePlanExceededModal,
        validateTrainingLimitReached,
        validatePredictingLimitReached,
        openPlanPredictionExceededModal,
        openPlanHasNoPermissionsModal,
      }}
    >
      {children}
    </UserPlanContext.Provider>
  );
};
