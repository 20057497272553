import { Grid, Typography } from '@material-ui/core';
import { IconClose } from 'assets';
import { ChipTag } from 'components/ChipTag';
import React, { useMemo } from 'react';
import { EntitiesResponse, PredictResponse } from 'types';
import { splitStringIntoLabeledFragments } from 'utils';
import { ResponseBox } from './../ResponseBox';
import useStyles from './nerresponsebox.styles';
import tagData from './tagdata';

interface NerResponseBoxProps {
  textResponse: PredictResponse;
  text: string;
  closeModalResultHandler(): void;
}

export const NerResponseBox = ({
  textResponse,
  text,
  closeModalResultHandler,
}: NerResponseBoxProps) => {
  const classes = useStyles();
  const wereEntitiesFound = useMemo(() => !!textResponse?.result.length, [
    textResponse,
  ]);

  const allEntities = useMemo(
    () =>
      splitStringIntoLabeledFragments(
        textResponse.result as EntitiesResponse[],
        text
      ),
    // eslint-disable-next-line
    [textResponse]
  );

  return (
    <ResponseBox
      textResponse={textResponse}
      renderHeader={() => (
        <Grid className={classes.resultTitleWrapper}>
          <Grid></Grid>
          <Typography variant="body1" className={classes.subtitle}>
            {wereEntitiesFound && 'I found these entities:'}
          </Typography>
          <Grid
            className={classes.closeResultModal}
            onClick={closeModalResultHandler}
          >
            {wereEntitiesFound && <IconClose />}
          </Grid>
        </Grid>
      )}
      renderBody={() => (
        <>
          <Grid className={classes.badge}>
            {wereEntitiesFound ? (
              <Typography className={classes.responseText}>
                {(allEntities as EntitiesResponse[]).map(
                  ({ text, label }: EntitiesResponse, idx: number) => (
                    <ChipTag
                      text={text}
                      tag={label}
                      tooltip={tagData[label]}
                      key={idx}
                    />
                  )
                )}
              </Typography>
            ) : (
              'I could not find anything'
            )}
          </Grid>
        </>
      )}
    />
  );
};
