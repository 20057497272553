import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { EraseSearchIcon } from 'assets';
import useStyles from './toastbox.styles';

type ToastBoxProps = {
  text: string;
  onCloseHandler(): void;
};

export const ToastBox = ({ text, onCloseHandler }: ToastBoxProps) => {
  const classes = useStyles();

  return (
    <Grid className={classes.modalWrapper}>
      <Typography>{text}</Typography>
      <EraseSearchIcon
        style={{ cursor: 'pointer', paddingLeft: '8px', width: '35px' }}
        onClick={onCloseHandler}
      />
    </Grid>
  );
};
