import React, { useState } from 'react';
import {
  TableCell,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import DeleteIcon from '@material-ui/icons/Delete';

import { copyTextToClipboard, getFormatedFromUTC } from 'utils';

import { FileCopy, Visibility, VisibilityOff } from '@material-ui/icons';
import useStyle from './apikeys.styles';

interface ApiKey {
  id: string;
  alias: string;
  key: string;
  created_at: string;
  provider: number;
}

interface Props {
  apiKeys: ApiKey[];
  handleDelete: (id: string) => void;
  hideKeys?: boolean;
}

type ShowMap = {
  [key: number]: boolean;
};

export const ApiKeysTable = ({
  apiKeys,
  handleDelete,
  hideKeys = true,
}: Props) => {
  const classes = useStyle();
  const [showApiKeys, setShowApiKeys] = useState<ShowMap>({});
  const [copied, setCopied] = useState(false);

  const onCopyHandler = (apiKey: string) => {
    copyTextToClipboard(apiKey);
    setCopied(true);
  };

  return (
    <>
      <TableContainer className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Token</TableCell>
              <TableCell align="left">Created At</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {apiKeys.map(({ id, alias, key, provider, created_at }, index) => (
              <TableRow key={index}>
                <TableCell align="left">{alias}</TableCell>
                <TableCell align="left">
                  {hideKeys ? (
                    <TextField
                      value={key}
                      type={showApiKeys[index] ? 'text' : 'password'}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowApiKeys((prev) => ({
                                  ...prev,
                                  [index]: !prev[index],
                                }))
                              }
                              edge="end"
                              size="small"
                            >
                              {showApiKeys[index] ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                            <Tooltip title="Copy">
                              <IconButton
                                onClick={() => onCopyHandler(key)}
                                edge="end"
                                size="small"
                                style={{ marginLeft: 10 }}
                              >
                                <FileCopy />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <TextField
                      value={key}
                      variant="outlined"
                      fullWidth
                      disabled
                    ></TextField>
                  )}
                </TableCell>
                <TableCell align="left">
                  {getFormatedFromUTC(created_at, 'yyyy/MM/dd HH:mm:ss')}
                </TableCell>
                <TableCell>
                  {provider !== 0 && (
                    <IconButton
                      onClick={() => handleDelete(id)}
                      color="secondary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Snackbar
        open={copied}
        onClose={() => setCopied(false)}
        autoHideDuration={3000}
      >
        <MuiAlert severity="success">Copied to clipboard!</MuiAlert>
      </Snackbar>
    </>
  );
};
