import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  subtitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  badge: {
    padding: '9px 16px',
    borderRadius: 8,
    marginLeft: 8,
    fontSize: 12,
    color: colors.blue70,
    display: 'flex',
    flexWrap: 'wrap',
  },
}));
