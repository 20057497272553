import React, { useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { DefaultStep } from './StepTaskComponent/DefaultStep';
import { EXPERIMENT_TASK, PROJECT_TYPE } from 'types';
import { CustomExtractor } from './StepTaskComponent';
import { useExpCreationContext } from 'context';

interface Params {
  type: 'text' | 'image' | 'audio';
  task: string;
}

const excludeAdvancedOptionsFor = [
  EXPERIMENT_TASK.TEXT_QANDA,
  EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR,
  EXPERIMENT_TASK.IMAGE_CUSTOM_ENTITY_EXTRACTOR,
];

const extractorTasks = [
  EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR,
  EXPERIMENT_TASK.IMAGE_CUSTOM_ENTITY_EXTRACTOR,
];

export const Step4 = () => {
  const { type, task } = useParams<Params>();
  const { push } = useHistory();
  const { expValues, setExpProps } = useExpCreationContext();

  useEffect(() => {
    const noDefaultTypes = [
      PROJECT_TYPE.TEXT,
      PROJECT_TYPE.IMAGE,
      PROJECT_TYPE.AUDIO,
    ];
    if (!expValues.title) {
      // eslint-disable-next-line
      const taskDefined = noDefaultTypes.includes(parseInt(type))
        ? ''
        : 'default';

      push(`/create/${type}/${taskDefined}`);
    }
  }, [push, expValues, type]);

  return extractorTasks.includes(parseInt(task)) ? (
    <CustomExtractor
      task={task}
      type={type}
      setExpProps={setExpProps}
      expValues={expValues}
    />
  ) : (
    <DefaultStep
      task={task}
      type={type}
      expValues={expValues}
      setExpProps={setExpProps}
      showAdvancedOptions={!excludeAdvancedOptionsFor.includes(parseInt(task))}
    />
  );
};
