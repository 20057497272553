import React from 'react';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import { HelpIcon } from 'assets';
import useStyle from './propertyhelplabel.style';

export interface Props {
  property: string;
  value: string;
  tooltip: string;
}

export const PropertyHelpLabel = ({ property, value, tooltip }: Props) => {
  const classes = useStyle();
  return (
    <Grid
      container
      direction="column"
      className={classes.configurationItemContainer}
      alignItems="flex-start"
    >
      <Grid container alignItems="flex-end" className={classes.labelTitle}>
        <Typography className={classes.containerSubtitleTitle}>
          {' '}
          {property.toUpperCase()}
        </Typography>
        <Tooltip title={tooltip}>
          <HelpIcon className={classes.helpIcon} />
        </Tooltip>
      </Grid>
      <Grid>
        <Typography className={classes.containerNormalSubtitle}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};
