import React from 'react';
import { Box } from '@material-ui/core';

import useStyles from './AudioFile.styles';
import { AudioFileIcon, DeleteIcon } from 'assets';
import { Text } from 'components';
import { formatFileSize, twoDecimalsFixed } from 'utils';

type AudioFileProps = {
  size: number;
  name: string;
  progress: number;
  onDelete: () => void;
  uploadCompleted?: boolean;
};

export const AudioFile = ({
  size,
  name,
  uploadCompleted,
  progress,
  onDelete,
}: AudioFileProps) => {
  const classes = useStyles({
    progress,
  });

  return (
    <Box className={classes.audioFileWrapper}>
      <Box display="flex" justifyContent="space-between" mb="8px">
        <Box>
          <Box display="flex" gridGap="8px" alignItems="center">
            <AudioFileIcon />
            <Box>
              <Text variant="paragraph1" align="left">
                {name}
              </Text>

              <Text
                variant="paragraph1"
                align="left"
                className={classes.fileSizeText}
              >
                {formatFileSize(size)}
              </Text>
            </Box>
          </Box>
        </Box>
        <Box>
          <DeleteIcon cursor="pointer" onClick={onDelete} />
        </Box>
      </Box>

      <Box display="flex" gridGap="12px" alignItems="center">
        <Box className={classes.progressWrapper}>
          <Box className={classes.progress}></Box>
        </Box>

        <Box className={classes.progressNum}>
          <Text variant="paragraph1" align="left">
            {uploadCompleted ? '100%' : `${twoDecimalsFixed(progress)}%`}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
