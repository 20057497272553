import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import useStyles from './breadcrumb-link.styles';
import { Typography } from '@material-ui/core';

export interface BreadcrumbLinkProps {
  label: ReactNode;
  lastLink: boolean;
  link: string;
}

export const BreadcrumbLink = ({
  label,
  lastLink,
  link,
}: BreadcrumbLinkProps) => {
  const classes = useStyles();
  if (lastLink) {
    return (
      <Typography className={classes.linkText} color="primary" variant="button">
        {label}
      </Typography>
    );
  }
  return (
    <Link to={link} className={classes.link}>
      <Typography
        className={classes.linkText}
        color="secondary"
        variant="button"
      >
        {label}
      </Typography>
    </Link>
  );
};
