import React, { ChangeEvent, useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Box, IconButton } from '@material-ui/core';

import { AvatarDropzone } from 'components';
import { useUploadProgress } from 'hooks';

import useStyles from './edit-avatar.styles';

interface Props {
  name: string;
  initialValue: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  setDisplayedImage?(payload: string | undefined | null): void;
}

export const EditAvatar = ({
  name,
  initialValue,
  onChange,
  setDisplayedImage,
}: Props) => {
  const classes = useStyles();
  const { progress, uploadFile, presignedResponse } = useUploadProgress();
  const [imgSrc, setImgSrc] = useState<string | undefined>(initialValue);

  useEffect(() => {
    if (progress === 100) {
      onChange({
        target: {
          name,
          value: presignedResponse?.filename,
        },
      });
      setTimeout(() => {
        setImgSrc(presignedResponse?.get_url);
        setDisplayedImage?.(presignedResponse?.get_url);
      }, 1000);
    }
    // eslint-disable-next-line
  }, [presignedResponse, progress]);

  useEffect(() => {
    setDisplayedImage?.(initialValue);
    // eslint-disable-next-line
  }, [initialValue]);

  const handleClear = () => {
    onChange({
      target: {
        name,
        value: null,
      },
    });
    setImgSrc(undefined);
  };

  return (
    <Box>
      {imgSrc ? (
        <Box className={classes.current}>
          <Box
            style={{ backgroundImage: `url(${imgSrc})` }}
            className={classes.currentImage}
          ></Box>
          <IconButton
            aria-label="close"
            color="inherit"
            size="medium"
            onClick={handleClear}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            <CloseIcon fontSize="inherit" color="error" />
          </IconButton>
        </Box>
      ) : (
        <AvatarDropzone
          imgSrc={imgSrc}
          dropzoneOptions={{ accept: 'image/*' }}
          onDrop={uploadFile}
          percent={progress}
        />
      )}
    </Box>
  );
};
