import React, { HTMLAttributes } from 'react';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula as theme } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { PredictResponse } from 'types';

interface CodeBlockProps extends HTMLAttributes<HTMLDivElement> {
  code: any;
  language: string;
  height?: number | string;
}

export const CodeBlock = React.memo(
  ({ code, language, height = 'auto', style, ...props }: CodeBlockProps) => {
    const responseAdapter = (response: PredictResponse) => {
      const hasImage = response?.image?.base64;

      if (hasImage) {
        return {
          ...response,
          image: {
            ...response.image,
            base64: 'data:image/png;base64,...',
          },
        };
      }

      return response;
    };

    return (
      <SyntaxHighlighter
        wrapLongLines
        wrapLines
        customStyle={{
          boxSizing: 'border-box',
          width: '100%',
          height,
          padding: 16,
          margin: 0,
          borderRadius: 6,
          ...style,
        }}
        language={language}
        style={theme}
        {...props}
      >
        {JSON.stringify(responseAdapter(code), null, '\t')}
      </SyntaxHighlighter>
    );
  }
);
