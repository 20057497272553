import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Grid } from '@material-ui/core';

import { Application } from './Application';

export const Embed = ({ match: { path } }: any) => {
  return (
    <Grid>
      <Switch>
        <Route exact path={`${path}/application/:id`}>
          <Application />
        </Route>
      </Switch>
    </Grid>
  );
};
