import React, { ReactNode, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { ProjectTypeCard, Text } from 'components';
import { PROJECT_TASK, PROJECT_TASK_URL_MAP } from 'types';
import { useParams } from 'react-router-dom';
import {
  AudioTypeImg,
  DocIcon,
  ImageTypeImg,
  SheetIcon,
  TextTypeImage,
} from 'assets';
import { useSelectType } from './useSelectType';
import { useProjectCreationContext } from 'context';

import useStyles from './SelectType.styles';

const assets: Record<string, ReactNode> = {
  image: <ImageTypeImg />,
  text: <TextTypeImage />,
  audio: <AudioTypeImg />,
  document: <DocIcon />,
  spreadsheet: <SheetIcon />,
};

export const SelectType = () => {
  const { taskUrl } = useParams<{ taskUrl: string }>();

  const task = PROJECT_TASK_URL_MAP[taskUrl];

  const { typeCards } = useSelectType();

  const { resetProjectValues } = useProjectCreationContext();

  useEffect(() => {
    resetProjectValues(task);
  }, [resetProjectValues, task]);

  const classifierText =
    'Choose the data type your project will be trained to classify';

  const textsMap: { [key: number]: string } = {
    [PROJECT_TASK.CLASSIFIER_TEXT]: classifierText,
    [PROJECT_TASK.CLASSIFIER_IMAGE]: classifierText,
    [PROJECT_TASK.CLASSIFIER_AUDIO]: classifierText,
    [PROJECT_TASK.CHATBOT]:
      'Select the source of information that AI will use to respond.',
    [PROJECT_TASK.OBJECT_DETECTION]: '',
    [PROJECT_TASK.EXTRACTOR]: 'Select the data source for your smart extractor',
  };

  const classes = useStyles();

  return (
    <Box>
      <Box mb="40px">
        <Text variant="paragraph1" className={classes.cardsTitle}>
          {textsMap[task]}
        </Text>
      </Box>
      <Box display="flex" justifyContent="center" gridGap="32px">
        {typeCards[task].map(
          (
            { title, description, image, type, url, route, ...props }: any,
            idx: number
          ) => (
            <ProjectTypeCard
              key={idx}
              title={title}
              description={description}
              image={assets[image]}
              to={route || `/new-project/${taskUrl}/${type}`}
              url={url}
              {...props}
            />
          )
        )}
      </Box>
    </Box>
  );
};
