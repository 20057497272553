import React, { ReactNode, useMemo } from 'react';
import { DatasetsModal } from 'components';
import { Typography, Link, Box } from '@material-ui/core';

import { NEED_HELP_LINKS } from 'constant';
import { DatasetProps, EXPERIMENT_TASK, PROJECT_TYPE } from 'types';
import {
  textDatasets,
  imageDatasets,
  audioDatasets,
  qandaDatasets,
} from '../../step4Utils';

import useStyles from './Helpers.styles';

type HelpersProps = {
  task: string;
  type: string;
};

export const Helpers = ({ type, task }: HelpersProps) => {
  const classes = useStyles();

  const datasets: { [key: string]: DatasetProps[] } = useMemo(
    () => ({
      [EXPERIMENT_TASK.TEXT_CLASSIFICATION]: textDatasets,
      [EXPERIMENT_TASK.IMAGE_CLASSIFICATION]: imageDatasets,
      [EXPERIMENT_TASK.AUDIO_CLASSIFICATION]: audioDatasets,
      [EXPERIMENT_TASK.TEXT_QANDA]: qandaDatasets,
      [EXPERIMENT_TASK.IMAGE_OBJECT_DETECTION]: [],
    }),
    []
  );

  const helpersMap: { [key: string]: ReactNode } = {
    [PROJECT_TYPE.TEXT]: (
      <Typography className={classes.needHelp} variant="body1">
        <Link
          rel="noopener noreferrer"
          target="_blank"
          color="secondary"
          href={NEED_HELP_LINKS[task]}
        >
          Need help?
        </Link>{' '}
        Learn how to make datasets or{' '}
        <DatasetsModal
          datasets={datasets[task] || []}
          button={
            <Link
              rel="noopener noreferrer"
              target="_blank"
              color="secondary"
              className={classes.pointer}
            >
              Download an example
            </Link>
          }
        />
      </Typography>
    ),
    [PROJECT_TYPE.IMAGE]: (
      <Typography className={classes.needHelp} variant="body1">
        <Link
          rel="noopener noreferrer"
          target="_blank"
          color="secondary"
          href={NEED_HELP_LINKS[task]}
        >
          Need help?
        </Link>{' '}
        {EXPERIMENT_TASK.IMAGE_OBJECT_DETECTION !== parseInt(task) && (
          <>
            Learn how to make datasets or{' '}
            <DatasetsModal
              datasets={datasets[task]}
              button={
                <Link
                  rel="noopener noreferrer"
                  target="_blank"
                  color="secondary"
                  className={classes.pointer}
                >
                  Download an example
                </Link>
              }
            />
          </>
        )}
      </Typography>
    ),
    [PROJECT_TYPE.AUDIO]: (
      <Typography className={classes.needHelp} variant="body1">
        <Link
          rel="noopener noreferrer"
          target="_blank"
          color="secondary"
          href={NEED_HELP_LINKS[task]}
        >
          Need help?
        </Link>{' '}
        Learn how to make datasets or{' '}
        <DatasetsModal
          datasets={audioDatasets}
          button={
            <Link
              rel="noopener noreferrer"
              target="_blank"
              color="secondary"
              className={classes.pointer}
            >
              Download an example
            </Link>
          }
        />
      </Typography>
    ),
  };

  return <Box>{helpersMap[type]}</Box>;
};
