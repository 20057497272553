import { makeStyles } from '@material-ui/core';
import { ICogniflowTheme } from 'material/theme';

export default makeStyles<ICogniflowTheme>(() => ({
  editableTitle: {
    fontSize: '24px',
  },

  horizontalLine: {
    borderBottom: '1px solid black',
  },

  cardsTitle: {
    fontWeight: 'bold',
    fontSize: '16px',
  },

  topProjectsContainer: {
    backgroundColor: '#f7f9ff',
    paddingTop: '40px',
    marginTop: '40px',
  },
}));
