import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { Dropzone, EditCsvColumnModal, StyledTextLink } from 'components';

import { DropEvent, FileRejection } from 'react-dropzone';
import { InvalidCsvFileModal } from './Step4Components';
import { useUploadProgress } from 'hooks';
import {
  ExperimentLabel,
  EXPERIMENT_TASK,
  ExperimentValues,
  Payload,
} from 'types';
import { getFilename } from 'services/files';

import { useCSVColumns } from './hooks';

import useStyles from './DefaultTraining.styles';
import { zipFiles } from 'utils';

type UploadFn = <T extends File>(
  acceptedFiles: T[],
  fileRejections: FileRejection[],
  event: DropEvent
) => void;

const acceptedFilesMap: ExperimentLabel = {
  [EXPERIMENT_TASK.TEXT_CLASSIFICATION]: '.zip, .csv',
  [EXPERIMENT_TASK.TEXT_QANDA]: '.txt, .zip, .csv, .pdf, .docx, .doc',
};

type DefaultStepProps = {
  type: number;
  task: number;
  expValues: ExperimentValues;
  showAdvancedOptions?: boolean;
  setExpProps(payload: Payload): void;
  setBuildDisabledHandler(value: boolean): void;
};

export const DefaultTraning = ({
  type,
  task,
  expValues,
  setExpProps,
  setBuildDisabledHandler,
}: DefaultStepProps) => {
  const [csvCols, setCsvCols] = useState<string[]>([]);
  const datasetFile = useRef<File>();

  const classes = useStyles();

  const MAX_FILE_SIZE = task === EXPERIMENT_TASK.TEXT_QANDA ? '100MB' : '2GB';

  // disable eslint until figuring out how to handle error
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    progress,
    uploadFile,
    presignedResponse,
    // error,
    fileName,
    isFinished,
    isStarted,
  } = useUploadProgress(task);

  const {
    invalidCsvErrors,
    openEditColsModal,
    setUploadingValidationHandler,
    setOpenEditColsModalHandler,
    getCsvColNamesOptions,
    isValidCsvFile,
    saveCols,
  } = useCSVColumns({
    datasetFile: datasetFile.current,
    task,
    uploadFile,
  });

  useEffect(() => {
    if (isFinished === true && presignedResponse?.filename) {
      setExpProps({
        train_url: presignedResponse.filename,
      });
    }
    if (isStarted) {
      setExpProps({
        train_url: '',
      });
    }
  }, [progress, presignedResponse, setExpProps, isFinished, isStarted]);

  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const closeErrorModalHandler = () => {
    setErrorModalOpen(false);
  };

  useEffect(() => {
    setErrorModalOpen(!!invalidCsvErrors.logs.length);
  }, [invalidCsvErrors, setErrorModalOpen]);

  const onDropDatasetHandler = useCallback(
    async (files: File[]) => {
      const data = await isValidCsvFile(files[0]);
      const csvCols = await getCsvColNamesOptions(files[0]);

      setUploadingValidationHandler(false);

      setCsvCols(csvCols);
      datasetFile.current = files[0];

      if (data?.valid && data.file) {
        setOpenEditColsModalHandler(true);
      }
    },
    [
      isValidCsvFile,
      getCsvColNamesOptions,
      setOpenEditColsModalHandler,
      setUploadingValidationHandler,
    ]
  );

  const onDropKBFiles = useCallback(
    async (files: File[]) => {
      if (files[0].name.split('.')[1] === 'zip') {
        uploadFile(files);
        return;
      }

      const zip = await zipFiles(files, 'kbs.zip');

      uploadFile([zip]);
    },
    [uploadFile]
  );

  const openEditColumnsModal = () => {
    setOpenEditColsModalHandler(true);
  };

  const closeEditColumnsModal = () => {
    setOpenEditColsModalHandler(false);
  };

  const dropFnMap: { [key: string]: UploadFn } = {
    [EXPERIMENT_TASK.TEXT_CLASSIFICATION]: onDropDatasetHandler,
    [EXPERIMENT_TASK.TEXT_QANDA]: onDropKBFiles,
  };

  useEffect(() => {
    setBuildDisabledHandler(
      (!isStarted && !expValues.train_url) || !expValues.train_url
    );
  }, [setBuildDisabledHandler, isStarted, expValues]);

  return (
    <Box className={classes.wrapper}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="flex-start"
      >
        <Dropzone
          onDrop={dropFnMap[task] || uploadFile}
          percent={progress}
          draggingLabel="Drop your file"
          filename={fileName || getFilename(presignedResponse?.filename || '')}
          uploadType="train_url"
          accept={acceptedFilesMap[task] || '.zip'}
          maxFileSize={MAX_FILE_SIZE}
        >
          <Grid container direction="column" justify="center">
            <StyledTextLink isLinkColor={false}>
              <StyledTextLink underlined component={'span'}>
                Browse your files
              </StyledTextLink>{' '}
              or drag and drop your dataset zip file here{' '}
            </StyledTextLink>
            <Typography className={classes.uploadTitle}>
              MAX. UPLOAD {MAX_FILE_SIZE}
            </Typography>
          </Grid>
        </Dropzone>
      </Grid>
      <EditCsvColumnModal
        options={csvCols}
        open={openEditColsModal}
        closeHandler={closeEditColumnsModal}
        onSaveHandler={saveCols}
      />
      <InvalidCsvFileModal
        logs={invalidCsvErrors.logs}
        open={errorModalOpen}
        closeHandler={closeErrorModalHandler}
        editColumnsHandler={openEditColumnsModal}
      />
    </Box>
  );
};
