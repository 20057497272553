import { makeStyles } from '@material-ui/core';
import { ICogniflowTheme } from 'material/theme';
import { colors } from 'material/colors';

interface ISquareStyleProps {
  color: string;
  isWhite?: boolean;
  size: string;
}

export default makeStyles(({ matrixSizes }: ICogniflowTheme) => ({
  row: ({ size }: ISquareStyleProps) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: matrixSizes[size],
    height: matrixSizes[size],
    boxSizing: 'border-box',
    position: 'relative',
  }),
  gridBackground: ({ color }) => ({
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: color,
  }),
  squareNumber: ({ color, isWhite }) => ({
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    fontSize: '12px',
    color: isWhite ? 'white' : colors.blue70,
  }),
}));
