import * as React from 'react';
import classNames from 'classnames';
import { Editor as DraftEditor } from 'react-draft-wysiwyg';
import { convertFromRaw, EditorState, RawDraftContentState } from 'draft-js';
import { useEditorStyles } from './details.styles';

interface Props {
  content: RawDraftContentState;
  onChange: (content: RawDraftContentState) => any;
  readOnly: boolean;
  cancelled: boolean;
}

const Editor = ({ content, onChange, readOnly = false, cancelled }: Props) => {
  const [editorState, setEditorState] = React.useState(
    content.blocks
      ? EditorState.createWithContent(convertFromRaw(content))
      : EditorState.createEmpty()
  );

  React.useEffect(() => {
    if (content.blocks) {
      setEditorState(EditorState.createWithContent(convertFromRaw(content)));
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [content, cancelled]);

  const classes = useEditorStyles();
  return (
    <DraftEditor
      toolbarClassName={classes.toolbar}
      wrapperClassName={classNames(classes.wrapper, {
        [classes.editingWrapper]: !readOnly,
      })}
      editorClassName={classes.editor}
      editorState={editorState}
      onContentStateChange={onChange}
      onEditorStateChange={setEditorState}
      readOnly={readOnly}
      toolbarHidden={readOnly}
      toolbar={{
        options: [
          'inline',
          'blockType',
          'fontSize',
          'fontFamily',
          'image',
          'colorPicker',
          'list',
          'textAlign',
          'link',
        ],
        inline: {
          // className: classes.inline,
          options: [
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'monospace',
          ],
          bold: { className: 'bordered-option-classname' },
          italic: { className: 'bordered-option-classname' },
          underline: { className: 'bordered-option-classname' },
          strikethrough: { className: 'bordered-option-classname' },
          code: { className: 'bordered-option-classname' },
          fontFamily: {
            className: 'bordered-option-classname',
          },
        },
        list: {
          inDropdown: true,
          className: 'bordered-option-classname',
          component: undefined,
          dropdownClassName: 'bordered-option-classname',
          options: ['unordered', 'ordered', 'indent', 'outdent'],
          unordered: { className: 'bordered-option-classname' },
          ordered: { className: 'bordered-option-classname' },
          indent: { className: 'bordered-option-classname' },
          outdent: { className: 'bordered-option-classname' },
        },
        blockType: {
          // className: classes.inline,
        },
        fontSize: {
          // className: classes.inline,
        },

        image: {
          // className: classes.inline
          defaultSize: {
            height: 'auto',
            width: '100%',
          },
        },

        colorPicker: { className: 'bordered-option-classname' },

        textAlign: {
          dropdownClassName: 'bordered-option-classname',
          options: ['left', 'center', 'right', 'justify'],
        },

        link: {
          inDropdown: true,
          dropdownClassName: 'bordered-option-classname',
          showOpenOptionOnHover: true,
          defaultTargetOption: '_self',
          options: ['link', 'unlink'],
        },
      }}
    />
  );
};

export default React.memo(Editor);
