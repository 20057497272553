import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles((theme) => ({
  container: {
    width: 1208,
    marginTop: 40,
  },
  icon: {
    width: 140,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.light,
  },
  itemTitle: {
    width: '100%',
    padding: 0,
    marginBottom: 16,
  },
  cardHeader: {
    paddingTop: 50,
    paddingBottom: 0,
  },
  cardHover: {
    boxShadow: '0px 20px 40px 12px rgba(72, 79, 121, 0.2)',
  },
  itemContainer: {
    position: 'relative',
    width: 360,
    height: 462,
    borderRadius: 16,
  },
  text: {
    height: 72,
    padding: '0 24px',
  },
  link: {
    textDecoration: 'none',
    marginTop: 16,
    marginBottom: 16,
  },
  comingSoon: {
    marginTop: 16,
    marginBottom: 32,
  },
  iconContainer: {
    background: '#fff',
    zIndex: 2,
    padding: 28,
    borderRadius: '50%',
  },
  expIcon: {
    width: 48,
    height: 48,
  },
  avatar: {
    width: 48,
    height: 48,
    backgroundColor: '#fff',
    zIndex: 2,
    boxSizing: 'border-box',
    padding: 4,
  },
  headerBackground: {
    backgroundRepeat: 'repeat',
    position: 'absolute',
    top: -20,
    left: 0,
    height: 120,
    width: '113%',
    zIndex: 1,
    transform: 'rotate(7deg)',
  },
  footer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: '0 24px',
    position: 'absolute',
    bottom: 0,
  },
  linkDecoration: {
    fontFamily: 'PTSerif',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.5',
    letterSpacing: 'normal',
    color: colors.orange,
    marginTop: '16px',
    textDecoration: 'underline',
  },
  newFeature: {
    display: 'inline-flex',
    padding: '5px 14px',
    background: colors.orange,
    boxShadow: `0 2px 16px 0 #ff6a61a3`,
    '& p': {
      color: 'white',
    },
    borderRadius: '14px',
    position: 'absolute',
    top: '120px',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 2,
    '& *': {
      fontSize: 11,
    },
  },
}));
