import React from 'react';
import { Box } from '@material-ui/core';

import useStyles from './step1.styles';
import { PROJECT_TYPE } from 'types';

import { ImageExpLogo, TextExpLogo, AudioExpLogo } from 'assets';
import { ExperimentCard } from './ExperimentCard';

export const Step1 = () => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      // justifyContent="space-between"
      className={classes.container}
      gridGap="10px"
    >
      {/* <CreateProjectCard
        title="Chatbot"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmod."
        to="/create"
        projectTask={PROJECT_TASK.CHATBOT}
      />
      <CreateProjectCard
        title="Smart Extractor"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmod."
        to="/create"
        projectTask={PROJECT_TASK.EXTRACTOR}
      />
      <CreateProjectCard
        title="Classifier"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmod."
        to="/create"
        projectTask={PROJECT_TASK.CLASSIFIER_TEXT}
      />
      <CreateProjectCard
        title="Classifier"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elitsed do eiusmod."
        to="/create"
        projectTask={PROJECT_TASK.OBJECT_DETECTION}
      /> */}

      {/* {[PROJECT_TYPE.AUDIO, PROJECT_TYPE.IMAGE, PROJECT_TYPE.AUDIO].map(
        (e, idx) => (
          <ProjectTypeCard
            title="Text"
            description="Classify texts, analyze sentiment and more."
            projectType={e}
            key={e + 'projectTypeCard' + idx}
          />
        )
      )} */}
      {/* <ProjectTypeCard
        title="Text"
        description="Classify texts, analyze sentiment and more."
        projectType={0}
        image={<ImageTypeImg />}
      />
      <ProjectTypeCard
        title="Text"
        description="Classify texts, analyze sentiment and more."
        projectType={1}
        image={<ImageTypeImg />}
      />
      <ProjectTypeCard
        title="Text"
        description="Classify texts, analyze sentiment and more."
        projectType={2}
        image={<AudioTypeImg />}
      /> */}

      {/* <ProjectCard projectTask={2} /> */}

      <ExperimentCard
        title="Image based"
        description="Create an experiment that uses photos, images or graphics as input to learn."
        logoImgName="imgImagePattern.png"
        Icon={ImageExpLogo}
        buttonLabel="Image based experiment"
        exampleLabel="See sample image experiments"
        type={PROJECT_TYPE.IMAGE}
      />
      <ExperimentCard
        title="Text based"
        description="Create an experiment that uses text documents or spreadsheets as input to learn"
        logoImgName="imgTextPattern.png"
        Icon={TextExpLogo}
        buttonLabel="Text based experiment"
        exampleLabel="See sample text experiments"
        type={PROJECT_TYPE.TEXT}
      />
      <ExperimentCard
        title="Audio based"
        description="Create an experiment that use audio samples as input to learn."
        logoImgName="imgTextPattern.png"
        Icon={AudioExpLogo}
        buttonLabel="Audio based experiment"
        exampleLabel="See sample audio experiments"
        type={PROJECT_TYPE.AUDIO}
      />
    </Box>
  );
};
