import { atom, selector, selectorFamily } from 'recoil';
import { STATUS_PENDING, STATUS_IN_QUEUE, STATUS_FINISHED } from 'types';
import { getModels, getModel } from 'services/models';
import { sortingState } from 'pages/ViewExperiment/ViewExperimentBody/ModelSelectModal/ModelSelectModal';
import { currentExperimentState } from './experiments';
import { Model, PredictResponse } from 'types';

export const getModelById = selectorFamily({
  key: 'getCurrentModel',
  get: (modelId: string) => async ({ get }) => {
    return await getModel(modelId);
  },
});

export const modelsQueryState = atom<{ [key: string]: string }>({
  key: 'modelsQueryState',
  default: {},
});

export const getModelsState = selector<Model[]>({
  key: 'getModelsState',
  get: async ({ get }) => {
    const query = get(modelsQueryState);
    try {
      const res = await getModels(query);
      return res.models;
    } catch (error) {
      return [];
    }
  },
});

export const currentModelState = atom<Model | undefined>({
  key: 'currentModelState',
  default: undefined,
});

export const currentModelListState = atom<Model[]>({
  key: 'currentModelListState',
  default: [],
});

export const sortedModelListState = selector<Model[]>({
  key: 'sortingModelListState',
  get: ({ get }) => {
    const sortValue = get(sortingState);
    const models = get(currentModelListState) || [];
    let sortedList = [];
    switch (sortValue) {
      case 1:
        sortedList = [...models]?.sort((a, b) =>
          a.result !== null && b.result !== null
            ? a.result.accuracy < b.result.accuracy
              ? -1
              : 1
            : 0
        );
        break;
      case 2:
        sortedList = [...models].sort((a, b) =>
          a.result !== null && b.result !== null
            ? a.result.accuracy > b.result.accuracy
              ? -1
              : 1
            : 0
        );
        break;
      case 3:
        sortedList = [...models].sort((a, b) =>
          a.result !== null && b.result !== null
            ? a.result.avg_inference_time_in_sec <
              b.result.avg_inference_time_in_sec
              ? -1
              : 1
            : 0
        );
        break;
      case 4:
        sortedList = [...models].sort((a, b) =>
          a.result !== null && b.result !== null
            ? a.result.avg_inference_time_in_sec >
              b.result.avg_inference_time_in_sec
              ? -1
              : 1
            : 0
        );
        break;

      default:
        sortedList = [...models].sort((a, b) =>
          a.result !== null && b.result !== null
            ? a?.result?.accuracy > b?.result?.accuracy
              ? -1
              : 1
            : 0
        );
        break;
    }
    return sortedList;
  },
});

export const areAllModelsDoneSelector = selector<boolean>({
  key: 'areAllModelsDoneSelector',
  get: ({ get }) => {
    const models = get(currentModelListState);
    return models.every(({ status }) => status >= STATUS_FINISHED);
  },
});

export const areAllModelsPendingOrInQueueSelector = selector<boolean>({
  key: 'areAllModelsPendingOrInQueueSelector',
  get: ({ get }) => {
    const models = get(currentModelListState);
    return models.every(
      ({ status }) => status === STATUS_PENDING || status === STATUS_IN_QUEUE
    );
  },
});

export const textResultState = atom<PredictResponse | undefined>({
  key: 'textResultStateResponse',
  default: undefined,
});

interface TextCodeParam {
  modelId: string;
  text: string;
}

export const codeState = atom<TextCodeParam>({
  key: 'textResultState',
  default: {
    modelId: '',
    text: '',
  },
});

interface TestFileType {
  modelId: string;
  base64: string;
  format: string;
}
export const codeImageState = atom<TestFileType>({
  key: 'codeImageState',
  default: {
    modelId: '',
    base64: '',
    format: '',
  },
});

export const codeAudioState = atom<TestFileType>({
  key: 'codeAudioState',
  default: {
    modelId: '',
    base64: '',
    format: '',
  },
});

export const getCurrentModelState = selector<Model>({
  key: 'getCurrentModelState',
  get: ({ get }) => {
    const currentExperiment = get(currentExperimentState);
    if (currentExperiment?.id_recommended_model === '') {
      const { preprocessing } = currentExperiment;
      const isRecommended = false;
      return { ...currentExperiment.models[0], preprocessing, isRecommended };
    } else {
      const preprocessing = currentExperiment?.preprocessing;
      //Assumming that if we have and id_recommended_model, is because at least one is recommended
      const filteredModel = currentExperiment?.models.filter((m) =>
        isThisModelRecommended(currentExperiment?.id_recommended_model, m.id)
      )[0];
      const isRecommended = true;
      return { ...filteredModel, preprocessing, isRecommended };
    }
  },
});

export const collapseModelDetails = atom({
  key: 'collapseModelDetails',
  default: false,
});

const isThisModelRecommended = (
  id_recommended_model: string,
  model_id: string
): boolean => {
  return id_recommended_model === model_id;
};
