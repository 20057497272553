import React, { useMemo } from 'react';
import { Box, Link, Typography } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { EXPERIMENT_TYPE, EXPERIMENT_TASK, PROJECT_TASK } from 'types';

import {
  IconApi,
  IconExcel,
  IconGoogleSheet,
  IconZapier,
  IconStreamlit,
  IconBubbleIo,
  IconMake,
  IconIphoneShortcut,
  IconWhatsapp,
} from 'assets';

import useStyles from './integrations.styles';

export interface Integration {
  param?: string;
  name: string;
  description: string;
  url?: string;
  imageUrl: string;
}

export const Integrations = ({
  onClick,
  task,
  type,
}: {
  onClick(item: Integration): void;
  task?: number;
  type?: number;
}) => {
  const classes = useStyles();

  const handleClick = (e: any, item: Integration) => {
    if (!item.url) e.preventDefault();
    onClick(item);
  };

  const integrations = useMemo(
    () => [
      {
        param: 'whatsapp',
        name: 'WhatsApp',
        description: 'Connect your chatbot with WhatsApp.',
        imageUrl: IconWhatsapp,
        hide: PROJECT_TASK.CHATBOT !== task,
      },
      {
        param: 'api',
        name: 'API',
        description:
          'Connect this model to your custom solution using our API.',
        imageUrl: IconApi,
      },
      {
        name: 'Bubble.io',
        description:
          'Use our Bubble Plugin to connect your bubble app with this model.',
        url: 'https://docs.cogniflow.ai/en/article/bubble-integration-zsbi65/',
        imageUrl: IconBubbleIo,
      },
      {
        name: 'Zapier',
        description:
          'Integrate this model into your workflow using our Zapier App.',
        url:
          'https://zapier.com/developer/public-invite/164090/c60b9b476c8e38a9ca03afb3139e98f1/',
        imageUrl: IconZapier,
      },
      {
        name: 'Streamlit',
        description:
          'Use our Streamlit templates to create your own AI-first web app with this model and deploy it for free using Streamlit Cloud.',
        url:
          'https://www.cogniflow.ai/blog/create-an-ai-first-app-using-streamlit-and-cogniflow',
        imageUrl: IconStreamlit,
      },
      {
        name: 'Make',
        description:
          'Use this model in your workflow in Make (previously Integromat).',
        url:
          'https://www.make.com/en/hq/app-invitation/a31be3e78f67e918824906ba066606e2',
        imageUrl: IconMake,
      },
      {
        name: 'iPhone Shortcut',
        description:
          'Run this model from your iPhone Camera and save time extracting information.',
        url:
          'https://www.icloud.com/shortcuts/1a4cf28b479e4872830f1103a170cc42',
        imageUrl: IconIphoneShortcut,
        hide: !(
          type === EXPERIMENT_TYPE.IMAGE &&
          task === EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR
        ),
      },
      {
        name: 'Excel',
        description: 'Use this model from Excel using our Add-in.',
        url:
          'https://docs.cogniflow.ai/en/article/how-to-use-the-excel-integration-1j6h5x/',
        imageUrl: IconExcel,
      },
      {
        name: 'Google Sheets',
        description: 'Use this model from Google Sheets using our Add-on.',
        url:
          'https://docs.cogniflow.ai/en/article/how-to-use-the-google-sheets-integration-1s537rg/',
        imageUrl: IconGoogleSheet,
      },
    ],
    [task, type]
  );

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>Integrations</Typography>
      <Typography className={classes.subtitle}>
        Connect your Cogniflow account to external apps
      </Typography>

      <Box className={classes.integrationList}>
        {integrations.map(
          (item, idx: number) =>
            !item.hide && (
              <Link
                onClick={(e) => handleClick(e, item)}
                href={item.url}
                target="_blank"
                className={classes.integrationItem}
                key={idx}
              >
                <Box className={classes.integrationImg}>
                  <img src={item.imageUrl} alt={item.name} />
                </Box>

                <Box className={classes.integrationInfo}>
                  <Typography className={classes.integrationName}>
                    {item.name}
                  </Typography>
                  <Typography className={classes.integrationDescription}>
                    {item.description}
                  </Typography>
                </Box>

                {item.url && (
                  <OpenInNewIcon className={classes.integrationIcon} />
                )}
              </Link>
            )
        )}
      </Box>
    </Box>
  );
};
