import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

const SPACING = 24;

export default makeStyles(() => ({
  resultWrapper: {},
  text: {
    padding: SPACING,
  },
  title: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: colors.blue70,
  },
  subtitle: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.blue70,
  },
  predictionWrapper: {
    padding: SPACING,
  },
}));
