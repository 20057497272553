import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 40px 40px',
  },
  userFormTitle: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 600,
    fontStretch: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: colors.blue50,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
  fieldContainer: {
    marginTop: '12px',
    marginBottom: '12px',
  },
  inputField: {
    marginTop: '12px',
    '& input': {
      fontFamily: 'Poppins',
    },
    '& label': {
      fontFamily: 'Poppins',
      fontSize: '14px',
    },
  },
  forgotPasswordLink: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: colors.orange,
    alignSelf: 'flex-end',
    marginBottom: '24px',
    cursor: 'pointer',
    textDecoration: 'underline',
    marginTop: '12px',
  },
  signinwith: {
    margin: '30px 0 32px',
    paddingTop: '12px',
  },
  loginButton: {
    '& div': {
      borderRadius: '8px !important',
    },

    '& span': {
      color: `${colors.orange}`,
      fontWeight: 500,
      fontFamily: 'Poppins',
      fontSize: '14px',
    },
  },
}));
