import React, { FC } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import { ActionButton, SubtitleHeaderMisc } from 'components';
import { ExperimentLabel, EXPERIMENT_TASK } from 'types';

import useStyles from '../accordionadvancedconfig.styles';

const vectorizersTextMap: ExperimentLabel = {
  [EXPERIMENT_TASK.IMAGE_OBJECT_DETECTION]: 'Backbones',
};

const classifiersTextMap: ExperimentLabel = {
  [EXPERIMENT_TASK.IMAGE_OBJECT_DETECTION]: 'Detectors',
};

export interface ImageConfigProps {
  config: any;
  type: string;
  task: string;
  configChangeHandler: any;
  applyDefault(): void;
}

export const ImageConfig: FC<ImageConfigProps> = ({
  configChangeHandler,
  config,
  task,
  applyDefault,
}) => {
  const classes = useStyles();
  return (
    <>
      <Grid item container direction="column">
        <Box className={classes.subTitleHeader}>
          <SubtitleHeaderMisc title="Preprocessing" showHelpIcon />
        </Box>
        <Grid
          className={classes.itemContainer}
          item
          container
          md={12}
          wrap="wrap"
        >
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.daug_rotation}
                  value={config.daug_rotation}
                  onChange={configChangeHandler}
                  name="daug_rotation"
                />
              }
              label="Random rotation"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={config.daug_brightness || false}
                    onChange={configChangeHandler}
                    name="daug_brightness"
                  />
                }
                label="Random brightness"
              />
            </>
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.daug_vertical_flip}
                  onChange={configChangeHandler}
                  name="daug_vertical_flip"
                />
              }
              label="Vertical flip"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.daug_horizontal_flip}
                  onChange={configChangeHandler}
                  name="daug_horizontal_flip"
                />
              }
              label="Horizontal flip"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.daug_zoom}
                  onChange={configChangeHandler}
                  name="daug_zoom"
                />
              }
              label="Random zoom"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container direction="column">
        <Box className={classes.subTitleHeader}>
          <SubtitleHeaderMisc
            title={vectorizersTextMap[task] || 'Vectorizer'}
            showHelpIcon
          />
        </Box>
        <Grid
          className={classes.itemContainer}
          item
          container
          md={12}
          wrap="wrap"
        >
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.cnn_small}
                  name="cnn_small"
                  onChange={configChangeHandler}
                />
              }
              label="Small CNN"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.cnn_inceptionv3}
                  name="cnn_inceptionv3"
                  onChange={configChangeHandler}
                />
              }
              label="InceptionV3"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.cnn_inception_resnetv2}
                  name="cnn_inception_resnetv2"
                  onChange={configChangeHandler}
                />
              }
              label="InceptionResnetV2"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.cnn_mobilenetv2}
                  name="cnn_mobilenetv2"
                  onChange={configChangeHandler}
                />
              }
              label="MobileNetV2"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.cnn_xception}
                  name="cnn_xception"
                  onChange={configChangeHandler}
                />
              }
              label="Xception"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start"></Grid>
          <Grid item container md={6} alignItems="flex-start"></Grid>
        </Grid>
      </Grid>
      <Grid item container direction="column">
        <Box className={classes.subTitleHeader}>
          <SubtitleHeaderMisc
            title={classifiersTextMap[task] || 'Classifiers'}
            showHelpIcon
          />
        </Box>
        <Grid
          className={classes.itemContainer}
          item
          container
          md={12}
          wrap="wrap"
        >
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.algo_logistic_regression}
                  name="algo_logistic_regression"
                  onChange={configChangeHandler}
                />
              }
              label="Logistic Regression"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.algo_linear_sgd}
                  name="algo_linear_sgd"
                  onChange={configChangeHandler}
                />
              }
              label="Linear Classifier with SGD training"
            />
          </Grid>

          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.algo_ffnn}
                  name="algo_ffnn"
                  onChange={configChangeHandler}
                />
              }
              label="Feed Forward Neural Network (FFNN)"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  checked={config.algo_svc_rbf}
                  name="algo_svc_rbf"
                  onChange={configChangeHandler}
                />
              }
              label="SVC (with RBF kernel)"
            />
          </Grid>
          <Grid item container md={6} alignItems="flex-start"></Grid>
        </Grid>
      </Grid>

      <Grid item container direction="column">
        <Box className={classes.subTitleHeader}>
          <SubtitleHeaderMisc title="Metric to optimize" showHelpIcon />
        </Box>
        <Grid
          className={classes.itemContainer}
          item
          container
          md={12}
          wrap="wrap"
        >
          <FormControl>
            <RadioGroup
              aria-label="metric"
              name="metric_to_optimize"
              onChange={configChangeHandler}
              value={config.metric_to_optimize}
              row
            >
              <FormControlLabel value="auto" control={<Radio />} label="Auto" />
              <FormControlLabel value="f1" control={<Radio />} label="F1" />
              <FormControlLabel
                value="accuracy"
                control={<Radio />}
                label="Accuracy"
              />
              <FormControlLabel
                value="precision"
                control={<Radio />}
                label="Precision"
              />
              <FormControlLabel
                value="recall"
                control={<Radio />}
                label="Recall"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>

      <Grid className={classes.actionGroup} item container justify="center">
        <ActionButton
          onClick={applyDefault}
          size="large"
          variant="outlined"
          color="secondary"
        >
          Revert to Default
        </ActionButton>
        {/**
						 * <ActionButton size="large" variant="contained" color="secondary" className={classes.applyBtn}>
						Apply
					</ActionButton>
						 */}
      </Grid>
    </>
  );
};
