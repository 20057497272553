import { Chip } from '@material-ui/core';
import React from 'react';
import {
  STATUS_CANCELED_BY_TIMEOUT,
  STATUS_CANCELED_BY_USER,
  STATUS_FINISHED_WITH_ERROR,
  STATUS_IN_QUEUE,
  STATUS_PENDING,
  STATUS_RUNNING,
} from 'types';
import { CheckIcon } from 'assets';
import useStyles from './experiment-status.styles';

export interface ESTProps {
  status: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  progress?: number;
}

export const ExperimentStatusTag = ({ status, progress = 0 }: ESTProps) => {
  const classes = useStyles();

  if ([STATUS_PENDING, STATUS_IN_QUEUE].includes(status)) {
    return <Chip className={classes.pending} label="Waiting" />;
  }

  if (status === STATUS_RUNNING) {
    return (
      <div className={classes.progressContainer}>
        <Chip className={classes.running} label={`${progress?.toString()}%`} />
        <span
          className={classes.progressbar}
          style={{ width: `${progress}%` }}
        />
      </div>
    );
  }

  if (status === STATUS_FINISHED_WITH_ERROR) {
    return <Chip className={classes.error} label="Error" />;
  }

  if ([STATUS_CANCELED_BY_USER, STATUS_CANCELED_BY_TIMEOUT].includes(status)) {
    return <Chip className={classes.error} label="Canceled" />;
  }

  return <Chip label="Ready" icon={<CheckIcon />} color="primary" />;
};
