export const dashboardCards = {
  dashboard_left_cards: [
    {
      title: 'First',
      description: 'This is the First card',
      url: '',
      img_url: '',
    },
    {
      title: 'Second',
      description: 'This is the Second card',
      url: '',
      img_url: '',
    },
    {
      title: 'Third',
      description: 'This is the Third card',
      url: '',
      img_url: '',
    },
  ],

  dashboard_right_cards: [
    {
      title: 'First',
      description: 'This is the First card',
      url: '',
      img_url: '',
    },
    {
      title: 'Second',
      description: 'This is the Second card',
      url: '',
      img_url: '',
    },
    {
      title: 'Third',
      description: 'This is the Third card',
      url: '',
      img_url: '',
    },
  ],

  featured_projects: [
    {
      id: '35e2415f-9c6d-4462-a9ce-1b9be14bd9a6',
      status: 3,
      type: 0,
      task: 3,
      title: 'Test 3 - transcription medicos',
      description: '',
      created_by: 'Kevin',
      logo: null,
      visibility: 3,
    },
    {
      id: 'c3ffee01-2351-45af-cc2a-dd1084bbc341',
      status: 3,
      type: 1,
      task: 101,
      title: 'Object Detection for Common Objects',
      description:
        'YOLOv5: model for Object Detection pretrained on COCO dataset (see: https://github.com/ultralytics/yolov5).',
      created_by: 'Cogniflow AI',
      deleted_at: null,
      logo: '',
      visibility: 1,
    },
    {
      id: '55a03539-5624-5353-d5b7-966a8ab20ea9',
      status: 3,
      type: 0,
      task: 0,
      title: 'Multilingual Sentiment Analysis',
      description:
        'This a multilingual model for sentiment analysis in six languages: English, Dutch, German, French, Spanish and Italian. It predicts the sentiment of the review as positive, negative, or neutral.',
      created_by: 'Cogniflow AI',
      deleted_at: null,
      logo:
        'logo/55a03539-5624-5353-d5b7-966a8ab20ea9351ebbb2-53bb-11ec-a244-228d3b4c4914.png',
      visibility: 1,
    },
    {
      id: '4f560592-dad2-47d7-b3a2-12aded2e80a2',
      status: 3,
      type: 1,
      task: 103,
      title: 'Face Similarity',
      description:
        'FaceNet directly learns a mapping from face images to a compact Euclidean space where distances directly correspond to a measure of face similarity. Once this space has been produced, tasks such as face recognition, verification and clustering can be easily implemented using standard techniques with FaceNet embeddings as feature vectors. Ref: FaceNet: A Unified Embedding for Face Recognition and Clustering (see: https://arxiv.org/abs/1503.03832)',
      created_by: 'Cogniflow AI',
      deleted_at: null,
      logo: '',
      visibility: 1,
    },
  ],
};

export const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
