import { useCallback, useState } from 'react';

import {
  textInitialConfig,
  imageInitialConfig,
  audioInitialConfig,
} from 'core';

import {
  PROJECT_TYPE,
  AdvancedConfig,
  TextConfig as TextConfigDefault,
  ImageConfig as ImageConfigDefault,
  AudioConfig as AudioConfigDefault,
} from 'types';
import { useProjectCreationContext } from 'context';

type UseProjectConfigurationProps = {
  type: number;
};

export const useProjectConfiguration = ({
  type,
}: UseProjectConfigurationProps) => {
  const initialConfig: { [key: string]: AdvancedConfig } = {
    [PROJECT_TYPE.TEXT]: textInitialConfig as TextConfigDefault,
    [PROJECT_TYPE.IMAGE]: imageInitialConfig as ImageConfigDefault,
    [PROJECT_TYPE.AUDIO]: audioInitialConfig as AudioConfigDefault,
  };

  const { expValues, setProjectProps } = useProjectCreationContext();

  const [showingTag, setShowingTag] = useState(true);

  const [config, setConfig] = useState<AdvancedConfig>(initialConfig[type]);

  const setConfigHandler = useCallback(
    (values: AdvancedConfig) => {
      setConfig(values);

      setProjectProps({
        config: {
          ...(expValues.config as { [key: string]: boolean | string }),
          ...(values as { [key: string]: boolean | string }),
        },
      });
    },
    [setProjectProps]
  );

  const setShowingTagHandler = (value: boolean) => {
    setShowingTag(value);
  };

  const configChangeHandler = useCallback(
    (e) => {
      let copyConfig;
      const { name, type: typeE, value } = e.target;
      switch (type) {
        case PROJECT_TYPE.IMAGE:
          copyConfig = { ...config };
          copyConfig[name] = typeE === 'radio' ? value : !copyConfig[name];
          setConfigHandler(copyConfig);
          setShowingTag(false);
          break;
        case PROJECT_TYPE.TEXT:
          copyConfig = { ...config };
          copyConfig[name] = typeE === 'radio' ? value : !copyConfig[name];

          setConfigHandler(copyConfig);
          setShowingTag(false);
          break;
        case PROJECT_TYPE.AUDIO:
          copyConfig = { ...config };
          if (name === 'data_augementation') {
            copyConfig['daug_rotation'] = !copyConfig.daug_rotation;
            copyConfig['daug_brightness'] = !copyConfig.daug_brightness;
            copyConfig['daug_zoom'] = !copyConfig.daug_zoom;
            copyConfig[
              'daug_horizontal_flip'
            ] = !copyConfig.daug_horizontal_flip;
            copyConfig['daug_vertical_flip'] = !copyConfig.daug_vertical_flip;
          }

          copyConfig[name] = typeE === 'radio' ? value : !copyConfig[name];
          if (copyConfig['data_augementation'])
            delete copyConfig['data_augementation'];
          setConfigHandler(copyConfig);
          setShowingTag(false);
          break;
        default:
          copyConfig = { ...config };
          copyConfig[name] = typeE === 'radio' ? value : !copyConfig[name];
          setConfig(copyConfig);
          setShowingTag(false);
          break;
      }
    },
    [type, setShowingTag, config, setConfigHandler]
  );

  return {
    initialConfig,
    config,
    showingTag,
    setConfigHandler,
    setShowingTagHandler,
    configChangeHandler,
  };
};
