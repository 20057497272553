import React from 'react';
import { Box } from '@material-ui/core';
import {
  DefaultFileIcon,
  PDFFileIcon,
  TxtFileIcon,
  DocFileIcon,
  DeleteIcon,
} from 'assets';
import { ProgressBar, Text } from 'components';

import useStyles from './Doc.style';
import { Doc as DocType } from '../documents.types';

interface Props extends DocType {
  onDelete: (name: string) => void;
}

const STATUS_COLOR_MAP = {
  Active: '#161616',
  Pending: '#878787',
  Completed: '#389738',
  Failed: '#ff1919',
};

export const Doc = ({
  id,
  name,
  type,
  size,
  status,
  progress,
  onDelete,
}: Props) => {
  const classes = useStyles();
  const extension = name.split('.').pop()?.toLowerCase() || '';
  const iconMap: { [key: string]: JSX.Element } = {
    pdf: <PDFFileIcon />,
    txt: <TxtFileIcon />,
    doc: <DocFileIcon />,
    docx: <DocFileIcon />,
  };
  const icon = React.cloneElement(iconMap[extension] || <DefaultFileIcon />, {
    width: 42,
    height: 42,
  });

  const deleteHandler = () => {
    if (!canDelete) return;
    onDelete(id);
  };

  const canDelete = ['Active', 'Completed', 'Failed'].includes(status);

  return (
    <Box
      display="grid"
      gridGap="6%"
      gridTemplateColumns="auto 100px 32px"
      className={classes.docWrapper}
    >
      <Box display="flex" gridGap="32px" className={classes.info}>
        {icon}

        <Box
          display="flex"
          flexDirection="column"
          gridGap="8px"
          justifyContent="center"
          overflow="hidden"
        >
          <Text variant="paragraph1" className={classes.docName} title={name}>
            {name}
          </Text>
          <Box display="flex">
            <Text variant="paragraph1">
              {type && <span className={classes.tag}>{type}</span>}
              {size != null && (
                <span className={classes.docSize}>{`${(
                  size /
                  1024 /
                  1024
                ).toFixed(2)} MB`}</span>
              )}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" className={classes.progress}>
        {status === 'Uploading' ? (
          <ProgressBar progress={progress} />
        ) : (
          <Box
            style={{ backgroundColor: STATUS_COLOR_MAP[status] }}
            className={classes.statusWrapper}
          >
            <Text variant="paragraph1">{status}</Text>
          </Box>
        )}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        className={classes.deleteButton}
        justifyContent="flex-end"
      >
        {canDelete && <DeleteIcon onClick={deleteHandler} />}
      </Box>
    </Box>
  );
};
