import { makeStyles } from '@material-ui/core';
import { ICogniflowTheme } from 'material/theme';

interface StyleProps {
  size: string;
}

export default makeStyles(({ colors, matrixSizes }: ICogniflowTheme) => ({
  flexElement: {
    display: 'flex',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  UpTitle: {
    textAlign: 'center',
    height: '30px',
    fontWeight: 'bold',
    fontFamily: 'Poppins',
  },
  leftHand: {
    display: 'flex',
    marginTop: '30px',
  },
  leftTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  leftTitle: {
    transform: 'rotate(-90deg)',
    fontWeight: 'bold',
    fontFamily: 'Poppins',
  },
  leftLabels: {
    justifyContent: 'flex-end',
  },
  leftLabelItem: ({ size }: StyleProps) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'right',
    padding: '0 1rem',
    height: matrixSizes[size],
  }),
  rightHand: { padding: 0 },
  data: {},
  downLabelContainer: {
    display: 'flex',
  },
  downLabelItem: ({ size }: StyleProps) => ({
    display: 'flex',
    justifyContent: 'center',
    width: matrixSizes[size],
    padding: '16px 0',
    textAlign: 'end',
  }),
  column: {
    display: 'flex',
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50px',
    height: '50px',
    border: '1px solid black',
    boxSizing: 'border-box',
  },
  label: {
    fontFamily: 'PTSerif',
    fontSize: '14px',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    color: colors.blue70,
  },
  smallLabel: {
    fontFamily: 'PTSerif',
    fontSize: '10px',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    color: colors.blue70,
  },
  verticalAlignment: {
    transform: 'rotate(-180deg)',
    writingMode: 'vertical-lr',
  },
}));
