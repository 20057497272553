import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { DeleteIcon2 } from 'assets';
import { GenericModalState } from 'components/GenericModal/GenericModal';
import { SubtitleHeaderMisc } from 'components/Title/SubtitleHeader';
import { ExperimentLabel, EXPERIMENT_TASK, STATUS_FINISHED } from 'types';
import { deleteModel } from 'services/models';
import { CustomTag } from './CustomTag';
import StarIcon from '@material-ui/icons/Star';
import { currentModelListState, sortedModelListState } from 'store/models';
import { UseModelIcon } from 'assets';
import useStyle from './datatable.styles';
import { useAuthenticationContext } from 'context';
import { currentExperimentState } from 'store/experiments';

const accuracyTextMap: ExperimentLabel = {
  [EXPERIMENT_TASK.IMAGE_OBJECT_DETECTION]: 'mAP score',
};

export const DataTable = () => {
  const classes = useStyle();
  const { push } = useHistory();
  const [confirmModel, setConfirmModel] = useState('');
  const models = useRecoilValue(sortedModelListState);
  const setModels = useSetRecoilState(currentModelListState);
  const setModalState = useSetRecoilState(GenericModalState);
  const experiment = useRecoilValue(currentExperimentState);
  const { user } = useAuthenticationContext();

  const removeModel = useCallback(
    (modelId) => {
      if (modelId) {
        deleteModel(modelId).then(() => {
          const updatedModel = models?.filter((m) => m.id !== modelId) || [];
          setModels([...updatedModel]);
          setConfirmModel('');
          window.location.reload();
        });
      }
    },
    [setModels, models]
  );

  const selectModel = useCallback(
    (modelId) => {
      if (modelId) {
        push(`/experiment/${models[0].id_experiment}/${modelId}`);
        setModalState(false);
      }
    },
    [push, setModalState, models]
  );

  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>
            <SubtitleHeaderMisc showHelpIcon={false} title="Model Name" />
          </TableCell>
          <TableCell align="left">
            <SubtitleHeaderMisc
              showHelpIcon
              title={accuracyTextMap[experiment?.task || 0] || 'Accuracy Score'}
            />
          </TableCell>
          <TableCell align="left">
            <SubtitleHeaderMisc showHelpIcon title="AVG. INFERENCE TIME" />
          </TableCell>
          <TableCell align="left">
            <SubtitleHeaderMisc showHelpIcon title="Status" />
          </TableCell>
          <TableCell colSpan={3} align="left"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {models.map((row, index) =>
          confirmModel !== '' && confirmModel === row.id ? (
            <TableRow>
              <TableCell
                classes={{ root: classes.confirmMessageBg }}
                colSpan={5}
                component="th"
                scope="row"
              >
                <Grid md={12} item container justify="space-between">
                  <Grid md={7} item container direction="column">
                    <Typography className={classes.confirmDeleteTitle}>
                      Delete This model?
                    </Typography>
                    <Typography className={classes.confirmDeleteSubTitle}>
                      Caution, this cannot be undone!!!
                    </Typography>
                  </Grid>
                  <Grid md={5} item container justify="flex-end">
                    <Button
                      onClick={() => removeModel(row.id)}
                      variant="contained"
                      color="secondary"
                      size="large"
                    >
                      Delete
                    </Button>
                    <Box ml={2}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => setConfirmModel('')}
                        size="large"
                      >
                        No, keep
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ) : (
            <TableRow hover classes={{ hover: classes.hoverRow }} key={row.id}>
              <TableCell width={180} align="left" component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="left">
                {row.result?.accuracy && row.result?.accuracy >= 0
                  ? Math.round(row.result?.accuracy * 100) / 100
                  : '-'}
              </TableCell>
              <TableCell align="left">
                {row.result?.avg_inference_time_in_sec &&
                row.result?.avg_inference_time_in_sec >= 0
                  ? Math.round(row.result?.avg_inference_time_in_sec * 100) /
                    100
                  : '-'}
              </TableCell>
              <TableCell align="left">
                <CustomTag
                  modelId={row.id}
                  experimentId={row.id_experiment}
                  model={row}
                />
              </TableCell>
              <TableCell align="left" width={110}>
                <Grid item container justify="flex-start" alignItems="center">
                  {row.status >= STATUS_FINISHED ? (
                    <IconButton
                      style={{ padding: '6px' }}
                      color="secondary"
                      onClick={() => setConfirmModel(row.id)}
                    >
                      {experiment?.id_user === user?.id && <DeleteIcon2 />}
                    </IconButton>
                  ) : null}
                  <IconButton
                    style={{
                      padding: '6px',
                      marginLeft: row.status < STATUS_FINISHED ? '38px' : '0px',
                    }}
                    color="secondary"
                    onClick={() => selectModel(row.id)}
                  >
                    <UseModelIcon />
                  </IconButton>
                  {row.isRecommended && (
                    <Tooltip title="This is the automatically recommended model">
                      <StarIcon className={classes.starIcon} />
                    </Tooltip>
                  )}
                </Grid>
              </TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  );
};
