import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { CreateProjectCard, ProjectCard, Text } from 'components';
import {
  Experiment,
  PROJECT_TASK,
  PROJECT_TASK_URL,
  PROJECT_TYPE_URL,
} from 'types';

import useStyles from './SelectTask.styles';

import { getJSONDashboardCards } from 'services';

const projectCards = [
  {
    title: 'Chatbot',
    description:
      'Build a conversational agent for engaging, answering questions, and facilitating interactions in natural language.',
    to: '/create',
    projectTask: PROJECT_TASK.CHATBOT,
    urlText: PROJECT_TASK_URL.CHATBOT,
  },

  {
    title: 'Smart Extractor',
    description:
      'Extract specific information from texts or images by defining the entities to extract using natural language',
    to: '/create',
    projectTask: PROJECT_TASK.EXTRACTOR,
    urlText: PROJECT_TASK_URL.EXTRACTOR,
  },

  {
    title: 'Classifier',
    description: `Train a model that helps to classify data, whether it's in the form of text, images, or audio.`,
    to: '/create',
    projectTask: PROJECT_TASK.CLASSIFIER_TEXT,
    urlText: PROJECT_TASK_URL.CLASSIFIER,
  },
  {
    title: 'Object Detection',
    description:
      'Create a project that uses photos, images, or graphs as input to learn how to locate and categorize different types of objects in them.',
    to: '/create',
    projectTask: PROJECT_TASK.OBJECT_DETECTION,
    urlText: PROJECT_TASK_URL.OBJECT_DETECTION,
  },
];

export const SelectTask = ({ match: { path } }: any) => {
  const [topProjects, setTopProjects] = useState([]);

  // eslint-disable-next-line
  const classes = useStyles();

  useEffect(() => {
    getJSONDashboardCards().then((data) => {
      setTopProjects(data.featured_projects);
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        maxWidth="400px"
        mx="auto"
        mb="40px"
      >
        <Box>
          <Text variant="paragraph1" className={classes.cardsTitle}>
            Select the type of project
          </Text>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" gridGap="8px" flexWrap="wrap">
        {projectCards.map((cardProps) => {
          const to =
            cardProps.urlText !== PROJECT_TASK_URL.OBJECT_DETECTION
              ? `${cardProps.urlText}`
              : `${cardProps.urlText}/${PROJECT_TYPE_URL.IMAGE}`;

          return (
            <CreateProjectCard
              key={cardProps.projectTask}
              {...cardProps}
              to={`${path}/${to}`}
            />
          );
        })}
      </Box>

      <Box className={classes.topProjectsContainer}>
        <Box mb="40px">
          <Box>
            <Text variant="paragraph1" className={classes.cardsTitle}>
              Or try our ready-to-use top AI projects
            </Text>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          gridGap="8px"
          flexWrap="wrap"
          pb="40px"
        >
          {topProjects.map((project: Experiment, idx) => (
            <Box width="305px" key={project.id}>
              <ProjectCard
                {...project}
                projectTask={project.task}
                showCreatedBy={false}
                key={idx}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
