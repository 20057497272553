import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  container: {
    maxWidth: 1400,
    margin: '48px auto',
  },
  stepper: {
    width: 576,
    padding: 0,
    marginTop: 32,
  },
}));
