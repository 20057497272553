import React, { useEffect, useCallback, useRef } from 'react';
import { Grid, Button } from '@material-ui/core';
import WaveSurfer from 'wavesurfer.js';
import useStyles from './audiocapture.styles';
interface Props {
  audioFile: string;
}

export const AudioWave = React.memo(({ audioFile }: Props) => {
  const classes = useStyles();
  let wave = useRef<WaveSurfer | null>();
  const waveRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (waveRef.current !== null && audioFile) {
      wave.current = WaveSurfer.create({
        container: waveRef?.current,
        barWidth: 3,
        cursorWidth: 1,
        height: 80,
        progressColor: '#2D5BFF',
        responsive: true,
        waveColor: '#484f79',
        cursorColor: 'transparent',
      });
      wave.current.load(audioFile);
    }

    return () => {
      if (null !== wave?.current) {
        wave.current && wave.current.destroy();
      }
    };
  }, [audioFile]);

  const handlePlay = useCallback(() => {
    if (wave && wave.current) {
      wave.current.playPause();
    }
  }, [wave]);

  return (
    <Grid container item xs={12}>
      <Grid item ref={waveRef} className={classes.wavediv}></Grid>
      <Grid container item justify="center" alignItems="center">
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={handlePlay}
        >
          Play/Pause
        </Button>
      </Grid>
    </Grid>
  );
});
