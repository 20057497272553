import { makeStyles } from '@material-ui/core';
import { ICogniflowTheme } from 'material/theme';

export default makeStyles<ICogniflowTheme>(() => ({
  title: {
    fontWeight: 'bold',
    fontSize: '16px',
    textAlign: 'left',
  },
  modalContentWrapper: {
    position: 'relative',
    padding: '40px',
    height: 400,
    width: 475,
    maxHeight: '100vh',
    maxWidth: '100vw',
    background: '#fff',
    borderRadius: 6,
    overflow: 'hidden',

    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
  },

  uploadingModal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  error: {
    '& span': {
      fontSize: '14px',
      fontFamily: 'Poppins',
    },
    // color: colors.errorRed,
  },
}));
