import React, { useMemo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { EditableTitle } from './EditableTitle';
import useStyles from './view-experiment-header.styles';
import { InfoWithCaption } from 'components/InfoWithCaption';
import { ExperimentStatusTag } from 'components/ExperimentStatusTag';
import { getFriendlyDate } from 'services/experiments';
import {
  Experiment,
  EXPERIMENT_TASK_LABEL,
  EXPERIMENT_VISIBILITY_LABEL,
  EXPERIMENT_TYPE_LABEL,
} from 'types';
import { useAuthenticationContext } from 'context';
import { SettingsIcon } from 'assets';
import { UnControlledModal } from 'components';
import { ExperimentSettings } from './ExperimentSettings';

interface Props {
  experiment: Experiment;
}

export const LoadableHeader = React.memo(({ experiment }: Props) => {
  const { user } = useAuthenticationContext();
  const classes = useStyles();
  const date = React.useMemo(() => {
    if (experiment) {
      const { created_at } = experiment;
      return getFriendlyDate(created_at || '');
    }
  }, [experiment]);

  const typeLabel = React.useMemo(
    () => EXPERIMENT_TYPE_LABEL[experiment?.type] || '',
    [experiment]
  );

  const { status } = experiment;
  const experimentTask = useMemo(
    () => EXPERIMENT_TASK_LABEL[experiment?.task] || 'Classification',
    [experiment]
  );

  return (
    <Grid container direction="column">
      <Grid container alignItems="center">
        <EditableTitle experiment={experiment} />
      </Grid>
      <Grid container alignItems="center" justify="space-between">
        <Grid item xs={12} sm={7} container alignItems="center" wrap="nowrap">
          <Grid item xs={4} container justify="flex-start">
            <InfoWithCaption
              caption="Created by"
              label={experiment.created_by}
            />
          </Grid>

          <Grid item xs={4} container justify="flex-start">
            <InfoWithCaption caption="Created" label={`${date} ago`} />
          </Grid>
          <Grid item xs={4} container justify="flex-start">
            <InfoWithCaption caption="Experiment Type" label={typeLabel} />
          </Grid>
          <Grid item xs={4} container justify="flex-start">
            <InfoWithCaption caption="Task" label={experimentTask} />
          </Grid>

          <Grid item xs={4} container justify="flex-start">
            <InfoWithCaption
              caption="Visibility"
              label={EXPERIMENT_VISIBILITY_LABEL[experiment.visibility]}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          container
          justify="flex-end"
          alignItems="center"
          className={classes.expConfigContainer}
        >
          <Typography className={classes.statusCaption} variant="caption">
            Status
          </Typography>
          <Grid style={{ position: 'relative' }}>
            <ExperimentStatusTag
              status={status}
              progress={experiment.progress}
            />
          </Grid>
          {experiment?.id_user === user?.id && (
            <UnControlledModal
              size="xs"
              openModalButton={
                <SettingsIcon
                  title="Settings"
                  style={{
                    cursor: 'pointer',
                    fill: '#ff6a61',
                  }}
                />
              }
            >
              {({ closeHandler }) => (
                <ExperimentSettings
                  experiment={experiment}
                  closeSettingsModal={closeHandler}
                />
              )}
            </UnControlledModal>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
});
