import React, { FC, useCallback, useEffect, useState } from 'react';
import { Box, Link } from '@material-ui/core';
import { EntityProps, Payload, ExperimentValues } from 'types';

import { Extractor, Text, ChipButton } from 'components';
// import { getExtractorConfig } from 'services';

import { SchoolOutlined } from '@material-ui/icons';

export interface CustomExtractorProps {
  task: number;
  type: number;
  setExpProps(payload: Payload): void;
  expValues: ExperimentValues;
  setBuildDisabledHandler(value: boolean): void;
}

export const ExtractorTraining: FC<CustomExtractorProps> = ({
  expValues,
  setExpProps,
  setBuildDisabledHandler,
}) => {
  // useEffect(() => {
  //   getExtractorConfig().then((res) => {
  //     setExpProps({
  //       config: {
  //         ...res,
  //         entities: expValues.config.entities as EntityProps[],
  //       },
  //     });
  //   });
  // }, []);

  const [disableNextStep, setDisableNextStep] = useState(
    !expValues.config.entities ||
      (expValues.config.entities as EntityProps[]).length === 0
  );

  const onEntitiesChange = useCallback(
    (entities: EntityProps[]) => {
      if (!expValues.config) {
        return;
      }

      setExpProps({
        config: { ...expValues.config, entities },
      });

      entities.length > 0
        ? setDisableNextStep(false)
        : setDisableNextStep(true);
    },
    // eslint-disable-next-line
    [setExpProps, expValues]
  );

  useEffect(() => {
    setBuildDisabledHandler(disableNextStep);
  }, [setBuildDisabledHandler, disableNextStep]);

  const readTutorialClickHander = () => {
    window.open(
      'https://docs.cogniflow.ai/en/article/create-an-info-extractor-using-ai-7pydjq/',
      '_blank'
    );
  };

  return (
    <Box>
      {/* <Box>
        <Text variant="paragraph1">
          <Link
            rel="noopener noreferrer"
            target="_blank"
            color="secondary"
            style={{ cursor: 'pointer' }}
            href="https://docs.cogniflow.ai/en/article/create-a-custom-extractor-7pydjq"
          >
            Need help?
          </Link>{' '}
          Learn how to define entities
        </Text>       
      </Box> */}

      <Box
        display="flex"
        justifyContent="space-evenly"
        width="100%"
        gridGap="24px"
      >
        <ChipButton
          title="Read our step-by-step tutorial"
          icon={<SchoolOutlined />}
          onClick={readTutorialClickHander}
        />
      </Box>

      <Box borderRadius="8px">
        <Extractor
          onEntitiesChange={onEntitiesChange}
          initialEntities={expValues.config.entities as EntityProps[]}
        />
      </Box>
      {/* <Box marginTop="32px">
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={clickHandler}
          disabled={disableNextStep}
        >
          Next Step
        </Button>
      </Box> */}
    </Box>
  );
};
