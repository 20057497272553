import { makeStyles } from '@material-ui/core';
import { ICogniflowTheme } from 'material/theme';

export default makeStyles<ICogniflowTheme>(({ breakpoints }) => ({
  billing: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    [breakpoints.down('sm')]: {
      marginRight: '24px',
    },
    [breakpoints.down('md')]: {
      marginRight: '24px',
    },
  },

  periodSwitch: {
    margin: '16px 0',
  },

  plansContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [breakpoints.down('md')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      justifyItems: 'center',
    },
    [breakpoints.down('sm')]: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      justifyItems: 'center',
    },
  },
  enterpriseCard: {
    marginTop: '16px',
    marginLeft: '39px',
    marginBottom: '42px',
  },
  faqComponentContainer: {
    marginLeft: '39px',
  },
}));
