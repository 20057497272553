import React from 'react';
import useStyles from './classificationreporttable.styles';
import { Grid, Typography } from '@material-ui/core';
import { twoDecimalsRound } from 'utils';
import { TextWithTooltip } from 'components/TextWithTooltip';

interface TClassificationReportTableProps {
  model: any;
  categories: string[];
}
const COLUMN_NAMES = ['Precision', 'Recall', 'f1-score', 'Support'];
const COLUMN_KEY_NAMES = ['precision', 'recall', 'f1-score', 'support'];
const TOOLTIP_TEXTS = {
  precision:
    "Precision is an evaluation metric. Possible values are between 0 and 1 (the higher the better). You can use this metric to know how confident the model is when predicting an specific category. For instance, If model's precision in category A is 0.8, it means that considering all the times the model predicts category A the model will success approximately 8 out of ten predictions.",
  recall:
    "Recall is an evaluation metric. Possible values are between 0 and 1 (the higher the better). You can use this metric to know how good the model is finding most of the elements in a category.  ​For instance, If model's recall in category A is 0.8, it means that every 10 elements in category A the model will predict correctly 8 out of 10 (approximately)",
  'f1-score':
    'F1-score is an evaluation metric. Possible values are between 0 and 1 (the higher the better). This metric combines precision and recall in a same formula. Typically when you try to maximize precision, recall goes down and vice versa. For this reason, If you want to have a good balance between precision and recall, then maximize F1-score is generally the recommended option.',
  support:
    'Support value is just another way to formally describe the number of elements in a category.',
};

export const ClassificationReportTable = ({
  model: {
    result: { classification_report },
  },
  categories,
}: TClassificationReportTableProps) => {
  const classes = useStyles();

  return (
    <Grid>
      <Typography
        className={`${classes.containerTitle}`}
        variant="body1"
        align="left"
      >
        Classification report
      </Typography>
      <Typography align="left" className={classes.textContentSpacing}>
        The aim of this report is to help you understand common classification
        metrics (
        <TextWithTooltip
          text="precision"
          description={TOOLTIP_TEXTS.precision}
        />
        , <TextWithTooltip text="recall" description={TOOLTIP_TEXTS.recall} />{' '}
        and{' '}
        <TextWithTooltip
          text="F1-score"
          description={TOOLTIP_TEXTS['f1-score']}
        />
        ,{' '}
        <TextWithTooltip
          text="support"
          description={TOOLTIP_TEXTS['support']}
        />
        ) evaluated in each of the experiment's categories. By analyzing this
        information you can get helpful insights about the model performance in
        each category and easily find which of them need to be improved (for
        instance, by adding more training data in them)
      </Typography>
      <div className={classes.mainContainer}>
        <div id="column-names-row" className={classes.tableRow}>
          <div></div>
          <div></div>
          <div>
            {COLUMN_NAMES.map((colName, key) => (
              <div key={key}>
                <Typography className={classes.lightTitle}>
                  {colName}
                </Typography>
              </div>
            ))}
          </div>
        </div>
        {categories.map((row, index) => (
          <div className={classes.tableRow} key={index}>
            <div>
              <Typography>{row}</Typography>
            </div>
            <div></div>
            <div>
              {COLUMN_KEY_NAMES.map((col, key) => (
                <div key={key}>
                  <Typography>
                    {twoDecimalsRound(classification_report[row][col])}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        ))}

        <div className={classes.tableRow}>
          <div></div>
          <div>
            <Typography className={classes.lightTitle}>
              Macro average
            </Typography>
          </div>
          <div>
            {COLUMN_KEY_NAMES.map((colKeyName, key) => (
              <div key={key}>
                <Typography>
                  {twoDecimalsRound(
                    classification_report['macro avg'][colKeyName]
                  )}
                </Typography>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.tableRow}>
          <div></div>
          <div>
            <Typography className={classes.lightTitle}>
              Weighted average
            </Typography>
          </div>
          <div>
            {COLUMN_KEY_NAMES.map((colKeyName, key) => (
              <div key={key}>
                <Typography>
                  {twoDecimalsRound(
                    classification_report['weighted avg'][colKeyName]
                  )}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Grid>
  );
};
