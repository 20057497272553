import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Grid, Link } from '@material-ui/core';
import { ActionButton } from 'components/ActionButton';
import {
  EXPERIMENT_TYPE_TEXT,
  EXPERIMENT_TYPE_IMAGE,
  EXPERIMENT_TASK_DOCS_PATH,
} from 'types';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import javascript from 'react-syntax-highlighter/dist/cjs/languages/prism/javascript';
import python from 'react-syntax-highlighter/dist/cjs/languages/prism/python';
import csharp from 'react-syntax-highlighter/dist/cjs/languages/prism/csharp';
import bash from 'react-syntax-highlighter/dist/cjs/languages/prism/bash';
import { darcula as theme } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { useRecoilValue } from 'recoil';
import {
  codeState,
  codeImageState,
  codeAudioState,
  currentModelState,
} from 'store/models';
import useStyles from './codetab.styles';
import { getEnv } from 'services/env';
import { useAlertContext } from 'context';
import { copyTextToClipboard } from 'utils';
import {
  EXPERIMENT_TYPE_SMART_EXTRACTOR_DOCS_PATH,
  EXPERIMENT_TASK,
} from 'types';

interface Props {
  language: string;
  code: string;
  type: number;
  task: number;
  children?: React.ReactNode;
}
SyntaxHighlighter.registerLanguage('javascript', javascript);
SyntaxHighlighter.registerLanguage('python', python);
SyntaxHighlighter.registerLanguage('csharp', csharp);
SyntaxHighlighter.registerLanguage('bash', bash);

const REACT_APP_PREDICT_URL = getEnv('REACT_APP_PREDICT_URL');

export const CodeEditor = React.memo(
  ({ language, code, type, task, children }: Props) => {
    const [customCode, setCustomCode] = useState(code);
    const codeTabParams = useRecoilValue(codeState);
    const codeTabParamsImage = useRecoilValue(codeImageState);
    const codeTabParamsAudio = useRecoilValue(codeAudioState);
    const currentModel = useRecoilValue(currentModelState);
    const classes = useStyles();
    const docsURL = useMemo(() => {
      const path =
        task === EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR
          ? EXPERIMENT_TYPE_SMART_EXTRACTOR_DOCS_PATH[type]
          : EXPERIMENT_TASK_DOCS_PATH[task] || '';
      return `${REACT_APP_PREDICT_URL}/docs#/default${path}`;
    }, [task, type]);

    const { setAlertHandler } = useAlertContext();

    const replaceCode = useCallback((code, str, param) => {
      return code.replace(str, param || str);
    }, []);

    useEffect(() => {
      let custom = code;

      if (currentModel) {
        custom = replaceCode(code, '/ModelHere', `/${currentModel?.id}`);
        setCustomCode(custom);
      }
      switch (type) {
        case EXPERIMENT_TYPE_TEXT:
          if (['javascript', 'node'].includes(language)) {
            custom = replaceCode(
              custom,
              'JsonEncodedTextHere',
              codeTabParams?.text.replace(/\n/g, ' ')
            );
            setCustomCode(custom);
          }
          break;
        case EXPERIMENT_TYPE_IMAGE:
          custom = replaceCode(
            custom,
            'FormatHere',
            codeTabParamsImage?.format
          );
          setCustomCode(custom);
          break;
        default:
          custom = replaceCode(
            custom,
            'FormatHere',
            codeTabParamsAudio?.format
          );
          setCustomCode(custom);
          break;
      }
    }, [
      codeTabParams,
      code,
      customCode,
      type,
      codeTabParamsImage,
      currentModel,
      codeTabParamsAudio,
      setCustomCode,
      replaceCode.length,
      language,
      replaceCode,
    ]);

    const copyToClipboard = useCallback(() => {
      const noBreakLinesCode = customCode
        .replace(/(\r\n|\n|\r)/gm, '')
        .replace(/  +/g, ' ');
      copyTextToClipboard(noBreakLinesCode);
    }, [customCode]);

    const handleCopy = useCallback(() => {
      copyToClipboard();
      setAlertHandler({
        showing: true,
        message: 'Code Copied!',
        severity: 'success',
      });
    }, [setAlertHandler, copyToClipboard]);

    return (
      <Grid container item direction="column" justify="center">
        <div className={classes.codeBoxHighlighter}>
          <SyntaxHighlighter
            wrapLongLines
            wrapLines
            customStyle={{
              boxSizing: 'border-box',
              position: 'relative',
              width: '100%',
              height: 280,
              padding: 16,
              margin: 0,
            }}
            language={language}
            style={theme}
          >
            {customCode}
          </SyntaxHighlighter>
        </div>

        {children}

        <Grid className={classes.swaggerMoreDetailsContainer}>
          <Link href={docsURL} className={classes.link} target="_blank">
            See the OpenAPI (formerly Swagger) specification for more details
          </Link>
        </Grid>

        <Grid container item justify="center">
          <ActionButton
            onClick={handleCopy}
            size="large"
            className={classes.button}
            variant="contained"
            color="secondary"
          >
            Copy code
          </ActionButton>
        </Grid>
      </Grid>
    );
  }
);
