import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Box } from '@material-ui/core';
import { Text } from 'components';
import { useApplication } from 'pages/Application/useApplication';
import { ApplicationTest, DEFAULT_VALUES } from 'pages/Application/Application';

export const Application = () => {
  const { id: applicationId }: { id: string } = useParams();
  const { loading, application, experiment, currentModel } = useApplication({
    applicationId,
  });

  const { colorBackground } = useMemo(
    () => ({
      colorBackground:
        application?.config?.colorBackground ||
        DEFAULT_VALUES['colorBackground'],
    }),
    [application]
  );

  return (
    <Box style={{ height: '100vh', background: colorBackground }}>
      {loading ? (
        <Text variant="h3">Loading...</Text>
      ) : !application || !experiment || !currentModel ? (
        <Text variant="h3">Application not found</Text>
      ) : (
        <ApplicationTest
          applicationId={applicationId}
          experiment={experiment}
          application={application}
        />
      )}
    </Box>
  );
};
