import { getEnv } from 'services/env';
import {
  EXPERIMENT_TASK,
  ExperimentLabel,
  experimentTypeMap,
  EXPERIMENT_TYPE,
} from 'types';

const REACT_APP_PREDICT_URL = getEnv('REACT_APP_PREDICT_URL');

const apiKeyPlaceholder = 'YOUR-API-KEY-HERE';

const experimentUrlMap: ExperimentLabel = {
  [EXPERIMENT_TASK.AUDIO_SPEECH2TEXT]: 'speech2text/transcribe',
  [EXPERIMENT_TASK.TEXT_TRANSLATION]: 'translation/translate',
  [EXPERIMENT_TASK.TEXT_NER_RESPONSE]:
    'information-extraction/extract-entities',
  [EXPERIMENT_TASK.TEXT_CLASSIFICATION]: 'classification/predict',
  [EXPERIMENT_TASK.TEXT_QANDA]: 'question-answering/ask',
  [EXPERIMENT_TASK.IMAGE_OCR]: 'ocr/recognize',
  [EXPERIMENT_TASK.IMAGE_OBJECT_DETECTION]: 'object-detection/detect',
  [EXPERIMENT_TASK.IMAGE_FACE_SIMILARITY]: 'image_verification/verify',
  [EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR]: 'smart-extraction/extract',
};

const imageBodyParams: ExperimentLabel = {
  [EXPERIMENT_TASK.IMAGE_OCR]:
    '{"format":"FormatHere","base64_image":"PasteBase64CodeHere","normalize_boxes":true,"include_boxes":true}',
  [EXPERIMENT_TASK.IMAGE_OBJECT_DETECTION]:
    '{ "format": "FormatHere", "base64_image": "PasteBase64CodeHere", "confidence_threshold": 0, "normalize_boxes": false }',
  [EXPERIMENT_TASK.IMAGE_FACE_SIMILARITY]: `{
                "format_img1": "FormatHere", 
                "format_img2": "FormatHere",
                "base64_img1": "PasteBase64CodeHere",
                "base64_img2": "PasteBase64CodeHere"
              }`,
};

export const getUrl = (
  typeNumber: number,
  task: number,
  modelId: string = 'ModelHere'
) => {
  const type = experimentTypeMap[typeNumber];
  const experimentUrl = experimentUrlMap[task] || 'classification/predict';
  const url = `${REACT_APP_PREDICT_URL}/${type}/${experimentUrl}/${modelId}`;
  return url;
};

export const getExperimentCodeList = (typeNumber: number, task: number) => {
  const type = experimentTypeMap[typeNumber];
  const url = getUrl(typeNumber, task);
  const data: ExperimentLabel = {
    text:
      task === EXPERIMENT_TASK.TEXT_QANDA
        ? `{"question":"JsonEncodedTextHere","lang_code":"Represents the language, e.g., en for English, es for Spanish, pt for Portuguese, etc. (is optional)","id_session":"A unique identifier for the conversation, which can be any string, enabling the chatbot to retain information (is optional)"}`
        : `{"text":"JsonEncodedTextHere"}`,
    image:
      imageBodyParams[task] ||
      '{"format":"FormatHere","base64_image":"PasteBase64CodeHere"}',
    audio: '{"format":"FormatHere","base64_audio":"PasteBase64CodeHere"}',
  };

  const bashBody: ExperimentLabel = {
    audio: `BODY='{"format": "FormatHere", "base64_audio": "PasteBase64CodeHere"}'`,
    image: `BODY='${
      imageBodyParams[task] ||
      '{"format": "FormatHere", "base64_image": "PasteBase64CodeHere"}'
    }'`,
  };

  const curlCodeFile = typeNumber !== EXPERIMENT_TYPE.TEXT;

  return [
    {
      language: 'javascript',
      code: `fetch('${url}', {
              method: 'POST',
              headers: {
                  accept: 'application/json',
                  'Content-Type': 'application/json',
                  'x-api-key': '${apiKeyPlaceholder}'
              },
              body: JSON.stringify(${data[type]}),
          });
          `,
    },
    {
      language: 'bash',
      code: curlCodeFile
        ? `${bashBody[type]} && echo $BODY | 
          curl -X POST 
          -H 'accept: application/json' 
          -H 'Content-Type: application/json' 
          -H 'x-api-key: ${apiKeyPlaceholder}' 
          -d @- 
          ${url}`
        : `curl -X POST 
          -H 'accept: application/json' 
          -H 'Content-Type: application/json' 
          -H 'x-api-key: ${apiKeyPlaceholder}' 
          -d '${data[type]}' 
          ${url}`,
    },
    {
      language: 'python',
      code: `import requests
  
          headers = {
              'accept': 'application/json',
              'Content-Type': 'application/json',
              'x-api-key': '${apiKeyPlaceholder}'
          }
  
          data = '${data[type]}'
  
          response = requests.post('${url}', headers=headers, data=data)
          response = response.json()`,
    },
    {
      language: 'javascript',
      code: `var fetch = require('node-fetch');
          fetch('${url}', {
              method: 'POST',
              headers: {
                  'accept': 'application/json',
                  'Content-Type': 'application/json',
                  'x-api-key': '${apiKeyPlaceholder}'
              },
              body: JSON.stringify(${data[type]})
          });`,
    },
    {
      language: 'csharp',
      code: `using (var httpClient = new HttpClient())
          {
              using (var request = new HttpRequestMessage(new HttpMethod("POST"), "${url}"))
              {
                  request.Headers.TryAddWithoutValidation("accept", "application/json");
                  request.Headers.TryAddWithoutValidation("x-api-key", "${apiKeyPlaceholder}");
  
                  request.Content = new StringContent("${data[type]}");
                  request.Content.Headers.ContentType = MediaTypeHeaderValue.Parse("application/json");
  
                  var response = await httpClient.SendAsync(request);
              }
          }`,
    },
  ];
};
