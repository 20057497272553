import * as Yup from 'yup';

export const signUpValidationSchema = Yup.object({
  password: Yup.string()
    .required('You must specify a password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Must contain at least 8 characters including  upper/lowercase letters and numbers'
    )
    .max(254),
  rePassword: Yup.string()
    .required('You must specify a password')
    .oneOf([Yup.ref('password')], 'Password must match')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Must contain at least 8 characters including  upper/lowercase letters and numbers'
    )
    .max(254),
});
