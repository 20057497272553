import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';
import { ICogniflowTheme } from 'material/theme';

export default makeStyles<ICogniflowTheme>(() => ({
  title: {
    fontSize: 18,
  },
  trainingDataWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px',
    padding: '30px 32px',
    boxShadow: '0 8px 16px 0 rgba(72, 79, 121, 0.15)',
  },
  info: {
    width: '100%',
    maxWidth: 744,
    padding: '24px 12px',
    backgroundColor: colors.blue10,
    position: 'relative',
  },
  img: {
    position: 'absolute',
    left: 0,
    top: -42,
  },
  infoTitle: {
    marginLeft: 40,
  },
  listContainer: {
    textAlign: 'left',
  },

  hintText: {
    color: colors.orange,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));
