import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { ResponseBox } from './../ResponseBox';
import { PredictResponse } from 'types';

import useStyles from './translationresponsebox.styles';
import { IconClose } from 'assets';

interface ClassificationResponseBoxProps {
  closeModalResultHandler(): void;
  textResponse: PredictResponse;
}

export const TranslationResponseBox = ({
  textResponse,
  closeModalResultHandler,
}: ClassificationResponseBoxProps) => {
  const classes = useStyles();

  return (
    <ResponseBox
      textResponse={textResponse}
      renderHeader={() => (
        <Grid className={classes.resultTitleWrapper}>
          <Grid></Grid>
          <Typography variant="body1" className={classes.subtitle}>
            I think this means:
          </Typography>
          <Grid
            className={classes.closeResultModal}
            onClick={closeModalResultHandler}
          >
            <IconClose />
          </Grid>
        </Grid>
      )}
      renderBody={() => (
        <Typography variant="button" className={classes.badge}>
          {textResponse.result}
        </Typography>
      )}
    />
  );
};
