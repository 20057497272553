import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  link: {
    textDecoration: 'none',
    '&:hover': {
      color: 'inherit',
    },
  },
}));
