import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { Grid, Typography } from '@material-ui/core';

import useStyles from './details.styles';
import { updateExperiment } from 'services/experiments';
import Editor from './Editor';
import { ContentState, convertToRaw, RawDraftContentState } from 'draft-js';
import { EditorFooter } from './EditorFooter';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  currentExperimentState,
  experimentError,
  experimentLoading,
} from 'store/experiments';
import { editExperimentState, editValuesState } from 'store/edit-experiment';
import { Experiment } from 'types';
import { useAuthenticationContext } from 'context';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

interface Props {
  experiment: Experiment;
}

export const Details = ({ experiment }: Props) => {
  const { user } = useAuthenticationContext();
  const classes = useStyles();
  const [editing, setEditing] = useRecoilState(editExperimentState);
  const [currExp, setExperiment] = useRecoilState(currentExperimentState);
  const setExperimentError = useSetRecoilState(experimentError);
  const [expValues, setExpValues] = useRecoilState(editValuesState);
  const [expLoading, setExperimentLoading] = useRecoilState(experimentLoading);
  const [isCancelled, setCancelled] = useState(false);

  const description = useMemo<RawDraftContentState>(() => {
    try {
      return JSON.parse(experiment.description);
    } catch (error) {
      return convertToRaw(ContentState.createFromText(experiment.description));
    }
  }, [experiment.description]);
  const contentRef = useRef<RawDraftContentState>(description);

  const isOwner = useMemo(() => currExp?.id_user === user?.id, [currExp, user]);

  const [textLengthReached, setTextLengthReached] = useState(false);

  useEffect(() => {
    return () => {
      // Close editing if leaving to other tab
      setEditing(false);
    };
  }, [setEditing]);

  const handleEdit = useCallback(() => {
    setExpValues({
      description,
      title: currExp?.title || experiment.title,
    });
  }, [setExpValues, experiment, description, currExp]);

  const handleChange = useCallback((content: RawDraftContentState) => {
    contentRef.current = content;
  }, []);

  const onConfirm = useCallback(() => {
    const contentStr = contentRef.current.blocks
      .map(({ text }) => text)
      .join('');

    if (contentStr.length > 10000) {
      setTextLengthReached(true);
      return;
    }

    setEditing(false);
    setExperimentLoading(true);
    setExperimentError('');
    setTimeout(() => {
      updateExperiment(experiment.id, {
        description: !!contentStr ? JSON.stringify(contentRef.current) : '',
        title: expValues.title || experiment.title,
      })
        .then((exp: Experiment) => {
          setExperiment({
            ...experiment,
            description: exp.description,
            title: expValues.title || experiment.title,
          });
          setExpValues({
            title: expValues.title,
            description: exp.description,
          });
          setEditing(false);
        })
        .catch((err) => {
          setExperimentError(err.message);
          setEditing(true);
        })
        .finally(() => {
          setExperimentLoading(false);
        });
    }, 200);
  }, [
    setEditing,
    experiment,
    expValues,
    setExperimentLoading,
    setExperiment,
    setExperimentError,
    setExpValues,
  ]);

  const handleCancel = useCallback(() => {
    setCancelled(!isCancelled);
  }, [setCancelled, isCancelled]);

  const closeSnackbarHandler = () => {
    setTextLengthReached(false);
  };

  return (
    <Grid container className={classes.container} justify="center">
      {!experiment.description && !editing && (
        <Grid item container justify="center" className={classes.noContent}>
          <p>{experiment.description}</p>
          <Typography variant="h3" align="center">
            You have no experiment details
          </Typography>
          <Typography variant="body1" align="center">
            Use this space to leave an experiment description, objectives,
            updates, notes or whatever makes sense to you!
          </Typography>
        </Grid>
      )}
      <Grid item container className={classes.content}>
        {(!!experiment.description || !!expValues.description || editing) && (
          <Editor
            content={description}
            onChange={handleChange}
            readOnly={!editing || expLoading}
            cancelled={isCancelled}
          />
        )}
      </Grid>
      <EditorFooter
        cancelEdit={handleCancel}
        onConfirm={onConfirm}
        onEdit={handleEdit}
        canEdit={isOwner}
      />
      <Snackbar
        open={textLengthReached}
        autoHideDuration={6000}
        onClose={closeSnackbarHandler}
      >
        <MuiAlert severity="error">
          Details text must be at most 10000 characters
        </MuiAlert>
      </Snackbar>
    </Grid>
  );
};
