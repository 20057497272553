import React, { useState, useMemo } from 'react';
import { Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Text } from 'components';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import useStyles from './QASettings.styles';

interface Props {
  value: string | undefined;
  disabled?: boolean;
  onChange: (value: string) => void;
}

const TONES = [
  {
    name: '👆 Sound anticipatory',
    value: 'Sound anticipatory',
  },
  {
    name: '😌 Make it diplomatic',
    value: 'Make it diplomatic',
  },
  {
    name: '💡 Make it inspirational',
    value: 'Make it inspirational',
  },
  {
    name: '☝ Make it assertive',
    value: 'Make it assertive',
  },
  {
    name: '😊 Make it friendly',
    value: 'Make it friendly',
  },
  {
    name: '👕 Sound casual',
    value: 'Sound casual',
  },
  {
    name: '🤗 Sound compassionate',
    value: 'Sound compassionate',
  },
  {
    name: '🤩 Make it exciting',
    value: 'Make it exciting',
  },
  {
    name: '👔 Sound formal',
    value: 'Sound formal',
  },
  {
    name: '👋 Make it constructive',
    value: 'Make it constructive',
  },
  {
    name: '😇 Make it empathetic',
    value: 'Make it empathetic',
  },
  {
    name: '🎯 Make it persuasive',
    value: 'Make it persuasive',
  },
  {
    name: '🤝 Sound confident',
    value: 'Sound confident',
  },
  {
    name: '🎯 Make it direct',
    value: 'Make it direct',
  },
  {
    name: '✨ Sound cooperative',
    value: 'Sound cooperative',
  },
];

export const QATone = ({ value, disabled = false, onChange }: Props) => {
  const classes = useStyles({ disabled });
  const [showOptions, setShowOptions] = useState(false);
  const optionsRef = useOnClickOutside(() => setShowOptions(false));

  const currentTone = useMemo(
    () => TONES.find((tone) => tone.value === value),
    [value]
  );

  const handleClick = (value: string) => {
    onChange(value);
    setShowOptions(false);
  };

  return (
    <Box ref={optionsRef} className={classes.tone}>
      <fieldset
        onClick={() => setShowOptions(true)}
        className={classes.toneValue}
      >
        <legend className={classes.toneLabel}>Chatbot tone</legend>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {currentTone ? (
            <>
              {currentTone.name}
              {!disabled && (
                <IconButton
                  onClick={() => onChange('')}
                  color="primary"
                  size="small"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              )}
            </>
          ) : (
            <>Choose Tone</>
          )}
        </Box>
      </fieldset>

      <Box
        style={{ display: showOptions ? 'initial' : 'none' }}
        className={classes.tonePopup}
      >
        <Text variant="h3" align="center">
          Adjust tone
        </Text>
        <Box className={classes.toneOptions}>
          {TONES.map(({ name, value }) => (
            <Box
              onClick={() => handleClick(value)}
              key={value}
              className={classes.toneOption}
            >
              {name}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
