import React from 'react';
import { DetailBox } from 'components/DetailBox/index';
import { OutLinedContainer } from 'components/OutLinedContainer';
import useStyle from './modeldetails.style';
import { DataNumericLabel } from 'components';
import {
  twoDecimalsRound,
  timesDifferenceFormatted,
  getTimeAgoString,
} from 'utils';
import { ExperimentLabel, EXPERIMENT_TASK } from 'types';

interface TModelDetailsProps {
  model: any;
  task: string | number;
}

const accuracyTextMap: ExperimentLabel = {
  [EXPERIMENT_TASK.IMAGE_OBJECT_DETECTION]: 'mAP score',
};

export function ModelDetails({ model, task }: TModelDetailsProps) {
  const style = useStyle();
  const timeAgoModelWasCreated = getTimeAgoString(model.training_finished_at);
  const modelDurationString = timesDifferenceFormatted(
    model.training_started_at,
    model.training_finished_at
  );

  return (
    <OutLinedContainer>
      <div className={style.flexParent}>
        <div className={style.aboveDetailsBox}>
          <div className={style.leftUpBox}>
            <div>
              <DetailBox
                title="SOLVER ALGORITHM"
                value={model.name_algorithm}
              />
            </div>
            <div>
              <DetailBox title="VECTORIZATION" value={model.name_vectorizer} />
            </div>
          </div>
          <div className={style.rightUpBox}></div>
        </div>

        <div className={style.belowDetailsBox}>
          <div className={style.leftDownBox}>
            <div>
              <DetailBox
                title="CREATED"
                value={timeAgoModelWasCreated}
                notUnderlined
              />
            </div>
            <div>
              <DetailBox
                title="TIME IT TOOK"
                value={modelDurationString}
                notUnderlined
              />
            </div>
          </div>
          <div className={style.rightDownBox}>
            <div
              className={`${style.numericDetail} ${style.leftNumericDetail}`}
            >
              {/* Needs to be the opposite acc first and then interface */}
              <DataNumericLabel
                label={accuracyTextMap[task] || 'ACCURACY SCORE'}
                number={twoDecimalsRound(model.result.accuracy)}
                tooltipText="This is the ratio between the number of correctly predicted examples and the total number of examples in the the validation set."
              />
            </div>
            <div className={style.numericDetail}>
              <DataNumericLabel
                label="AV. INTERFACE TIME"
                number={twoDecimalsRound(
                  model.result.avg_inference_time_in_sec
                )}
                tooltipText="This is an approximation of the time required to use the model with a new input. This value results from measuring the average inference time by taking some randomly examples from the validation set."
              />
            </div>
          </div>
        </div>
      </div>
    </OutLinedContainer>
  );
}
