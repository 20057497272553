import React, { useState, useCallback, useMemo, FC } from 'react';
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Box,
} from '@material-ui/core';
import { CodeEditor } from '../CodeEditor';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { TextWithClipboard } from 'components/TextWithClipboard/TextWithClipboard';
import { ActionButton } from 'components';

import { useAuthenticationContext } from 'context';
import { getExperimentCodeList, getUrl } from '../codeExported';
import { useRecoilValue } from 'recoil';
import classNames from 'classnames';
import { currentModelState } from 'store/models';

import useStyles from './APIIntegration.styles';

export interface ApiIntegrationProps {
  task: number;
  type: number;
  backToIntegrations?: () => {};
}

export const ApiIntegration: FC<ApiIntegrationProps> = ({
  task,
  type,
  backToIntegrations,
}) => {
  const classes = useStyles();

  const [tab, setTab] = useState(0);
  const theme = useTheme();

  const currentModel = useRecoilValue(currentModelState);

  const languages = ['Javascript', 'Curl', 'Python', 'Nodejs', 'C#'];

  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const { userApiKey } = useAuthenticationContext();

  const experimentCodeList = useMemo(() => getExperimentCodeList(type, task), [
    task,
    type,
  ]);

  const handleChange = useCallback(
    (e, newValue) => {
      setTab(newValue);
    },
    [setTab]
  );

  const url = useMemo(() => getUrl(type, task, currentModel?.id || ''), [
    type,
    task,
    currentModel,
  ]);

  return (
    <>
      <Typography className={classes.title}>
        Use this model in your project
      </Typography>
      <Typography variant="body1" className={classes.subtitle}>
        Copy and paste this code into your project to consume from our API
      </Typography>
      <Grid
        container
        item
        direction="column"
        justify="center"
        style={{ alignItems: 'center', marginTop: 32 }}
      >
        {matches ? (
          <Box className={classes.tabsWrapper}>
            {languages.map((language, idx: number) => (
              <div
                onClick={() => setTab(idx)}
                className={classNames(classes.tab, {
                  [classes.tabActive]: tab === idx,
                })}
                key={idx}
              >
                {language}
              </div>
            ))}
          </Box>
        ) : (
          <FormControl style={{ width: '95%' }}>
            <Select
              value={tab}
              onChange={(e) => handleChange(e, e.target.value)}
            >
              {languages.map((language, idx: number) => (
                <MenuItem value={idx} key={idx}>
                  {language}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <Grid container justify="center" className={classes.codeBoxContainer}>
          {languages.map(
            (_, idx: number) =>
              tab === idx && (
                <CodeEditor
                  type={type}
                  task={task}
                  language={experimentCodeList[idx].language}
                  code={experimentCodeList[idx].code}
                  key={idx}
                >
                  <Box className={classes.parameterList}>
                    <TextWithClipboard value={url} label="Url" />
                    <TextWithClipboard
                      value={userApiKey}
                      label="Api Key"
                      isPassword
                    />
                  </Box>
                </CodeEditor>
              )
          )}
        </Grid>
      </Grid>
    </>
  );
};
