import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles((theme) => ({
  wrapper: {
    // width: '600px',
    // [theme.breakpoints.down('md')]: {
    //   width: '600px',
    // },
    // [theme.breakpoints.down('xs')]: {
    //   width: '100%',
    // },
  },

  container: {
    marginTop: 32,
  },

  button: {
    marginTop: 32,
  },

  advanceOptionContainer: {
    // width: 744,
    padding: 24,
    borderRadius: 8,
    boxShadow: '0 16px 24px 0 rgba(72, 79, 121, 0.15);',
    marginTop: 24,
    backgroundColor: '#ffff',
  },
  titleContainer: {
    //	marginBottom: 24,
  },
  advanceTitleCollpasible: {
    fontFamily: 'Poppins',
    fontSize: 10,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: colors.blue70,
  },
  collapsibleContainer: {
    width: '100%',
  },
  valdiationTitle: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.blue70,
  },
  optionalTitle: {
    fontFamily: 'Poppins',
    fontSize: 8,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: 0.6,
    color: colors.blue20,
  },
  uploadTitle: {
    fontFamily: 'Poppins',
    fontSize: 8,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: 0.6,
    color: colors.blue50,
  },

  errorModalTitle: {
    textAlign: 'center',
  },
  infoContainer: {
    marginTop: '20px',
    marginBottom: '10px',
  },
  containerTitle: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'bold',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: colors.blue70,
    margin: '16px 0px 16px',
  },
  algoDetailContainer: {
    borderRadius: '8px',
    border: `solid 1px  ${colors.blue15}`,
  },
  algoDetail: {
    padding: '12px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },

  logPos: {
    fontWeight: 'bold',
    width: '5%',
    margin: 'auto',
  },
  logMessage: {
    width: '95%',
  },
  logContainer: {
    display: 'flex',
    width: '100%',
  },
}));
