import React, { useState, useMemo } from 'react';

import { Box, Button, Snackbar } from '@material-ui/core';
import { Link } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import LinkIcon from '@material-ui/icons/Link';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import CodeIcon from '@material-ui/icons/Code';
import EditIcon from '@material-ui/icons/Edit';
import useStyle from './share-model.styles';

import { getEnv } from 'services/env';
import { Text } from 'components';
import { copyTextToClipboard } from 'utils';
import { useUserPlanContext } from 'context';
import { usePermissions } from 'hooks/usePermissions';
import { EXPERIMENT_TYPE, Experiment } from 'types';
import {
  getApplicationByModel,
  createApplicationByModel,
  deleteApplication,
} from 'services/application';
import { EmbedModal } from './EmbedModal';
import { CustomizationModal } from './CustomizationModal';
import { Application } from 'types';
import {
  getQAGenerativeUrlByApplication,
  isQAGenerative,
  isQAGenerativeStructured,
} from 'utils/experiment';
import { buildChatWidgetUrl } from 'services';

const REACT_APP_API_URL = getEnv('REACT_APP_API_URL');
const CHAT_WIDGET_URL = buildChatWidgetUrl();

interface Props {
  idModel: string;
  type: EXPERIMENT_TYPE.TEXT | EXPERIMENT_TYPE.IMAGE | EXPERIMENT_TYPE.AUDIO;
  experiment: Experiment;
  idApplication: string | null;
  application: Application | null;
  loading: boolean;
  setLoading: (value: boolean) => void;
  setIdApplication: (id: string | null) => void;
  setApplication: React.Dispatch<React.SetStateAction<Application | null>>;
}

export const ShareModel = React.memo(
  ({
    idModel,
    type,
    experiment,
    idApplication,
    application,
    loading,
    setIdApplication,
    setApplication,
    setLoading,
  }: Props) => {
    const classes = useStyle();
    const { canDoUpgrade } = useUserPlanContext();
    const { canCreateApplication } = usePermissions();
    const [showEmbed, setShowEmbed] = useState(false);
    const [showCustomization, setShowCustomization] = useState(false);

    const [message, setMessage] = useState('');

    const expIsQAGen = useMemo(() => isQAGenerative(experiment), [experiment]);
    const expIsQAGenStructured = useMemo(
      () => isQAGenerativeStructured(experiment),
      [experiment]
    );

    const QAGenerativeUrl = useMemo(
      () => getQAGenerativeUrlByApplication(idApplication || ''),
      [idApplication]
    );

    const modelUrl = useMemo(
      () =>
        expIsQAGen
          ? QAGenerativeUrl
          : `${window.location.origin}/app/${idApplication}`,
      [idApplication, QAGenerativeUrl, expIsQAGen]
    );

    const embedCode = useMemo(
      () =>
        expIsQAGen
          ? `<iframe src="${modelUrl}" frameborder="0" width="100%" height="750"></iframe>`
          : `<iframe src="${REACT_APP_API_URL}/application/embed/${idApplication}"${
              EXPERIMENT_TYPE.IMAGE === type
                ? ' allow="camera"'
                : EXPERIMENT_TYPE.AUDIO === type
                ? ' allow="microphone"'
                : ''
            } frameborder="0" width="100%" height="750"></iframe>`,
      [idApplication, type, expIsQAGen]
    );

    const embedWidgetCode = useMemo(
      () =>
        expIsQAGen
          ? `
    <script type="text/javascript">
      window.COGNIFLOW_APPLICATION_ID = '${idApplication}';
      (function () {
        d = document;
        s = d.createElement('script');
        s.src = '${CHAT_WIDGET_URL}';
         s.async = 1;
        d.getElementsByTagName('head')[0].appendChild(s);
      })();
    </script>`
          : undefined,
      [expIsQAGen, idApplication]
    );

    const handleCreate = async () => {
      if (idApplication || !idModel) return;
      try {
        const { id } = await createApplicationByModel(idModel);
        setIdApplication(id);
        const application = await getApplicationByModel(idModel);
        setApplication(application);
        setMessage('Successfully updated!');
      } catch {}
    };

    const handleDelete = async () => {
      if (!idApplication) return;
      try {
        await deleteApplication(idApplication);
        setIdApplication(null);
        setApplication(null);
        setMessage('Successfully updated!');
      } catch {}
    };

    const copyToClipboard = () => {
      copyTextToClipboard(modelUrl);
      setMessage('Copied to clipboard!');
    };

    if (loading)
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ margin: 40 }}
        >
          <Text variant="h3">Loading...</Text>
        </Box>
      );

    if (!canCreateApplication)
      return (
        <Box className={classes.root}>
          <Text
            variant="h2"
            className={classes.title}
            style={{
              marginTop: 16,
            }}
          >
            Instant Web App
          </Text>
          <Text variant="paragraph1" className={classes.description}>
            <strong>
              Share this model with others in just one click on embed it on your
              website.
            </strong>
          </Text>
          {canDoUpgrade && (
            <Link to="/settings/subscription">
              <Button
                variant="contained"
                color="secondary"
                size="large"
                disableElevation
                style={{
                  marginTop: 28,
                }}
              >
                Upgrade Your Plan
              </Button>
            </Link>
          )}
        </Box>
      );

    return (
      <>
        <Box className={classes.root}>
          {idApplication ? (
            <>
              <Text variant="h4" className={classes.title}>
                Your Web App is already published
              </Text>
              <Text variant="paragraph1" className={classes.description}>
                Anyone on the Internet with the link will be able to use your
                model:
              </Text>
              <a
                href={modelUrl}
                target="_blank"
                rel="noreferrer"
                className={classes.url}
              >
                {modelUrl}
              </a>
            </>
          ) : (
            <>
              <Text variant="h4" className={classes.title}>
                Share this model as a Web App
              </Text>
              <Text variant="paragraph1" className={classes.description}>
                Anyone on the Internet with the link will be able to use your
                model, or embed it on your website.
              </Text>
            </>
          )}

          <Box className={classes.buttons}>
            {idApplication ? (
              <>
                <Button
                  onClick={copyToClipboard}
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<LinkIcon />}
                >
                  Copy link
                </Button>
                <Button
                  onClick={() => setShowEmbed(true)}
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<CodeIcon />}
                >
                  Embed
                </Button>
                <Button
                  onClick={() => setShowCustomization(true)}
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<EditIcon />}
                >
                  Customize
                </Button>
                <Button
                  onClick={handleDelete}
                  variant="contained"
                  color="secondary"
                  size="large"
                  disableElevation
                >
                  Unpublish
                </Button>
              </>
            ) : (
              <Button
                onClick={handleCreate}
                variant="contained"
                color="secondary"
                size="large"
                disableElevation
              >
                Publish
              </Button>
            )}
          </Box>

          <Box className={classes.info}>
            <InfoIcon style={{ fontSize: 14 }} />
            Predictions will be consumed from your account.
          </Box>
        </Box>

        <EmbedModal
          code={embedCode}
          widgetCode={embedWidgetCode}
          open={showEmbed}
          onClose={() => setShowEmbed(false)}
          setMessage={setMessage}
          isQAGenerative={expIsQAGen}
        />

        {application && (
          <CustomizationModal
            isQAGenerative={expIsQAGen}
            isQAGenerativeStructured={expIsQAGenStructured}
            application={application}
            setApplication={setApplication}
            open={showCustomization}
            onClose={() => setShowCustomization(false)}
            setMessage={setMessage}
          />
        )}

        <Snackbar
          open={!!message}
          autoHideDuration={6000}
          onClose={() => setMessage('')}
        >
          <MuiAlert severity="success">{message}</MuiAlert>
        </Snackbar>
      </>
    );
  }
);
