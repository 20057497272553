import { http } from './http';
import { Company } from 'types';

export const getCompany = (): Promise<Company> => http.get('company/');

export const createCompany = (payload: { name: string }) =>
  http.post(`company/`, JSON.stringify(payload));

export const updateCompany = (id: string, payload: { name: string }) =>
  http.patch(`company/${id}`, JSON.stringify(payload));
