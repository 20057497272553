import React, { memo, useCallback, useState } from 'react';
import {
  Text,
  LoginForm,
  SimpleMessage,
  LoginWrapper,
  HorizontalDivider,
} from 'components';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { useHistory } from 'react-router-dom';
import useStyle from './login.styles';
import { Box, Button } from '@material-ui/core';
import { loginValidationSchema } from './loginValidationSchema';
import { useAuthenticationContext } from 'context';
import GoogleButton from 'react-google-button';
import { useGoogleLogin } from '@react-oauth/google';
import { getEnv, googleSignIn } from 'services';
import { colors } from 'material/colors';

const REACT_APP_QA_URL = getEnv('REACT_APP_QA_URL');

export const Login = memo(() => {
  const { login, authenticateUser } = useAuthenticationContext();
  const classes = useStyle();
  const [error, setError] = useState('');
  const { push } = useHistory();

  const getLoginCallback = useCallback(() => {
    // Redirect if param app exists
    const urlParams = new URLSearchParams(window.location.search);
    const app = urlParams.get('app');
    const next = urlParams.get('next');
    let cb: any = () => push('/');

    if (app === 'COGNIGPT') {
      cb = (token: string) => {
        let url = `${REACT_APP_QA_URL}${next || ''}`;

        const hasQueryParams = new URL(url).search !== '';
        if (hasQueryParams) url += `&token=${token}`;
        else url += `?token=${token}`;

        window.open(url, '_self');
      };
    }

    return cb;
  }, [push]);

  const handleLogin = useCallback(
    async ({ username, password }) => {
      setError('');
      const cb = getLoginCallback();
      login?.(username, password, cb).catch((err) => setError(err.message));
    },
    [login, getLoginCallback]
  );

  const handleForgotPasswd = useCallback(() => {
    push('/forgot');
  }, [push]);

  const continueWithGoogleHandler = useGoogleLogin({
    onSuccess: (response) =>
      googleSignIn(response)
        .then(({ access_token, email }: any) => {
          (window as any).lintrk?.('track', { conversion_id: 14509892 });

          authenticateUser?.({
            email,
            accessToken: access_token,
          });
          const cb = getLoginCallback();
          cb(access_token);
          push('/experiments');
        })
        .catch((err) => {
          setError(err.message);
        }),
    onError: (error) => console.log(error),
    flow: 'auth-code',
  });

  return (
    <LoginWrapper>
      <LoginForm>
        <Formik
          onSubmit={handleLogin}
          validationSchema={loginValidationSchema}
          initialValues={{
            username: '',
            password: '',
          }}
        >
          <Form className={classes.container}>
            <span className={classes.userFormTitle}>Log in</span>
            <Box className={classes.signinwith}>
              <GoogleButton
                style={{
                  width: '100%',
                  boxShadow: 'none',
                  border: `1px solid ${colors.orange}`,
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                type="light"
                onClick={() => continueWithGoogleHandler()}
                label="Continue with google"
                disabled={false}
                className={classes.loginButton}
              />
            </Box>

            <HorizontalDivider text="OR" />

            <Field
              className={classes.inputField}
              component={TextField}
              name="username"
              label="Email"
              type="email"
            />
            <Field
              className={classes.inputField}
              component={TextField}
              name="password"
              label="Password"
              type="password"
            />
            <label
              onClick={handleForgotPasswd}
              className={classes.forgotPasswordLink}
            >
              Forgot your password?
            </label>
            {error ? (
              <SimpleMessage type="error" icon={true}>
                <Text variant="paragraph1">{error}</Text>
              </SimpleMessage>
            ) : null}
            <Button
              variant="contained"
              color="secondary"
              size="large"
              type="submit"
            >
              Login
            </Button>
          </Form>
        </Formik>
      </LoginForm>
    </LoginWrapper>
  );
});
