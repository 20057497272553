import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(({ breakpoints }) => ({
  other: {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    gap: '24px',
    padding: '20px',
  },

  dropzonesContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gridGap: '12px',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    '& > div': {
      width: '100%',
    },
  },

  subtitle: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.orange,
  },

  yMargin: {
    margin: '24px 0',
  },

  // metricsText: {
  //   lineHeight: 1,
  // },

  matchText: {
    color: '#2fb12f',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    fontSize: '16px',
  },

  noMatchText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    fontSize: '16px',
    '& b': { color: '#ff4343' },
  },

  badge: {
    backgroundColor: colors.blue10,
    padding: '9px 16px',
    borderRadius: 8,
    marginLeft: 8,
    fontSize: 12,
    color: colors.blue70,
    fontWeight: 'bold',
  },

  testArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gridGap: '12px',
    borderRadius: '8px',
    padding: '0 24px',
    position: 'relative',
    height: '100%',
    gridColumn: '1 / span 2',
  },
  loadingOverlay: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    backgroundColor: 'white',
    zIndex: 1,
    opacity: '0.8',
    borderRadius: '8px',
    top: '0',
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    gridGap: '12px',
    top: '0',
    bottom: '0',
    zIndex: 2,
  },
  resultcontainer: {
    width: '100%',
    padding: '15px 0',
    // backgroundColor: 'rgba(77, 135, 204, 0.2)',
    color: '#4D87CC',
    fontWeight: 600,
    borderRadius: '5px',
  },
  resultText: {
    color: '#FF5C5C',
  },
  card: {
    backgroundColor: '#FFFF',
    boxShadow: '0 8px 16px 0 rgb(72 79 121 / 15%)',
    borderRadius: '10px',
  },

  link: {
    textDecoration: 'underline',
    color: colors.orange,
    cursor: 'pointer',
  },
}));
