import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ProjectCard,
  ProjectSection,
  SectionTitleDivider,
  Text,
} from 'components';
import {
  ProjectSearchBar,
  InformativeSection,
  Header,
  NoExperiments,
} from '../components';
import { Box, CircularProgress } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';

import { getLastVisitedProjects, getProjects } from 'services';
import { Experiment, PROJECT_VISIBILITY } from 'types';

import useStyles from './Projects.styles';
import { useAuthenticationContext } from 'context';

export const Dashboard = () => {
  const [experiments, setExperiments] = useState<Experiment[]>([]);
  const [loading, setLoading] = useState(false);
  const pageCount = useRef(5);

  const { user } = useAuthenticationContext();

  const classes = useStyles();

  const [tasks, setTasks] = useState<number[]>([]);

  const [recentProjects, setRecentProjects] = useState<Experiment[]>([]);

  const [visibilities, setVisibilities] = useState([
    PROJECT_VISIBILITY.PRIVATE,
    PROJECT_VISIBILITY.COMPANY,
  ]);

  const fetchProps = useMemo(
    () => ({
      visibilities,
      tasks,
    }),
    [tasks, visibilities]
  );

  useEffect(() => {
    getLastVisitedProjects().then((projects: Experiment[]) => {
      setRecentProjects(projects);
    });
  }, []);

  const fetchExperiments = useCallback(
    async (cleanProjects: boolean = false) => {
      setLoading(true);

      if (cleanProjects) {
        setExperiments([]);
      }
      const response = await getProjects({
        ...fetchProps,
        pageSize: pageCount.current,
      });
      const exps = (response as any).experiments as Experiment[];

      pageCount.current += 5;

      setExperiments(exps as Experiment[]);

      setLoading(false);
    },
    [fetchProps]
  );

  useEffect(() => {
    fetchExperiments(true);
  }, [fetchExperiments]);

  const onProjectSearchBarChange = useCallback((tags: number[]) => {
    pageCount.current = 10;
    setTasks(tags);
  }, []);

  const onVisibilityChange = (value: string) => {
    switch (value) {
      case 'organization':
        setVisibilities([PROJECT_VISIBILITY.COMPANY]);
        break;
      case 'private':
        setVisibilities([PROJECT_VISIBILITY.PRIVATE]);
        break;
      case 'all':
        setVisibilities([
          PROJECT_VISIBILITY.PRIVATE,
          PROJECT_VISIBILITY.COMPANY,
        ]);
        break;
    }
  };

  return (
    <Box>
      <Header />
      <Box className={classes.dataSection} mx="195px">
        <Box marginTop="60px">
          <InformativeSection />
        </Box>

        <Box className={classes.projects} mt="60px">
          {!!recentProjects.length && (
            <Box mb="40px">
              <ProjectSection title="Latest views" projects={recentProjects} />
            </Box>
          )}

          <Box mb="12px">
            <SectionTitleDivider>My Projects</SectionTitleDivider>
          </Box>

          <Box mb="30px">
            <ProjectSearchBar
              onChange={onProjectSearchBarChange}
              onVisibilityChange={onVisibilityChange}
              visibilities={[
                PROJECT_VISIBILITY.PRIVATE,
                PROJECT_VISIBILITY.COMPANY,
                PROJECT_VISIBILITY.PUBLIC,
              ]}
              showVisibilityDropdown={!!user?.id_company}
              justify="space-between"
            />
          </Box>

          {experiments.length > 0 && (
            <Box mb="40px">
              <InfiniteScroll
                dataLength={experiments.length}
                next={fetchExperiments}
                hasMore={true}
                loader={<div></div>}
                className={classes.infiniteScroll}
              >
                {experiments.map((props) => (
                  <ProjectCard
                    key={props.id}
                    projectTask={props.task}
                    {...props}
                  />
                ))}
              </InfiniteScroll>
            </Box>
          )}
        </Box>

        {loading && (
          <Box
            mb="30px"
            mt="30px"
            display="flex"
            // justifyContent="center"
            alignItems="center"
            gridGap="8px"
            flexDirection="column"
            height="100vh"
          >
            <CircularProgress />
            <Text variant="h4">Loading...</Text>
          </Box>
        )}

        {!loading && experiments.length === 0 && (
          <Box mt="100px">
            <NoExperiments tagSelected={tasks.length > 0} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
