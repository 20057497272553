import React, { useMemo, useRef, useState } from 'react';

import * as Yup from 'yup';
import { Formik, Form, Field, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';

import { Box } from '@material-ui/core';

import { ActionButton } from 'components';

import { createCompany, updateCompany } from 'services/company';

import { Company as CompanyType } from 'types';
import { FetchError } from 'utils';

import useStyle from '../company.styles';

interface CompanyProps {
  company?: CompanyType | null;
  onSuccess?(payload: { msg: string }): void;
  onError?(payload: { msg: string }): void;
  updateMode?: boolean;
}

interface FormProps {
  name: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('You must specify an name').max(254),
});

export const CompanyForm = ({
  company,
  onSuccess,
  onError,
  updateMode = false,
}: CompanyProps) => {
  const classes = useStyle();
  const formikRef = useRef<FormikProps<FormProps>>(null);
  const [canSave, setCanSave] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const initialValues = useMemo<FormProps>(
    () => ({
      name: company?.name || '',
    }),
    [company]
  );

  const onSubmitHandler = async ({ name }: FormProps) => {
    setLoading(true);
    try {
      if (updateMode) {
        if (company) {
          await updateCompany(company.id, { name });
          onSuccess?.({ msg: 'Changes saved' });
        }
      } else {
        await createCompany({ name });
        onSuccess?.({ msg: 'Organization created' });
      }
      setCanSave(false);
    } catch (error) {
      if (error instanceof FetchError) {
        onError?.({
          msg: error.message,
        });
      }
    }
    setLoading(false);
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmitHandler}
      innerRef={formikRef}
    >
      {({ values, setFieldValue }) => (
        <Form onChange={() => setCanSave(true)} className={classes.form}>
          <Field
            component={TextField}
            name="name"
            label="Organization name"
            type="text"
            variant="outlined"
          />

          <Box>
            {updateMode ? (
              <ActionButton
                variant="contained"
                color="secondary"
                size="large"
                type="submit"
                disabled={!canSave}
                loading={loading}
              >
                Save Changes
              </ActionButton>
            ) : (
              <ActionButton
                variant="contained"
                color="secondary"
                size="large"
                type="submit"
                disabled={!canSave}
                loading={loading}
              >
                Create
              </ActionButton>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
};
