import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useQueryParams } from 'hooks';
import { Box, Button, Link } from '@material-ui/core';
import { ProgressBar, Text } from 'components';
import Lottie from 'lottie-react';
import Building from 'animations/building.json';

import useStyles from './BuildingProject.styles';
import { useHistory, useParams } from 'react-router-dom';
import { getExperiment, updateExperiment } from 'services';
import {
  Experiment,
  PROJECT_STATUS,
  PROJECT_TASK,
  STATUS_FINISHED,
} from 'types';
import { ErrorIcon } from 'assets';
import { ConfirmCancel } from 'pages/ViewExperiment/Modals/ConfirmCancel';
import { getUtcDate } from 'utils';

type Params = {
  experimentId: string;
};

export const BuildingProject = () => {
  const classes = useStyles();
  const { experimentId } = useParams<Params>();
  const { push } = useHistory();
  const { isUpdate } = useQueryParams<{ isUpdate: string }>();
  const [open, setOpen] = useState(false);

  const intervalIdRef = useRef<number>(0);

  const [experiment, setExperiment] = useState<Experiment>();
  const snapshotTime = useRef(new Date());

  const trainingDurationInMinMap: Record<number, number> = {
    [PROJECT_TASK.CHATBOT]: 3,
  };
  const trainingDurationInMin =
    trainingDurationInMinMap[experiment?.task || 0] || 8;
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!experiment) return;
    const isFinished =
      PROJECT_STATUS.STATUS_FINISHED === (experiment?.status || 0);
    if (progress === 100 || isFinished) push(`/experiment/${experiment.id}`);
  }, [experiment, progress]);

  useEffect(() => {
    if (!experiment) return;
    if (experiment.task === PROJECT_TASK.OBJECT_DETECTION) return;

    const trainingDurationInMs = trainingDurationInMin * 60 * 1000;
    // If it is an update it will not take into account the original creation date of the experiment since it is very old.
    const timeElapsedInMs =
      isUpdate === 'true'
        ? new Date() - snapshotTime.current
        : new Date() - getUtcDate(experiment.created_at);
    const updateIntervalInMs = 5 * 1000; // 5 seconds

    setProgress(
      Math.min(100, Math.max((timeElapsedInMs / trainingDurationInMs) * 100, 0))
    );

    intervalIdRef.current = window.setInterval(() => {
      setProgress((prev) =>
        Math.min(
          100,
          Math.max(prev + (updateIntervalInMs / trainingDurationInMs) * 100, 0)
        )
      );
    }, updateIntervalInMs);

    return () => clearInterval(intervalIdRef.current);
  }, [experiment, isUpdate]);

  useEffect(() => {
    const progressCheckInterval = window.setInterval(() => {
      getExperiment(experimentId).then((res: Experiment) => {
        setExperiment(res);
        if (res.progress === 100 || res.status >= STATUS_FINISHED) {
          [intervalIdRef.current, progressCheckInterval].forEach(
            (interval: number) => {
              clearInterval(interval);
            }
          );
          setProgress(100);
        }
      });
    }, 12000);

    return () => clearInterval(progressCheckInterval);
  }, [experimentId]);

  useEffect(() => {
    getExperiment(experimentId).then((res: Experiment) => {
      setExperiment(res);

      if (res.task === PROJECT_TASK.EXTRACTOR) {
        push(`/experiment/${res.id}/model/${res.id_recommended_model}/test`);
      }
    });
  }, [experimentId, push]);

  const titleMap: Record<number, string> = {
    [PROJECT_STATUS.STATUS_FINISHED]: 'Your project is ready!',
    [PROJECT_STATUS.STATUS_CANCELED_BY_USER]: 'Project canncelled',
    [PROJECT_STATUS.STATUS_CANCELED_BY_TIMEOUT]:
      'Project canncelled by timeout',
    [PROJECT_STATUS.STATUS_FINISHED_WITH_ERROR]:
      'Project could not be created, something was wrong',
  };

  const showCancel = useMemo(
    () => (experiment?.status || 0) < PROJECT_STATUS.STATUS_RUNNING,
    [experiment]
  );

  const goToProject = () => {
    push(`/experiment/${experiment?.id}`);
  };

  const goToDashboard = () => {
    push(`/projects/dashboard`);
  };

  const openModal = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleCancel = useCallback(() => {
    setOpen(false);
    // setExperimentLoading(true);
    // setExperimentError('');
    updateExperiment(experimentId, { status: 4 })
      .then((exp) => {
        setExperiment(exp);
        push('/projects/dashboard');
        window.location.reload();
      })
      .catch((err) => {
        // setExperimentError(err.message);
      })
      .finally(() => {
        // setExperimentLoading(false);
      });
  }, [
    setExperiment,
    // setExperimentLoading,
    // setExperimentError,
    push,
    experimentId,
    setOpen,
  ]);

  const trainingDurationTextMap: Record<number, string> = {
    [PROJECT_TASK.CHATBOT]: '3 minutes',
  };

  const finishedWithError =
    experiment?.status === PROJECT_STATUS.STATUS_FINISHED_WITH_ERROR;

  const goToTestProject = () => {
    const redirectionTab =
      experiment?.task === PROJECT_TASK.CHATBOT ? 'chat' : 'test';
    push(
      `/experiment/${experiment?.id}/model/${experiment?.id_recommended_model}/${redirectionTab}`
    );
  };

  const buttonData =
    PROJECT_STATUS.STATUS_FINISHED === (experiment?.status || 0)
      ? {
          text: 'Test this project',
          fn: goToTestProject,
        }
      : {
          text: 'Go to dashboard',
          fn: goToDashboard,
        };

  return (
    <Box className={classes.buildingWrapper}>
      {experiment && (
        <Box display="flex" justifyContent="center" flexDirection="column">
          <Box height="300px" mb="24px" display="flex" justifyContent="center">
            {finishedWithError ? (
              <ErrorIcon width="300px" height="300px" />
            ) : (
              <Lottie animationData={Building} />
            )}
          </Box>
          <Box display="flex" flexDirection="column" gridGap="12px">
            <Text variant="h4" align="center">
              {titleMap[experiment?.status || 0] ||
                'Your AI project is taking shape!'}
            </Text>
            {finishedWithError && (
              <Box marginBottom="12px">
                <Text
                  variant="paragraph1"
                  className={classes.errorSubtitleText}
                >
                  Go to the <span onClick={goToProject}>project</span> to see
                  details
                </Text>
              </Box>
            )}
            {(experiment?.status || 0) < PROJECT_STATUS.STATUS_FINISHED && (
              <Box>
                <ProgressBar progress={progress} showText={false} />

                <Box my="12px">
                  {experiment?.task === PROJECT_TASK.OBJECT_DETECTION ? (
                    <Text variant="paragraph1">
                      It can take up to 24 hours to be trained. <br /> Keep calm
                      and relax!.🧘
                      <br />
                      Why don't you learn{' '}
                      <Link
                        rel="noopener noreferrer"
                        target="_blank"
                        color="secondary"
                        href="https://docs.cogniflow.ai/en/"
                      >
                        what other things you can do with cogniflow?
                      </Link>
                      📖
                    </Text>
                  ) : (
                    <Text variant="paragraph1">
                      We are currently building and setting up everything
                      needed. <br />
                      <br />
                      This process may take a few minutes, but feel free to
                      navigate <br />
                      other parts of our platform while you wait.”
                    </Text>
                  )}
                </Box>
              </Box>
            )}

            <Box display="flex" gridGap="12px" justifyContent="center">
              <Button
                variant="contained"
                color="secondary"
                onClick={buttonData.fn}
              >
                {buttonData.text}
              </Button>

              {showCancel && (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={openModal}
                >
                  Cancel project
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      )}

      {open && (
        <ConfirmCancel
          onCancel={closeModal}
          onConfirm={handleCancel}
          onClose={closeModal}
        />
      )}
    </Box>
  );
};
