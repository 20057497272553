import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { Experiment, PROJECT_VISIBILITY } from 'types';
import { getJSONDashboardCards, getProjects } from 'services';
import {
  ProjectCard,
  SectionTitleDivider,
  SingleSelect,
  Text,
} from 'components';
import { ProjectSearchBar } from '../components';
import InfiniteScroll from 'react-infinite-scroll-component';

import useStyles from './Community.styles';
import { sleep } from 'utils';
import { DebouncedSearch } from './DebouncedSearch';

type SortByValues = {
  value: string;
  label: string;
};

const SORT_BY_OPTIONS: SortByValues[] = [
  {
    value: 'recent',
    label: 'Most recent',
  },
  {
    value: 'oldest',
    label: 'Oldest',
  },
  {
    value: 'popularity',
    label: 'Popularity',
  },
];

export const Community = () => {
  const [experiments, setExperiments] = useState<Experiment[]>([]);
  const [loading, setLoading] = useState(false);

  const [topProjects, setTopProjects] = useState([]);

  const classes = useStyles();

  const [tasks, setTasks] = useState<number[]>([]);

  const pageCount = useRef(5);

  const [sort, setSort] = useState<string>(SORT_BY_OPTIONS[2].value);

  const fetchProps = useMemo(
    () => ({
      visibilities: [PROJECT_VISIBILITY.COMMUNITY, PROJECT_VISIBILITY.PUBLIC],
      tasks,
      sort,
    }),
    [tasks, sort]
  );

  const fetchExperiments = useCallback(
    async (cleanProjects: boolean = false) => {
      setLoading(true);

      if (cleanProjects) {
        setExperiments([]);
      }

      const response = await getProjects({
        ...fetchProps,
        pageSize: pageCount.current,
      });
      const exps = (response as any).experiments as Experiment[];

      pageCount.current += 5;

      await sleep(500);

      setExperiments(exps as Experiment[]);

      setLoading(false);
    },
    [fetchProps]
  );

  const onProjectSearchBarChange = useCallback((tags: number[]) => {
    pageCount.current = 10;
    setTasks(tags);
  }, []);

  useEffect(() => {
    fetchExperiments(true);
  }, [fetchExperiments]);

  const onSoryByChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setSort(event.target.value as string);
  };

  useEffect(() => {
    getJSONDashboardCards().then((data: any) => {
      setTopProjects(data.featured_projects);
    });
  }, []);

  return (
    <Box mx="195px" className={classes.viewContainer}>
      <Box
        mt="65px"
        display="flex"
        flexDirection="column"
        gridGap="8px"
        mb="24px"
      >
        <Text variant="h3" align="center">
          Welcome to Cogniflow Community
        </Text>
        <Text variant="paragraph1" align="center">
          Explore thousands of free and paid projects
        </Text>
      </Box>
      <Box mb="28px" display="flex" justifyContent="center">
        <DebouncedSearch />
      </Box>

      <Box mb="100px" mt="30px">
        {!!topProjects.length && (
          <Box>
            <Box mb="20px">
              <SectionTitleDivider>Featured projects</SectionTitleDivider>
            </Box>

            <Box className={classes.infiniteScroll}>
              {topProjects.map((proj: Experiment, idx: number) => (
                <ProjectCard
                  {...proj}
                  projectTask={proj.task}
                  key={proj.id}
                  showCreatedBy={false}
                />
              ))}
            </Box>
          </Box>
        )}
        <Box mb="20px" mt="30px">
          <SectionTitleDivider>Community projects</SectionTitleDivider>
        </Box>

        <Box display="flex" justifyContent="flex-start" mb="30px">
          <SingleSelect
            className={classes.select}
            label="Sort By"
            value={sort}
            options={SORT_BY_OPTIONS}
            onChange={onSoryByChange}
          />

          <ProjectSearchBar
            onChange={onProjectSearchBarChange}
            visibilities={[
              PROJECT_VISIBILITY.COMMUNITY,
              PROJECT_VISIBILITY.PUBLIC,
            ]}
            direction="column"
            align="center"
            justify="center"
            lineHeight="24px"
            showSearch={false}
          />
        </Box>
        {experiments.length > 0 && (
          <InfiniteScroll
            dataLength={experiments.length}
            next={fetchExperiments}
            hasMore={true}
            loader={<div></div>}
            style={{}}
            className={classes.infiniteScroll}
          >
            {experiments.map((props) => (
              <ProjectCard key={props.id} projectTask={props.task} {...props} />
            ))}
          </InfiniteScroll>
        )}

        {loading && (
          <Box
            mb="50vh"
            mt="30px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            gridGap="8px"
            flexDirection="column"
          >
            <CircularProgress />
            <Text variant="h4">Loading...</Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};
