import React, { useState, useMemo, useEffect } from 'react';
import { ModelDetails } from './ModelDetails/ModelDetails';
import { Grid, Typography } from '@material-ui/core';
import { PageContentTable } from 'components/PageContentTable';
import useStyles from './modelmetrics.styles';
import { ConfusionMatrixNotNormalized } from 'components/ConfusionMatrix/ConfusionMatrixNotNormalized';
import { ClassificationReportTable } from './ClassificationReportTable';
import { ConfusionMatrixNormalized } from 'components/ConfusionMatrix/ConfusionMatrixNormalized';
import {
  useRecoilValueLoadable,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { getModelById } from 'store/models';
import { useParams } from 'react-router-dom';
import { filterModelCategories } from 'utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SwitchControl } from 'components/SwitchControl/SwitchControl';
import { breadcrumbsState } from 'store/breadcrumbs';
import { currentExperimentState } from 'store/experiments';
import { Model } from 'types';
import { MainLayout } from 'components';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { useBreadcrumbInitialPath } from 'components/Breadcrumbs/useBreadcrumbInitialPath';

type TCurrentParams = {
  modelId: string;
};

export const ModelMetricsView = () => {
  const setBreadcrumbs = useSetRecoilState(breadcrumbsState);
  const experiment = useRecoilValue(currentExperimentState);
  const { modelId } = useParams<TCurrentParams>();
  const { state, contents } = useRecoilValueLoadable<Model | {}>(
    getModelById(modelId)
  );
  const [matrixType, setMatrixType] = useState('notNormalized');
  const classes = useStyles();
  const { text } = useBreadcrumbInitialPath(experiment);
  const categories = useMemo(() => {
    return state === 'hasValue'
      ? filterModelCategories(
          (contents as Model).result.classification_report
        ).sort()
      : [];
  }, [contents, state]);

  useEffect(() => {
    if (experiment) {
      const modelName = contents ? (contents as Model).name : '';
      setBreadcrumbs(['My Experiments', experiment.title, modelName]);
    }
  }, [setBreadcrumbs, experiment, state, contents]);

  const breadcrumbPaths = useMemo(() => {
    if (experiment) {
      return [
        { text: text, link: `/projects/dashboard` },
        {
          text: experiment?.title,
          link: `/experiment/${experiment?.id}/${modelId}`,
        },
        { text: 'Metrics', link: '' },
      ];
    }
    return [];
  }, [experiment, modelId, text]);

  return (
    <MainLayout>
      {state === 'hasValue' ? (
        <>
          <Breadcrumbs paths={breadcrumbPaths} />
          <Grid container justify="space-between" alignItems="center">
            <Typography variant="h1">Model metrics</Typography>
          </Grid>
          <Grid container className={classes.modelMetricsViewWrapper}>
            <Grid className={classes.pageContent}>
              <Grid
                className={`${classes.contentSection} ${classes.detailsAndContentBoxWrapper}`}
                id="model-details"
              >
                <Grid className={classes.modelDetailsTitle}>
                  <Typography
                    className={classes.containerTitle}
                    variant="body1"
                    align="left"
                  >
                    Model Details
                  </Typography>
                </Grid>
                <Grid className={classes.detailsAndTableContent}>
                  <Grid className={classes.modelDetails}>
                    <ModelDetails
                      model={contents}
                      task={experiment?.task || 0}
                    />
                  </Grid>
                  <Grid className={classes.contentTable}>
                    <PageContentTable
                      linksList={[
                        { text: 'Model details', redirect: '#model-details' },
                        {
                          text: 'Classification report',
                          redirect: '#classification-report',
                        },
                        {
                          text: 'Confusion matrix',
                          redirect: '#confusion-matrix',
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                className={classes.contentSection}
                id="classification-report"
              >
                <ClassificationReportTable
                  model={contents}
                  categories={categories}
                />
              </Grid>

              <Grid className={classes.contentSection} id="confusion-matrix">
                <Typography
                  className={classes.containerTitle}
                  variant="body1"
                  align="left"
                >
                  Confusion matrix
                </Typography>
                <Typography align="left" className={classes.textContentSpacing}>
                  The confusion matrix is an artifact that helps you identify
                  how the model confuses between different categories. Rows in
                  this matrix represent the expected categories (actual/true
                  labels) and columns indicate the model predictions. . For
                  example, if you are predicting emotions, a value of 10 in row
                  "Angry" and column "Sad" means that the model has confused 10
                  validation examples that should have been predicted as "Angry"
                  but were actually predicted as "Sad". That said, only diagonal
                  elements in this matrix represents successful predictions,
                  other elements not in the diagonal are model mistakes.
                </Typography>
                <Grid className={classes.matrixType}>
                  <SwitchControl
                    changeHandler={(value: string) => setMatrixType(value)}
                    label="Normalized"
                    defaultValue={false}
                  />
                </Grid>
                {matrixType === 'notNormalized' ? (
                  <ConfusionMatrixNotNormalized
                    matrix={(contents as Model).result.confusion_matrix.rows}
                    labels={categories}
                  />
                ) : (
                  <ConfusionMatrixNormalized
                    matrix={(contents as Model).result.confusion_matrix.rows}
                    labels={categories}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <CircularProgress />
      )}
    </MainLayout>
  );
};
