import React from 'react';
import { ConfusionMatrix } from '../ConfusionMatrix';
import { getArray2dMaxValue } from 'utils/matrix';
import { Square } from '../Square';

interface IConfusionMatrixNotNormalizedProps {
  labels: string[];
  matrix: Array<Array<number>>;
}

export const ConfusionMatrixNotNormalized = ({
  matrix,
  labels,
}: IConfusionMatrixNotNormalizedProps) => {
  const largestMatrixValue = getArray2dMaxValue(matrix);
  return (
    <ConfusionMatrix
      labels={labels}
      matrix={matrix}
      renderContent={(row, key, size, styles) => (
        <div className={styles} key={key}>
          {row.map((col, idx) => (
            <Square
              key={idx}
              value={col}
              colorPercentage={col / largestMatrixValue}
              tooltipText={`Actual ${labels[key]}, Prediction ${labels[idx]}`}
              size={size}
            />
          ))}
        </div>
      )}
    />
  );
};
