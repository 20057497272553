import { useMemo } from 'react';
import {
  Experiment,
  EXPERIMENT_VISIBILITY_NUMBER,
  EXPERIMENT_VISIBILITY_LABEL,
} from 'types';

const visibilityText: { [key: number]: string } = {
  [EXPERIMENT_VISIBILITY_NUMBER.PUBLIC]: 'Community projects',
  [EXPERIMENT_VISIBILITY_NUMBER.PRIVATE]: 'My projects',
  [EXPERIMENT_VISIBILITY_NUMBER.COMPANY]: 'My projects',
  [EXPERIMENT_VISIBILITY_NUMBER.COMMUNITY]: 'Community projects',
};

export const useBreadcrumbInitialPath = (
  experiment: Experiment | undefined
) => {
  return useMemo(
    () => ({
      text: visibilityText[experiment?.visibility || 0],
      visibility: EXPERIMENT_VISIBILITY_LABEL[
        experiment?.visibility || 0
      ].toLocaleLowerCase(),
    }),

    [experiment]
  );
};
