import React, { ReactNode, useMemo, useState } from 'react';

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Chip,
  Dialog,
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';

import { ActionButton } from 'components';

import { MemberForm } from './MemberForm';

import {
  Company as CompanyType,
  CompanyUser as CompanyUserType,
  USER_STATUS,
} from 'types';

import { getFormattedDate } from 'utils';

import { useMembers } from './useMembers';

import { ResendInvitation } from './ResendInvitation';

import useStyle from './members.styles';

interface MembersProps {
  company: CompanyType;
  onSuccess?(payload: { msg: string }): void;
  onError?(payload: { msg: string }): void;
}

export const Members = ({ company, onSuccess, onError }: MembersProps) => {
  const classes = useStyle();
  const [showAddMember, setShowAddMember] = useState<boolean>(false);
  const {
    users,
    setUsers,
    removeUser,
    userToRemove,
    setUserToRemove,
  } = useMembers({
    company,
    onSuccess,
    onError,
  });

  const handleSuccess = ({
    msg,
    user,
  }: {
    msg: string;
    user: CompanyUserType;
  }) => {
    setShowAddMember(false);
    setUsers([...users, user]);
    onSuccess?.({ msg });
  };

  const statusElement: { [key: number]: ReactNode } = useMemo(
    () => ({
      [USER_STATUS.ACTIVE]: (
        <Chip
          label="Active"
          size="small"
          style={{ lineHeight: 1, backgroundColor: '#b5ffa3' }}
        />
      ),
      [USER_STATUS.INACTIVE]: (
        <Chip label="Inactive" size="small" style={{ lineHeight: 1 }} />
      ),

      [USER_STATUS.ACTIVATION_PENDING]: (
        <Chip label="Inactive" size="small" style={{ lineHeight: 1 }} />
      ),
    }),
    []
  );

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <ActionButton
          onClick={() => setShowAddMember(true)}
          variant="contained"
          color="secondary"
          startIcon={<Add />}
        >
          Add Member
        </ActionButton>
      </Box>
      <TableContainer className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Role</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Expiration</TableCell>
              <TableCell align="left">Invite</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, idx) => (
              <TableRow key={user.id}>
                <TableCell align="left">{user.user_name}</TableCell>
                <TableCell align="left">{user.email}</TableCell>
                <TableCell align="left">
                  {user.id === company.id_owner ? 'Owner' : 'Member'}
                </TableCell>
                <TableCell align="left">
                  {statusElement[user.status || 0]}
                </TableCell>
                <TableCell align="center">
                  {user.status === USER_STATUS.ACTIVATION_PENDING &&
                    user.account_token_expiration &&
                    getFormattedDate(
                      new Date(user.account_token_expiration || ''),
                      'PP'
                    )}
                </TableCell>
                <TableCell align="center">
                  {user.status === USER_STATUS.ACTIVATION_PENDING && (
                    <ResendInvitation
                      email={user.email}
                      expiration={user.account_token_expiration || ''}
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  {user.id !== company.id_owner && (
                    <Tooltip title="Remove Member">
                      <IconButton
                        onClick={() => setUserToRemove(user)}
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* ADD USER DIALOG */}
      <Dialog
        open={showAddMember}
        onClose={() => setShowAddMember(false)}
        maxWidth="sm"
        fullWidth
      >
        <MemberForm company={company} onSuccess={handleSuccess} />
      </Dialog>

      {/* REMOVE USER DIALOG */}
      <Dialog open={!!userToRemove} onClose={() => setUserToRemove(null)}>
        <Box
          display="flex"
          flexDirection="column"
          className={classes.removeUserRoot}
        >
          <Typography className="" variant="h3" align="center">
            Are you sure you want to remove this user from the organization
          </Typography>
          <Typography className="" variant="body1" align="center">
            Any experiment and usage of the guest user will be transferred to
            the company’s owner as part of this process.
          </Typography>
          <ActionButton
            onClick={() => {
              setUserToRemove(null);
              removeUser(userToRemove?.id || '');
            }}
            size="large"
            color="secondary"
            fullWidth
            variant="contained"
          >
            Yes, remove user
          </ActionButton>
          <ActionButton
            onClick={() => setUserToRemove(null)}
            size="large"
            color="secondary"
            fullWidth
            variant="outlined"
          >
            No, keep user
          </ActionButton>
        </Box>
      </Dialog>
    </>
  );
};
