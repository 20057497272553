import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  current: {
    position: 'relative',
    height: 120,
    width: '100%',
    background: '#f7f9ff',
    border: '1px solid #d8ddeb',
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  currentImage: {
    height: 64,
    width: 64,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '100%',
  },
}));
