import { AnswerProps, ActionProps } from './profileTypes';

/**
 * TODO - We have to take a better approach to handle the states for the profile form
 * We can use simple states since the amount of inputs will stay the same always
 *
 */

export const reducer = (state: AnswerProps[], action: ActionProps) => {
  switch (action.type) {
    case 'ADD_ANSWER': {
      const allOptions = [...state];
      const { index, answerId, textAnswer } = action;

      if (allOptions[index].text_answer) {
        allOptions[index].text_answer = textAnswer || '';
        return allOptions;
      }

      if (allOptions[index].id_options && answerId) {
        const options = allOptions[index].id_options;

        if (options?.includes(answerId)) {
          allOptions[index].id_options = options.filter(
            (option: string) => option !== answerId
          );
        } else {
          if (options && options.length >= 3) return allOptions;
          options?.push(answerId);
        }
      }
      return allOptions;
    }

    case 'SET_ANSWER': {
      const allOptions = [...state];
      const { index, answerId, textAnswer } = action;

      if (allOptions[index].id_options && answerId) {
        allOptions[index].id_options = [answerId];
      } else {
        allOptions[index].text_answer = textAnswer || '';
      }

      return allOptions;
    }

    default:
      return state;
  }
};
