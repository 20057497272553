import React from 'react';
import { Dialog, Grid, IconButton, Typography } from '@material-ui/core';
import { ActionButton } from 'components/ActionButton';
import useStyles from './confirm-cancel.styles';
import { StopLogo } from 'assets';
import { IconClose } from 'assets';

interface Props {
  onConfirm: () => void;
  onCancel: () => void;
  onClose: () => void;
}

export const ConfirmCancel = ({ onClose, onConfirm, onCancel }: Props) => {
  const classes = useStyles();
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open
      classes={{
        paper: classes.paper,
      }}
    >
      <IconButton onClick={onClose} className={classes.close}>
        <IconClose />
      </IconButton>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="flex-start"
      >
        <StopLogo />
        <Typography className={classes.title} variant="h3" align="center">
          Are you sure you want to stop running this experiment?
        </Typography>
        <Typography className={classes.subtitle} variant="body1" align="center">
          The experiment will still be available with any models we’ve trained
          so far.
        </Typography>
        <ActionButton
          className={classes.confirmButton}
          size="large"
          color="secondary"
          fullWidth
          variant="contained"
          onClick={onConfirm}
        >
          Yes, stop running
        </ActionButton>
        <ActionButton
          size="large"
          color="secondary"
          fullWidth
          variant="outlined"
          onClick={onCancel}
        >
          No, keep running
        </ActionButton>
      </Grid>
    </Dialog>
  );
};
