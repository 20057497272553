import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(({ breakpoints }) => ({
  container: {
    minHeight: '100vh',
    background: '#f7f9ff',
  },
  tabs: {
    marginTop: 24,
  },
  experBody: {
    marginBottom: '100px',
  },
  tryAnotherBtn: {
    marginLeft: '10px',
  },
  tryAnotherMsg: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    color: colors.blue70,
  },
  modelNameTitle: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.blue70,
  },
  alertContainer: {
    position: 'absolute',
  },
  mainContainer: {
    margin: '30px 0',
  },
  btnBack: {
    alignSelf: 'start',
    position: 'absolute',
    [breakpoints.down('sm')]: {
      position: 'initial',
      marginBottom: 16,
    },
  },
  tabsElement: {
    marginBottom: '30px',
  },
  hide: {
    display: 'none',
  },
}));
