export const textDatasets = [
  {
    name: 'BBC news classification',
    description:
      'Train a model that is capable of recognizing the topic of a news article. The data was collected from the BBC news web portal.',
    url:
      'https://drive.google.com/u/0/uc?id=1THO26xCOeIrl9TPVUvxmTbBJmdT3LUuh&export=download',
    stats: [
      { category: 'tech', amount: 401 },
      { category: 'business', amount: 510 },
      { category: 'sport', amount: 511 },
      { category: 'entertainment', amount: 386 },
      { category: 'politics', amount: 417 },
    ],
  },
  {
    name: 'Customer support intention recognition',
    description: `Train a model that is capable of recognizing user intent in customer support comments.. The dataset has twenty-seven classes, with a total of 21672 examples, from intentions like 'cancel_order', 'get_refund' or 'complaint'.`,
    url:
      'https://drive.google.com/u/0/uc?id=1O4ZpE14fnxiEygIN9uae4uQr2mmyFfBe&export=download',
    stats: [
      {
        category: 'complaint',
        amount: 746,
      },
      {
        category: 'track_order',
        amount: 1303,
      },
      {
        category: 'contact_customer_service',
        amount: 2055,
      },
      {
        category: 'payment_issue',
        amount: 4366,
      },
      {
        category: 'check_cancellation_fee',
        amount: 360,
      },
      {
        category: 'create_account',
        amount: 2123,
      },
      {
        category: 'check_invoices',
        amount: 1013,
      },
      {
        category: 'delete_account',
        amount: 913,
      },
      {
        category: 'recover_password',
        amount: 986,
      },
      {
        category: 'get_invoice',
        amount: 1430,
      },
      {
        category: 'check_refund_policy',
        amount: 470,
      },
      {
        category: 'contact_human_agent',
        amount: 1093,
      },
      {
        category: 'delivery_period',
        amount: 141,
      },
      {
        category: 'track_refund',
        amount: 303,
      },
      {
        category: 'get_refund',
        amount: 1150,
      },
      {
        category: 'newsletter_subscription',
        amount: 236,
      },
      {
        category: 'change_order',
        amount: 926,
      },
      {
        category: 'delivery_options',
        amount: 360,
      },
      {
        category: 'switch_account',
        amount: 273,
      },
      {
        category: 'review',
        amount: 580,
      },
      {
        category: 'registration_problems',
        amount: 130,
      },
      {
        category: 'edit_account',
        amount: 133,
      },
      {
        category: 'change_shipping_address',
        amount: 110,
      },
      {
        category: 'place_order',
        amount: 70,
      },
      {
        category: 'check_payment_methods',
        amount: 270,
      },
      {
        category: 'set_up_shipping_address',
        amount: 96,
      },
      {
        category: 'cancel_order',
        amount: 36,
      },
    ],
  },
];

export const imageDatasets = [
  {
    name: 'Coke or Pepsi?',
    description:
      'Train a model capable of recognizing if a picture belongs to whether Coke or Pepsi. The dataset has two classes, Coke and Pepsi, with 1410 and 1295 images, respectively.',
    url:
      'https://drive.google.com/u/0/uc?id=1are-zefIhygpENpBIynG9mTzdAMCQx9W&export=download',
    stats: [
      {
        category: 'coke',
        amount: 1410,
      },
      {
        category: 'pepsi',
        amount: 1295,
      },
    ],
  },
  {
    name: 'Tire or not',
    description:
      'Train a model capable of recognizing if a picture belongs to a car Tire or not. The dataset has two classes, Tire and Other, with 274 and 353 images, respectively.',
    url:
      'https://drive.google.com/u/0/uc?id=1Fx7pxegVKWc5cjyGpLfcA690x4MMZdij&export=download',
    stats: [
      {
        category: 'Tire',
        amount: 353,
      },
      {
        category: 'Other',
        amount: 274,
      },
    ],
  },
  {
    name: 'Chest Xray classification',
    description:
      'Train a model capable of recognizing if a patient has Covid, Pneumonia or if she/he is healthy, based on chest X-Ray scans. The dataset has three classes, Covid, Pneumonia and Normal, with 132, 90 and 90 images, respectively.',
    url:
      'https://drive.google.com/uc?id=1KpW-L7NpV9jlEUy-QePYRtdMn0GPhRR7&export=download',
    stats: [
      {
        category: 'Covid',
        amount: 132,
      },
      {
        category: 'Normal',
        amount: 90,
      },
      {
        category: 'Pneumonia',
        amount: 90,
      },
    ],
  },
];

export const audioDatasets = [
  {
    name: 'Environmental Sounds Classification',
    description:
      'Train a model capable of recognizing environmental sounds. The dataset has sixteen classes, from birds singing to a helicopter flying, trough sirens, clock ticking or people sneezing. Each of the sixteen classes has 32 examples, achieving a total of 512 audio files for training.',
    url:
      'https://drive.google.com/u/0/uc?id=1R84bUXBdab9BKiYblgPfzUshSO6TKFyE&export=download',
    stats: [
      {
        category: 'birds',
        amount: 32,
      },
      {
        category: 'cat',
        amount: 32,
      },
      {
        category: 'chainsaw',
        amount: 32,
      },
      {
        category: 'clicking',
        amount: 32,
      },
      {
        category: 'cow',
        amount: 32,
      },
      {
        category: 'crying',
        amount: 32,
      },
      {
        category: 'dog',
        amount: 32,
      },
      {
        category: 'helicopter',
        amount: 32,
      },
      {
        category: 'hen',
        amount: 32,
      },
      {
        category: 'rooster',
        amount: 32,
      },
      {
        category: 'siren',
        amount: 32,
      },
      {
        category: 'sneezing',
        amount: 32,
      },
      {
        category: 'snoring',
        amount: 32,
      },
      {
        category: 'ticking',
        amount: 32,
      },
      {
        category: 'water',
        amount: 32,
      },
      {
        category: 'wind',
        amount: 32,
      },
    ],
  },
];

export const qandaDatasets = [
  {
    name: 'No-Code AI KB',
    description:
      'Create a QA experiment to ask questions about No-Code AI. The KB has four files, one .docx, one .pdf and two .txt files.',
    url:
      'https://drive.google.com/u/0/uc?id=1DiJ7kxWz7i7H6NSplC7Nd2GX46EeJ2Kd&export=download',
    stats: [
      { category: 'ai.docx', amount: 565976 },
      {
        category:
          'How Artificial Intelligence Is Changing Business _ Cogniflow Blog.pdf',
        amount: 271713,
      },
      { category: 'no-code1.txt', amount: 630 },
      { category: 'no-code2.txt', amount: 2287 },
    ],
  },
  {
    name: 'English Small AI KB',
    description:
      'Create a QA experiment to ask questions about AI (English). The KB has three text files, with data extracted from Wikipedia.',
    url:
      'https://drive.google.com/u/0/uc?id=1w9uvRMaxH3Dfd0RfL-ZbRz93Iw51KI_D&export=download',
    stats: [
      { category: 'doc_1.txt', amount: 3433 },
      { category: 'doc_2.txt', amount: 1065 },
      { category: 'doc_3.txt', amount: 6740 },
    ],
  },
  {
    name: 'Spanish Small AI KB',
    description:
      'Create a QA experiment to ask questions about AI (Spanish). The KB has three text files, with data extracted from Wikipedia.',
    url:
      'https://drive.google.com/u/0/uc?id=1TZXf84T_eVudbnaVCFHGR_g0uCGCzOlx&export=download',
    stats: [
      { category: 'doc_1.txt', amount: 1238 },
      { category: 'doc_2.txt', amount: 847 },
      { category: 'doc_3.txt', amount: 3248 },
    ],
  },
];
