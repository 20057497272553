import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  emptyWrapper: {
    paddingTop: 32,
    alignItems: 'center',
  },
}));
