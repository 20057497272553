import React, { useCallback } from 'react';
import { ActionButton } from '../ActionButton';
import { GenericModal, GenericModalState } from '../GenericModal/GenericModal';
import { AudioRecord } from './AudioRecord';
import { useRecoilState } from 'recoil';

interface AudioCaptureProps {
  onRecordClick(): void;
  submitText?: string | null;
  colorButton?: string;
}

export const AudioCapture = ({
  onRecordClick,
  submitText,
  colorButton,
}: AudioCaptureProps) => {
  const [togleModal, setTogelModal] = useRecoilState(GenericModalState);

  const handleShowModal = useCallback(() => {
    setTogelModal(!togleModal);
    onRecordClick();
  }, [setTogelModal, togleModal, onRecordClick]);

  return (
    <>
      <ActionButton
        variant="contained"
        type="submit"
        onClick={handleShowModal}
        color="secondary"
        customColor={colorButton}
        size="large"
      >
        {submitText || 'Record audio'}
      </ActionButton>
      <GenericModal size="xs">
        <AudioRecord />
      </GenericModal>
    </>
  );
};
