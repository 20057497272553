import React, { useState } from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';

interface SwitchControlProps {
  changeHandler(value: string): void;
  label: string;
  defaultValue: boolean;
}

export const SwitchControl = ({
  changeHandler,
  label,
  defaultValue,
}: SwitchControlProps) => {
  const [checked, setChecked] = useState(defaultValue);

  const onChangeHandler = () => {
    changeHandler(!checked ? 'normalized' : 'notNormalized');
    setChecked((state) => !state);
  };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={onChangeHandler}
          name="checkedB"
          color="primary"
        />
      }
      label={label}
    />
  );
};
