import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';
import { ICogniflowTheme } from 'material/theme';

export default makeStyles<ICogniflowTheme>(() => ({
  fileItemWrapper: {
    border: `1px solid ${colors.blue15}`,
    borderRadius: '8px',
    padding: '8px 12px',
    display: 'flex',
    justifyContent: 'space-between',
  },

  fileName: {
    fontWeight: 'bold',
    fontSize: '12px',
  },
  docIcon: {
    width: '32px',
    height: '32px',
  },

  fileText: {
    fontSize: '12px',
  },

  deleteIcon: {
    cursor: 'pointer',
  },
}));
