import React, { useCallback, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { Text } from 'components';
import useStyles from './Extractor.styles';
import { EntityProps } from 'types';
import { generateId } from 'utils';

import { EmptyEntities } from './EmptyEntities';
import { EntityTable } from './EntityTable';
import { EntityForm } from './EntityForm';

import Papa, { ParseResult } from 'papaparse';

import { ToastContainer, toast } from 'react-toastify';

type EntitiesExtractorProps = {
  flag?: string;
  onEntitiesChange(entities: EntityProps[]): void;
  initialEntities?: EntityProps[];
  showActions?: boolean;
  allowImport?: boolean;
  allowExport?: boolean;
};

export const Extractor = ({
  onEntitiesChange,
  initialEntities,
  showActions = true,
  allowImport = false,
  allowExport = false,
}: EntitiesExtractorProps) => {
  const classes = useStyles();
  const [entities, setEntities] = useState<EntityProps[]>(
    initialEntities || []
  );
  const importInputRef = React.useRef<HTMLInputElement>(null);

  const addEntityHandler = ({
    name,
    description,
    output_format,
    input_format,
    color,
  }: any) => {
    const newEntity = {
      id: generateId(),
      name,
      description,
      input_format,
      output_format,
      color,
    };

    setEntities((s) => [...s, newEntity]);

    onEntitiesChange([...entities, newEntity]);
  };

  const IMPORT_ENTITIES_LIMIT = 100;

  const editEntityHandler = (entity: EntityProps) => {
    const entriesTemp = [...entities];
    const idx = entities.findIndex((el) => el.id === entity.id);

    entriesTemp[idx] = entity;
    setEntities(entriesTemp);
    onEntitiesChange(entriesTemp);
  };

  const deleteEntity = (id: string) => {
    const newEntityList = entities.filter((item) => item.id !== id);
    setEntities(newEntityList);
    onEntitiesChange(newEntityList);
  };

  const importFiles = useCallback(() => {
    if (importInputRef.current) {
      importInputRef.current.click();
    }
  }, []);

  const uploadEntitiesHandler = (event: any) => {
    const excludedEntities = entities.map((entity) => entity.name);
    const excludedFound: string[] = [];

    const [file] = event.target.files;
    if (file) {
      Papa.parse(file, {
        complete: (results: ParseResult<any>) => {
          const [, ...data] = results.data;

          if (data.length > IMPORT_ENTITIES_LIMIT) {
            toast.error(
              `You can only import ${IMPORT_ENTITIES_LIMIT} entities at a time`,
              {
                position: toast.POSITION.TOP_CENTER,
                toastId: 'import-entities',
              }
            );
            return;
          }

          const addedEntities = [];

          for (const row of data) {
            const [name, description, output_format] = row;

            if (!name) {
              continue;
            }

            if (excludedEntities.includes(name)) {
              excludedFound.push(name);
              continue;
            }

            const entity = {
              id: generateId(),
              name,
              description,
              input_format: '',
              output_format,
              color: '',
            };

            addedEntities.push(entity);
          }

          onEntitiesChange([...entities, ...addedEntities]);
          setEntities([...entities, ...addedEntities]);

          if (excludedFound.length > 0) {
            toast.warn(
              `The following entities were not imported because they already exist: ${excludedFound.join(
                ', '
              )}`,
              {
                position: toast.POSITION.TOP_CENTER,
                toastId: 'import-entities',
              }
            );
          }
          if (importInputRef.current) {
            importInputRef.current.value = '';
          }
        },
      });
    }
  };

  const entityExists = (entityName: string) => {
    return entities.some((entity) => entity.name === entityName);
  };

  const exportEntities = () => {
    const csvContent = Papa.unparse(entities, {
      header: true,
      columns: ['name', 'description', 'output_format'],
    });

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'entities.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={classes.entriesContainer}
    >
      <ToastContainer />
      <input
        type="file"
        ref={importInputRef}
        hidden
        onChange={uploadEntitiesHandler}
        accept=".csv"
      />
      <Box>
        {entities.length > 0 ? (
          <Box className={classes.tableContainer}>
            <Box display="flex" className={classes.addEntryWrapper}>
              <Text variant="h4" className={classes.entitiesTitle}>
                Entities
              </Text>

              <Box display="flex" gridGap="12px">
                <EntityForm
                  entityExists={entityExists}
                  addEntityHandler={addEntityHandler}
                  openModalElement={
                    showActions ? (
                      <Button
                        className={classes.actionButton}
                        variant="contained"
                        color="secondary"
                        size="medium"
                      >
                        Add entity
                      </Button>
                    ) : null
                  }
                />

                {allowImport && (
                  <Button
                    className={classes.actionButton}
                    variant="outlined"
                    color="secondary"
                    size="medium"
                    onClick={importFiles}
                  >
                    Import entities
                  </Button>
                )}

                {allowExport && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="medium"
                    onClick={exportEntities}
                    className={classes.actionButton}
                  >
                    Export entities
                  </Button>
                )}
              </Box>
            </Box>

            <EntityTable
              entities={entities}
              editEntityHandler={editEntityHandler}
              deleteEntity={deleteEntity}
              showActions={showActions}
            />
          </Box>
        ) : (
          <Box
            style={{
              borderRadius: '10px',
            }}
          >
            <EmptyEntities
              entityExists={entityExists}
              addEntityHandler={addEntityHandler}
              addFromCsvHandler={importFiles}
              showActions={showActions}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
