import { Grid, Box } from '@material-ui/core';
import React, { useMemo } from 'react';
import { PredictResponse } from 'types';
import { ResponseBox } from '../ResponseBox';
import useStyles from './TextCustomExtractor.styles';

import { ExtractorResult, Text } from 'components';

interface TextCustomExtractorProps {
  textResponse: PredictResponse;
  text: string;
}

export const TextCustomExtractor = ({
  textResponse,
}: TextCustomExtractorProps) => {
  const classes = useStyles();
  const wereEntitiesFound = useMemo(() => !!textResponse?.result.length, [
    textResponse,
  ]);

  return (
    <Box>
      <ResponseBox
        textResponse={textResponse}
        renderHeader={() => (
          <Text variant="paragraph1" className={classes.subtitle}>
            Entities
          </Text>
        )}
        renderBody={() => (
          <>
            <Grid className={classes.badge}>
              {wereEntitiesFound ? (
                <ExtractorResult response={textResponse} />
              ) : (
                'I could not find anything'
              )}
            </Grid>
          </>
        )}
      />
    </Box>
  );
};
