import React from 'react';
import { withStyles, Tab, TabProps, Box } from '@material-ui/core';
import { colors } from 'material';

interface AntTabProps extends TabProps {
  chip?: string;
}

export const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: 600,
    marginRight: theme.spacing(4),
    color: theme.palette.secondary.main,
    lineHeight: '1.46',
    letterSpacing: 'normal',
    paddingTop: 14,
    paddingBottom: 14,
    marginBottom: 12,
    '&:hover': {
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.primary.dark,
    },
    '&:focus': {
      color: theme.palette.primary.dark,
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 32,
    },
    '& .chip': {
      backgroundColor: colors.blue70,
      display: 'inline-block',
      color: 'white',
      fontSize: '12px',
      padding: '0 4px',
      borderRadius: '4px',
    },
  },
  selected: {
    '& .chip': {
      backgroundColor: colors.orange,
      display: 'inline-block',
      color: 'white',
      fontSize: '12px',
      padding: '0 4px',
      borderRadius: '4px',
    },
  },
}))(({ label, chip, ...props }: AntTabProps) => (
  <Tab
    disableRipple
    {...props}
    label={
      <Box>
        {label} {chip && <Box className="chip">{chip}</Box>}
      </Box>
    }
  ></Tab>
));
