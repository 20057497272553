import React, { ReactNode } from 'react';
import {
  DialogContent,
  DialogTitle,
  DialogActions,
  Dialog,
} from '@material-ui/core';

import useStyles from './simpledialog.styles';

type SimpleDialogProps = {
  header?: ReactNode;
  children: ReactNode;
  open: boolean;
  handleClose(): void;
  actionButtons?: ReactNode;
  maxW?: false | 'xl' | 'xs' | 'sm' | 'md' | 'lg' | undefined;
};

export const SimpleDialog = ({
  open,
  handleClose,
  actionButtons,
  header,
  maxW,
  children,
}: SimpleDialogProps) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.modalContainer}
      style={{ maxWidth: '100%' }}
    >
      <DialogTitle id="alert-dialog-title">{header}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions
        style={{
          justifyContent: 'start',
        }}
      >
        {actionButtons}
      </DialogActions>
    </Dialog>
  );
};
