import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, Collapse, IconButton } from '@material-ui/core';
import { SettingsIcon } from 'assets';
import { Text } from 'components';

import useStyles from './ProjectConfiguration.styles';

import { TextConfig } from '../../../Ready/AccordionConfig/TextConfig';
import { ImageConfig } from '../../../Ready/AccordionConfig/ImageConfig';
import { AudioConfig } from '../../../Ready/AccordionConfig/AudioConfig';

import { AdvancedConfig, PROJECT_TYPE } from 'types';

import { getExperimentConfig } from 'services/experiments';
import { Chevron } from 'components/Icons/Chevron';

export interface ProjectConfigurationProps {
  task: number;
  type: number;
  config: AdvancedConfig;
  setConfigHandler(data: AdvancedConfig): void;
  setShowingTagHandler(value: boolean): void;
  configChangeHandler(data: AdvancedConfig): void;
}

export const ProjectConfiguration = ({
  task,
  type,
  config,
  setConfigHandler,
  setShowingTagHandler,
  configChangeHandler,
}: ProjectConfigurationProps) => {
  const classes = useStyles();

  const applyDefault = useCallback(async () => {
    const resp = await getExperimentConfig(type.toString(), task.toString());
    setConfigHandler(resp);

    setShowingTagHandler(true);
  }, [type, setShowingTagHandler, setConfigHandler, task]);

  const optionProps = useMemo(
    () => ({
      configChangeHandler,
      config,
      task,
      applyDefault,
      type,
    }),
    [type, task, config, configChangeHandler, applyDefault]
  );

  const options: { [key: number]: ReactNode } = useMemo(
    () => ({
      [PROJECT_TYPE.TEXT]: <TextConfig {...optionProps} />,
      [PROJECT_TYPE.IMAGE]: <ImageConfig {...optionProps} />,
      [PROJECT_TYPE.AUDIO]: <AudioConfig {...optionProps} />,
    }),
    [optionProps]
  );

  useEffect(() => {
    getExperimentConfig(type.toString(), task.toString()).then((resp) => {
      setConfigHandler(resp);
    });
  }, [task, setConfigHandler]);

  const [collapsed, setCollapsed] = useState(false);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    const getDefaultConfig = async () => {
      const resp = await getExperimentConfig(type.toString());

      setConfigHandler(resp);
    };
    getDefaultConfig();
  }, [setConfigHandler, type]);

  return (
    <>
      <Box className={classes.validationDatasetWrapper}>
        <Box className={classes.section}>
          <Box display="flex" gridGap="8px" alignItems="center">
            <SettingsIcon className={classes.icon} />
            <Text variant="paragraph1">Project configuration</Text>
            <Text className={classes.defaultText} variant="button">
              Optional
            </Text>
          </Box>

          <Box className={classes.editIconWrapper}>
            <IconButton
              disableRipple
              onClick={handleCollapse}
              style={{ padding: 0 }}
            >
              <Chevron direction={!collapsed ? 'down' : 'up'} />
            </IconButton>
          </Box>
        </Box>
        <Collapse in={collapsed} className={classes.collapsibleContainer}>
          <Box mt="32px" px="32px">
            <Box>
              <Text
                className={classes.valdiationTitle}
                variant="h3"
                align="left"
              >
                Project configuration
              </Text>
              {options[type]}
            </Box>
          </Box>
        </Collapse>
      </Box>

      {/* <ControlledModal isOpen={open} closeHandler={closeHandler}>
        <Text variant="h3">Project configuration</Text>
        {options[type]}
      </ControlledModal> */}
    </>
  );
};
