import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  container: {
    width: 1208,
    marginTop: 40,
  },
  linkDecoration: {
    color: colors.orange,
    marginTop: '16px',
  },
}));
