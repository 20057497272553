import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { currentModelState } from 'store/models';

import { Application, Experiment } from 'types';
import { getApplication } from 'services/application';
import { useApplicationContext } from 'context';

export const useApplication = ({
  applicationId,
}: {
  applicationId: string;
}) => {
  const [loading, setLoading] = useState(true);
  const [application, setApplication] = useState<Application | null>(null);
  const { setApplicationState } = useApplicationContext();
  const [currentModel, setModel] = useRecoilState(currentModelState);
  const [experiment, setExperiment] = useState<Experiment | null>(null);
  const [showDescription, setShowDescription] = useState(false);

  useEffect(() => {
    const fetchModel = async () => {
      setLoading(true);
      setModel(undefined);
      try {
        const app = await getApplication(applicationId);
        setApplication(app);
        setModel({ ...app.model, id: applicationId });
        setExperiment(app.experiment);
      } catch {}
      setLoading(false);
    };
    fetchModel();
  }, [applicationId, setModel, setExperiment, setLoading]);

  useEffect(() => {
    setApplicationState?.(application);
    return () => {
      setApplicationState?.(null);
    };
  }, [application, setApplicationState]);

  return {
    applicationId,
    loading,
    application,
    currentModel,
    experiment,
    showDescription,
    setShowDescription,
  };
};
