import React, { useMemo, useState } from 'react';
import { TestDropzone } from 'components';
import { Grid, Typography } from '@material-ui/core';
import { ImgDrag } from 'assets';

import useStyles from './fsdropzones.styles';
import { convertBase64ToFile, toBase64File } from 'services';

type FSDropzoneProps = {
  onFSChangeHandler?(img: string): void;
  initialImg?: string;
};

export const FSDropzone = ({
  initialImg,
  onFSChangeHandler,
}: FSDropzoneProps) => {
  const classes = useStyles();

  const [image, setImage] = useState(initialImg || '');

  const imgUrl = useMemo(
    () => window.URL.createObjectURL(convertBase64ToFile(image || '')),
    [image]
  );

  const onDropHandler = (files: File[]) => {
    const [file] = files;

    toBase64File(file).then((base64File) => {
      const b64Img = (base64File as string).split(',')[1];
      setImage(b64Img);
      onFSChangeHandler?.(b64Img);
    });
  };

  return (
    <TestDropzone
      dropzoneOptions={{ accept: 'image/*' }}
      customClass={classes.dropzone}
      onDrop={onDropHandler}
    >
      {image ? (
        <img src={imgUrl} alt="loaded img" className={classes.loadedImage} />
      ) : (
        <Grid container direction="column" alignContent="center">
          <img className={classes.imageCover} src={ImgDrag} alt="Cover" />
          <Typography variant="body2">Drag and drop here</Typography>
        </Grid>
      )}
    </TestDropzone>
  );
};
