import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';

import useStyles from './InformativeSection.styles';
import { WhatsNew } from './WhatsNew';
import { ChatBotFormSection } from './ChatBotFormSection';
import { getJSONDashboardCards } from 'services';

const RIGHT_CARD_INDEX = 1;
const LEFT_CARD_INDEX = 0;

export const InformativeSection = () => {
  const [news, setNews] = useState<any[]>([]);
  const classes = useStyles();

  useEffect(() => {
    getJSONDashboardCards().then((data: any) => {
      setNews([data.dashboard_left_cards, data.dashboard_right_cards]);
    });
  }, []);

  return (
    <Box
      className={classes.informativeSectionWrapper}
      display="flex"
      justifyContent="space-between"
      gridGap="30px"
    >
      {news[LEFT_CARD_INDEX] && !!news[LEFT_CARD_INDEX].length && (
        <Box width="55%">
          <WhatsNew news={news[LEFT_CARD_INDEX]} />
        </Box>
      )}

      {news[RIGHT_CARD_INDEX] && !!news[RIGHT_CARD_INDEX].length && (
        <Box width="45%">
          <ChatBotFormSection news={news[RIGHT_CARD_INDEX]} />
        </Box>
      )}
    </Box>
  );
};
