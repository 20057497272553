import { Grid, IconButton, Typography } from '@material-ui/core';
import React, { ReactNode, FC } from 'react';
import { Chevron } from 'components/Icons/Chevron';
import { EXPERIMENT_TASK } from 'types';
import useStyles from '../accordionadvancedconfig.styles';
import { useQueryParams } from 'hooks';

type URLQuery = {
  'display-advanced-setting': string;
};

export interface ConfigWrapperProps {
  children: ReactNode;
  task: string;
  toggleCollapse(): void;
  collapseStatus: boolean;
  isTagShowing: boolean;
}

export const ConfigWrapper: FC<ConfigWrapperProps> = ({
  children,
  task,
  toggleCollapse,
  collapseStatus,
  isTagShowing,
}) => {
  const classes = useStyles();
  const query = useQueryParams<URLQuery>();

  const excludeAdvancedOptionsFor = [
    EXPERIMENT_TASK.TEXT_QANDA,
    EXPERIMENT_TASK.IMAGE_OBJECT_DETECTION,
    ...(query['display-advanced-setting'] === 'true'
      ? []
      : [EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR]),
  ];

  return (
    <Grid
      container
      direction="column"
      className={classes.advanceOptionContainer}
      alignItems="center"
      justify="flex-start"
    >
      <Grid
        item
        container
        justify="space-between"
        className={classes.titleContainer}
        alignItems="center"
      >
        <Grid container justify="space-between" alignItems="center">
          <Grid>
            <Typography
              variant="body1"
              className={classes.advanceTitleCollpasible}
            >
              Configuration
              {isTagShowing ? (
                <Typography className={classes.defaultText} variant="button">
                  Default
                </Typography>
              ) : null}
            </Typography>
          </Grid>
          {!excludeAdvancedOptionsFor.includes(parseInt(task)) && (
            <Grid container md={6} justify="flex-end" alignItems="center">
              <Typography className={classes.advanceSubTitleCollpasible}>
                Advanced Options
              </Typography>
              <IconButton onClick={toggleCollapse}>
                <Chevron direction={collapseStatus ? 'up' : 'down'} />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      {children}
    </Grid>
  );
};
