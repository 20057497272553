import { Button, Collapse, Grid, Typography } from '@material-ui/core';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useHistory } from 'react-router-dom';
import { IcoCheck, HelpIcon, DownloadIcon } from 'assets';
import { currentExperimentState } from 'store/experiments';
import { useRecoilValue } from 'recoil';
import useStyle from './detailcollapsible.styles';
import { useModelLogs } from 'hooks';
import {
  getFormatedFromUTC,
  timeTookToRun,
  getFormatedLocalDate,
} from 'utils/date-handlers';
import {
  Model,
  TrainingParams,
  AudioConfig,
  STATUS_FINISHED,
  EXPERIMENT_TASK,
} from 'types';
import { PropertyHelpLabel } from 'components/PropertyHelpLabel';
import { DetailBox } from 'components/DetailBox';
import { PropertyValueLabel } from 'components/PropertyValueLabel';
import { useExperimentContext } from 'context';
import { downloadFile } from 'utils';

interface Props {
  open: boolean;
  model: Model;
  preprocessing: { [key: string]: boolean | string };
}

export const DetailCollapsible = ({ open, model }: Props) => {
  const classes = useStyle();
  const { push } = useHistory();
  const { logs } = useExperimentContext();
  const initialLogs = useMemo(() => {
    if (logs[model.id]) {
      return logs[model.id]
        .map(
          (item) =>
            `[${getFormatedFromUTC(item.created_at, 'yyyy/MM/dd HH:mm:ss')}]- ${
              item.message
            }`
        )
        .join('\n\n');
    }
    return '';
  }, [logs, model]);
  const [textAreaVal, setVal] = useState('');
  const [params, setParams] = useState<TrainingParams[]>(
    model.algorithm_training_params
  );
  const [runDuration, setRunDuration] = useState<string>('');

  const experiment = useRecoilValue(currentExperimentState);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const { model: currentModel } = useExperimentContext();

  useEffect(() => {
    setVal('');
    if (initialLogs) {
      setVal(initialLogs);
    }
  }, [initialLogs, setVal]);

  useEffect(() => {
    if (experiment?.type === 2) {
      setParams([
        ...model.algorithm_training_params,
        {
          id_model: model.id,
          param: 'audio_source',
          value: (experiment?.config as AudioConfig)['audio_type'],
        },
      ]);
    } else {
      setParams([...model.algorithm_training_params]);
    }
  }, [model.algorithm_training_params, model.id, experiment]);

  useEffect(() => {
    const message = timeTookToRun(
      model.training_started_at,
      model.training_finished_at
    );
    setRunDuration(message);
  }, [model.training_started_at, model.training_finished_at]);

  const checkDaug = useCallback((key) => {
    return [
      'Random rotation',
      'Random brightness',
      'Random zoom',
      'Horizontal flip',
      'Vertical flip',
      'Audio source',
    ].includes(key);
  }, []);

  const message = useModelLogs(model.id);

  useEffect(() => {
    if (null != textAreaRef.current) {
      if (message) {
        setVal((val) =>
          val.concat(
            '\n\n',
            `[${getFormatedLocalDate(new Date(), 'yyyy/MM/dd HH:mm:ss')}]- ${
              message.data.log_message
            }`
          )
        );
        textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, textAreaRef.current, model.id]);

  const handleViewMetrics = useCallback(() => {
    push(`/experiment/${experiment?.id}/model/${currentModel?.id}/metrics`);
  }, [push, currentModel, experiment]);

  const dataAugmentationValue = useMemo(
    () =>
      model?.preprocessing?.['Random rotation'] ? (
        <IcoCheck />
      ) : (
        <span className={classes.labelValueSpace}>x</span>
      ),
    [model, classes]
  );

  const downloadLogsHandler = () => {
    downloadFile(new File([textAreaVal || ''], 'Experiment_logs.txt'));
  };

  const viewMetrics = useMemo(
    () =>
      model.result &&
      model.status === STATUS_FINISHED &&
      [
        EXPERIMENT_TASK.TEXT_CLASSIFICATION,
        EXPERIMENT_TASK.IMAGE_CLASSIFICATION,
        EXPERIMENT_TASK.AUDIO_CLASSIFICATION,
      ].includes(experiment?.task || 0),
    [model, experiment]
  );

  return (
    <Collapse className={classes.collapsibleContainer} in={open}>
      <Grid className={classes.infoContainer}>
        <Grid container>
          <Typography className={classes.containerTitle} variant="body1">
            Model Detail
          </Typography>
        </Grid>
        <Grid container className={classes.algoDetailContainer}>
          <Grid
            container
            className={classes.algoDetail}
            justify="space-between"
          >
            <DetailBox title="ALGORITHM" value={model.name_algorithm} />
            <DetailBox title="MODEL" value={model.name_vectorizer} />
            <DetailBox
              title="TIME IT TOOK TO RUN"
              value={runDuration}
              notUnderlined={runDuration === '-'}
            />

            {viewMetrics ? (
              <Grid item md={3} className={classes.textAlignEnd}>
                <Button
                  size="large"
                  color="secondary"
                  variant="outlined"
                  onClick={handleViewMetrics}
                >
                  View metrics
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.infoContainer}>
        <Grid container>
          <Typography className={classes.containerTitle} variant="body1">
            Configuration
          </Typography>
        </Grid>
        <Grid
          item
          container
          spacing={2}
          justify="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={6}>
            <Grid
              container
              alignItems="center"
              className={classes.configurationSubTitle}
            >
              <Typography className={classes.itemSubtitle} variant="body1">
                Parameters
              </Typography>
            </Grid>
            <Grid container className={classes.configurationContainer}>
              <Grid
                container
                className={classes.algoDetail}
                justify="space-between"
              >
                {params.map((item, index) => (
                  <PropertyHelpLabel
                    key={index}
                    property={item.param}
                    value={item.value}
                    tooltip={
                      'This is a model hyperparameter. An hyperparameter is a parameter whose value is fixed before the training process instead of being learned during the training process itself'
                    }
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid
              container
              alignItems="center"
              className={classes.configurationSubTitle}
            >
              <Typography className={classes.itemSubtitle} variant="body1">
                Preprocessing
              </Typography>
              <HelpIcon />
            </Grid>

            <Grid container className={classes.configurationContainer}>
              <Grid container className={classes.algoDetail}>
                {experiment?.type === 2 ? (
                  <>
                    <PropertyValueLabel
                      property="Data augmentation"
                      value={dataAugmentationValue}
                    />
                    {Object.entries(
                      model.preprocessing ?? {}
                    ).map((item, index) =>
                      checkDaug(item[0]) ? null : item[0] === '' ? null : (
                        <PropertyValueLabel
                          property={item[0]}
                          key={index}
                          value={
                            typeof item[1] === 'string' ? (
                              <span>
                                {item[1] === 'auto'
                                  ? ': (Auto)'
                                  : `: ${item[1]}`}
                              </span>
                            ) : item[1] === true ? (
                              <IcoCheck />
                            ) : (
                              <span className={classes.labelValueSpace}>x</span>
                            )
                          }
                        />
                      )
                    )}
                  </>
                ) : (
                  Object.entries(
                    model.preprocessing ?? {}
                  ).map((item, index) => (
                    <PropertyValueLabel
                      key={index}
                      value={
                        typeof item[1] === 'string' ? (
                          <span>
                            {item[1] === 'auto' ? ': (Auto)' : `: ${item[1]}`}
                          </span>
                        ) : item[1] === true ? (
                          <IcoCheck />
                        ) : (
                          <span className={classes.labelValueSpace}>x</span>
                        )
                      }
                      property={item[0]}
                    />
                  ))
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.infoContainer}>
        <Grid container justify="space-between" alignItems="center">
          <Typography className={classes.containerTitle} variant="body1">
            Log
          </Typography>
          <DownloadIcon
            className={classes.downloadIcon}
            onClick={downloadLogsHandler}
            cursor="pointer"
          />
        </Grid>
        <Grid item container lg={12} className={classes.algoDetailContainer}>
          <Grid
            container
            className={classes.algoDetail}
            justify="space-between"
            alignItems="center"
          >
            <textarea
              ref={textAreaRef}
              rows={10}
              className={classes.textarea}
              value={textAreaVal}
              disabled
            ></textarea>
          </Grid>
        </Grid>
      </Grid>
    </Collapse>
  );
};
