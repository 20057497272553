import { withStyles, Tabs } from '@material-ui/core';
import { colors } from 'material/colors';

export const AntTabs = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${colors.blue15}`,
    overflow: 'visible',
  },
  scroller: {
    overflow: 'visible !important', // sorry, need to override inline styles applied by material-ui
  },
  indicator: {
    backgroundColor: theme.palette.secondary.main,
    height: 7,
    borderRadius: 4,
    bottom: -4,
  },
}))(Tabs);
