import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  linkText: {
    textTransform: 'uppercase',
    fontSize: 12,
    lineHeight: '1',
  },
  link: {
    textDecoration: 'none',
  },
}));
