import React, { useEffect, useState } from 'react';
import { Box, Chip, Grid, Typography, Tooltip } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import { HelpIcon } from 'assets';
import { ExperimentStatusTag } from 'components/ExperimentStatusTag';
import { Model } from 'types';
import { getLastErrorLogMessage } from 'utils/errors';
import useStyle from './modelheader.styles';
import { ToastBox } from 'components/ToastBox';
import { useExperimentContext } from 'context';

interface Props {
  isRecommended: true | false;
  model: Model;
}

export const ModelHeader = ({ isRecommended, model }: Props) => {
  const classes = useStyle();
  const { logs } = useExperimentContext();
  const { name } = model;

  const [lastErrorLog, setLastErrorLog] = useState('');

  useEffect(() => {
    setLastErrorLog(getLastErrorLogMessage(logs[model.id] || []));
  }, [model, logs]);

  const onCloseErrorLogHandler = () => {
    setLastErrorLog('');
  };

  return (
    <Grid container className={classes.modelHeader}>
      <Grid item lg={12} style={{ width: '100%' }}>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
          className={classes.modelTitle}
        >
          <Typography variant="body1" className={classes.modelNameLabel}>
            MODEL NAME
          </Typography>
          <Grid container justify="space-between">
            <Typography className={classes.modelNameTitle} variant="body1">
              {name}
            </Typography>
            <Grid item>
              <Grid container justify="flex-end" alignItems="center">
                {isRecommended && (
                  <>
                    {' '}
                    <Chip
                      label="Recommended"
                      className={classes.chipRecommended}
                      icon={<StarIcon className={classes.starIcon} />}
                    />{' '}
                    <Tooltip title="This is the automatically recommended model.">
                      <HelpIcon />
                    </Tooltip>
                  </>
                )}
                <Box m={1} style={{ position: 'relative' }}>
                  <ExperimentStatusTag
                    status={model.status}
                    progress={model.progress}
                  />
                  {lastErrorLog && (
                    <Grid className={classes.errorLogBox}>
                      <ToastBox
                        text={lastErrorLog}
                        onCloseHandler={onCloseErrorLogHandler}
                      />
                    </Grid>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
