import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';

import { OCRResponse, PredictResponse } from 'types';

import { DownloadIcon } from 'assets';

import { json2csv } from 'json-2-csv';

import { downloadFile } from 'utils';
import useStyles from './ocrresultspanel.styles';
import { TextResultItem } from './TextResultItem';
import { useOCRContext } from '../OCRContext';

type OCRConfigProps = {
  showTextLocation: boolean;
  text: string;
  confidence?: number | undefined;
};

type OCRResultsProps = {
  prediction: PredictResponse | null;
  OCRConfig?: OCRConfigProps;
};

const FILE_NAME = 'ocr_result';

export const OCRResultsPanel = ({ prediction, OCRConfig }: OCRResultsProps) => {
  const classes = useStyles();

  const [textsFragments, setTextsFragments] = useState<string[]>([]);
  const {
    OCROptions: { boxes },
  } = useOCRContext();

  const texts = useMemo(
    () => (boxes as OCRResponse[]).map(({ text }) => text),
    [boxes]
  );

  const textsJoined = useMemo(
    () =>
      ((prediction?.result as OCRResponse[]) || [])
        .map(({ text }) => text)
        .join(' '),
    [prediction]
  );

  useEffect(() => {
    OCRConfig?.showTextLocation
      ? setTextsFragments(texts)
      : setTextsFragments([textsJoined]);
  }, [OCRConfig, texts, textsJoined]);

  const saveResults = () => {
    const { showTextLocation } = OCRConfig || {};

    if (!prediction) return;

    if (showTextLocation) {
      const orderedResult = ((prediction.result as OCRResponse[]) || []).map(
        (item) => ({
          text: item.text,
          confidence_score: item.confidence_score,
          x_left_top: item.x_left_top,
          y_left_top: item.y_left_top,
          x_right_top: item.x_right_top,
          y_right_top: item.y_right_top,
          x_right_bottom: item.x_right_bottom,
          y_right_bottom: item.y_right_bottom,
          x_left_bottom: item.x_left_bottom,
          y_left_bottom: item.y_left_bottom,
        })
      );

      json2csv(orderedResult, function (err, csv) {
        downloadFile(new File([csv || ''], `${FILE_NAME}.csv`));
      });
    } else {
      const file = new File([textsJoined], `${FILE_NAME}.txt`);
      downloadFile(file);
    }
  };

  return !prediction?.result.length ? (
    <Grid className={classes.messageContainer}>
      <Typography variant="body1">
        I could not detect any text in the image
      </Typography>
    </Grid>
  ) : (
    <Grid className={classes.container}>
      <Grid
        container
        alignItems="center"
        wrap="nowrap"
        className={classes.overdRectText}
      >
        {OCRConfig?.showTextLocation && (
          <Typography>
            <b>Tip:</b> Select a box in the image to highlight the recognized
            result text
          </Typography>
        )}

        <DownloadIcon onClick={saveResults} className={classes.download} />
      </Grid>

      <Grid className={classes.texts}>
        {(textsFragments || []).map((text, idx) => (
          <TextResultItem
            text={text}
            configText={OCRConfig?.text || ''}
            key={idx}
          />
        ))}
      </Grid>
    </Grid>
  );
};
