import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';
export default makeStyles(() => ({
  algoDetail: {
    padding: '24px 24px 30px 24px',
  },

  collapsibleContainer: {
    /*paddingRight: '32px' */
    width: '100%',
  },
  algoDetailContainer: {
    borderRadius: '8px',
    border: `solid 1px  ${colors.blue15}`,
  },
  configurationContainer: {
    borderRadius: '8px',
    border: `solid 1px  ${colors.blue15}`,
    width: '100%',
  },
  configurationSubTitle: {
    textAlign: 'left',
    marginBottom: '10px',
  },
  itemSubtitle: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: colors.blue50,
  },
  logContainer: {
    borderRadius: '8px',
    border: `solid 1px  ${colors.blue15}`,
    width: '520px',
  },
  infoContainer: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  containerTitle: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'bold',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: colors.blue70,
    margin: '16px 0px 16px',
  },
  containerSubtitleTitle: {
    fontFamily: 'Poppins',
    fontSize: '8px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'bold',
    lineHeight: 1.25,
    letterSpacing: '0.6px',
    color: colors.blue50,
    marginBottom: '3px',
  },
  containerUnderlinedSubtitle: {
    fontFamily: 'PTSerif',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'underline',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.orange,
    marginBottom: '3px',
    textDecoration: 'underline',
  },
  containerNormalSubtitle: {
    fontFamily: 'PTSerif',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'underline',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.blue70,
  },
  processingItem: { textAlign: 'left' },
  textarea: {
    width: '100%',
    backgroundColor: 'white',
    color: 'black',
  },
  preprocessingBox: {
    paddingBottom: '10px',
  },
  textAlignEnd: {
    textAlign: 'end',
  },
  labelValueSpace: {
    marginLeft: '4px',
  },
  downloadIcon: {
    color: colors.orange,
  },
}));
