import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  needHelp: {
    marginTop: 24,
    marginBottom: 32,
  },
  pointer: {
    cursor: 'pointer',
  },
}));
