import React, { useMemo } from 'react';
import { Grid, Input, Typography, Box, Fade } from '@material-ui/core';
import { useRecoilState, useSetRecoilState } from 'recoil';
import imgPlaceholder from 'assets/img-placeholder.svg';
import { editValuesState } from 'store/edit-experiment';
import { EditPen } from 'assets';
import { updateExperiment } from 'services/experiments';
import useStyles from './view-experiment-header.styles';
import { buildPublicUrl } from 'services/files';
import { SmallDropzone, Avatar } from 'components';
import { useUploadProgress } from 'hooks/useUploadProgress';
import { currentExperimentState } from 'store/experiments';
import { getEnv } from 'services/env';
import { Experiment, ExperimentPatch } from 'types';
import { useAuthenticationContext, useExpCreationContext } from 'context';

const REACT_APP_BUCKET_NAME = getEnv('REACT_APP_BUCKET_NAME');
interface Props {
  experiment: Experiment;
}
export const EditableTitle = React.memo(({ experiment }: Props) => {
  const classes = useStyles();
  const { setExpProps } = useExpCreationContext();
  const updateCurrentExp = useSetRecoilState(currentExperimentState);
  const titleMain = React.useRef(experiment.title);
  const [editing, setEdit] = React.useState(false);
  const [expValues, setExpValues] = useRecoilState(editValuesState);
  const [editingLogo, setEditLogo] = React.useState(false);
  const [logoUrl, setLogoUrl] = React.useState(experiment.logo);
  const [displayEditIcon, setDisplayEditIcon] = React.useState(false);
  const { progress, uploadFile, presignedResponse } = useUploadProgress();

  const { user } = useAuthenticationContext();

  React.useEffect(() => {
    setExpValues({
      title: experiment.title,
      description: experiment.description,
    });
  }, [experiment, setExpValues]);

  React.useEffect(() => {
    if (progress === 100) {
      const updateLogo = async () => {
        const res = await updateExperiment(experiment.id, {
          logo: presignedResponse?.filename,
          description: experiment.description,
          title: titleMain.current,
        } as ExperimentPatch);
        setLogoUrl(res.logo);
      };
      updateLogo();
      setTimeout(() => {
        setExpProps({
          preview: presignedResponse?.get_url,
        });

        setEditLogo(false);
        setDisplayEditIcon(false);
      }, 100);
    }
  }, [
    presignedResponse,
    progress,
    setExpProps,
    experiment.id,
    setLogoUrl,
    experiment.title,
    experiment.description,
  ]);

  const isOwner = useMemo(() => user?.id === experiment.id_user, [
    user,
    experiment,
  ]);

  const handleEdit = React.useCallback(() => {
    isOwner && setEdit(true);
  }, [setEdit, isOwner]);

  const handelIconClick = React.useCallback(() => {
    setEditLogo(!editingLogo);
  }, [setEditLogo, editingLogo]);

  const handleMouseEnter = React.useCallback(() => {
    setDisplayEditIcon(true);
  }, [setDisplayEditIcon]);

  const handleMouseLeave = React.useCallback(() => {
    setDisplayEditIcon(false);
  }, [setDisplayEditIcon]);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setExpValues({
        description: expValues.description,
        title: e.currentTarget.value,
      });
    },
    [setExpValues, expValues]
  );

  const udpateTitle = React.useCallback(
    async (title) => {
      try {
        if (title.trim()) {
          const description = experiment.description;
          const res: Experiment = await updateExperiment(experiment.id, {
            title: title.trim(),
            description: description,
          } as ExperimentPatch);
          setExpValues({
            title: res.title,
            description: !isJSON(res.description)
              ? res.description
              : JSON.parse(res.description),
          });
          updateCurrentExp({
            ...experiment,
            title: res.title,
          });
          titleMain.current = res.title;
        }
      } catch (error) {
        console.log(error);
      } finally {
        setEdit(false);
      }
    },
    [setEdit, setExpValues, experiment, updateCurrentExp]
  );

  const isJSON = (str: any) => {
    try {
      return JSON.parse(str) && !!str;
    } catch (e) {
      return false;
    }
  };

  const handleClick = React.useCallback((e) => {
    const el = document.getElementById('inputTitle');
    el?.focus();
  }, []);

  const handleKeyPress = React.useCallback(
    (e) => {
      switch (e.key) {
        case 'Escape':
          setExpValues({
            title: titleMain.current,
            description: experiment.description,
          });
          setEdit(false);
          break;
        case 'Enter':
          const title = e.currentTarget.value;
          setExpValues({
            title: expValues.title.trim(),
            description: experiment.description,
          });
          udpateTitle(title.trim());
          setEdit(false);
          break;
      }
    },
    [setExpValues, setEdit, expValues, udpateTitle, experiment.description]
  );

  const handleFinishEdit = React.useCallback(
    async (e) => {
      if (e.currentTarget.value) {
        setEdit(false);
        const title = e.currentTarget.value;
        setExpValues({
          title: title.trim(),
          description: experiment.description,
        });
        udpateTitle(title.trim());
      }
      setEdit(false);
    },
    [setEdit, setExpValues, udpateTitle, experiment.description]
  );

  const { title } = experiment;
  return (
    <Grid container alignItems="center">
      {editingLogo || (
        <Grid className={classes.avatarContainer}>
          {
            <Box
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Avatar
                text={title}
                img={
                  logoUrl
                    ? buildPublicUrl(logoUrl, REACT_APP_BUCKET_NAME || 'static')
                    : imgPlaceholder
                }
                className={classes.avatar}
              />
            </Box>
          }

          {isOwner ? (
            <Fade in={displayEditIcon}>
              <EditPen
                onClick={handelIconClick}
                className={classes.iconEdit}
                onMouseOver={handleMouseEnter}
                onMouseOut={handleMouseLeave}
                onBlur={handleMouseLeave}
              />
            </Fade>
          ) : (
            <Grid
              style={{
                marginBottom: '30px',
              }}
            ></Grid>
          )}
        </Grid>
      )}

      {editingLogo && (
        <SmallDropzone
          imgSrc={presignedResponse?.get_url}
          dropzoneOptions={{ accept: 'image/*' }}
          onDrop={uploadFile}
          percent={progress}
        />
      )}

      <Typography
        style={{ display: editing ? 'none' : 'block' }}
        onClick={handleEdit}
        variant="h1"
        className={classes.title}
      >
        {experiment.title}
      </Typography>
      <Input
        id="inputTitle"
        style={{ display: editing ? 'block' : 'none' }}
        color="primary"
        className={classes.input}
        name="title"
        value={expValues.title}
        onChange={handleChange}
        onBlur={handleFinishEdit}
        onKeyDown={handleKeyPress}
        onFocus={handleClick}
        onMouseOver={handleClick}
      />
    </Grid>
  );
});
