import { useEffect, useState, useCallback } from 'react';

import { useAuthenticationContext } from 'context';

import { getCompany } from 'services/company';

import { Company as CompanyType } from 'types';

export const useCompany = () => {
  const { setUserProp } = useAuthenticationContext();
  const [company, setCompany] = useState<CompanyType | null>(null);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [tab, setTab] = useState<number>(0);
  const [message, setMessage] = useState<{
    text: string;
    type: 'success' | 'error';
  } | null>(null);
  const [showPlans, setShowPlans] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    setCompany(null);
    setLoadingData(true);
    try {
      const res = await getCompany();
      setCompany(res);
      res && setUserProp?.({ id_company: res.id || '' });
      setLoadingData(false);
    } catch {}
  }, [setUserProp]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    company,
    fetchData,
    loadingData,
    tab,
    setTab,
    message,
    setMessage,
    showPlans,
    setShowPlans,
  };
};
