import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { MessageIcon } from 'assets';
import useStyles from './verifyemail.styles';
import { useHistory } from 'react-router-dom';

import { Text } from 'components';

export const VerifyEmail = () => {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState('');

  useEffect(() => {
    const state = history.location.state as { from: string };

    if (state && state.from !== 'signup') history.push('/login');
    if (!state) history.push('/login');
  }, [history]);

  useEffect(() => {
    const email = JSON.parse(localStorage.getItem('profile-data') || '').email;

    setEmail(email || '');
  }, [setEmail]);

  return (
    <div className={classes.container}>
      <div className={classes.headerElement}></div>
      <div className={classes.headerElementContrapart}></div>
      <div className={classes.content}>
        <MessageIcon />

        <Typography className={classes.title} variant="body1" align="center">
          Verify your email
        </Typography>

        <div className={classes.bodyText}>
          <Text variant="paragraph1">
            You will shortly receive an email confirmation link to
            <b> {email}</b>.
            <br />
            <br />
            <span>
              Didn’t receive the link? Please check your spam folder or reach
              out to <span className={classes.link}>support@cogniflow.ai</span>
            </span>
          </Text>
        </div>
      </div>
    </div>
  );
};
