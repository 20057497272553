import React from 'react';
import { useRecoilValue } from 'recoil';

import {
  currentExperimentState,
  experimentError,
  experimentLoading,
} from 'store/experiments';
import { LoadableHeader } from './LoadableHeader';

export const ViewExperimentHeader = () => {
  const experiment = useRecoilValue(currentExperimentState);
  const loading = useRecoilValue(experimentLoading);
  const error = useRecoilValue(experimentError);
  return (
    <>
      {loading && <span>loading</span>}
      {error && <span>{error}</span>}
      {experiment && !loading && !error && (
        <LoadableHeader experiment={experiment} />
      )}
    </>
  );
};
