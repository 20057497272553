import { makeStyles } from '@material-ui/core';
import { colors } from 'material';
import { PROJECT_TASK } from 'types';

const classifiersGradient =
  'linear-gradient(90deg, #66ADFF 0%, #2F8AF4 49.06%, #117AF3 100%)';

const backgroundGradient: Record<number, string> = {
  [PROJECT_TASK.CHATBOT]:
    'linear-gradient(90deg, #AE2DFC 0%, #7D2FF4 49.06%, #3C33E8 100%)',
  [PROJECT_TASK.EXTRACTOR]:
    'linear-gradient(160deg, #FE987F -9.73%, #CB457C 52.58%, #ED3D85 86.74%);',
  [PROJECT_TASK.CLASSIFIER_TEXT]: classifiersGradient,
  [PROJECT_TASK.CLASSIFIER_IMAGE]: classifiersGradient,
  [PROJECT_TASK.CLASSIFIER_AUDIO]: classifiersGradient,
  [PROJECT_TASK.OBJECT_DETECTION]:
    'linear-gradient(90deg, #7ADE9C 0%, #4DB39B 45.42%, #2F9079 100%)',
  [PROJECT_TASK.DEFAULT]:
    'linear-gradient(90deg, #7FABD4 -9.73%, #5186B7 52.58%, #5186B7 86.74%)',
};

const imageBackgroundGradient: Record<number, string> = {
  [PROJECT_TASK.CHATBOT]:
    'linear-gradient(0deg, #AE2DFC 0%, rgba(174, 45, 252, 0.77) 0.01%, #7D2FF4 21.06%, rgba(60, 51, 232, 0.48) 100%)',
  [PROJECT_TASK.EXTRACTOR]:
    'linear-gradient(0deg, rgba(254, 152, 127, 0.87) 6.2%, rgba(226, 94, 148, 0.86) 42.51%, rgba(226, 93, 147, 0.86) 62.42%)',
};

type StyleProps = {
  projectTask: PROJECT_TASK;
  progress?: number;
};

export default makeStyles(() => ({
  card: {
    borderRadius: '24px',
    border: '1px solid #D8DDEB',
    // width: '247px',
    height: '305px',
    overflow: 'hidden',
    backgroundColor: 'white',

    boxShadow: '0px 1px 8px 0px rgba(72, 79, 121, 0.15)',

    transition: 'box-shadow 0.2s ease-in-out',

    cursor: 'pointer',

    '&:hover': {
      boxShadow: '0px 1px 8px 0px rgba(72, 79, 121, 0.4)',
    },
  },

  header: ({ projectTask }: StyleProps) => ({
    padding: '16px',
    background:
      backgroundGradient[projectTask] ||
      backgroundGradient[PROJECT_TASK.DEFAULT],
    width: '100%',
    height: '188px',
    borderBottomRightRadius: '24px',
    boxSizing: 'border-box',
    position: 'relative',
    overflow: 'hidden',
  }),

  avatar: {
    width: '56px',
    height: '56px',
    border: '3px solid white',
    borderRadius: '16px',
    overflow: 'hidden',
    background: 'white',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },

  dots: {
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
  },

  ellipse: {
    position: 'absolute',
    top: '65px',
    left: 0,
    zIndex: 2,
  },

  task: {
    textAlign: 'left',
    color: 'white',
  },

  title: {
    textAlign: 'left',
    color: 'white',
    fontSize: '24px',
    display: '-webkit-box',
    maxWidth: 400,
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  btnContainer: {
    padding: '32px 24px',
    boxSizing: 'border-box',
    borderWidth: '2px',
  },

  image: ({ projectTask }: StyleProps) => {
    const isChatbot = projectTask === PROJECT_TASK.CHATBOT;

    const imgWidth: Record<number, string> = {
      [PROJECT_TASK.CHATBOT]: '100%',
      [PROJECT_TASK.EXTRACTOR]: 'auto',
      [PROJECT_TASK.CLASSIFIER_TEXT]: '100%',
      [PROJECT_TASK.CLASSIFIER_IMAGE]: '100%',
      [PROJECT_TASK.CLASSIFIER_AUDIO]: '100%',
      [PROJECT_TASK.OBJECT_DETECTION]: '80%',
    };

    const imgHeight: Record<number, string> = {
      [PROJECT_TASK.CHATBOT]: '100%',
      [PROJECT_TASK.EXTRACTOR]: 'auto',
      [PROJECT_TASK.CLASSIFIER_AUDIO]: 'auto',
      [PROJECT_TASK.CLASSIFIER_TEXT]: 'auto',
      [PROJECT_TASK.CLASSIFIER_IMAGE]: 'auto',
      [PROJECT_TASK.OBJECT_DETECTION]: 'auto',
    };

    const borderRadius = isChatbot
      ? {
          borderBottomRightRadius: '24px',
          borderTopRightRadius: '24px',
        }
      : {};

    return {
      zIndex: 2,
      position: 'absolute',
      top: '0',
      right: '0',
      width: '50%',
      height: '100%',

      '& img': {
        width: imgWidth[projectTask],
        height: imgHeight[projectTask],
        position: 'absolute',
        right: '0',
        top: '0',
        ...borderRadius,
      },
    };
  },

  imgBg: ({ projectTask }: StyleProps) => {
    const chatbotCustomStyle = {
      width: '100%',
      height: '100%',
      borderBottomRightRadius: '24px',
      borderTopRightRadius: '24px',
    };

    const customStyle = {
      width: '144.1px',
      height: '216px',
      borderBottomLeftRadius: '12px',
    };

    const style =
      projectTask === PROJECT_TASK.CHATBOT ? chatbotCustomStyle : customStyle;

    const hasImageGradient =
      projectTask === PROJECT_TASK.CHATBOT ||
      projectTask === PROJECT_TASK.EXTRACTOR;

    return {
      position: 'absolute',
      top: 0,
      right: 0,
      background: hasImageGradient
        ? imageBackgroundGradient[projectTask]
        : 'none',
      ...style,
    };
  },

  background: ({ projectTask }: StyleProps) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    background: backgroundGradient[projectTask] || backgroundGradient[4],
    width: '100%',
    height: '100%',
    borderRadius: '24px',
    transform: 'rotate(180deg)',
    zIndex: -1,
  }),

  personImage: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    border: `2px solid ${colors.blue20}`,
  },

  createdBy: {
    fontSize: '12px',
    color: colors.blue20,
  },

  owner: {
    fontSize: '14px',
    colors: colors.blue50,
  },

  tryItButton: {
    position: 'relative',
    borderWidth: '2px',
    boxSizing: 'border-box',
  },

  tryItButtonProgress: ({ progress }: StyleProps) => ({
    position: 'absolute',
    backgroundColor: '#FF6A611F',
    height: '100%',
    width: progress,
    left: 0,
    transition: 'width 1s ease-in-out',
  }),
}));
