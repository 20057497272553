import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    padding: '15px',
  },
  texts: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    gap: '8px',
  },
  overdRectText: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '12px',
    '& p': {
      fontFamily: 'Poppins',
      textAlign: 'left',
    },
  },
  textContainer: {
    backgroundColor: colors.blue15,
    padding: '5px 10px',
    borderRadius: '10px',
    transition: '.1s ease-in',
  },
  framgentsWrapper: {
    display: 'flex',
  },
  downloadIcon: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: '5px',
  },
  messageContainer: {
    padding: '38px',
    borderRadius: 10,
    boxShadow: `0 8px 16px 0 rgba(72, 79, 121, 0.15)`,
    backgroundColor: '#FFFF',
    marginTop: '70px',
    '& p': {
      fontFamily: 'Poppins',
      fontSize: 16,
    },
  },
  textHighlight: {
    backgroundColor: colors.blue50,
    color: 'white',
  },
  download: {
    flexShrink: 0,
    marginLeft: 20,
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    textAlign: 'right',
  },
}));
