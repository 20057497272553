import React, { ReactNode } from 'react';

import { Box, Grid } from '@material-ui/core';
import { ActionButton } from 'components';

import { useHistory } from 'react-router-dom';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useStyles from './IntegrationsWrapper.styles';

export const IntegrationsWrapper = ({ children }: { children: ReactNode }) => {
  const classes = useStyles();

  const { replace } = useHistory();

  const goBack = () => {
    replace({ search: '' });
  };

  return (
    <Grid
      container
      item
      direction="column"
      justify="center"
      alignContent="flex-start"
      className={classes.root}
      md={12}
    >
      <Box style={{ textAlign: 'start', marginBottom: 20 }}>
        <ActionButton
          onClick={goBack}
          size="medium"
          variant="outlined"
          color="secondary"
          className={classes.buttonClose}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </ActionButton>
      </Box>
      {children}
    </Grid>
  );
};
