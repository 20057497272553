import { useState } from 'react';
import { Company as CompanyType, CompanyUser as CompanyUserType } from 'types';

import { removeUserFromCompany } from 'services';

interface useMembersProps {
  company: CompanyType;
  onSuccess?(payload: { msg: string }): void;
  onError?(payload: { msg: string }): void;
}

export const useMembers = ({
  company,
  onSuccess,
  onError,
}: useMembersProps) => {
  const [users, setUsers] = useState<CompanyUserType[]>(company.users);
  const [userToRemove, setUserToRemove] = useState<CompanyUserType | null>(
    null
  );

  const removeUser = async (idUser: string) => {
    try {
      await removeUserFromCompany(idUser);
      onSuccess?.({ msg: 'Member successfully removed' });
    } catch (error) {
      onError?.({ msg: 'An unexpected error has occurred' });
    }
  };

  return { users, setUsers, removeUser, userToRemove, setUserToRemove };
};
