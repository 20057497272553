import React, { memo, useMemo, useRef, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { Extractor, Text } from 'components';

import useStyles from './Settings.styles';
import { EntityProps, Experiment, PreTrainedExperimentConfig } from 'types';
import { ToastContainer, toast } from 'react-toastify';
import { SettingsDropdown } from './SettingsDropdown';

import { getExperiment, updateExperimentConfig } from 'services';
import { useAuthenticationContext } from 'context';
import { useRecoilState } from 'recoil';
import { currentExperimentState } from 'store/experiments';
import { useHistory } from 'react-router-dom';

import { useExperimentConditions, usePrompt } from 'hooks';
import { usePermissions } from 'hooks/usePermissions';
import { isQAGenerative } from 'utils/experiment';
import { QASettings } from './QASettings';
import { AmazonProvider, OpenAIProvider, PaddleaddleProvider } from 'assets';

type SettingsProps = {
  experiment: Experiment;
  onSave: () => void;
};

type LlmsProps = {
  llm_model_name?: string;
  llm_openai?: boolean;
  llm_gpt3_5_turbo?: boolean;
  llm_gpt3_5_turbo_16k?: boolean;
  llm_gpt3_5_turbo_1106?: boolean;
  llm_gpt4?: boolean;
  llm_gpt4_turbo?: boolean;
};

export interface QAConfig extends PreTrainedExperimentConfig {
  chatbotName?: string;
  chatbotTone?: string;
  companyName?: string;
  companyDescription?: string;
  noAnswerMessage?: string;
  lang_code?: string;
  prompt_template?: string;
}

interface LLmsConfig extends PreTrainedExperimentConfig {}

export const Settings = memo(({ experiment, onSave }: SettingsProps) => {
  const classes = useStyles();
  const { user } = useAuthenticationContext();
  const {
    canUseGPT3_5_16k,
    canUseGPT4,
    canUseTextractModel,
  } = usePermissions();
  const [currentExperiment, setExperiment] = useRecoilState(
    currentExperimentState
  );
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [configToUpdate, setConfigToUpdate] = useState<Partial<QAConfig>>({});

  const { isExtractor, isImage } = useExperimentConditions({
    task: experiment.task,
    type: experiment.type,
  });

  const allowLoaderDropdown = useMemo(() => isImage && isExtractor, [
    isImage,
    isExtractor,
  ]);

  const [pdfLoader, setPdfLoader] = useState(
    (experiment?.config?.convert_pdf_to_image as string) || 'default'
  );
  const [ocrEngine, setOcrEngine] = useState(
    (experiment?.config?.ocr_engine as string) || 'paddleocr'
  );

  const Prompt = usePrompt({
    when: hasUnsavedChanges,
    message: 'You have unsaved changes, are you sure you want to leave?',
  });

  const [saving, setSaving] = useState(false);

  const { replace } = useHistory();

  const expIsQAGen = useMemo(() => isQAGenerative(experiment), [experiment]);

  const updateExperiment = (exp: Experiment) => {
    if (!currentExperiment) return;

    getExperiment(currentExperiment.id).then((res) => {
      setExperiment(res);

      setHasUnsavedChanges(false);

      const modelID = res.models[0].id;
      const experimentID = res.id;

      setSaving(false);
      onSave();
      replace(
        `/experiment/${experimentID}/model/${modelID}/${
          expIsQAGen ? 'chat' : 'test'
        }`
      );
    });
  };

  const expConfig = experiment.config as
    | LLmsConfig
    | PreTrainedExperimentConfig;

  const isOwner = useMemo(() => experiment.id_user === user?.id, [
    experiment,
    user,
  ]);

  const [entities, setEntities] = useState<EntityProps[]>(
    expConfig.entities as EntityProps[]
  );

  const llms = useRef<LlmsProps>({
    llm_model_name: expConfig.llm_model_name ?? '',
    llm_openai: expConfig.llm_openai ?? false,
    llm_gpt3_5_turbo: expConfig.llm_gpt3_5_turbo,
    llm_gpt3_5_turbo_16k: expConfig.llm_gpt3_5_turbo_16k,
    llm_gpt3_5_turbo_1106: expConfig.llm_gpt3_5_turbo_1106,
    llm_gpt4: expConfig.llm_gpt4,
    llm_gpt4_turbo: expConfig.llm_gpt4_turbo,
  });

  const saveConfig = () => {
    setSaving(true);

    const updateConfig = {
      ...expConfig,
      ...llms.current,
      entities,
      ...configToUpdate,
      ...(isImage && isExtractor
        ? { convert_pdf_to_image: pdfLoader, ocr_engine: ocrEngine }
        : {}),
    };

    updateExperimentConfig(experiment.id, updateConfig)
      .then((res) => {
        updateExperiment(res);
      })
      .catch((err) => {
        let message = 'Error saving config';

        switch (err.statusCode) {
          case 400:
            message = 'Request is invalid';
            break;
          case 401:
            message = 'You are not authorized to perform this action';
            break;
          case 404:
            message =
              'You can not edit an experiment that does not belong to you';
            break;
        }

        setSaving(false);

        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
          toastId: 'settings',
        });
      });
  };

  const onEntitiesChange = (entitiesList: EntityProps[]) => {
    setEntities(entitiesList);
    setHasUnsavedChanges(true);
  };

  const handleConfigToUpdateChange = (config: Partial<QAConfig>) => {
    setHasUnsavedChanges(true);
    setConfigToUpdate(config);
  };

  const handleModelsDropdownChange = (value: string) => {
    setHasUnsavedChanges(true);

    for (const [key] of Object.entries(llms.current)) {
      if (key === value) {
        llms.current[key as keyof LlmsProps] = true;
      } else {
        llms.current[key as keyof LlmsProps] = false;
      }
    }

    const isNewModel = !Object.keys(llms.current).includes(value);
    llms.current.llm_openai = isNewModel;
    llms.current.llm_model_name = isNewModel ? value : '';
  };

  const handleLoaderDropdownChange = (value: string) => {
    setHasUnsavedChanges(true);
    setPdfLoader(value);
  };

  const handleOcrEngineDropdownChange = (value: string) => {
    setHasUnsavedChanges(true);
    setOcrEngine(value);
  };

  const textItems = [
    {
      title: 'GPT-4o mini',
      // description: ``,
      subtitle: '(Default)',
      value: 'gpt-4o-mini-2024-07-18',
      Icon: OpenAIProvider,
    },
    {
      title: 'GPT-4o-2024-08-06',
      // description: ``,
      subtitle: '',
      value: 'gpt-4o-2024-08-06',
      disabled: !canUseGPT4,
      link: !canUseGPT4 ? '/settings/subscription' : '',
      Icon: OpenAIProvider,
    },
    {
      title: 'GPT-4o-2024-05-13',
      // description: ``,
      subtitle: '',
      value: 'gpt-4o-2024-05-13',
      disabled: !canUseGPT4,
      link: !canUseGPT4 ? '/settings/subscription' : '',
      Icon: OpenAIProvider,
    },
    {
      title: 'GPT-4 Turbo',
      // description: `With 128k context, fresher knowledge, and the broadest set of capabilities, GPT-4 Turbo is more powerful than GPT-4. It computes ${
      //   requiresCustomOpenAIAPIKey
      //     ? '3 credits'
      //     : '25 credits per 4k characters'
      // }.`,
      subtitle: '',
      value: 'llm_gpt4_turbo',
      disabled: !canUseGPT4,
      link: !canUseGPT4 ? '/settings/subscription' : '',
      Icon: OpenAIProvider,
    },
    {
      title: 'GPT-4',
      // description: `With broad general knowledge and domain expertise, GPT-4 can follow complex instructions in natural language and solve difficult problems with accuracy. It is slower and computes ${
      //   requiresCustomOpenAIAPIKey
      //     ? '3 credits'
      //     : '40 credits per 4k characters'
      // }. We recommend upgrading to GPT-4 Turbo.`,
      subtitle: '',
      value: 'llm_gpt4',
      disabled: !canUseGPT4,
      link: !canUseGPT4 ? '/settings/subscription' : '',
      Icon: OpenAIProvider,
    },
    {
      title: 'GPT-3.5 Turbo-0125',
      description: ``,
      subtitle: '(Legacy)',
      value: 'gpt-3.5-turbo-0125',
      Icon: OpenAIProvider,
    },
    {
      title: 'GPT-3.5 Turbo-0613',
      // description: `This is the GPT-3.5 0613 version. We recommend upgrading to the latest version.`,
      subtitle: '(Legacy)',
      value: 'llm_gpt3_5_turbo',
      Icon: OpenAIProvider,
    },
    {
      title: 'GPT-3.5 Turbo-1106',
      // description: `This model is capable and cost-effective. Supports a 16K context window and is optimized for dialog. It computes ${
      //   requiresCustomOpenAIAPIKey ? '1 credit' : '2 credits per 4k characters'
      // }.`,
      subtitle: '(Legacy)',
      value: 'llm_gpt3_5_turbo_1106',
      Icon: OpenAIProvider,
    },
    ...(llms.current['llm_gpt3_5_turbo_16k']
      ? [
          {
            title: 'GPT-3.5 Turbo-0613-16k',
            // description: `This is the GPT-3.5 0613-16k version. We recommend upgrading to the latest version, which is better and cheaper. It computes ${
            //   requiresCustomOpenAIAPIKey
            //     ? '2 credits'
            //     : '10 credits per 4k characters'
            // }.`,
            subtitle: '(Legacy)',
            value: 'llm_gpt3_5_turbo_16k',
            disabled: !canUseGPT3_5_16k,
            link: !canUseGPT3_5_16k ? '/settings/subscription' : '',
            Icon: OpenAIProvider,
          },
        ]
      : []),
  ];

  const imageItems = [
    {
      title: 'Automatic',
      description:
        'Let Cogniflow decide which is the best loader for the PDF files.',
      subtitle: '(Default)',
      value: 'default',
    },
    {
      title: 'Convert PDF to image',
      description:
        'All PDF files will be converted to images and then processed using Cogniflow OCR.',
      subtitle: '',
      value: 'yes',
    },
  ];

  const ocrEngineItems = [
    {
      title: 'PP-OCRv3',
      description: 'An open-source OCR engine.',
      subtitle: '(Default)',
      value: 'paddleocr',
      Icon: PaddleaddleProvider,
    },
    {
      title: 'Textract',
      description: 'A cloud-based OCR service provided by AWS.',
      subtitle: '',
      value: 'textract',
      Icon: AmazonProvider,
      disabled: !canUseTextractModel,
      link: !canUseTextractModel ? '/settings/subscription' : '',
    },
  ];

  return (
    <Box
      maxWidth="900px"
      style={{
        backgroundColor: 'white',
        margin: '0 auto',
        boxShadow: '0 8px 16px 0 rgb(72 79 121 / 15%)',
        borderRadius: '8px',
        padding: '50px',
      }}
    >
      <Prompt />
      <ToastContainer autoClose={2000} />
      <Text variant="h3" align="center">
        {expIsQAGen ? 'Chatbot Settings' : 'Settings'}
      </Text>

      <Box
        display="flex"
        gridGap="24px"
        mt="24px"
        mb="16px"
        className={classes.modelsWrapper}
      >
        {allowLoaderDropdown && (
          <Box width="50%" display="flex" flexDirection="column">
            <Box mb="16px">
              <Text variant="h4" align="left">
                OCR Engine
              </Text>
            </Box>
            <Box display="flex" flexGrow={1}>
              <SettingsDropdown
                style={{ width: '100%' }}
                disabled={!isOwner}
                options={ocrEngineItems}
                onChange={handleOcrEngineDropdownChange}
                initialValue={ocrEngine}
              />
            </Box>
          </Box>
        )}
        {allowLoaderDropdown && (
          <Box width="50%" display="flex" flexDirection="column">
            <Box mb="16px">
              <Text variant="h4" align="left">
                PDF loader
              </Text>
            </Box>
            <Box display="flex" flexGrow={1}>
              <SettingsDropdown
                style={{ width: '100%' }}
                disabled={!isOwner}
                options={imageItems}
                onChange={handleLoaderDropdownChange}
                initialValue={pdfLoader}
              />
            </Box>
          </Box>
        )}
        <Box width="50%" display="flex" flexDirection="column">
          <Box mb="16px">
            <Text variant="h4" align="left">
              Models
            </Text>
          </Box>
          <Box display="flex" flexGrow={1}>
            <SettingsDropdown
              style={{ width: '100%' }}
              disabled={!isOwner}
              options={textItems}
              onChange={handleModelsDropdownChange}
              initialValue={
                (Object.keys(llms.current).find(
                  (key) =>
                    [
                      'llm_gpt3_5_turbo',
                      'llm_gpt3_5_turbo_16k',
                      'llm_gpt3_5_turbo_1106',
                      'llm_gpt4',
                      'llm_gpt4_turbo',
                    ].includes(key) && llms.current[key as keyof LlmsProps]
                ) as string) ||
                llms.current.llm_model_name ||
                ''
              }
              optionStyle={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            />
          </Box>
        </Box>
      </Box>

      {expIsQAGen ? (
        <>
          <QASettings
            initialValues={expConfig}
            experiment={currentExperiment}
            showActions={isOwner}
            onChange={handleConfigToUpdateChange}
          />
        </>
      ) : (
        <>
          <Box>
            <Text variant="h4" align="left" className={classes.defineEntities}>
              Define entities
            </Text>
          </Box>

          <Box className={classes.extractorWrapper}>
            <Extractor
              onEntitiesChange={onEntitiesChange}
              initialEntities={entities as EntityProps[]}
              showActions={isOwner}
              allowExport
            />
          </Box>
        </>
      )}

      {isOwner && (
        <Box mt="32px">
          <Button
            size="large"
            color="secondary"
            variant="contained"
            className={classes.saveButton}
            onClick={saveConfig}
            disabled={saving}
          >
            {saving ? 'Saving...' : 'Save'}
          </Button>
        </Box>
      )}
    </Box>
  );
});
