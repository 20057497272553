import React from 'react';
import { LoginWrapper } from 'components/LoginWrapper';
import { Route, Switch } from 'react-router-dom';
import { SignupForm } from './SignupForm';
import { SetUserProfile } from './SetUserProfile';
import { VerifyEmail } from './VerifyEmail';

export const Signup = ({ match: { path } }: any) => {
  return (
    <LoginWrapper>
      <Switch>
        <Route exact path={path} component={SignupForm} />
        <Route exact path={`${path}/profile`} component={SetUserProfile} />
        <Route exact path={`${path}/verify`} component={VerifyEmail} />
      </Switch>
    </LoginWrapper>
  );
};
