import React, { useCallback, useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { useLocation } from 'react-router-dom';
import useStyle from './forgot.styles';
import { resetPassword } from 'services/auth';
import { Button, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { LoginWrapper } from 'components/LoginWrapper/LoginWrapper';
import { LoginForm } from 'components/AuthFormCard/LoginForm/LoginForm';
import { loginValidationSchema } from './validation.forgotpasswd';
const queryString = require('query-string');

interface QueryParams {
  email: string;
  token: string;
}

export const Reset = () => {
  const classes = useStyle();
  const [error, setError] = useState('');
  const [params, setParams] = useState<QueryParams | null>();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  let location = useLocation();

  useEffect(() => {
    const p = queryString.parse(location.search);
    setParams(p);
  }, [location.search]);

  const handleChangePassword = useCallback(
    async (values, { resetForm }) => {
      try {
        if (params && values.password) {
          const activationData = {
            password: values.password,
            ...params,
          };
          const data = await resetPassword(activationData);
          if (data) {
            setMessage('Your new password was succesfully changed.');
            setOpen(true);
            resetForm({});
          }
        }
      } catch (error) {
        setError((error as Error).message);
        setOpen(true);
      }
    },
    [setError, params, setMessage, setOpen]
  );

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <LoginWrapper>
      <LoginForm>
        <Formik
          onSubmit={handleChangePassword}
          validationSchema={loginValidationSchema}
          initialValues={{
            password: '',
            passwordConfirm: '',
          }}
        >
          {({ isValid, touched }) => (
            <Form className={classes.container}>
              <span className={classes.userFormTitle}>Reset password</span>
              <Field
                component={TextField}
                name="password"
                label="New password"
                type="password"
              />
              <Field
                className={classes.fieldContainer}
                component={TextField}
                name="passwordConfirm"
                label="Confirm new password"
                type="password"
              />

              <Button
                variant="contained"
                disabled={
                  !isValid || (!touched.password && !touched.passwordConfirm)
                }
                color="secondary"
                size="large"
                type="submit"
              >
                Change password
              </Button>
            </Form>
          )}
        </Formik>
      </LoginForm>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          classes={{
            filledError: classes.filledError,
            filledSuccess: classes.filledSuccess,
          }}
          variant="filled"
          color={error ? 'error' : 'success'}
          severity={error ? 'error' : 'success'}
          onClose={handleClose}
        >
          {error || message}
          {message && (
            <a style={{ color: 'white' }} href="/login">
              Go to Login
            </a>
          )}
        </Alert>
      </Snackbar>
    </LoginWrapper>
  );
};
