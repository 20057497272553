import React, { useMemo, useState } from 'react';
import { CircularProgress, Grid, Box, Tooltip } from '@material-ui/core';
import {
  Application,
  ErrorDataProps,
  PredictResponse,
  TaskComponentProps,
} from 'types';
import { ActionButton, TestMessageContainer, Text } from 'components';
import useStyles from './facesimilarity.styles';
import { FSDropzone } from './FSDropzone';
import { TaskWrapper } from '../TaskWrapper';
import { predictImageSimilarity } from 'services';
import { HelpIcon, OutlinedCancelIcon, OutlinedCheckIcon } from 'assets';
import { getFaceSimilarityResultText } from './metrics';
import { useAuthenticationContext, usePredictionContext } from 'context';
import { useHistory } from 'react-router-dom';

interface FaceSimilarityProps extends Omit<TaskComponentProps, 'imgUrl'> {
  setPredictionHandler(data: any): void;
  modelId: string;
  task: number;
  onPredictionFails(data: ErrorDataProps): void;
  application?: Application;
  isApplication?: boolean;
}

export const FaceSimilarity = ({
  prediction,
  modelId,
  task,
  setPredictionHandler,
  onPredictionFails,
  application,
  isApplication = false,
  subtitle = null,
  submitText = null,
}: FaceSimilarityProps) => {
  const classes = useStyles();
  const {
    loading,
    setLoadingHandler: setLoading,
    FSImages: images,
    FSImage1Upload: image1Upload,
    FSImage2Upload: image2Upload,
  } = usePredictionContext();

  const { push } = useHistory();

  const { userApiKey } = useAuthenticationContext();

  const [error, setError] = useState('');

  const webhookUrl = useMemo(() => application?.config?.webhookUrl, [
    application,
  ]);

  const predict = () => {
    if (!images.img1 || !images.img2) {
      setError('There are empty fields!');

      return;
    }
    setLoading(true);
    setError('');

    const payload = {
      format_img1: 'jpg',
      format_img2: 'jpg',
      base64_img1: images.img1,
      base64_img2: images.img2,
    };
    predictImageSimilarity(modelId, payload, {
      'x-api-key': isApplication ? '' : userApiKey,
    })
      .then((response) => {
        setPredictionHandler(response);
        setLoading(false);

        // Send the result to the webhook
        if (isApplication && webhookUrl)
          fetch(webhookUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ response, payload }),
          }).catch(() => {});
      })
      .catch((err) => {
        onPredictionFails({ ...err, status: err.statusCode });
        setLoading(false);
      });
  };

  const goToDocs = () => {
    window.open(
      'https://cogniflow.notion.site/Faces-API-d1efbfd8cd4a441089c0d9ce148ad542',
      '_blank'
    );
  };

  return (
    <TaskWrapper
      isApplication={isApplication}
      subtitle={subtitle}
      showJsonTab={!isApplication}
      task={task}
      imagePanel={
        <Grid className={classes.testArea}>
          {loading && (
            <>
              <Grid className={classes.loading}>
                <CircularProgress color="secondary" />
              </Grid>
              <Grid className={classes.loadingOverlay}></Grid>
            </>
          )}
          <Box marginBottom="14px">
            <Text variant="paragraph1">
              Do you want to execute a full face recognition system?
            </Text>
            <Text variant="paragraph1">
              Use our Faces API to upload a set of reference images to match
              faces. Check out our{' '}
              <span className={classes.link} onClick={goToDocs}>
                documentation!
              </span>
            </Text>
          </Box>
          <Grid className={classes.dropzonesContainer}>
            <FSDropzone
              initialImg={images.img1}
              onFSChangeHandler={image1Upload}
            />
            <FSDropzone
              initialImg={images.img2}
              onFSChangeHandler={image2Upload}
            />
          </Grid>

          <ActionButton
            onClick={predict}
            variant="contained"
            color="secondary"
            customColor={application?.config?.colorButton}
            size="large"
          >
            {submitText || 'Verify'}
          </ActionButton>
          {error && <Text variant="error">{error}</Text>}
        </Grid>
      }
      resultsPanel={
        <Grid
          container
          className={classes.other}
          justify="center"
          alignContent="center"
        >
          {prediction && (
            <TestMessageContainer>
              <Box className={classes.yMargin}>
                {(prediction.result as any).verified ? (
                  <Text variant="paragraph1" className={classes.matchText}>
                    <b>Match!</b>
                    <OutlinedCheckIcon />
                  </Text>
                ) : (
                  <Text className={classes.noMatchText} variant="paragraph1">
                    <b>No match</b>

                    <OutlinedCancelIcon />
                  </Text>
                )}
              </Box>

              <Text variant="paragraph1" className={classes.yMargin}>
                <b>Similarity score: </b>
                <span className={classes.badge}>
                  {(prediction.result as any).similarity_score}
                </span>{' '}
                <Tooltip
                  title={getFaceSimilarityResultText(
                    (prediction.result as any).similarity_metric,
                    (prediction.result as any).threshold
                  )}
                >
                  <HelpIcon />
                </Tooltip>
              </Text>
            </TestMessageContainer>
          )}
        </Grid>
      }
      prediction={prediction as PredictResponse}
    ></TaskWrapper>
  );
};
