import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './detailbox.style';

import cn from 'classnames';

export interface IProps {
  title: string;
  value: string;
  notUnderlined?: boolean;
}

export const DetailBox = ({ title, value, notUnderlined = false }: IProps) => {
  const classes = useStyles();
  return (
    <Grid>
      <Typography className={classes.containerSubtitleTitle} align="left">
        {title}
      </Typography>
      <Typography
        align="left"
        className={cn(classes.containerUnderlinedSubtitle, {
          [classes.containerNormalSubtitle]: notUnderlined,
        })}
      >
        {value}
      </Typography>
    </Grid>
  );
};
