import React, { useRef, useState } from 'react';
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { SubtitleHeaderMisc } from 'components';
import useStyles from '../../accordionadvancedconfig.styles';
import { useExpCreationContext } from 'context';

export const ExtractorConfig = () => {
  const classes = useStyles();
  const [config, setConfig] = useState('llm_gpt3_5_turbo');
  const llms = useRef<{ [key: string]: boolean }>({
    llm_gpt3_5_turbo: true,
    llm_gpt3_5_turbo_16k: false,
    llm_gpt4: false,
  });

  const { expValues, setExpProps } = useExpCreationContext();

  const configChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfig(event.target.value);
    for (const [key] of Object.entries(llms.current)) {
      if (key === event.target.value) {
        llms.current[key] = true;
      } else {
        llms.current[key] = false;
      }
    }

    setExpProps({
      config: {
        ...(expValues.config as { [key: string]: boolean }),
        ...llms.current,
      },
    });
  };

  return (
    <Grid item container direction="column">
      <Box className={classes.subTitleHeader}>
        <SubtitleHeaderMisc title="Large Language Model (LLM)" showHelpIcon />
      </Box>
      <Grid
        className={classes.itemContainer}
        item
        container
        md={12}
        wrap="wrap"
      >
        <Grid item container md={12} alignItems="flex-start">
          <Grid
            item
            container
            alignItems="flex-start"
            style={{ marginTop: 10 }}
          >
            <RadioGroup
              aria-label="remove"
              name=""
              onChange={configChangeHandler}
              row
              value={config}
            >
              <FormControlLabel
                value="llm_gpt3_5_turbo"
                control={<Radio />}
                label="gpt3.5-turbo (2 credit /request)"
              />
              <FormControlLabel
                value="llm_gpt3_5_turbo_16k"
                control={<Radio />}
                label="gpt3.5-turbo-16k (10 credit /request)"
              />
              <FormControlLabel
                value="llm_gpt4"
                control={<Radio />}
                label="gpt4 (40 credit /request)"
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid item container md={6} alignItems="flex-start"></Grid>
      </Grid>
    </Grid>
  );
};
