import { makeStyles } from '@material-ui/core';
import { colors } from 'material';

export default makeStyles(() => ({
  docWrapper: {
    padding: '18px',
  },
  tag: {
    padding: '1px 2px',
    backgroundColor: colors.blue15,
    color: colors.blue70,
    borderRadius: '4px',
    marginRight: '8px',
  },

  docSize: {
    color: colors.blue20,
  },

  docName: {
    fontWeight: 700,
    fontSize: '16px',
  },

  info: {
    boxSizing: 'border-box',
    alignItems: 'center',
    overflow: 'hidden',
    flexGrow: 1,

    '& svg': {
      flexShrink: 0,
    },

    '& p': {
      width: '100%',
      textAlign: 'left',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },

  progress: {
    flexShrink: 0,
    width: '100px',
    maxWidth: '300px',
  },

  statusWrapper: {
    padding: '4px 12px',
    borderRadius: '100px',
    '& p': {
      color: '#fff !important',
      fontSize: '12px',
    },
  },

  deleteButton: {
    flexShrink: 0,
    width: 'max-content',
    cursor: 'pointer',
  },
}));
