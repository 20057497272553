import React, { useCallback, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import useStyle from './forgot.styles';
import { forgotPasswordLinkRequest } from 'services/auth';
import { Button, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { LoginWrapper } from 'components/LoginWrapper/LoginWrapper';
import { LoginForm } from 'components/AuthFormCard/LoginForm/LoginForm';
import { forgotPasswdvalidation } from './validation.forgotpasswd';

export const ForgotPasswd = () => {
  const classes = useStyle();
  const [error, setError] = useState('');
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const handleResetPassword = useCallback(
    async (values, { resetForm }) => {
      setError('');
      try {
        const data = await forgotPasswordLinkRequest(values);
        if (data) {
          if (data.message === 'OK') {
            resetForm({});
            setMessage('Check your email to continue.');
            setOpen(true);
          }
        }
      } catch (error) {
        setError((error as Error).message);
        setOpen(true);
      }
    },
    [setError, setOpen]
  );

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <LoginWrapper>
      <LoginForm>
        <Formik
          onSubmit={handleResetPassword}
          validationSchema={forgotPasswdvalidation}
          initialValues={{
            email: '',
          }}
        >
          {({ isValid, touched }) => (
            <Form className={classes.container}>
              <span className={classes.userFormTitle}>Reset Password</span>
              <Field
                component={TextField}
                name="email"
                label="Email"
                type="email"
              />
              <Button
                variant="contained"
                className={classes.btnContainer}
                disabled={!isValid || !touched.email}
                color="secondary"
                size="large"
                type="submit"
              >
                Reset Password
              </Button>
            </Form>
          )}
        </Formik>
      </LoginForm>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          classes={{
            filledError: classes.filledError,
            filledSuccess: classes.filledSuccess,
          }}
          variant="filled"
          color={error ? 'error' : 'success'}
          severity={error ? 'error' : 'success'}
          onClose={handleClose}
        >
          {error || message}
        </Alert>
      </Snackbar>
    </LoginWrapper>
  );
};
