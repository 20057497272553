import { makeStyles } from '@material-ui/core';
import { ICogniflowTheme } from 'material/theme';

export default makeStyles<ICogniflowTheme>(() => ({
  payment: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '600px',
    margin: 'auto',
  },
  desc: {
    fontSize: '16px',
  },

  link: {
    fontSize: '16px',
    display: 'inline',
    fontWeight: 'bold',
  },
}));
