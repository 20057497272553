import React, { useCallback } from 'react';
import { Button, Grid } from '@material-ui/core';
import classNames from 'classnames';
import useStyles from './details.styles';
import { EditIcon, PublishIcon } from 'assets';
import { useRecoilState, useRecoilValue } from 'recoil';
import { experimentLoading } from 'store/experiments';
import { editExperimentState } from 'store/edit-experiment';

interface Props {
  onConfirm: () => any;
  onEdit?: () => any;
  cancelEdit?: () => any;
  canEdit?: boolean;
}

export const EditorFooter = ({
  onConfirm,
  onEdit: handleEdit,
  cancelEdit,
  canEdit = false,
}: Props) => {
  const classes = useStyles();
  const expLoading = useRecoilValue(experimentLoading);
  const [editing, setEditing] = useRecoilState(editExperimentState);
  const onEdit = useCallback(() => {
    setEditing(true);
    if (handleEdit) {
      handleEdit();
    }
  }, [setEditing, handleEdit]);
  const onCancel = useCallback(() => {
    setEditing(false);
    if (cancelEdit) {
      cancelEdit();
    }
  }, [setEditing, cancelEdit]);
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classNames(classes.footerContainer, {
        [classes.activeFooter]: editing,
      })}
    >
      <Grid
        container
        justify="flex-end"
        alignItems="center"
        className={classes.footerContent}
      >
        {editing && (
          <>
            <Button
              disabled={expLoading}
              className={classes.button}
              color="secondary"
              variant="outlined"
              size="large"
              onClick={onCancel}
            >
              Discard changes
            </Button>
            <Button
              disabled={expLoading}
              className={classNames(classes.button, classes.buttonWithIcon)}
              color="secondary"
              variant="contained"
              size="large"
              onClick={onConfirm}
            >
              <Grid container alignItems="center" justify="flex-start">
                <PublishIcon className={classes.buttonIcon} />
                Publish
              </Grid>
            </Button>
          </>
        )}
        {!editing && canEdit && (
          <Button
            disabled={expLoading}
            className={classNames(classes.button, classes.buttonWithIcon)}
            color="secondary"
            variant="contained"
            size="large"
            onClick={onEdit}
          >
            <Grid container alignItems="center" justify="flex-start">
              <EditIcon className={classes.buttonIcon} />
              Edit details
            </Grid>
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
