import { makeStyles } from '@material-ui/core';
import { ICogniflowTheme } from 'material/theme';

export default makeStyles<ICogniflowTheme>(() => ({
  chatBotSection: {
    backgroundColor: '#484F79',
    '& p': {
      color: 'white',
    },
    borderRadius: '24px',
    height: '240px',
    boxSizing: 'border-box',

    position: 'relative',
    overflow: 'hidden',
    boxShadow: '0px 32px 40px 0px rgba(72, 79, 121, 0.20)',
    transition: '0.2s ease-in',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: 'none',
    },
  },

  chatImage: {
    height: '180px',
  },

  carousel: {
    height: '100%',
    boxSizing: 'border-box',
    '& ul': {
      height: '100%',
    },
  },

  title: {
    fontWeight: 600,
    fontSize: '16px',
    marginBottom: '8px',
  },

  description: {
    whiteSpace: 'break-spaces',
    lineHeight: '22px',
  },
}));
