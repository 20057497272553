import { useCallback, useState } from 'react';
import { useUploadProgress } from 'hooks';
import { zipFiles } from 'utils';

type UseChatbotUploadProps = {
  task: number;
};

export const useChatbotUpload = ({ task }: UseChatbotUploadProps) => {
  const [files, setFiles] = useState<File[]>([]);

  const {
    progress,
    uploadFile,
    presignedResponse,
    fileName,
    isFinished,
    isStarted,
  } = useUploadProgress(task);

  const uploadChatbotDocuments = async () => {
    const zip = await zipFiles(files, 'kbs.zip');

    uploadFile([zip]);
  };

  const setFilesHandler = useCallback((docs: File[]) => {
    setFiles((s) => [...(s || []), ...docs]);
  }, []);

  const deleteFileHandler = useCallback((filename: string) => {
    setFiles((s) => s.filter(({ name }) => name !== filename));
  }, []);

  return {
    uploadChatbotDocuments,
    setFilesHandler,
    deleteFileHandler,
    files,
    progress,
    presignedResponse,
    isFinished,
    fileName,
    isStarted,
  };
};
