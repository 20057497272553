import React, { useState, useEffect } from 'react';
import { Box, Card, Chip } from '@material-ui/core';

import { Text } from 'components';
import {
  PlanData,
  promotionalPlansInfo,
} from 'components/UpgradePlanModal/plans';

import { PlAN_IDS } from 'types';
import { useAuthenticationContext } from 'context';
import { getCommercialPlan } from 'services/commercialPlan';

import { RobotLearningImage } from 'assets';
import { getFormattedDate } from 'utils';

const styles = {
  root: {
    padding: '26px 12px 26px 36px',
    margin: '16px 0 24px 39px',
    border: '3px solid #ff616169',
    borderRadius: '8px',
  },
  features: {
    margin: '26px 0 20px',
  },
  feature: {
    fontSize: 14,
    textAlign: 'left',
  },
  image: {
    height: 140,
  },
};

export const FreeTrialCard = () => {
  const { user } = useAuthenticationContext();
  const [planData, setPlanData] = useState<PlanData | null>(null);

  useEffect(() => {
    getCommercialPlan(PlAN_IDS.FREE_TRIAL)
      .then((data: any) => {
        setPlanData(promotionalPlansInfo[PlAN_IDS.FREE_TRIAL]({ ...data }));
      })
      .catch();
  }, []);

  if (!user || !planData) return null;

  return (
    <Card style={styles.root}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Box display="flex" alignItems="center" gridGap={8}>
            <Text variant="h3" align="left" style={{ marginRight: 16 }}>
              {planData.planName}
            </Text>
            <Chip
              size="small"
              label="Currently running"
              color="secondary"
              style={{ color: '#fff' }}
            />
            <Chip
              size="small"
              label={`Expires on ${getFormattedDate(
                new Date(user.commercial_plan_expiration),
                'PP'
              )}`}
            />
          </Box>
          <Box>
            <ul style={styles.features}>
              {planData.properties.map((property, idx) => (
                <li key={idx} style={styles.feature}>
                  {property}
                </li>
              ))}
            </ul>
          </Box>
        </Box>

        <Box>
          <RobotLearningImage style={styles.image} />
        </Box>
      </Box>
    </Card>
  );
};
