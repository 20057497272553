import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  root: {
    maxWidth: 600,
    margin: '0 auto',
  },

  buttonClose: {
    boxShadow: '',
  },
}));
