import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  wrapper: {
    display: 'inline-block',
    position: 'relative',
    width: 'max-content',
    padding: 2,
    border: '1px solid #ccc',
    borderRadius: 4,
  },
  button: {
    width: 40,
    height: 40,
    backgroundColor: (props: any) => props.color,
    borderRadius: 4,
    cursor: 'pointer',
  },
  input: {
    position: 'absolute',
    visibility: 'hidden',
    pointerEvents: 'none',
  },
}));
