import { PROJECT_TASK } from 'types';

const taskMapper: Record<string, any> = {
  classifier: {
    image: PROJECT_TASK.CLASSIFIER_IMAGE,
    audio: PROJECT_TASK.CLASSIFIER_AUDIO,
    text: PROJECT_TASK.CLASSIFIER_TEXT,
  },
  extractor: {
    text: PROJECT_TASK.EXTRACTOR,
    image: PROJECT_TASK.EXTRACTOR,
  },
  'object-detection': {
    image: PROJECT_TASK.OBJECT_DETECTION,
  },
  chatbot: {
    text: PROJECT_TASK.CHATBOT,
  },
};

export const getTaskNumber = (taskUrl: string, typeUrl: string) =>
  taskMapper[taskUrl][typeUrl];
