import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@material-ui/core';

import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';

import { Text } from 'components';
import InfoIcon from '@material-ui/icons/Info';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SendIcon from '@material-ui/icons/Send';

import { LogoCogniflow } from 'assets';
import LogoIco from 'assets/logo_ico.svg';

import useStyle from './chat-preview.styles';
import { BotMessage } from './BotMessage';

interface Props {
  title: string;
  pinnedMessage: string;
  colorButton: string;
  colorBackground: string;
  welcomeMessage: string;
  fontFamily: string;
  pinnedMessageIsCollapsible: boolean;
  avatar: string;
  hideLogo: boolean;
  includeMessageSources?: boolean;
}

export const ChatPreview = ({
  title,
  pinnedMessage,
  colorButton,
  colorBackground,
  welcomeMessage,
  fontFamily,
  pinnedMessageIsCollapsible,
  avatar,
  hideLogo,
  includeMessageSources,
}: Props) => {
  const classes = useStyle();
  const pinnedRef = useRef<HTMLDivElement>();
  const _title = useMemo(() => title || 'My App', [title]);
  const _colorBtn = useMemo(() => colorButton || '#FF6A61', [colorButton]);
  const _welcomeMsg = useMemo(
    () =>
      welcomeMessage ||
      `👋 Hi!, I am CogniGPT, ask me anything about ${_title}`,
    [_title, welcomeMessage]
  );
  const [collapsed, setCollapsed] = useState(true);
  useEffect(() => {
    if (pinnedMessageIsCollapsible) setCollapsed(true);
    else setCollapsed(false);
  }, [pinnedMessageIsCollapsible]);
  useEffect(() => {
    if (pinnedRef.current) pinnedRef.current.scrollTop = 0;
  }, [collapsed]);

  return (
    <Box
      className={classes.root}
      style={{
        '--color-btn': _colorBtn,
        '--color-background': colorBackground,
        '--fontFamily': fontFamily,
      }}
    >
      <Box className={classes.header}>
        <Text variant="h4">{_title}</Text>
      </Box>

      {pinnedMessage && (
        <div
          ref={pinnedRef}
          onClick={() =>
            pinnedMessageIsCollapsible && setCollapsed((prev) => !prev)
          }
          className={classes.pinnedMessage}
          style={{
            maxHeight: '50%',
            overflow: collapsed ? 'hidden' : 'auto',
            cursor: pinnedMessageIsCollapsible ? 'pointer' : 'initial',
          }}
        >
          <InfoIcon
            style={{
              position: 'absolute',
              top: 12,
              left: 12,
              fontSize: 16,
              color: 'var(--color-btn)',
            }}
          />
          <Box
            style={{
              overflow: 'hidden',
              height: collapsed ? '24px' : 'max-content',
            }}
          >
            <ReactMarkdown
              remarkPlugins={[remarkGfm, remarkBreaks]}
              linkTarget="_blank"
            >
              {pinnedMessage}
            </ReactMarkdown>
          </Box>
          {pinnedMessageIsCollapsible && (
            <ExpandMoreIcon
              style={{
                transform: collapsed ? 'none' : 'rotate(180deg)',
                position: 'absolute',
                top: 12,
                right: 12,
                fontSize: 20,
              }}
            />
          )}
        </div>
      )}

      <Box className={classes.chat}>
        <BotMessage avatar={avatar}>
          <ReactMarkdown
            remarkPlugins={[remarkGfm, remarkBreaks]}
            linkTarget="_blank"
          >
            {_welcomeMsg}
          </ReactMarkdown>
        </BotMessage>

        <Box className={classes.userMessage}>Hi</Box>

        {includeMessageSources && (
          <BotMessage avatar={avatar} sources={[]}>
            <ReactMarkdown
              remarkPlugins={[remarkGfm, remarkBreaks]}
              linkTarget="_blank"
            >
              Hi, this message includes the sources that were used to generate
              it
            </ReactMarkdown>
          </BotMessage>
        )}
      </Box>
      <Box className={classes.bottom}>
        <Box className={classes.input}>
          <Text variant="paragraph1">Message</Text>
          <SendIcon style={{ fill: 'var(--color-btn)', fontSize: 20 }} />
        </Box>

        {!hideLogo && (
          <Box className={classes.footer}>
            <Text variant="paragraph1">Powered by</Text>{' '}
            <LogoCogniflow height={24} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
