import { EXPERIMENT_TASK, Experiment } from 'types';
import { getEnv } from 'services';

const REACT_APP_QA_URL = getEnv('REACT_APP_QA_URL');

const isQA = (experiment: Experiment | undefined) =>
  experiment?.task === EXPERIMENT_TASK.TEXT_QANDA;

export const isQAGenerative = (experiment: Experiment | undefined) =>
  isQA(experiment) && experiment?.config?.qa_processor_type === 'generative';

export const isQAGenerativeStructured = (experiment: Experiment | undefined) =>
  isQAGenerative(experiment) &&
  experiment?.config?.knowledge_base_data_type === 1;

export const isQAOld = (experiment: Experiment | undefined) =>
  isQA(experiment) && experiment?.config?.qa_processor_type !== 'generative';

export const getQAGenerativeUrlByModel = (id_model: string, token: string) =>
  `${REACT_APP_QA_URL}/chat?idModel=${id_model}&token=${token}`;

export const getQAGenerativeUrlByApplication = (id_application: string) =>
  `${REACT_APP_QA_URL}/chat?idApplication=${id_application}`;
