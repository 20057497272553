import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  confirmMessageBg: {
    backgroundColor: colors.blue70,
    borderRadius: '8px',
    boxShadow: '0 16px 24px 0 rgba(72, 79, 121, 0.15)',
  },
  confirmDeleteTitle: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#fff',
  },
  confirmDeleteSubTitle: {
    fontFamily: 'PTSerif',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.3,
    letterSpacing: 'normal',
    color: '#fff',
  },
  hoverRow: {
    color: colors.orange,
    backgroundColor: colors.blue5,
    paddingTop: 16,
    paddingBottom: 16,

    '&:hover': {
      color: 'red',
      cursor: 'pointer',
      boxShadow: '0 16px 24px 0 rgba(72, 79, 121, 0.15)',
      border: `solid 1px ${colors.blue20}`,
      borderRadius: 8,
      backgroundColor: '#fff !important',
    },
  },
  starIcon: {
    fontSize: '14px',
    color: colors.blue90,
    padding: 8,
  },
}));
