import { Collapse } from '@material-ui/core';
import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  ReactNode,
} from 'react';
import { AdvancedConfig, PROJECT_TYPE } from 'types';
import { getExperimentConfig } from 'services/experiments';
import { ConfigWrapper } from './ConfigWrapper';
import { ImageConfig as ImageConfigComponent } from './ImageConfig';
import { AudioConfig as AudioConfigComponent } from './AudioConfig';
import { TextConfig as TextConfigComponent } from './TextConfig';

import useStyles from './accordionadvancedconfig.styles';
interface Props {
  type: 'text' | 'image' | 'audio';
  task: string;
  config: AdvancedConfig;
  setConfigHandler(values: AdvancedConfig): void;
}

export const AccordionAdvancedConfig = ({
  type,
  task,
  config,
  setConfigHandler,
}: Props) => {
  const classes = useStyles();
  const [collapseStatus, setCollapseStatus] = useState<boolean>(false);
  const [isTagShowing, setShowingTag] = useState(true);

  useEffect(() => {
    const getDefaultConfig = async () => {
      const resp = await getExperimentConfig(type, task);

      setConfigHandler(resp);
    };
    getDefaultConfig();
  }, [setConfigHandler, type, task]);

  const configChangeHandler = useCallback(
    (e) => {
      let copyConfig;
      const { name, type: typeE, value } = e.target;
      switch (type) {
        case 'image':
          copyConfig = { ...config };
          copyConfig[name] = typeE === 'radio' ? value : !copyConfig[name];
          setConfigHandler(copyConfig);
          setShowingTag(false);
          break;
        case 'text':
          copyConfig = { ...config };
          copyConfig[name] = typeE === 'radio' ? value : !copyConfig[name];

          setConfigHandler(copyConfig);
          setShowingTag(false);
          break;
        case 'audio':
          copyConfig = { ...config };
          if (name === 'data_augementation') {
            copyConfig['daug_rotation'] = !copyConfig.daug_rotation;
            copyConfig['daug_brightness'] = !copyConfig.daug_brightness;
            copyConfig['daug_zoom'] = !copyConfig.daug_zoom;
            copyConfig[
              'daug_horizontal_flip'
            ] = !copyConfig.daug_horizontal_flip;
            copyConfig['daug_vertical_flip'] = !copyConfig.daug_vertical_flip;
          }

          copyConfig[name] = typeE === 'radio' ? value : !copyConfig[name];
          if (copyConfig['data_augementation'])
            delete copyConfig['data_augementation'];
          setConfigHandler(copyConfig);
          setShowingTag(false);
          break;
        default:
          copyConfig = { ...config };
          copyConfig[name] = typeE === 'radio' ? value : !copyConfig[name];
          setConfigHandler(copyConfig);
          setShowingTag(false);
          break;
      }
    },
    [type, setShowingTag, config, setConfigHandler]
  );

  const applyDefault = useCallback(async () => {
    const resp = await getExperimentConfig(type, task);
    setConfigHandler(resp);

    setShowingTag(true);
  }, [type, setShowingTag, setConfigHandler, task]);

  const toggleCollapse = useCallback(
    () => setCollapseStatus((collapseStatus) => !collapseStatus),
    [setCollapseStatus]
  );

  const optionProps = useMemo(
    () => ({
      configChangeHandler,
      config,
      task,
      applyDefault,
      type,
    }),
    [config, type, task, applyDefault, configChangeHandler]
  );

  const options: { [key: string]: ReactNode } = useMemo(
    () => ({
      [PROJECT_TYPE.TEXT]: <TextConfigComponent {...optionProps} />,
      [PROJECT_TYPE.IMAGE]: <ImageConfigComponent {...optionProps} />,
      [PROJECT_TYPE.AUDIO]: <AudioConfigComponent {...optionProps} />,
    }),
    [optionProps]
  );

  return (
    <ConfigWrapper
      task={task}
      toggleCollapse={toggleCollapse}
      collapseStatus={collapseStatus}
      isTagShowing={isTagShowing}
    >
      <Collapse in={collapseStatus} className={classes.collapsibleContainer}>
        {options[type]}
      </Collapse>
    </ConfigWrapper>
  );
};
