import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  button: {
    marginTop: 24,
  },
  buttonClose: {},
  subtitle: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.blue70,
  },
  codePlayGround: {
    height: 300,
  },
  link: {
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.orange,
    textDecoration: 'underline',
    marginBottom: '12px',
  },
  title: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: colors.blue70,
    marginBottom: 16,
  },
  root: {
    maxWidth: 600,
    margin: '0 auto',
  },
  swaggerMoreDetailsContainer: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& a': {
      marginBottom: 0,
    },
  },
  apikeyIconContainer: {
    cursor: 'pointer',
  },
  codeBoxContainer: {
    flexDirection: 'column',
  },
  codeBoxHighlighter: {
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    borderRadius: '0 0 4px 4px',
  },
  parameterList: {
    width: '100%',
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: 14,
  },
  tabsWrapper: {
    overflow: 'hidden',
    boxSizing: 'border-box',
    width: '100%',
    paddingLeft: 12,
    background: '#242424',
    borderRadius: '4px 4px 0 0',

    display: 'flex',
    gap: 6,
  },
  tab: {
    cursor: 'pointer',
    padding: 12,
    fontWeight: 'bold',
    fontSize: 14,
    color: '#b5b5b5',
  },
  tabActive: {
    color: colors.orange,
  },
}));
