import React, { FC, useCallback, useState } from 'react';
import { Box, Button, Link } from '@material-ui/core';
import { StepTaskComponent } from '../StepTaskComponent';
import { EntityProps, Payload, ExperimentValues } from 'types';

import { useHistory } from 'react-router-dom';
import { Extractor, Text } from 'components';
import { getExtractorConfig } from 'services';

export interface CustomExtractorProps {
  task: string;
  type: string;
  setExpProps(payload: Payload): void;
  expValues: ExperimentValues;
}

export const CustomExtractor: FC<CustomExtractorProps> = ({
  task,
  type,
  expValues,
  setExpProps,
}) => {
  const history = useHistory();

  const clickHandler = () => {
    getExtractorConfig().then((res) => {
      setExpProps({
        config: {
          ...res,
          entities: expValues.config.entities as EntityProps[],
        },
      });
      history.push(`/create/${type}/${task}/ready`);
    });
  };

  const [disableNextStep, setDisableNextStep] = useState(
    !expValues.config.entities ||
      (expValues.config.entities as EntityProps[]).length === 0
  );

  const onEntitiesChange = useCallback(
    (entities: EntityProps[]) => {
      if (!expValues.config) {
        return;
      }

      setExpProps({
        config: { ...expValues.config, entities },
      });

      entities.length > 0
        ? setDisableNextStep(false)
        : setDisableNextStep(true);
    },
    // eslint-disable-next-line
    [setExpProps]
  );

  return (
    <StepTaskComponent task={task} type={type}>
      <Box marginTop="24px"></Box>

      <Box mb="18px">
        <Text variant="paragraph1">
          <Link
            rel="noopener noreferrer"
            target="_blank"
            color="secondary"
            style={{ cursor: 'pointer' }}
            href="https://docs.cogniflow.ai/en/article/create-a-custom-extractor-7pydjq"
          >
            Need help?
          </Link>{' '}
          Learn how to define entities
        </Text>
      </Box>

      <Box
        style={{
          boxShadow: '0 8px 16px 0 rgba(72, 79, 121, 0.15)',
          borderRadius: '8px',
        }}
      >
        <Extractor
          onEntitiesChange={onEntitiesChange}
          initialEntities={expValues.config.entities as EntityProps[]}
          allowImport
        />
      </Box>
      <Box marginTop="32px">
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={clickHandler}
          disabled={disableNextStep}
        >
          Next Step
        </Button>
      </Box>
    </StepTaskComponent>
  );
};
