import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  select: {
    // width: '15%',
  },
  infiniteScroll: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '40px',
    '@media (max-width: 1500px)': {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    '@media (max-width: 1280px)': {
      gap: '20px',
    },
    '@media (max-width: 1200px)': {
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: '40px',
    },
    '@media (max-width: 900px)': {
      gap: '20px',
    },
    '@media (max-width: 800px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '50px',
    },
    '@media (max-width: 590px)': {
      gridTemplateColumns: 'repeat(1, 1fr)',
      gap: '30px',
    },
  },
  viewContainer: {
    [theme.breakpoints.down('sm')]: {
      margin: '0 50px',
    },
  },

  searchInput: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));
