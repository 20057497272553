import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles((theme) => ({
  container: {
    // width: 1208,
    marginTop: 40,
    flexWrap: 'wrap',
  },
  linkDecoration: {
    color: colors.orange,
    marginTop: '16px',
  },
  smallLinkDecoration: {
    color: colors.orange,
    '&:hover': {
      textDecoration: 'underline',
    },
    position: 'absolute',
    whiteSpace: 'nowrap',
    transform: 'translateX(-50%)',
    left: '50%',
  },
}));
