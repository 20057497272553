import React, { FC } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import useStyles from './StepTaskComponent.styles';
import { getTexts } from '../step4Utils';
import { EXPERIMENT_TASK } from 'types';
import { Hints } from 'components';

export interface StepTaskComponentProps {
  task: string;
  type: string;
  children: React.ReactNode;
}

export const StepTaskComponent: FC<StepTaskComponentProps> = ({
  children,
  task,
  type,
}) => {
  const classes = useStyles();

  const { title, subtitle } = getTexts({ type, task });

  return (
    <Grid
      container
      direction="column"
      className={classes.container}
      alignItems="center"
    >
      <Grid container alignItems="center" direction="column" justify="center">
        <Typography variant="h3" className={classes.title}>
          {title}
        </Typography>
        {parseInt(task) !== EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR && (
          <Typography variant="body1">{subtitle}</Typography>
        )}
      </Grid>

      <Box>
        {parseInt(task) !== EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR && (
          <Hints type={type} task={task} />
          // <div className={classes.info}>
          //   <Lightbulb className={classes.img} />
          //   <Typography align="left" variant="h4" className={classes.infoTitle}>
          //     Keep in mind
          //   </Typography>

          //   <ul className={classes.listContainer}>
          //     <Tips task={task} type={type} />
          //   </ul>
          // </div>
        )}
        {children}
      </Box>
    </Grid>
  );
};
