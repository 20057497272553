import React, { ReactNode } from 'react';
import { Box } from '@material-ui/core';
import LogoIco from 'assets/logo_ico.svg';
import { Text } from 'components';

import useStyles from './BotMessage.styles';

type BotMessageProps = {
  avatar?: string;
  children: ReactNode;
  sources?: string[];
};

export const BotMessage = ({ children, avatar, sources }: BotMessageProps) => {
  const classes = useStyles();

  return (
    <Box>
      <Box display="flex" gridGap={8} mb="8px">
        <Box className={classes.avatar}>
          {avatar ? (
            <img src={avatar} className={classes.avatarImg} />
          ) : (
            <img src={LogoIco} style={{ padding: 4 }} />
          )}
        </Box>
        <Box className={classes.welcomeMessage}>{children}</Box>
      </Box>

      {sources && (
        <Box mt="8px">
          <Text variant="paragraph1" className={classes.messageSources}>
            <b
              style={{
                fontSize: '12px',
              }}
            >
              Sources:
            </b>{' '}
            document1.pdf, document2.pdf, document3.pdf, document4.pdf
          </Text>
        </Box>
      )}
    </Box>
  );
};
