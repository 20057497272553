import { Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './square.style';
import colorInterpolate from 'color-interpolate';
import { Tooltip } from '@material-ui/core';

export interface ISquareProps {
  value: number;
  colorPercentage: number;
  tooltipText: string;
  size: string;
}
const WHITE_PERCENTAGE_VALUE = 0.5;
const colorMap = colorInterpolate(['#ECEEF6', '#5A5F7A']);

export const Square = React.memo(
  ({ value, colorPercentage, tooltipText, size }: ISquareProps) => {
    const classes = useStyles({
      color: colorMap(colorPercentage),
      isWhite: colorPercentage >= WHITE_PERCENTAGE_VALUE,
      size,
    });

    return (
      <div className={classes.row}>
        <Tooltip title={tooltipText}>
          <div className={classes.gridBackground}></div>
        </Tooltip>
        <Typography variant="button" className={classes.squareNumber}>
          {value}
        </Typography>
      </div>
    );
  }
);
