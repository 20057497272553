import React, { useMemo } from 'react';
import { NavLink, Redirect, Switch } from 'react-router-dom';

import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

import AccountIcon from '@material-ui/icons/AccountCircle';
import BusinessIcon from '@material-ui/icons/Business';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import { AuthRoute, MainLayout } from 'components';
import { Profile } from './Profile';
import { Company } from './Company';
import { ApiKeys } from './ApiKeys';

import useStyle from './settings.styles';
import { Billing } from './Billing';
import { useAuthenticationContext } from 'context';
import { PlAN_IDS } from 'types';

const newPlans: string[] = [
  PlAN_IDS.FREE,
  PlAN_IDS.FREE_TRIAL,
  PlAN_IDS.STARTER,
  PlAN_IDS.TEAM,
  PlAN_IDS.BUSINESS,
];

export const Settings = ({ match: { path } }: any) => {
  const { user } = useAuthenticationContext();

  const isGuest = useMemo(() => user?.is_company_commercial_plan, [user]);

  const renderSubscription = useMemo(
    () => newPlans.includes(user?.commercial_plan.id || '') && !isGuest,
    [user, isGuest]
  );

  const classes = useStyle();
  const links = useMemo(
    () => [
      {
        text: 'My Profile',
        icon: <AccountIcon />,
        path: '/profile',
      },
      // ...(user?.is_company_commercial_plan
      //   ? []
      // :
      // [
      {
        text: 'Organization',
        icon: <BusinessIcon />,
        path: '/company',
      },
      // ],

      ...(renderSubscription
        ? [
            {
              text: 'Subscription',
              icon: <CreditCardIcon />,
              path: '/subscription',
            },
          ]
        : []),
      // {
      //   text: 'Members',
      //   icon: <GroupIcon />,
      // },
      {
        text: 'Api Keys',
        icon: <VpnKeyIcon />,
        path: '/apikeys',
      },
    ],
    [renderSubscription]
  );

  return (
    <MainLayout>
      <Grid className={classes.root}>
        <Grid className={classes.sidebar}>
          <List component="nav">
            {links.map((link, idx) => (
              <NavLink
                to={`${path}${link.path}`}
                className={classes.link}
                activeClassName={classes.linkActive}
                key={idx}
              >
                <ListItem button>
                  <ListItemIcon>{link.icon}</ListItemIcon>
                  <ListItemText primary={link.text} />
                </ListItem>
              </NavLink>
            ))}
          </List>
        </Grid>

        <Grid className={classes.contentContainer}>
          <Switch>
            <AuthRoute exact path={`${path}`}>
              <Redirect to={`${path}/profile`} />
            </AuthRoute>

            <AuthRoute exact path={`${path}/profile`}>
              <Profile />
            </AuthRoute>

            <AuthRoute exact path={`${path}/company`}>
              <Company />
            </AuthRoute>

            <AuthRoute exact path={`${path}/apikeys`}>
              <ApiKeys />
            </AuthRoute>

            {renderSubscription && (
              <AuthRoute exact path={`${path}/subscription`}>
                <Billing />
              </AuthRoute>
            )}
          </Switch>
        </Grid>
      </Grid>
    </MainLayout>
  );
};
