import React from 'react';
import { WaitingRobot } from 'assets';
import useStyles from './creatingaccount.styles';

export const CreatingAccount = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <WaitingRobot />
      <span className={classes.subtitle}>Creating your account …</span>
    </div>
  );
};
