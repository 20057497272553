import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createExperiment } from 'services';
import {
  ExperimentValues,
  PROJECT_TASK,
  PROJECT_TYPE,
  ProjectTaskLabels,
} from 'types';

type UseBuildProjectProps = {
  type: number;
  task: number;
  projectParams: ExperimentValues;
};

export const useBuildProject = ({
  type,
  task,
  projectParams,
}: UseBuildProjectProps) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [modalType, setModalType] = useState<'dataset' | 'validation'>(
    'dataset'
  );

  useEffect(() => {
    if (errorMessage !== '') {
      setOpen(true);
    }
  }, [errorMessage, setOpen]);

  const configAccesor = useCallback(
    (config: any, key: string) => config[key] === true,
    []
  );

  const isVectorizer = useCallback((key: string, type: number) => {
    const isAudio = type === PROJECT_TYPE.AUDIO;

    const mustContain = isAudio
      ? ['vectorization_', 'cnn_']
      : type === PROJECT_TYPE.IMAGE
      ? ['cnn_']
      : ['vectorization_'];
    if (isAudio) {
      return key.includes(mustContain[0]) || key.includes(mustContain[1]);
    }
    return key.includes(mustContain[0]);
  }, []);

  const isClasifier = useCallback((key: string) => {
    let mustContain = ['algo_'];
    return key.includes(mustContain[0]);
  }, []);

  const isValidVectorizer = useCallback(
    (config: any, type: number) => {
      return Object.keys(config).some(
        (e) => isVectorizer(e, type) && configAccesor(config, e)
      );
    },
    [isVectorizer, configAccesor]
  );

  const isValidClasifier = useCallback(
    (config: any, type: number) => {
      const algoMlaState =
        !config['vectorization_vggish'] && !config['vectorization_l3net'];

      const totalOfClassifierSelected = Object.keys(config).filter(
        (e) => isClasifier(e) && configAccesor(config, e)
      ).length;

      if (type === PROJECT_TYPE.AUDIO) {
        if (totalOfClassifierSelected > 0) {
          if (
            totalOfClassifierSelected === 1 &&
            config['algo_mla'] &&
            algoMlaState
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      }

      return Object.keys(config).some(
        (e) => isClasifier(e) && configAccesor(config, e)
      );
    },
    [isClasifier, configAccesor]
  );

  const isValidSelection = useCallback(
    () =>
      isValidClasifier({ ...projectParams.config }, type) &&
      isValidVectorizer({ ...projectParams.config }, type),
    [projectParams, type, isValidClasifier, isValidVectorizer]
  );

  const { push } = useHistory();

  const buildProject = useCallback(
    async (train_url?: string) => {
      try {
        setLoading(true);

        const forwardValidation = [
          PROJECT_TASK.CHATBOT,
          PROJECT_TASK.EXTRACTOR,
        ].includes(task);

        if (isValidSelection() || forwardValidation) {
          const exp = await createExperiment({
            ...projectParams,
            title:
              projectParams.title ||
              `My ${ProjectTaskLabels[task].toLocaleLowerCase()} project`,
            type: type as 0 | 1 | 2,
            send_notification: task !== PROJECT_TASK.EXTRACTOR,
            config: projectParams.config,
            task,
            train_url: projectParams.train_url || train_url,
          });

          setLoading(false);
          push(`/building/${exp.id}`);
          window.location.reload();
        } else {
          setOpen(true);
          setErrorMessage(
            'You need to specify at least one classifier and one vectorizer to create the experiment.'
          );
          setModalType('validation');
          setLoading(false);
        }
      } catch (err) {
        setErrorMessage((err as Error).message);
        setOpen(true);
        setLoading(false);
        setModalType('dataset');
      }
    },
    [push, isValidSelection, task, projectParams, type]
  );

  const closeModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  // const handleCancel = useCallback(() => {
  //   setOpen(false);
  //   push(`/create/${type}/upload`);
  // }, [setOpen, type, push]);

  return {
    buildProject,
    errorMessage,
    loading,
    open,
    modalType,
    closeModal,
  };
};
