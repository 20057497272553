import { EXPERIMENT_TASK, ExperimentLabel, PROJECT_TYPE } from 'types';

const taskTitleLabels: ExperimentLabel = {
  [EXPERIMENT_TASK.TEXT_QANDA]: 'Upload your knowledge base',
  [EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR]:
    'Define the entities you want to extract',
};

const taskSubtitlesLabels: ExperimentLabel = {
  [EXPERIMENT_TASK.TEXT_QANDA]:
    'These are the documents with the text information to train the Question-Answering models',
  [EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR]: '',
};

type TextsMap = { [key: string]: { title: string; subtitle: string } };

type GetTextsProps = {
  task: string;
  type: string;
};

export const getTexts = ({ task, type }: GetTextsProps) => {
  const textsMap: TextsMap = {
    [PROJECT_TYPE.TEXT]: {
      title: taskTitleLabels[task] || 'Upload your sample text and labels',
      subtitle:
        taskSubtitlesLabels[task] ||
        'These are the texts used to train the ML models.',
    },
    [PROJECT_TYPE.IMAGE]: {
      title:
        taskTitleLabels[task] ||
        'Create a single zip file of your images classified into folders',
      subtitle: 'These are the images used to train the ML models.',
    },
    [PROJECT_TYPE.AUDIO]: {
      title: 'Create a single zip file of your audios classified into folder',
      subtitle: 'These are the audios used to train the ML models.',
    },
  };

  return textsMap[type];
};
