import React, { ChangeEvent } from 'react';
import useStyles from './color-picker.styles';

export interface ColorPickerProps {
  name?: string;
  value?: string;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
}

export const ColorPicker = ({
  name = '',
  value = '#fff',
  onChange,
}: ColorPickerProps) => {
  const classes = useStyles({ color: value });

  return (
    <label className={classes.wrapper}>
      <div className={classes.button}></div>
      <input
        type="color"
        name={name}
        value={value}
        onChange={onChange}
        className={classes.input}
      />
    </label>
  );
};
