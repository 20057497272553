import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  captureCameraOff: {
    display: 'none',
  },
  captureCameraOn: {
    display: 'block',
  },
  cameraWarning: {
    height: 250,
  },
  wavediv: {
    width: '100%',
    heigh: 90,
  },
  tooltipFont: {
    fontSize: 32,
  },
  audioBtnContainer: {
    marginTop: 32,
  },
  mic: {
    position: 'absolute',
    top: '10%',
    left: '45%',
    zIndex: 999999,
  },
  micContainer: {
    position: 'relative',
    paddingBottom: 32,
  },
  btnMic: {
    backgroundColor: '#ffe1df',
  },
}));
