import React, { ChangeEvent, useCallback, useState } from 'react';
import { SearchInput, SearchModal } from 'components';
import useStyles from './DebouncedSearch.styles';
import debounce from 'just-debounce-it';
import { getProjects } from 'services';
import { Experiment, PROJECT_VISIBILITY } from 'types';

export const DebouncedSearch = () => {
  const classes = useStyles();

  const [experiments, setExperiments] = useState<Experiment[]>([]);

  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const openSearchModal = useCallback(() => {
    setSearchModalOpen(true);
  }, []);

  const closeSearchModal = useCallback(() => {
    setSearchModalOpen(false);
  }, []);

  const getProjectsFn = debounce((keyword: string) => {
    getProjects({
      visibilities: [PROJECT_VISIBILITY.COMMUNITY, PROJECT_VISIBILITY.PUBLIC],
      keyword,
      pageSize: 10,
    }).then((response) => {
      setExperiments((response as any).experiments as Experiment[]);
    });
  }, 500);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    getProjectsFn(e.target.value);
  };

  return (
    <>
      <SearchInput
        className={classes.searchInput}
        placeholder="Search for project name like ‘‘name’’"
        onSearch={() => {}}
        onClick={openSearchModal}
      />

      <SearchModal
        isOpen={searchModalOpen}
        experiments={experiments}
        closeHandler={closeSearchModal}
        onChangeHandler={onInputChange}
      >
        <div>Search modal</div>
      </SearchModal>
    </>
  );
};
