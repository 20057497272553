import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import { SearchInput, SearchModal, SearchTag, SingleSelect } from 'components';

import { Experiment, PROJECT_TASK } from 'types';

import useStyles from './ProjectSearchBar.styles';
import { getProjects } from 'services';
import debounce from 'just-debounce-it';

enum VISIBILITY_VALUES {
  ALL = 'all',
  ORG = 'organization',
  PRIVATE = 'private',
}

const visibilityDropdown = [
  { label: 'All projects', value: VISIBILITY_VALUES.ALL },
  { label: 'Private', value: VISIBILITY_VALUES.PRIVATE },
  {
    label: 'Organization',
    value: VISIBILITY_VALUES.ORG,
  },
];

const tags = [
  {
    name: 'Chatbot',
    projectTask: PROJECT_TASK.CHATBOT,
  },
  {
    name: 'Extractor',
    projectTask: PROJECT_TASK.EXTRACTOR,
  },
  {
    name: 'Classifier',
    projectTask: PROJECT_TASK.CLASSIFIER_TEXT,
  },
  {
    name: 'Object detection',
    projectTask: PROJECT_TASK.OBJECT_DETECTION,
  },
];

type ProjectSearchBarProps = {
  onChange: (tags: number[]) => void;
  onSearchClick?: () => void;
  direction?: 'row' | 'column';
  align?: 'flex-start' | 'center' | 'flex-end';
  justify?: 'flex-start' | 'center' | 'flex-end' | 'space-between';
  lineHeight?: string;
  visibilities?: number[];
  showVisibilityDropdown?: boolean;
  onVisibilityChange?(visibility: string): void;
  showSearch?: boolean;
};

export const ProjectSearchBar = ({
  direction = 'row',
  showSearch = true,
  align,
  justify,
  lineHeight,
  onChange,
  visibilities,
  showVisibilityDropdown = false,
  onVisibilityChange,
}: ProjectSearchBarProps) => {
  const [selectedTags, setSelectedTags] = useState<PROJECT_TASK[]>([]);
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [experiments, setExperiments] = useState<Experiment[]>([]);

  const [visibility, setVisibility] = useState<string>(VISIBILITY_VALUES.ALL);

  const classes = useStyles();

  const getProjectsFn = debounce((keyword: string) => {
    getProjects({ visibilities, keyword, pageSize: 10 }).then((response) => {
      setExperiments((response as any).experiments as Experiment[]);
    });
  }, 500);

  const tagClick = (task: PROJECT_TASK) => {
    const classifiers = [
      PROJECT_TASK.CLASSIFIER_TEXT,
      PROJECT_TASK.CLASSIFIER_IMAGE,
      PROJECT_TASK.CLASSIFIER_AUDIO,
    ];

    if (task === PROJECT_TASK.CLASSIFIER_TEXT) {
      setSelectedTags((prev) => {
        if (prev.includes(task)) {
          return prev.filter((t) => !classifiers.includes(t));
        }

        return [...prev, ...classifiers];
      });

      return;
    }

    setSelectedTags((prev) => {
      if (prev.includes(task)) {
        return prev.filter((t) => t !== task);
      }

      return [...prev, task];
    });
  };

  const closeSearchModal = useCallback(() => {
    setSearchModalOpen(false);
  }, []);

  const openSearchModal = useCallback(() => {
    setSearchModalOpen(true);
  }, []);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    getProjectsFn(e.target.value);
  };

  const onVisibilityChangeHandler = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setVisibility(event.target.value as string);
    onVisibilityChange?.(event.target.value as string);
  };

  useEffect(() => {
    onChange(selectedTags);
  }, [selectedTags, onChange]);

  return (
    <Box display="flex" justifyContent={justify}>
      <Box
        className={classes.bar}
        display="flex"
        justifyContent={justify}
        alignItems={align}
        flexDirection={direction}
        gridGap={lineHeight}
      >
        {showSearch && (
          <>
            <SearchInput
              className={classes.searchInput}
              placeholder="Search for project name like ‘‘name’’"
              onSearch={() => {}}
              onClick={openSearchModal}
            />

            <SearchModal
              isOpen={searchModalOpen}
              experiments={experiments}
              closeHandler={closeSearchModal}
              onChangeHandler={onInputChange}
            >
              <div>Search modal</div>
            </SearchModal>
          </>
        )}

        <Box
          className={classes.tags}
          display="flex"
          gridGap="12px"
          ml="24px"
          alignItems="center"
          justifyContent={justify}
        >
          {tags.map(({ projectTask, name }) => {
            const isSelected = selectedTags.includes(projectTask);

            return (
              <SearchTag
                name={name}
                projectTask={projectTask}
                isSelected={isSelected}
                key={projectTask}
                onClick={() => {
                  tagClick(projectTask);
                }}
              />
            );
          })}
        </Box>
      </Box>

      {showVisibilityDropdown && (
        <SingleSelect
          // label=""
          value={visibility}
          options={visibilityDropdown}
          onChange={onVisibilityChangeHandler}
        />
      )}
    </Box>
  );
};
