import React, { useRef, useEffect, useMemo, useCallback } from 'react';
import {
  ObjectDetectionResponse as ObjectDetectionResponseType,
  PredictResponse,
  TEST_PAGE_OPACITY_FACTOR,
} from 'types';
import {
  clearCanvas,
  drawBoxesResponse,
  drawInCanvas,
  twoDecimalsFixed,
} from 'utils';
import { Grid } from '@material-ui/core';
import { ImageControls } from 'components/ImageControls';

import { useObjectDetectionContext } from '../ObjectDetectionContext';

type ObjectDetectionResponseProps = {
  prediction: PredictResponse | null;
  boxesToShow: string[];
  image: HTMLImageElement;
  showControls?: boolean;
  onMaximize?(): void;
  width?: number;
  height?: number;
  colors?: { [key: string]: string };
};

export const ODImagePanel = ({
  prediction,
  boxesToShow,
  image,
  showControls,
  onMaximize,
  width,
  height,
  colors,
}: ObjectDetectionResponseProps) => {
  const canvasEl = useRef<HTMLCanvasElement>(null);

  const { options, updateOptions } = useObjectDetectionContext();

  const boxesResult = useMemo(
    () =>
      ((prediction?.result as ObjectDetectionResponseType[]) || []).map(
        (box) => {
          return {
            x1: box.x_left_top,
            y1: box.y_left_top,
            x2: box.x_right_bottom,
            y2: box.y_right_bottom,
            category: box.category,
            confidence: box.confidence_score,
            color: colors?.[box.category],
          };
        }
      ),
    [prediction, colors]
  );

  const setShowLabelsHandler = (value: boolean) => {
    updateOptions({ showLabels: value });
  };
  const setShowScoreHandler = (value: boolean) => {
    updateOptions({ showScore: value });
  };

  const setOpacityHandler = useCallback(
    (value: number) => {
      updateOptions({ opacity: value });
    },
    [updateOptions]
  );

  const setShowBoxesHandler = (value: boolean) => {
    updateOptions({ showBoxes: value });
  };

  const setConfidenceSlider = useCallback(
    (value: number) => {
      const resultValue = value / 100;
      const resultFiltered = boxesResult.filter(
        (box) => box.confidence >= resultValue
      );
      updateOptions({ boxes: resultFiltered, confidence: value });
    },
    [boxesResult, updateOptions]
  );

  useEffect(() => {
    if (!canvasEl.current) return;

    clearCanvas(canvasEl.current);

    drawInCanvas(canvasEl.current, image);

    options.showBoxes &&
      drawBoxesResponse({
        canvas: canvasEl.current as HTMLCanvasElement,
        image,
        boxes: options.boxes,
        drawLabels: options.showLabels,
        drawScore: options.showScore,
        boxesToShow,
        opacity: twoDecimalsFixed(
          (options.opacity * TEST_PAGE_OPACITY_FACTOR) / 100
        ) as number,
        fillRects: true,
      });
  }, [options, boxesToShow, image]);

  useEffect(() => {
    setConfidenceSlider(options.confidence);
    // eslint-disable-next-line
  }, []);

  return (
    <Grid>
      {showControls && (
        <ImageControls
          maximizeImage
          showLabels={options.showLabels}
          showScore={options.showScore}
          showBoxes={options.showBoxes}
          opacity={options.opacity}
          confidence={options.confidence}
          onMaximizeImageHandler={onMaximize}
          onShowLabelsHandler={setShowLabelsHandler}
          onShowScoreHandler={setShowScoreHandler}
          onShowBoxesHandler={setShowBoxesHandler}
          onOpacityChangeHandler={setOpacityHandler}
          onConfidenceChangeHandler={setConfidenceSlider}
        />
      )}

      <canvas
        ref={canvasEl}
        width={width || '509'}
        height={height || '354'}
        style={{ maxWidth: '100%' }}
      />
    </Grid>
  );
};
