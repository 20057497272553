import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  genericModalContent: {
    padding: '10px 5px',
  },
  titleHeder: {
    paddingBottom: '24px',
  },
  modalTitle: {
    fontFamily: 'Poppins',
    fontSize: 32,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: colors.blue70,
  },
  selectInput: {
    border: '0px',
  },
}));
