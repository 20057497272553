import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  root: {
    maxWidth: 1150,
    margin: '0 auto',
    marginBottom: 80,
    padding: '0 16px',
    '& p': {
      fontFamily: 'Poppins',
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: 24,
  },
  subtitle: {
    marginTop: 10,
    marginBottom: 32,
    fontSize: 16,
    color: colors.blue70,
  },
  integrationList: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 24,
    '@media (max-width: 1000px)': {
      gridTemplateColumns: '1fr',
    },
  },
  integrationItem: {
    position: 'relative',
    background: '#fff',
    boxShadow: '0 8px 25px 1px rgba(0, 0, 0, 0.05)',
    borderRadius: 8,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  integrationImg: {
    flexShrink: 0,
    width: 96,
    '& img': {
      minWidth: 40,
      maxWidth: 52,
      maxHeight: 44,
    },
  },
  integrationInfo: {
    padding: 18,
    paddingLeft: 0,
    textAlign: 'start',
  },
  integrationName: {
    fontWeight: 'bold',
  },
  integrationDescription: {
    marginTop: 4,
    color: '#50567a',
    fontSize: 14,
  },
  integrationIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    color: '#c6d3eb',
    fontSize: 18,
    cursor: 'pointer',
  },
}));
