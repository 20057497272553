import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  container: {
    borderRadius: 10,
    boxShadow: `0 8px 16px 0 rgba(72, 79, 121, 0.15)`,
    backgroundColor: '#FFFF',

    maxWidth: 500,
    margin: '0 auto',
  },
}));
