import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  longTranscriptionWrapper: {
    borderRadius: '8px',
    backgroundColor: 'white',
    padding: '32px',
    maxWidth: '450px',
    margin: 'auto',
    boxShadow: '0 8px 16px 0 rgb(72 79 121 / 15%)',
  },

  headerText: {
    fontWeight: 500,
    fontSize: '14px',
  },

  urlInput: {
    '& input': {
      fontFamily: 'Poppins',
      fontSize: '14px',
    },
    '& label': {
      fontFamily: 'Poppins',
      fontSize: '14px',
    },

    '& input::placeholder': {
      color: '#a4a9c8',
      zIndex: 1,
      opacity: 1,
    },
  },

  uploader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '12px',
    border: `2px dashed ${colors.blue15}`,
    padding: '24px',
    borderRadius: '8px',
    '& svg': {
      fill: colors.blue50,
    },
    cursor: 'pointer',
    transition: '0.2s ease-in',
    '&:hover': {
      backgroundColor: colors.blue5,
    },
    maxWidth: '270px',
    margin: '24px auto',
  },

  uploadText: {
    color: colors.orange,
    fontWeight: 700,
  },

  maxFileSizeText: {
    fontSize: '12px',
    color: colors.blue50,
  },

  uploaderDisabled: {
    cursor: 'not-allowed',
    backgroundColor: colors.blue5,
    '& p': {
      color: colors.blue20,
    },
    '& svg': {
      fill: colors.blue20,
    },
  },

  urlInputLabelDisabled: {
    color: colors.blue20,
  },

  errorMessage: {
    fontSize: '12px',
  },

  transcribeFile: {
    color: colors.orange,
    fontWeight: 700,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
