import { Grid, Paper } from '@material-ui/core';
import React, { useMemo } from 'react';
import { ExperimentConfig, Model, PreTrainedExperimentConfig } from 'types';
import { ModelHeader } from './ModelHeader';
import { ModelResume } from './ModelResume';
import useStyle from './modelView.styles';
import { useRecoilValue } from 'recoil';
import { currentExperimentState } from 'store/experiments';
import { getMetricsExperimentConfig } from 'core';

interface Props {
  model: Model;
  preprocessing: { [key: string]: boolean };
  isRecommended: boolean;
}

export const ModelView = React.memo(
  ({ model, preprocessing, isRecommended }: Props) => {
    const experiment = useRecoilValue(currentExperimentState);
    const classes = useStyle();

    const { accuracy, inference } = useMemo(() => {
      return getMetricsExperimentConfig(
        model,
        experiment?.config as PreTrainedExperimentConfig
      );
    }, [experiment, model]);

    return (
      <Grid item lg={12}>
        <Paper
          className={classes.modelContainer}
          variant="elevation"
          elevation={8}
        >
          <ModelHeader isRecommended={isRecommended} model={model} />
          <ModelResume
            model={model}
            preprocessing={preprocessing}
            accuracy={accuracy}
            inference={inference}
            task={experiment?.task || 0}
            config={experiment?.config as ExperimentConfig}
          />
        </Paper>
      </Grid>
    );
  }
);
