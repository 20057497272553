import React, { ChangeEvent } from 'react';
import { Checkbox, Grid, Slider, Tooltip, Typography } from '@material-ui/core';
import { twoDecimalsFixed } from 'utils';
import { SliderProps } from './sliderReducer';
import { ExpandIcon, HelpIcon } from 'assets';

import useStyles from './imagecontrols.styles';

type ImageControlsProps = {
  opacity?: number;
  showTextLocation?: boolean;
  showLabels?: boolean;
  showScore?: boolean;
  imageContrast?: boolean;
  maximizeImage?: boolean;
  showBoxes?: boolean;
  slider?: string;
  silderTooltipText?: string;
  sliders?: SliderProps[];
  confidence?: number;
  OCRConfidence?: number;

  showTextLocationHandler?(value: boolean): void;
  onMaximizeImageHandler?(): void;
  onSliderConfidenceChange?(value: number): void;
  onShowLabelsHandler?(value: boolean): void;
  onShowScoreHandler?(value: boolean): void;
  onShowBoxesHandler?(value: boolean): void;
  onOpacityChangeHandler?(value: number): void;
  onConfidenceChangeHandler?(value: number): void;
};

export const ImageControls = ({
  opacity,
  showTextLocation,
  showLabels,
  showScore,
  imageContrast,
  maximizeImage,
  showBoxes,
  slider,
  confidence,
  silderTooltipText,
  OCRConfidence,
  showTextLocationHandler,
  onMaximizeImageHandler,
  onSliderConfidenceChange,
  onShowLabelsHandler,
  onShowScoreHandler,
  onShowBoxesHandler,
  onOpacityChangeHandler,
  onConfidenceChangeHandler,
}: ImageControlsProps) => {
  const classes = useStyles();

  const onShowTextLocationChange = (e: ChangeEvent<HTMLInputElement>) => {
    showTextLocationHandler?.(e.target.checked);
  };

  const onShowLabelsChange = (e: ChangeEvent<HTMLInputElement>) => {
    onShowLabelsHandler?.(e.target.checked);
  };

  const onShowScoreChange = (e: ChangeEvent<HTMLInputElement>) => {
    onShowScoreHandler?.(e.target.checked);
  };

  const handleSliderChange = (
    event: ChangeEvent<{}>,
    value: number | number[]
  ) => {
    onSliderConfidenceChange?.(
      twoDecimalsFixed((value as number) / 100) as number
    );
  };

  const onShowBoxesChange = (e: ChangeEvent<HTMLInputElement>) => {
    onShowBoxesHandler?.(e.target.checked);
  };

  return (
    <Grid className={classes.options}>
      <Grid className={classes.controlsWrapper}>
        <Grid className={classes.controls}>
          {showTextLocation !== undefined && (
            <Grid className={classes.optionItem}>
              <Checkbox
                checked={showTextLocation}
                onChange={onShowTextLocationChange}
              />
              <Typography className={classes.optionText}>
                Show text location
              </Typography>
            </Grid>
          )}

          {showBoxes !== undefined && (
            <Grid className={classes.optionItem}>
              <Checkbox checked={showBoxes} onChange={onShowBoxesChange} />
              <Typography className={classes.optionText}>boxes</Typography>
            </Grid>
          )}

          {showBoxes && (
            <>
              {showLabels !== undefined && (
                <Grid className={classes.optionItem}>
                  <Checkbox
                    checked={showLabels}
                    onChange={onShowLabelsChange}
                  />
                  <Typography className={classes.optionText}>labels</Typography>
                </Grid>
              )}

              {showScore !== undefined && (
                <Grid className={classes.optionItem}>
                  <Checkbox checked={showScore} onChange={onShowScoreChange} />
                  <Typography className={classes.optionText}>score</Typography>
                </Grid>
              )}
            </>
          )}

          {imageContrast && (
            <Grid>
              <Typography className={classes.optionText}>
                Image contrast
              </Typography>
              <Slider className={classes.slider} />
            </Grid>
          )}

          {OCRConfidence !== undefined && (
            <Grid>
              <Typography className={classes.sliderText}>
                Confidence threshold({twoDecimalsFixed(OCRConfidence)}){' '}
                {silderTooltipText && (
                  <Tooltip title={silderTooltipText}>
                    <HelpIcon />
                  </Tooltip>
                )}
              </Typography>
              <Slider
                value={OCRConfidence * 100}
                onChange={handleSliderChange}
                step={1}
              />
            </Grid>
          )}
        </Grid>

        <Grid className={classes.sliderControls}>
          <>
            {opacity !== undefined && (
              <Grid className={classes.sliderBox}>
                <Typography className={classes.sliderText}>
                  Image opacity({twoDecimalsFixed(opacity / 100)})
                </Typography>
                <Slider
                  value={opacity}
                  onChange={(
                    event: ChangeEvent<{}>,
                    value: number | number[]
                  ) => {
                    onOpacityChangeHandler?.(value as number);
                  }}
                  step={1}
                />
              </Grid>
            )}
            {confidence !== undefined && (
              <>
                <Grid className={classes.sliderBox}>
                  <Typography className={classes.sliderText}>
                    Confidence threshold({twoDecimalsFixed(confidence / 100)})
                  </Typography>
                  <Slider
                    value={confidence}
                    onChange={(
                      event: ChangeEvent<{}>,
                      value: number | number[]
                    ) => {
                      onConfidenceChangeHandler?.(value as number);
                    }}
                    step={1}
                  />
                </Grid>
              </>
            )}
          </>
        </Grid>
      </Grid>
      <Grid className={classes.fullscreenControl}>
        {maximizeImage && (
          <Grid onClick={onMaximizeImageHandler}>
            <ExpandIcon />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
