import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  options: {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px',
    '& p': {
      fontSize: '12px !important',
    },
  },
  controlsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    padding: '2%',
  },
  controls: {
    display: 'flex',
    gap: 12,
  },
  sliderControls: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0 12px',
    columnGap: 24,
  },
  sliderBox: {
    width: '170px',
  },
  fullscreenControl: {
    marginRight: '24px',
    cursor: 'pointer',
  },
  responseWrapper: {
    border: 'solid 1px #d8ddeb',
    borderRadius: '8px',
  },
  optionItem: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  optionText: {
    fontFamily: 'Poppins',
    fontSize: '14px',
  },
  slider: {
    padding: '0',
  },
  sliderText: {
    fontSize: '14px',
    fontFamily: 'Poppins',
    textAlign: 'left',
  },
}));
