import React, { ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
} from '@material-ui/core';
import useStyles from './WhatsappIntegration.styles';
import InfoIcon from '@material-ui/icons/Info';
import { ActionButton, Text } from 'components';
import { IconWhatsapp } from 'assets';

import { ApplicationWhatsapp } from 'types';
import { copyTextToClipboard } from 'utils';

import { toast } from 'react-toastify';
import { colors } from 'material';
import { WhatsappTokenStep } from './WhatsappTokenStep';
import { VerificationStep } from './VerificationStep';
import { usePermissions } from 'hooks/usePermissions';
import {
  createApplicationByModel,
  getApplicationByModel,
} from 'services/application';

export interface WhatsappIntegrationProps {
  modelId: string;
}

const STEPS = {
  WHATSAPP_TOKEN: 0,
  VERIFICATION: 1,
  ADD_NUMBER: 2,
};

export const WhatsappIntegration = ({ modelId }: WhatsappIntegrationProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { canIntegrateWithWhatsApp } = usePermissions();

  const [whatsappConfig, setWhatsappConfig] = useState({
    verification_token: '',
    whatsapp_access_token: '',
  });
  const [applicationId, setApplicationId] = useState<string>();
  const [loading, setLoading] = useState(false);

  const fecthData = async () => {
    setLoading(true);
    try {
      const data = await getApplicationByModel(modelId);
      setApplicationId(data.id);
      const whatsappConfig = data.config?.whatsapp;
      setWhatsappConfig({
        verification_token: whatsappConfig?.verification_token || '',
        whatsapp_access_token: whatsappConfig?.whatsapp_access_token || '',
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const activateIntegration = async () => {
    try {
      await createApplicationByModel(modelId);
      window.location.reload();
    } catch (error) {}
  };

  useEffect(() => {
    fecthData();
  }, [modelId]);

  const copyToClipboardHandler = (value: string) => {
    copyTextToClipboard(value);

    toast.success('Copied to clipboard', {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 2000,
    });
  };

  const [step, setStep] = useState<number>(STEPS.WHATSAPP_TOKEN);

  const onNextStepClickHandler = () => {
    setStep(STEPS.VERIFICATION);
  };

  const changeStepHandler = (value: number) => {
    setStep(value);
  };

  const onTokenSubmit = ({
    verification_token,
    whatsapp_access_token,
  }: ApplicationWhatsapp) => {
    setWhatsappConfig({ verification_token, whatsapp_access_token });
  };

  useEffect(() => {
    if (whatsappConfig?.whatsapp_access_token) {
      setStep(STEPS.VERIFICATION);
    }
  }, [whatsappConfig]);

  const StepComponentsMap: Record<number, ReactNode> = {
    [STEPS.WHATSAPP_TOKEN]: (
      <WhatsappTokenStep
        applicationID={applicationId}
        whatsappToken={whatsappConfig?.whatsapp_access_token}
        onNextStepClickHandler={onNextStepClickHandler}
        onSubmit={onTokenSubmit}
      />
    ),
    [STEPS.VERIFICATION]: (
      <VerificationStep
        applicationID={applicationId}
        verificationToken={whatsappConfig?.verification_token}
        copyToClipboardHandler={copyToClipboardHandler}
        changeStepHandler={changeStepHandler}
      />
    ),
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gridGap="12px"
      width="100%"
    >
      <Box
        mb="12px"
        display="flex"
        justifyContent="left"
        gridGap="8px"
        alignItems="center"
      >
        <Box>
          <img src={IconWhatsapp} alt="" width="30px" height="30px" />
        </Box>
        <Text className={classes.title}>WhatsApp Integration</Text>
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center" marginY="2rem" width="100%">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {canIntegrateWithWhatsApp ? (
            <>
              {applicationId ? (
                <Box className={classes.stepsCard}>
                  <Box className={classes.guideTextContainer} mb="12px">
                    <Text variant="paragraph1">
                      Follow our guide about how to configure <b>Meta</b> for
                      this integration{' '}
                      <a
                        href="https://docs.cogniflow.ai/en/article/chatbot-whatsapp-integration-a52rca/"
                        target="_blank"
                        className={classes.guideLink}
                      >
                        here
                      </a>
                    </Text>
                  </Box>
                  <Box mb="12px">
                    <Stepper
                      style={{
                        backgroundColor: colors.blue5,
                      }}
                      alternativeLabel
                      activeStep={step}
                    >
                      <Step>
                        <StepLabel>WhatsApp token</StepLabel>
                      </Step>
                      <Step>
                        <StepLabel>Verification</StepLabel>
                      </Step>
                    </Stepper>
                  </Box>

                  {StepComponentsMap[step]}
                </Box>
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gridGap="28px"
                  border="1px solid #e9e9e9"
                  borderRadius="12px"
                  padding="6%"
                  style={{
                    backgroundColor: 'rgb(251 251 251)',
                  }}
                >
                  <Text
                    variant="paragraph1"
                    style={{ lineHeight: '24px', fontSize: '16px' }}
                  >
                    Unlock the potential of WhatsApp integration for your model.
                  </Text>
                  <ActionButton
                    onClick={activateIntegration}
                    color="secondary"
                    variant="contained"
                    size="medium"
                  >
                    Enable WhatsApp Integration
                  </ActionButton>
                  <Box
                    style={{
                      color: '#1f2345',
                      opacity: 0.6,
                      fontSize: 14,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 6,
                    }}
                  >
                    <InfoIcon style={{ fontSize: 14 }} />
                    Please note that deploying the model is necessary for
                    integration.
                  </Box>
                </Box>
              )}
            </>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gridGap="28px"
              border="1px solid #e9e9e9"
              borderRadius="12px"
              padding="6%"
              style={{
                backgroundColor: 'rgb(251 251 251)',
              }}
            >
              <Text variant="paragraph1" style={{ lineHeight: '24px' }}>
                WhatsApp integration is available exclusively with our paid
                plans. Upgrade your plan now to unlock this feature.
              </Text>
              <ActionButton
                onClick={() => {
                  history.replace('/settings/subscription');
                }}
                color="secondary"
                variant="contained"
                size="medium"
              >
                Upgrade your plan
              </ActionButton>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
