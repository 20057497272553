import React from 'react';
import { Box, Button } from '@material-ui/core';
import { Text } from 'components';
import { SearchRobotImg } from 'assets';
import { useHistory } from 'react-router-dom';

type NoExperimentsProps = {
  tagSelected?: boolean;
};

export const NoExperiments = ({ tagSelected }: NoExperimentsProps) => {
  const { push } = useHistory();

  const goToNewProject = () => {
    push('/new-project');
  };

  return (
    <Box>
      <SearchRobotImg />

      <Box display="flex" flexDirection="column" gridGap="12px" mb="50px">
        <Text variant="h4" align="center">
          {tagSelected ? 'No projects found' : `You don't have a project yet`}
        </Text>

        <Text variant="paragraph1" align="center">
          Click on new project
        </Text>

        <Box>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={goToNewProject}
          >
            New Project
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
