import React, { ChangeEvent, useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { QAConfig } from '../Settings';
import { QATone } from './QATone';
import { LANGUAGES_SORT } from 'utils';
import { Experiment } from 'types';
import { isQAGenerativeStructured } from 'utils/experiment';

interface Props {
  initialValues: QAConfig;
  experiment?: Experiment;
  showActions: boolean;
  onChange(config: Partial<QAConfig>): void;
}

export const QASettings = ({
  initialValues: {
    chatbotName,
    chatbotTone,
    companyName,
    companyDescription,
    noAnswerMessage,
    lang_code,
    prompt_template,
  },
  experiment,
  showActions,
  onChange,
}: Props) => {
  const isStructured = isQAGenerativeStructured(experiment);
  const [state, setState] = useState<Partial<QAConfig>>({
    chatbotName,
    chatbotTone,
    companyName,
    companyDescription,
    noAnswerMessage,
    lang_code,
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    const newState = {
      ...state,
      [name]: value === 'default' ? '' : value,
    };
    setState(newState);
    onChange(newState);
  };

  const handleChangeTone = (value: string) => {
    const newState = {
      ...state,
      chatbotTone: value,
    };
    setState(newState);
    onChange(newState);
  };

  const supportsPromptKey = (prompt_key: string) => {
    if (isStructured) return true;
    if (!prompt_template) return true;
    if (prompt_template.includes(prompt_key)) return true;
    return false;
  };

  return (
    <Box display="flex" flexDirection="column" paddingY={2} style={{ gap: 30 }}>
      {supportsPromptKey('chatbot_name') && (
        <TextField
          label="Chatbot name"
          name="chatbotName"
          value={state.chatbotName}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          helperText="e.g. CogniAI, CogniBot"
          inputProps={{ maxLength: 50 }}
          disabled={!showActions}
        />
      )}
      {supportsPromptKey('chatbot_tone') && (
        <QATone
          value={state.chatbotTone}
          onChange={handleChangeTone}
          disabled={!showActions}
        />
      )}
      {(supportsPromptKey('lang') || supportsPromptKey('lang_prompt')) && (
        <FormControl variant="outlined">
          <InputLabel>Language</InputLabel>
          <Select
            name="lang_code"
            value={state.lang_code || ''}
            onChange={handleChange}
            label="Language"
            style={{ textAlign: 'left' }}
            disabled={!showActions}
          >
            <MenuItem value="">
              <em>Detect automatically</em>
            </MenuItem>
            {Object.entries(LANGUAGES_SORT).map(([key, value]) => (
              <MenuItem value={key} key={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {supportsPromptKey('company_name') && (
        <TextField
          label="Company name"
          name="companyName"
          value={state.companyName}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          helperText="e.g. Cogniflow"
          inputProps={{ maxLength: 50 }}
          disabled={!showActions}
        />
      )}
      {supportsPromptKey('company_description') && (
        <TextField
          label="Company description"
          name="companyDescription"
          value={state.companyDescription}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          multiline
          helperText="Press [enter] key to add a new line."
          inputProps={{ maxLength: 800 }}
          disabled={!showActions}
        />
      )}
      {(supportsPromptKey('no_answer_message') ||
        supportsPromptKey('no_answer_message_prompt')) && (
        <TextField
          label="Message when no answer found"
          name="noAnswerMessage"
          value={state.noAnswerMessage}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          multiline
          helperText={
            <>
              <span style={{ display: 'block', marginBottom: 1 }}>
                Press [enter] key to add a new line.
              </span>
              <br />
              <span>
                e.g. Sorry, I am not sure. Please try another question or
                contact our support team
              </span>
            </>
          }
          inputProps={{ maxLength: 400 }}
          disabled={!showActions}
        />
      )}
    </Box>
  );
};
