import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 40px 40px',
  },
  userFormTitle: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.blue20,
    alignSelf: 'flex-start',
  },
  emailField: {
    marginTop: '16px',
    '& p': {
      fontFamily: 'Poppins',
    },
  },
  fieldContainer: {
    marginTop: '12px',
    marginBottom: '12px',
  },
  forgotPasswordLink: {
    fontFamily: 'PTSerif',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: colors.orange,
    alignSelf: 'flex-end',
    marginBottom: '24px',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  error: {
    marginTop: '10px',
  },
  createAccountButton: {},
  suggestedName: {
    fontSize: '12px',
    textAlign: 'left',
    colors: colors.blue50,
  },
  termsAndConditions: {
    marginTop: '32px',
    textAlign: 'left',
    fontSize: '12px',
    fontFamily: 'Poppins',
    color: colors.blue50,
  },
  termsLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  signupwith: {
    margin: '30px 0 32px',
    paddingTop: '12px',
    // '& g#logo_googleg_48dp > path:nth-child(1), & g#logo_googleg_48dp > path:nth-child(2), & g#logo_googleg_48dp > path:nth-child(3), & g#logo_googleg_48dp > path:nth-child(4),': {
    //   fill: colors.orange,
    // },
  },

  signupButton: {
    '& div': {
      borderRadius: '8px !important',
    },

    '& span': {
      color: `${colors.orange}`,
      fontWeight: 500,
      fontFamily: 'Poppins',
      fontSize: '14px',
    },
  },
  inputField: {
    marginTop: '12px',
    '& input': {
      fontFamily: 'Poppins',
    },
    '& label': {
      fontFamily: 'Poppins',
      fontSize: '14px',
    },
  },
}));
