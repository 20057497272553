export const getFaceSimilarityResultText = (
  metric: string,
  threshold: string
) => {
  const texts: { [key: string]: string } = {
    euclidean_l2: `A match is verified when the score is lower than the threshold value (${threshold})`,
  };

  return texts[metric];
};
