import { EXPERIMENT_TASK, ExperimentLabel } from 'types';

export const urlMap: ExperimentLabel = {
  [EXPERIMENT_TASK.TEXT_CLASSIFICATION]: 'text/classification/predict',
  [EXPERIMENT_TASK.TEXT_NER_RESPONSE]:
    'text/information-extraction/extract-entities',
  [EXPERIMENT_TASK.TEXT_TRANSLATION]: 'text/translation/translate',
  [EXPERIMENT_TASK.TEXT_QANDA]: 'text/question-answering/ask',
  [EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR]:
    'text/smart-extraction/extract',
  [EXPERIMENT_TASK.IMAGE_CLASSIFICATION]: 'image/classification/predict',
  [EXPERIMENT_TASK.IMAGE_OBJECT_DETECTION]: 'image/object-detection/detect',
  [EXPERIMENT_TASK.IMAGE_OCR]: 'image/ocr/recognize',
  [EXPERIMENT_TASK.IMAGE_FACE_SIMILARITY]: 'face-similarity/predict',
  [EXPERIMENT_TASK.AUDIO_CLASSIFICATION]: 'audio/classification/predict',
  [EXPERIMENT_TASK.AUDIO_SPEECH2TEXT]: 'audio/speech2text/transcribe',
};
// /text/information-extraction/extract-entities/{id_model}
