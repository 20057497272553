import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import makeStyles from './confusionmatrix.style';

export interface IConfusionMatrixProps {
  labels: string[];
  matrix: Array<Array<number>>;
  renderContent(
    row: number[],
    key: number,
    size: string,
    styles?: string
  ): void;
}
const MATRIX_SIZES = {
  XSMALL: 'xsm',
  SMALL: 'sm',
  MEDIUM: 'md',
};
const MATRIX_LABELS_THRESHOLD = 10;
const MAX_LABELS_BEFORE_SCROLL = 20;

export const ConfusionMatrix = ({
  labels,
  matrix,
  renderContent,
}: IConfusionMatrixProps) => {
  const size =
    labels.length > MAX_LABELS_BEFORE_SCROLL
      ? MATRIX_SIZES.XSMALL
      : labels.length < MATRIX_LABELS_THRESHOLD
      ? MATRIX_SIZES.MEDIUM
      : MATRIX_SIZES.SMALL;
  const style = makeStyles({
    size,
  });
  const isScrollTresholdPassed = useMemo(
    () => labels.length > MAX_LABELS_BEFORE_SCROLL,
    [labels]
  );

  return (
    <div className={style.container}>
      <div className={style.leftHand}>
        <div className={style.leftTitleContainer}>
          <Typography className={style.leftTitle} variant="body1">
            Actual
          </Typography>
        </div>
        <div className={style.leftLabels}>
          {labels.map((label, key) => (
            <div className={style.leftLabelItem} key={key}>
              <Typography
                className={
                  isScrollTresholdPassed ? style.smallLabel : style.label
                }
              >
                {label}
              </Typography>
            </div>
          ))}
        </div>
      </div>
      <div className={style.rightHand}>
        <div>
          <Typography className={style.UpTitle} variant="body1">
            Prediction
          </Typography>
        </div>
        <div className={style.data}>
          {matrix.map((row, key) =>
            renderContent(row, key, size, style.flexElement)
          )}
        </div>
        <div className={style.downLabelContainer}>
          {labels.map((label, key) => (
            <div key={key} className={style.downLabelItem}>
              <Typography
                className={`${
                  isScrollTresholdPassed ? style.smallLabel : style.label
                } ${style.verticalAlignment}`}
              >
                {label}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
