import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  container: {
    marginTop: 32,
  },
  configContainer: {
    maxWidth: 724,
    padding: '0 24px',
  },
  button: {
    marginTop: 32,
    width: 257,
  },
  buttonLoading: {
    marginTop: 32,
    width: 257,
    backgroundColor: colors.blue5,
  },
  title: {
    maxWidth: 552,
    marginBottom: 16,
  },
  info: {
    width: '100%',
    maxWidth: 744,
    padding: 24,
    backgroundColor: colors.blue10,
    position: 'relative',
    marginTop: 32,
  },
  headerInfo: {
    maxWidth: 552,
  },
  genericModal: {
    //width: 448,
  },
  spinnerLoader: {
    backgroundColor: colors.blue5,
  },
  sendEmailContainer: {
    marginTop: 32,
  },
  emailToSend: {
    color: colors.orange,
    fontWeight: 'bold',
    textDecoration: 'underline',
    fontFamily: 'PTSerif',
    fontSize: 16,
  },
}));
