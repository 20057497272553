import React from 'react';
import { Redirect } from 'react-router-dom';
import { useQueryParams } from 'hooks';

type QueryProps = {
  visibility: string;
};

export const Experiments = () => {
  const query = useQueryParams<QueryProps>();

  if (['public', 'community'].includes(query.visibility)) {
    return <Redirect to="/projects/community" />;
  }

  return <Redirect to="/projects/dashboard" />;
};
