import React from 'react';
import { Grid } from '@material-ui/core';
import { EXPERIMENT_TASK, ExperimentConfig, Model, TextConfig } from 'types';
import { DetailActionBox } from './DetailActionBox';
import { DetailCollapsible } from './DetailCollapsible';
import useStyle from './modelresume.styles';
import { useRecoilState } from 'recoil';
import { collapseModelDetails } from 'store/models';
import { DataNumericLabel } from 'components';

interface ScoreLabel {
  label: string;
  number: number;
  tooltip: string;
}

interface Props {
  preprocessing: { [key: string]: boolean };
  model: Model;
  accuracy: ScoreLabel;
  inference: ScoreLabel;
  task: string | number;
  config: ExperimentConfig;
}

export const ModelResume = React.memo(
  ({ preprocessing, model, accuracy, inference, task, config }: Props) => {
    const classes = useStyle();
    const [showing, setShowing] = useRecoilState(collapseModelDetails);

    const collapseShowingHandler = (value: boolean) => setShowing(value);

    const isGenerativeQA =
      task === EXPERIMENT_TASK.TEXT_QANDA &&
      (config as TextConfig)?.qa_processor_type === 'generative';

    const showAccuracyScoreLabel =
      !isGenerativeQA &&
      accuracy.number > 0 &&
      EXPERIMENT_TASK.IMAGE_CUSTOM_ENTITY_EXTRACTOR !== task;

    return (
      <Grid
        container
        justify="flex-start"
        className={classes.modelHeaderContainer}
        style={{ justifyContent: 'space-between' }}
      >
        {showAccuracyScoreLabel && (
          <Grid item xs={6} md={2} className={classes.wrapperBox}>
            <Grid container justify="center" alignItems="center">
              <Grid
                container
                justify="flex-start"
                alignItems="center"
                className={classes.inferanceBox}
              >
                <DataNumericLabel
                  label={accuracy.label}
                  number={accuracy.number}
                  tooltipText={accuracy.tooltip}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={6} md={2}>
          <Grid container justify="center" alignItems="center">
            <Grid
              container
              justify="flex-start"
              alignItems="center"
              className={classes.accuracyBox}
            >
              <DataNumericLabel
                label={inference.label}
                number={inference.number}
                tooltipText={inference.tooltip}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <DetailActionBox
            setChevron={collapseShowingHandler}
            status={model.status}
            isOpen={showing}
            task={(task as number) || 0}
          />
        </Grid>
        <Grid container>
          <DetailCollapsible
            open={showing}
            model={model}
            preprocessing={preprocessing}
          />
        </Grid>
      </Grid>
    );
  }
);
