import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  imageCaptured: {
    width: '100%',
    height: 350,
  },
  loadingContainer: {
    height: 250,
  },
}));
