import React, { FunctionComponent, SVGProps, useMemo } from 'react';
import { Typography, Link } from '@material-ui/core';
import useStyles from './step1.styles';
import { OptionCard } from 'components/OptionCard';
import { ExperimentLabel, PROJECT_TYPE } from 'types';
import { StyledTextLink } from 'components/StyledTextLink';

interface Props {
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  logoImgName: string;
  type: number;
  title: string;
  description: string;
  buttonLabel: string;
  exampleLabel: string;
}

const helpLinkMap: ExperimentLabel = {
  text:
    'https://docs.cogniflow.ai/en/article/how-to-create-an-ai-service-for-sentiment-analysis-text-data-1kjtxhh',
  image:
    'https://docs.cogniflow.ai/en/article/how-to-create-an-ai-service-for-image-recognition-t6n68m',
  audio:
    'https://docs.cogniflow.ai/en/article/how-to-create-a-speech-recognizer-service-audio-data-1dakrxb',
};

export const ExperimentCard = ({
  Icon,
  logoImgName,
  type,
  title,
  description,
  buttonLabel,
  exampleLabel,
}: Props) => {
  const classes = useStyles();

  const helpLink = useMemo(() => helpLinkMap[type], [type]);

  const redirectTo = useMemo(
    () =>
      type !== PROJECT_TYPE.AUDIO ? `/create/${type}` : `/create/${type}/200`,
    [type]
  );

  const startGuide = () => {
    (window as any).userGuiding.previewGuide(50701);
  };

  return (
    <div style={{ position: 'relative' }}>
      <OptionCard
        Icon={Icon}
        logoImgName={logoImgName}
        title={title}
        description={description}
        buttonLabel={buttonLabel}
        redirectTo={redirectTo}
        readyToTrain={true}
      >
        <Typography variant="body1">Need inspiration?</Typography>
        <Link
          className={classes.linkDecoration}
          rel="noopener"
          href={helpLink}
          target="_blank"
          component="a"
        >
          <StyledTextLink component={'span'} size="14px">
            {exampleLabel}
          </StyledTextLink>
        </Link>
      </OptionCard>
      {type === PROJECT_TYPE.TEXT && (
        <>
          <br />
          <StyledTextLink
            size="14px"
            className={classes.smallLinkDecoration}
            onClick={startGuide}
          >
            Start the interactive guide to learn how to create a new experiment
          </StyledTextLink>
        </>
      )}
    </div>
  );
};
