import React, { ReactNode } from 'react';
import { Box } from '@material-ui/core';
import { Navbar } from 'components';
import { Dashboard } from './Dashboard';
import { useParams } from 'react-router-dom';
import { Community } from './Community';

enum TABS {
  DASHBOARD = 'dashboard',
  COMMUNITY = 'community',
}

export const Projects = ({ match: { path } }: any) => {
  const { tab } = useParams<{ tab: string }>();

  const tabComponentMap: Record<string, ReactNode> = {
    [TABS.COMMUNITY]: <Community />,
    [TABS.DASHBOARD]: <Dashboard />,
  };

  return (
    <Box>
      <Navbar />

      {tabComponentMap[tab]}
    </Box>
  );
};
