import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  containerUnderlinedSubtitle: {
    fontFamily: 'PTSerif',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'underline',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.orange,
    marginBottom: '3px',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));
