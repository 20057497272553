import React, {
  useCallback,
  useState,
  ReactNode,
  FunctionComponent,
  SVGProps,
} from 'react';
import { CardContent, Card, Grid, Typography, Box } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { ActionButton } from 'components/ActionButton';
import useStyles from './optioncard.styles';
import { Text } from 'components';
import { EXPERIMENT_TASK } from 'types';
import { getExperimentConfig, me } from 'services';
import { ErrorMessageModal } from 'pages/CreateExperiment/Steps/Step5/ErrorMessageModal';

interface Props {
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  logoImgName: string;
  title: string;
  description: string;
  buttonLabel: string;
  redirectTo: string;
  children: ReactNode;
  readyToTrain: boolean;
  isNew?: boolean;
  task?: string;
  type?: string;
}

export const OptionCard = ({
  Icon,
  logoImgName,
  title,
  description,
  buttonLabel,
  redirectTo,
  children,
  readyToTrain,
  isNew = false,
  task,
  type,
}: Props) => {
  const classes = useStyles();
  const [hover, setHover] = useState(false);
  const onMouseOver = useCallback(() => {
    setHover(true);
  }, [setHover]);
  const onMouseLeave = useCallback(() => {
    setHover(false);
  }, [setHover]);
  const { push } = useHistory();
  const [showOpenAIError, setShowOpenAIError] = useState(false);

  const handleClick = async (e: any, redirectTo: string) => {
    e.preventDefault();
    if (type != null && task != null) {
      const newConfig = await getExperimentConfig(type, task);
      const updatedUser = await me();
      if (
        !updatedUser.can_use_openai_models &&
        (newConfig['llm_gpt3_5_turbo'] ||
          newConfig['llm_gpt3_5_turbo_16k'] ||
          newConfig['llm_gpt4'])
      ) {
        setShowOpenAIError(true);
        return;
      }
    }
    push(redirectTo);
  };

  return (
    <>
      <Card
        className={classNames(classes.itemContainer, {
          [classes.cardHover]: hover,
        })}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        elevation={0}
      >
        <CardContent className={classes.cardHeader}>
          <Grid container justify="center" alignItems="center">
            <div
              className={classes.headerBackground}
              style={{ backgroundImage: `url(/img/${logoImgName})` }}
            />

            <div className={classes.iconContainer}>
              <Icon className={classes.expIcon} />
            </div>
          </Grid>
        </CardContent>
        {isNew && (
          <Box className={classes.newFeature}>
            <Text variant="paragraph1">New version</Text>
          </Box>
        )}
        {parseInt(task || '') ===
          EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR && (
          <Box className={classes.newFeature}>
            <Text variant="paragraph1">New</Text>
          </Box>
        )}
        {}
        <Typography className={classes.itemTitle} variant="h3">
          {title}
        </Typography>
        <Typography variant="body1" className={classes.text}>
          {description}
        </Typography>
        <CardContent className={classes.footer}>
          <Grid container direction="column" alignItems="center">
            {readyToTrain ? (
              <Link
                to={redirectTo}
                className={classes.link}
                onClick={(e) => handleClick(e, redirectTo)}
              >
                <ActionButton
                  variant="contained"
                  color="secondary"
                  size="large"
                >
                  {buttonLabel}
                </ActionButton>
              </Link>
            ) : (
              <a
                href={redirectTo}
                className={classes.link}
                onClick={(e) => handleClick(e, redirectTo)}
              >
                <ActionButton
                  variant="contained"
                  color="secondary"
                  size="large"
                >
                  {buttonLabel}
                </ActionButton>
              </a>
            )}
            {children}
          </Grid>
        </CardContent>
      </Card>

      {showOpenAIError && (
        <ErrorMessageModal
          onClose={() => setShowOpenAIError(false)}
          type="openai_api_key"
        />
      )}
    </>
  );
};
