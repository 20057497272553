import { useCallback, useState } from 'react';
import { EXPERIMENT_TASK } from 'types';
import {
  getCsvColNames,
  getCsvContentString,
  makeStringId,
  splitCsvHeaders,
} from 'utils';
import { downloadZip } from 'client-zip';
import Zip from 'jszip';

type ColsEditionProps = {
  modalOpen: boolean;
  errorModal: boolean;
  cols: string[];
};

type CsvDatasetValidationProps = {
  validFile: boolean;
  logs: { message: string }[];
};

const EXCEL_FORMATS = ['text/csv', 'application/vnd.ms-excel'];

type UseCSVColumnsProps = {
  datasetFile: File | undefined;
  task: number;
  uploadValFile?(files: File[]): void;
  uploadFile(files: File[]): void;
};

export const useCSVColumns = ({
  datasetFile,
  uploadValFile,
  uploadFile,
  task,
}: UseCSVColumnsProps) => {
  const [csvString, setCsvString] = useState<string>('');
  const [invalidCsvErrors, setInvalidCsvErrors] = useState<
    CsvDatasetValidationProps
  >({
    validFile: true,
    logs: [],
  });

  const [openEditColsModal, setOpenEditColsModal] = useState(false);

  const [editValidationCols, setEditValidationCols] = useState<
    ColsEditionProps
  >({
    errorModal: false,
    modalOpen: false,
    cols: [],
  });
  const [uploadingValidation, setUploadingValidation] = useState(false);

  const setUploadingValidationHandler = (value: boolean) => {
    setUploadingValidation(value);
  };

  const setOpenEditColsModalHandler = (value: boolean) => {
    setOpenEditColsModal(value);
  };

  const getCsvColNamesOptions = useCallback(async (file: File) => {
    const csvStr = await getCsvContentString(file);
    setCsvString(csvStr);
    const colNames = getCsvColNames(csvStr);

    return colNames.map((col: string) => col.replaceAll(`"`, ''));
  }, []);

  const isValidCsvFile = useCallback(
    async (file: File) => {
      if (
        !EXCEL_FORMATS.includes(file.type) &&
        task === EXPERIMENT_TASK.TEXT_CLASSIFICATION
      ) {
        const zip = new Zip();
        const unZippedFiles = await zip.loadAsync(file);

        const filesCount = Object.keys(unZippedFiles.files).filter((el) => {
          const [, extension] = el.split('.');
          return extension === 'csv';
        }).length;

        const fileNames = Object.keys(unZippedFiles.files)
          .map((f) => `"${f}"`)
          .join(', ');

        if (filesCount > 1 || filesCount === 0) {
          setInvalidCsvErrors((s) => ({
            ...s,
            logs: [
              { message: 'Your zip must contain only one CSV file' },
              {
                message:
                  filesCount === 0
                    ? 'No files found'
                    : `Files found: ${fileNames}`,
              },
            ],
          }));
          return {
            valid: false,
            file,
          };
        }
      }

      return {
        valid: true,
        file,
      };
    },
    [task]
  );

  const saveCols = useCallback(
    (selected) => {
      const [currentCols, currentCsvContent] = splitCsvHeaders(csvString);

      const currentColsList = currentCols
        .split(',')
        .map((col) => col.replaceAll('"', ''));
      const updatedCols = [...currentColsList];

      updatedCols[currentColsList.indexOf('Text')] = makeStringId(5);
      updatedCols[currentColsList.indexOf('Label')] = makeStringId(5);

      updatedCols[currentColsList.indexOf(selected.text)] = 'Text';
      updatedCols[currentColsList.indexOf(selected.label)] = 'Label';

      const csv = [updatedCols.join(','), currentCsvContent].join('');

      const filecsv = new File([csv], 'dataset.csv', {
        type: 'text/csv',
      });

      downloadZip([filecsv])
        .blob()
        .then((blobf) => {
          if (!datasetFile) return;

          const zipfile = new File([blobf], datasetFile.name);

          if (uploadingValidation) {
            uploadValFile?.([zipfile]);
            setEditValidationCols((s) => ({
              ...s,
              modalOpen: false,
            }));
          } else {
            uploadFile([zipfile]);
            setOpenEditColsModal(false);
          }
        });
    },
    [csvString, uploadingValidation, uploadFile, uploadValFile, datasetFile]
  );

  return {
    invalidCsvErrors,
    openEditColsModal,
    editValidationCols,
    uploadingValidation,
    setUploadingValidationHandler,
    setOpenEditColsModalHandler,
    getCsvColNamesOptions,
    setEditValidationCols,
    isValidCsvFile,
    saveCols,
  };
};
