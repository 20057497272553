import React from 'react';
import { Box, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';

import { ActionButton, Text } from 'components';
import { EditIcon } from 'assets';
import { getEnv } from 'services';

const REACT_APP_WHATSAPP_WEBHOOK_URL = getEnv('REACT_APP_WHATSAPP_WEBHOOK_URL');

type VerificationStepProps = {
  applicationID: string | undefined;
  verificationToken: string | undefined;
  copyToClipboardHandler(value: string): void;
  changeStepHandler(step: number): void;
};

export const VerificationStep = ({
  applicationID,
  verificationToken,
  copyToClipboardHandler,
  changeStepHandler,
}: VerificationStepProps) => {
  const prevStep = () => {
    changeStepHandler(0);
  };
  const whatsappWebhookUrl = `${REACT_APP_WHATSAPP_WEBHOOK_URL}/?idApplication=${applicationID}`;

  return (
    <Box>
      <Box mb="12px">
        <Box mb="4px">
          <Text variant="paragraph1" align="left">
            Webhook callback URL
          </Text>
        </Box>
        <TextField
          name="webhook_url"
          variant="outlined"
          fullWidth
          value={whatsappWebhookUrl}
          helperText="Paste this value in the Meta webhook URL field"
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    copyToClipboardHandler(whatsappWebhookUrl);
                  }}
                  edge="end"
                  size="small"
                  style={{ marginLeft: 10 }}
                >
                  <FileCopy />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box>
        <Box mb="4px">
          <Text variant="paragraph1" align="left">
            Verify token
          </Text>
        </Box>
        <TextField
          value={verificationToken}
          type="text"
          variant="outlined"
          fullWidth
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    copyToClipboardHandler(verificationToken || '');
                  }}
                  edge="end"
                  size="small"
                  style={{ marginLeft: 10 }}
                >
                  <FileCopy />
                </IconButton>
              </InputAdornment>
            ),
          }}
          helperText="Paste this value in the Meta verify_token field"
        />
      </Box>

      <Box display="flex" justifyContent="left" width="100%" mt="32px">
        <ActionButton
          variant="contained"
          color="secondary"
          onClick={prevStep}
          size="small"
        >
          <EditIcon width="22px" style={{ marginRight: '3px' }} />
          Edit WhatsApp Access Token
        </ActionButton>
      </Box>
    </Box>
  );
};
