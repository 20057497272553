import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  needHelp: {
    fontFamily: 'Poppins',
    fontSize: '14px',
  },
  pointer: {
    cursor: 'pointer',
  },
}));
