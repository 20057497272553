import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  checkCircle: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    border: `1px solid ${colors.blue15}`,
    boxShadow: '0px 8px 16px 0px rgba(72, 79, 121, 0.15)',
    boxSizing: 'border-box',
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  checked: {
    background: colors.orange,
    border: 'none',
  },

  icon: {
    '& g': {
      fill: `white !important`,
    },
  },
}));
