import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  container: {
    marginTop: 34,
    marginBottom: 110,
  },
  content: {
    width: '100%',
    maxWidth: 936,
  },
  noContent: {
    width: '100%',
    maxWidth: 936,
    marginTop: 106,
  },
  button: {
    marginLeft: 24,
    borderRadius: 8,
    paddingRight: 32,
    paddingLeft: 32,
  },
  buttonWithIcon: {
    paddingRight: 32,
    paddingLeft: 16,
  },
  buttonIcon: {
    marginRight: 8,
    marginLeft: 0,
  },
  footerContainer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 80,
    backgroundColor: colors.blue10,
    zIndex: 2,
  },
  activeFooter: {
    backgroundColor: colors.blue70,
  },
  footerContent: {
    maxWidth: 1128,
    width: '100%',
  },
}));

export const useEditorStyles = makeStyles(() => ({
  toolbar: {
    width: '100%',
    display: 'flex',
    boxSizing: 'border-box',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  inline: {
    display: 'inline-flex',
  },
  editor: {
    overflow: 'visible',
    paddingBottom: 12,
    padding: '0 12px',
  },
  editingWrapper: {
    boxShadow: '0px 0px 13px 6px rgba(72, 79, 121, 0.15)',
  },
}));
