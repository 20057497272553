import React, { useMemo, useEffect } from 'react';
import {
  Grid,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';

import useStyles from './create-experiment.styles';
import { Step1, Step2, Step3, Step4, Step5 } from './Steps';
import { Link } from 'components/Link';
import { useExpCreationContext } from 'context';

const experimentsRoute = '/experiments';

interface Props {
  step: 1 | 2 | 3 | 4 | 5;
}

interface Params {
  type: string;
  task: string;
}

const titles = [
  'Choose a type',
  'Choose a task',
  'Customize',
  'Dataset',
  'Configure',
];

export const CreateExperimentStep = ({ step }: Props) => {
  const classes = useStyles();
  const { type, task }: Params = useParams();
  const { expValues, resetExperimentValues } = useExpCreationContext();

  useEffect(() => {
    return () => {
      resetExperimentValues();
    };
  }, [resetExperimentValues]);

  const StepComponent = useMemo(() => {
    switch (step) {
      case 1:
        return Step1;
      case 2:
        return Step2;
      case 3:
        return Step3;
      case 4:
        return Step4;
      case 5:
        return Step5;
      default:
        break;
    }
  }, [step]);

  const { prevPath } = useMemo(() => {
    if (step === 5) {
      return {
        title: 'The experiment is ready to run!',
        prevPath: `/create/${type}/${task}/upload`,
      };
    }
    if (step === 4) {
      return {
        title: 'Create your dataset',
        prevPath: `/create/${type}/${task}`,
      };
    }
    if (step === 3) {
      const _prevPath = '/create';
      if (type !== 'audio') {
        return {
          title: 'Create a text experiment',
          prevPath: _prevPath + `/${type}`,
        };
      }
      return {
        title: 'Create an image experiment',
        prevPath: _prevPath,
      };
    }
    if (step === 2) {
      return {
        title: 'Create your dataset',
        prevPath: `/create`,
      };
    }
    return {
      title: 'Choose an experiment type',
    };
  }, [type, step, task]);

  return (
    <div className={classes.container}>
      <Grid container alignItems="center">
        <Grid item xs={3}>
          {prevPath && (
            <Link to={prevPath}>
              <Button size="large" color="secondary" variant="outlined">
                Go back
              </Button>
            </Link>
          )}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h1">
            {step <= 2 || !expValues.title ? 'New experiment' : expValues.title}
          </Typography>
        </Grid>
        <Grid item xs={3} container alignItems="center" justify="flex-end">
          <Link to={experimentsRoute}>
            <Button size="large" color="secondary" variant="outlined">
              Close
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="flex-start"
      >
        <Stepper
          activeStep={step - 1}
          alternativeLabel
          className={classes.stepper}
        >
          {titles.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {StepComponent && <StepComponent />}
      </Grid>
    </div>
  );
};
