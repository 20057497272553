import React from 'react';
import { Box } from '@material-ui/core';
import { DashboardCardProps } from '../types';
import { Text } from 'components';

import { ChatImage } from 'assets';

import useStyles from './ChatBotFormSection.styles';
import Carousel from 'react-multi-carousel';
import { responsive } from '../dashboardCards';

export const ChatBotFormSection = ({ news }: DashboardCardProps) => {
  const classes = useStyles();

  const carouselProps = {
    autoplay: news.length > 1,
    infinite: news.length > 1,
    transitionDuration: 400,
    responsive,
  };

  return (
    <Box className={classes.chatBotSection}>
      <Carousel {...carouselProps} className={classes.carousel}>
        {news.map(({ title, description, img_url, url }, idx) => (
          <Box
            display="flex"
            justifyContent="space-between"
            height="100%"
            boxSizing="border-box"
            padding="32px"
            onClick={() => {
              window.open(url, '_blank');
            }}
            key={idx}
          >
            <Box>
              <Text align="left" variant="paragraph1" className={classes.title}>
                {title}
              </Text>
              <Text
                align="left"
                variant="paragraph1"
                className={classes.description}
              >
                {description}
              </Text>
            </Box>

            <img
              src={img_url || ChatImage}
              className={classes.chatImage}
              alt="chat img"
            />
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};
