import React from 'react';
import { useParams } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { useApplication } from './useApplication';
import { TextTest } from 'pages/TestExperiment/ModelTests/TextTest';
import { ImageTest } from 'pages/TestExperiment/ModelTests/ImageTest';
import { AudioTest } from 'pages/TestExperiment/ModelTests/AudioTest/AudioTest';
import { Text } from 'components';
import { Footer } from './Footer';

import {
  Application as ApplicationType,
  EXPERIMENT_TYPE,
  Experiment,
  ExperimentTest,
} from 'types';

import useStyle from './application.styles';
import { PredictionProvider } from 'context';

interface ApplicationTestProps extends ExperimentTest {
  applicationId: string;
  experiment: Experiment;
  application: ApplicationType;
}

export const DEFAULT_VALUES = {
  colorBackground: '#f7f9ff',
  colorBox: '#fff',
  colorButton: '#ff6a61',
  colorText: '#848aa9',
  fontFamily: 'Poppins',
  pinnedMessageIsCollapsible: true,
  hideLogo: false,
  hideDownloadButton: false,
  lang_code: 'default',
};

export const ApplicationTest = React.memo(
  ({
    applicationId,
    experiment,
    subtitle = null,
    submitText = null,
    application,
  }: ApplicationTestProps) => {
    return (
      <PredictionProvider>
        <>
          {experiment.type === EXPERIMENT_TYPE.TEXT && (
            <TextTest
              modelId={applicationId}
              task={experiment.task}
              title=""
              subtitle={subtitle}
              submitText={submitText}
              application={application}
              isApplication
            />
          )}

          {experiment.type === EXPERIMENT_TYPE.IMAGE && (
            <ImageTest
              modelId={applicationId}
              task={experiment.task}
              experiment={experiment}
              title=""
              subtitle={subtitle}
              fileInstruction="or drag and drop an image."
              submitText={submitText}
              application={application}
              isApplication
            />
          )}

          {experiment.type === EXPERIMENT_TYPE.AUDIO && (
            <AudioTest
              modelId={applicationId}
              task={experiment.task}
              experiment={experiment}
              title=""
              subtitle={subtitle}
              submitText={submitText}
              fileInstruction="or drag an audio file."
              application={application}
              isApplication
            />
          )}
        </>
      </PredictionProvider>
    );
  }
);

export const Application = React.memo(() => {
  const { applicationId }: { applicationId: string } = useParams();
  const {
    loading,
    application,
    experiment,
    currentModel,
    showDescription,
    setShowDescription,
  } = useApplication({
    applicationId,
  });
  const classes = useStyle({
    colorText: application?.config?.colorText || DEFAULT_VALUES['colorText'],
    colorBackground:
      application?.config?.colorBackground || DEFAULT_VALUES['colorBackground'],
    fontFamily: application?.config?.fontFamily || DEFAULT_VALUES['fontFamily'],
  });

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ height: '100vh' }}
      >
        <Text variant="h3">Loading...</Text>
      </Box>
    );

  if (!application || !experiment || !currentModel)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ height: '100vh' }}
      >
        <Text variant="h3">Application not found</Text>
      </Box>
    );

  return (
    <Box className={classes.root}>
      {application.header && (
        <div dangerouslySetInnerHTML={{ __html: application.header }}></div>
      )}
      <Typography variant="body1" className={classes.title}>
        {application.title || experiment.title}
      </Typography>

      {application.description && (
        <>
          <Box
            onClick={() => setShowDescription((prev) => !prev)}
            className={classes.descriptionBtn}
          >
            {showDescription ? (
              <>
                <ExpandLessIcon className={classes.descriptionBtnTxt} />
                <Text className={classes.descriptionBtnTxt}>
                  Hide description
                </Text>
              </>
            ) : (
              <>
                <ExpandMoreIcon className={classes.descriptionBtnTxt} />
                <Text className={classes.descriptionBtnTxt}>
                  Show description
                </Text>
              </>
            )}
          </Box>
          {showDescription && (
            <Text variant="body1" className={classes.description}>
              {application.description}
            </Text>
          )}
        </>
      )}

      <Box flexGrow={1} className={classes.testWrapper}>
        <Box>
          <ApplicationTest
            applicationId={applicationId}
            experiment={experiment}
            subtitle={application?.config?.subtitle}
            submitText={application?.config?.submitText}
            application={application}
          />
        </Box>
      </Box>
      {application.footer && (
        <div dangerouslySetInnerHTML={{ __html: application.footer }}></div>
      )}
      {!application.config?.hideLogo && <Footer />}
    </Box>
  );
});
