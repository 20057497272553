import React, { FC, useMemo, useState } from 'react';

import useStyles from './Billing.styles';
import { Box } from '@material-ui/core';
import { CurrentPlanCard, FAQ, LongSwitch, PlanCard, Text } from 'components';
import { FreeTrialCard } from './FreeTrialCard';
import { redirectToStripePage, paymentLinks, plansInfo } from './plans';
import { upgradePlanRequestService } from 'services';
import { useAuthenticationContext } from 'context';
import { AppSumo } from 'components/UpgradePlanModal/AppSumo.tsx';
import { planIndex, PlAN_IDS } from 'types';

const paymentMonthsMap: { [key: number]: number } = {
  0: 12,
  1: 1,
};

export interface BillingProps {}

export const Billing: FC<BillingProps> = () => {
  const classes = useStyles();

  const [timeLapseSelected, settimeLapseSelected] = useState(0);

  const onTimeLapseChange = (idx: number) => {
    settimeLapseSelected(idx);
  };

  const upgradePlan = (id: string) => {
    if (!user) return;

    (window as any).lintrk?.('track', { conversion_id: 14509876 });

    const hasStripeSubscription =
      planIndex[user.commercial_plan.id] >= planIndex[PlAN_IDS.STARTER];

    if (hasStripeSubscription) {
      redirectToStripePage(user.email);
      return;
    }

    upgradePlanRequestService(id, paymentMonthsMap[timeLapseSelected]).then(
      (response) => {
        window.location.href = `${paymentLinks[timeLapseSelected][id]}?prefilled_email=${response.email}&client_reference_id=${response.commercial_plan_upgrade_request_token}`;
      }
    );
  };

  const { user } = useAuthenticationContext();

  const isAppSumoPlan = useMemo(
    () => user?.commercial_plan?.name.startsWith('AppSumo'),
    [user]
  );

  return !isAppSumoPlan ? (
    <Box className={classes.billing}>
      {user?.commercial_plan.id === PlAN_IDS.FREE_TRIAL && <FreeTrialCard />}
      <Text variant="h2">Plans and pricing</Text>
      <Box className={classes.periodSwitch}>
        <LongSwitch
          options={[
            { text: 'annually', helperText: '(Save up to 30%)' },
            { text: 'monthly' },
          ]}
          selectedIndex={timeLapseSelected}
          selectIndexHandler={onTimeLapseChange}
        />
      </Box>
      <Box
        display="flex"
        gridGap="12px"
        className={classes.plansContainer}
        justifyContent="center"
      >
        {plansInfo[timeLapseSelected].map((planData, index) => (
          <PlanCard
            key={index}
            {...planData}
            index={index}
            isCurrentPlan={user?.commercial_plan.id === planData.id}
            onUpgradeHandler={upgradePlan}
            user={user}
            disabled={
              planData.id === PlAN_IDS.FREE &&
              user?.commercial_plan.id === PlAN_IDS.FREE_TRIAL
            }
          />
        ))}
      </Box>
      <Box className={classes.enterpriseCard}>
        <CurrentPlanCard />
      </Box>

      <Box className={classes.faqComponentContainer}>
        <FAQ />
      </Box>
    </Box>
  ) : (
    <Box>
      <AppSumo open={true} onClose={() => {}} onUpgradeHandler={() => {}} />
    </Box>
  );
};
