import { createMuiTheme, Theme } from '@material-ui/core';
import { overrides } from './overrides';
import { colors } from './colors';

export interface ICogniflowTheme extends Theme {
  colors: any;
  matrixSizes: any;
  tagColors: any;
}
export const theme = createMuiTheme({
  overrides: overrides,
  palette: {
    primary: {
      main: colors.blue50,
      dark: colors.blue70,
      light: colors.blue20,
    },
    secondary: {
      main: colors.orange,
    },
  },
  typography: {
    fontFamily: '"Poppins", sans-serif;',
    h1: {
      fontSize: 40,
      fontWeight: 600,
      letterSpacing: 'normal',
      color: colors.blue20,
    },
    h2: {
      fontSize: 32,
      fontWeight: 'bold',
      letterSpacing: 'normal',
      color: colors.blue70,
    },
    h3: {
      fontSize: 24,
      fontWeight: 'bold',
      letterSpacing: 'normal',
      color: colors.blue70,
    },
    h4: {
      fontSize: 16,
      fontWeight: 'bold',
      letterSpacing: 'normal',
      color: colors.blue70,
    },
    body1: {
      fontFamily: "'PT Serif', serif",
      fontSize: 16,
      fontWeight: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      color: colors.blue70,
    },
    body2: {
      fontFamily: "'PT Serif', serif",
      fontSize: 14,
      fontWeight: 'normal',
      lineHeight: 1.29,
      letterSpacing: 'normal',
      color: colors.blue50,
    },
    button: {
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: 'normal',
      textTransform: 'none',
    },
    caption: {
      fontSize: 8,
      fontWeight: 600,
      color: colors.blue50,
      letterSpacing: 0.6,
      lineHeight: '1',
    },
  },
  colors,
  matrixSizes: {
    xsm: '25px',
    sm: '50px',
    md: '70px',
  },
  tagColors: {
    pink: {
      light: '#FAE8FF',
      main: '#D946EF',
      dark: '#86198F',
    },
    red: {
      light: '#FCE7F3',
      main: '#EC4899',
      dark: '#9D174D',
    },
    green: {
      light: '#CCFBF1',
      main: '#14B8A6',
      dark: '#115E59',
    },
    purple: {
      light: '#EDE9FE',
      main: '#8B5CF6',
      dark: '#5B21B6',
    },
    orange: {
      light: '#FFEDD5',
      main: '#F97316',
      dark: '#9A3412',
    },
  },
} as ICogniflowTheme);
