import React, { useMemo } from 'react';

import {
  Container,
  CircularProgress,
  Typography,
  Box,
  Snackbar,
  Grid,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { CreateCompany } from './CreateCompany';
import { CompanyForm } from './CompanyForm';
import { Members } from './Members';
import { AntTab, AntTabs } from 'components/Tabs';
import { ActionButton } from 'components';

import { useCompany } from './useCompany';
import { useAuthenticationContext, useUserPlanContext } from 'context';
import { usePermissions } from 'hooks/usePermissions';

import { useHistory } from 'react-router-dom';

import classNames from 'classnames';
import useStyle from './company.styles';

export const Company = () => {
  const classes = useStyle();
  const {
    company,
    fetchData,
    loadingData,
    tab,
    setTab,
    message,
    setMessage,
  } = useCompany();
  const { canCreateCompany } = usePermissions();

  const { canDoUpgrade } = useUserPlanContext();

  const { push } = useHistory();

  const createCompanySuccessHandler = ({ msg }: { msg: string }) => {
    setMessage({ text: msg, type: 'success' });
    fetchData();
  };

  const createCompanyErrorHandler = ({ msg }: { msg: string }) => {
    setMessage({ text: msg, type: 'error' });
  };

  const companyOwnerEmail = useMemo(
    () => company?.users.find(({ id }) => id === company?.id_owner)?.email,
    [company]
  );

  const { user } = useAuthenticationContext();

  const upgradePlanHandler = () => {
    push('/settings/subscription');
  };

  if (loadingData) return <CircularProgress color="secondary" />;

  if (!canCreateCompany)
    return (
      <Container
        maxWidth="sm"
        className={classNames(classes.wrapper, classes.emptyWrapper)}
      >
        {!user?.is_company_commercial_plan && (
          <img
            src="https://static.userguiding.com/ext-assets/1651176536305_group_14_2x.png"
            width={200}
            alt="company"
          ></img>
        )}

        <Box className={classes.textWrapper}>
          {user?.is_company_commercial_plan ? (
            <Grid>
              <Typography variant="h3">Organization</Typography>
              <Typography variant="body1">
                You are part of the organization: <b>{company?.name}</b>
                <br />
                Owner contact: <b>{companyOwnerEmail}</b>
              </Typography>
            </Grid>
          ) : (
            <Grid>
              <Typography variant="h3">Organization (for Teams)</Typography>
              <Typography variant="body1">
                Increase your productivity.
                <br />
                Invite users and collaborate to create great AI together.
              </Typography>
            </Grid>
          )}
        </Box>
        <Box>
          {canDoUpgrade && (
            <ActionButton
              onClick={upgradePlanHandler}
              color="secondary"
              variant="contained"
              size="large"
            >
              Upgrade your plan
            </ActionButton>
          )}
        </Box>
      </Container>
    );

  return (
    <>
      {company ? (
        <Container maxWidth="md" className={classes.wrapper}>
          <Box>
            <Typography variant="h2" align="left">
              Organization
            </Typography>
            <AntTabs
              value={tab}
              onChange={(_, value) => setTab(value)}
              centered
            >
              <AntTab label="General" />
              <AntTab label="Members" />
            </AntTabs>
          </Box>

          {tab === 0 ? (
            <CompanyForm
              company={company}
              onSuccess={({ msg }) =>
                setMessage({ text: msg, type: 'success' })
              }
              onError={({ msg }) => {
                setMessage({ text: msg, type: 'error' });
              }}
              updateMode
            />
          ) : (
            <Members
              company={company}
              onSuccess={({ msg }) => {
                setMessage({ text: msg, type: 'success' });
                fetchData();
              }}
              onError={({ msg }) => {
                setMessage({ text: msg, type: 'error' });
              }}
            />
          )}
        </Container>
      ) : (
        <CreateCompany
          onSuccess={createCompanySuccessHandler}
          onError={createCompanyErrorHandler}
        />
      )}

      <Snackbar
        open={!!message}
        onClose={() => setMessage(null)}
        autoHideDuration={3000}
      >
        <MuiAlert severity={message?.type} variant="filled">
          {message?.text}
        </MuiAlert>
      </Snackbar>
    </>
  );
};
