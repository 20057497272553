/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useReducer } from 'react';
import { Typography, Button, TextField } from '@material-ui/core';
import { AuthFormCard } from 'components/AuthFormCard';
import useStyles from './setuserprofile.styles';
import { LogoCogniflow } from 'assets';
import { useHistory } from 'react-router-dom';
import { reducer } from './answersReducer';
import { http } from 'services/http';
import { CreatingAccount } from '../CreatingAccount';

import { useAuthenticationContext } from 'context';
import { User } from 'types';

/**
 * TODO: We have to re-implement this flow to properly use states and material handlers
 * in order to reduce the complexity
 */

const initialAnswers = [
  {
    id_question: 'eeffaabb-ae29-4992-bb8f-139448dd9d02',
    id_options: [],
  },
  {
    id_question: '00000000-0000-4992-bb8f-139448dd9d02',
    id_options: [],
  },
  {
    id_question: '11111111-1111-4992-bb8f-139448dd9d02',
    id_options: [],
  },
  {
    id_question: '22222222-2222-4992-bb8f-139448dd9d02',
    id_options: [],
  },
];

type ProfileProps = {
  industry: string;
  workYouDo: string;
  dataYouWorkWith: string;
  challengesToSolve: string;
  receiveNews: boolean;
};

export const SetUserProfile = () => {
  const [creatingAccount, setCreatingAccount] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  const { user } = useAuthenticationContext();

  const [answers] = useReducer(reducer, initialAnswers);

  const [profile, setProfile] = useState<ProfileProps>({
    industry: '',
    workYouDo: '',
    dataYouWorkWith: '',
    challengesToSolve: '',
    receiveNews: true,
  });

  useEffect(() => {
    const state = history.location.state as { from: string };
    if (state && !['third-party', 'activation'].includes(state.from))
      history.push('/login');
    if (!state) history.push('/login');
  }, [history]);

  const updateProfile = ({ target }: any) => {
    setProfile({
      ...profile,
      [target.name]: target.value,
    });
  };

  const onSaveProfileHandler = () => {
    const { id, account_token } = user as User;

    const body = {
      id_user: id,
      token: account_token,
      receive_newsletter: profile.receiveNews,
      answers: [...answers],
    };

    body.answers[3] = {
      id_question: body.answers[3].id_question,
      text_answer: profile.challengesToSolve,
    };

    http
      .post('user/profile-answers', JSON.stringify(body))
      .then(() => {
        setCreatingAccount(true);
        setTimeout(() => {
          setCreatingAccount(false);

          history.push('/experiments');
        }, 2000);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      {creatingAccount ? (
        <CreatingAccount />
      ) : (
        <AuthFormCard
          headerComponent={
            <header className={classes.headerSection}>
              <LogoCogniflow />
            </header>
          }
          subtitleComponent={
            <Typography
              className={classes.subtitle}
              variant="body1"
              align="center"
            >
              Tell us about your main challenge
            </Typography>
          }
          bodyComponent={
            <div className={classes.bodyContainer}>
              <div className={classes.dropdownContainer}>
                <TextField
                  label="I want to use AI to help me…"
                  multiline
                  rows={5}
                  style={{
                    width: '100%',
                    textAlign: 'left',
                  }}
                  variant="outlined"
                  onChange={updateProfile}
                  name="challengesToSolve"
                />
              </div>

              <Button
                variant="contained"
                color="secondary"
                size="large"
                type="submit"
                className={classes.createAccountButton}
                onClick={onSaveProfileHandler}
              >
                Submit
              </Button>
            </div>
          }
          footerComponent={<div className={classes.footerSection}></div>}
        />
      )}
    </>
  );
};
