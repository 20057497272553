import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  avatar: {
    overflow: 'hidden',
    flexShrink: 0,
    width: 30,
    height: 30,
    border: '1px solid #ccc',
    borderRadius: '100%',
    background: '#fff',
    display: 'flex',
  },
  avatarImg: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  welcomeMessage: {
    fontSize: '0.8rem',
    border: '1px solid rgb(223, 227, 238)',
    backgroundColor: 'rgb(247, 249, 255)',
    padding: '8px 12px',
    borderRadius: '0px 12px 12px',
    boxShadow: 'rgba(72, 79, 101, 0.18) 0px 1px 8px 0px',
  },

  messageSources: {
    fontSize: '12px',
    marginLeft: '45px',
  },
}));
