import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

const flexBetween = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

interface StyleProps {
  progressColor: string;
}

export default makeStyles(() => ({
  statsWrapper: {
    ...flexBetween,
    margin: '8px 0',
  },
  checkboxLabel: {
    ...flexBetween,
    '& p': {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 500,
      textAlign: 'start',
    },
  },

  percentageWrapper: {
    ...flexBetween,
  },
  percentageNumber: {
    width: '50px',
    fontSize: '14px',
    fontFamily: 'Poppins',
    fontWeight: 600,
    color: colors.blue70,
    margin: '0 8px 0 0',
  },
  percentage: {
    '& .MuiLinearProgress-root': {
      backgroundColor: colors.blue10,
    },
  },
  amount: {
    backgroundColor: colors.blue10,
    borderRadius: '10px',
    '& p': {
      fontSize: '12px',
      fontFamily: 'Poppins',
      fontWeight: 600,
    },
    width: '45px',
    height: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '12px',
  },
  progress: ({ progressColor }: StyleProps) => ({
    height: '8px',
    width: '128px',
    borderRadius: '16px',
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: progressColor,
    },
  }),
}));
