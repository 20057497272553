import React, { useRef, useCallback, useState, useEffect } from 'react';
import {
  Grid,
  IconButton,
  Box,
  Tooltip,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { toBase64File } from '../../services/models';
import StopIcon from '@material-ui/icons/Stop';
import MicOn from '@material-ui/icons/MicOutlined';
import { GenericModalState } from '../GenericModal/GenericModal';
import { atom, useSetRecoilState } from 'recoil';
import WaveSurfer from 'wavesurfer.js';
import useStyles from './audiocapture.styles';
import { useIntervalTimer } from './useIntervalTimer';
import { useMicRecording } from 'hooks';
import { usePredictionContext } from 'context';
const MicrophonePlugin = require('wavesurfer.js/dist/plugin/wavesurfer.microphone.js');

interface AudioExported {
  base64encode: string;
  file: string;
}

export const audioRecordState = atom<AudioExported | null>({
  key: 'snapshotStateAudio',
  default: null,
});

export const AudioRecord = () => {
  const classes = useStyles();
  const {
    setAudioRecordedHandler: setAudio,
    setAudioPredRespHandler,
  } = usePredictionContext();
  // const setAudio = useSetRecoilState(audioRecordState);
  const setTogelModal = useSetRecoilState(GenericModalState);
  const [record, setStartRecording] = useState(false);
  const waveRef = useRef<HTMLDivElement>(null);
  const {
    timer,
    handleStart: handleStartTimer,
    handleStop: handleStopTimer,
  } = useIntervalTimer(30);

  const { mediaBlob, startRecording, stopRecording } = useMicRecording({});

  useEffect(() => {
    if (timer >= 30) {
      stopRecording();
      handleStopTimer();
      setTogelModal(false);
    }
  }, [timer, stopRecording, handleStopTimer, setTogelModal]);

  useEffect(() => {
    let wavesurfer: WaveSurfer;
    if (waveRef.current) {
      wavesurfer = WaveSurfer.create({
        container: waveRef?.current,
        barWidth: 3,
        cursorWidth: 1,
        height: 80,
        progressColor: '#2D5BFF',
        responsive: true,
        waveColor: '#484f79',
        cursorColor: 'transparent',
        plugins: [
          MicrophonePlugin.create({
            container: waveRef?.current,
          }),
        ],
      });

      record && wavesurfer.microphone.start();
      !record || wavesurfer.microphone.stop();
    }

    return () => {
      if (wavesurfer) {
        wavesurfer.destroy();
      }
    };
  }, [record]);

  useEffect(() => {
    if (mediaBlob) {
      const getEncodeAudio = async () => {
        const encoded = (await toBase64File(mediaBlob)) as string;
        setAudioPredRespHandler(null);

        setAudio({
          base64encode: encoded.split(',')[1],
          file: URL.createObjectURL(mediaBlob),
        });
      };
      getEncodeAudio();
    }
  }, [mediaBlob, setAudio, setAudioPredRespHandler]);

  const handleRecord = useCallback(async () => {
    setStartRecording(true);
    await startRecording();
    handleStartTimer();
  }, [setStartRecording, startRecording, handleStartTimer]);

  const handleStop = useCallback(() => {
    setStartRecording(false);
    stopRecording();
    handleStopTimer();
    setTogelModal(false);
  }, [setStartRecording, stopRecording, setTogelModal, handleStopTimer]);

  const normalise = (value: number) => ((value - 0) * 100) / (30 - 0);

  return (
    <Grid container direction="column">
      <Box className={classes.micContainer}>
        {!record ? (
          <>
            <Tooltip title="Click to start recording, 30 seconds max allowed">
              <IconButton
                classes={{
                  root: classes.btnMic,
                }}
                value="camera-front"
                size="medium"
                color="secondary"
                onClick={handleRecord}
                className={classes.mic}
              >
                <MicOn />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <>
            <Tooltip title="Click to stop recording">
              <>
                <IconButton
                  classes={{
                    root: classes.btnMic,
                  }}
                  color="secondary"
                  onClick={handleStop}
                  value="camera-iris "
                  className={classes.mic}
                >
                  <StopIcon color="secondary" />
                </IconButton>
                <CircularProgress
                  onClick={handleStop}
                  style={{ transform: 'rotate(-90deg)' }}
                  variant="determinate"
                  color="secondary"
                  className={classes.mic}
                  size={50}
                  value={normalise(timer > 30 ? 30 : timer)}
                />
              </>
            </Tooltip>
          </>
        )}

        <Grid
          item
          ref={waveRef}
          className={classes.wavediv}
          justify="center"
        ></Grid>
        {record && (
          <Grid container justify="center">
            <Typography color="secondary" variant="button">
              00:{`0${timer % 60}`.slice(-2)}
            </Typography>
          </Grid>
        )}
      </Box>
    </Grid>
  );
};
