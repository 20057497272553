import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';
import { ICogniflowTheme } from 'material/theme';

export default makeStyles<ICogniflowTheme>(() => ({
  validationDatasetWrapper: {
    border: `1px solid ${colors.blue10}`,
    padding: '8px 12px',

    borderRadius: '4px',
    boxSizing: 'border-box',
  },

  section: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
  },

  defaultText: {
    backgroundColor: colors.blue10,
    padding: '4px 16px',
    borderRadius: 8,
    marginLeft: 8,
    fontSize: 12,
    color: colors.blue70,
  },

  collapseWrapper: {
    display: 'flex',
    alignItems: 'center',
  },

  icon: {
    width: '24px',
    height: '24px',
    '& path': {
      fill: colors.blue50,
    },
  },

  valdiationTitle: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.blue70,
  },
  optionalTitle: {
    fontFamily: 'Poppins',
    fontSize: 8,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: 0.6,
    color: colors.blue20,
  },
  uploadTitle: {
    fontFamily: 'Poppins',
    fontSize: 8,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: 0.6,
    color: colors.blue50,
  },
}));
