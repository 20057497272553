import { Button, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { CancelIcon } from 'assets';
import { updateExperiment } from 'services/experiments';
import {
  currentExperimentState,
  experimentError,
  experimentLoading,
} from 'store/experiments';
import { ConfirmCancel } from '../../Modals/ConfirmCancel';
import useStyles from './cancel-footer.styles';

interface Props {
  experimentId: string;
}

export const CancelFooter = ({ experimentId }: Props) => {
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const setExperiment = useSetRecoilState(currentExperimentState);
  const setExperimentError = useSetRecoilState(experimentError);
  const [expLoading, setExperimentLoading] = useRecoilState(experimentLoading);
  const handleCancel = useCallback(() => {
    setOpen(false);
    setExperimentLoading(true);
    setExperimentError('');
    updateExperiment(experimentId, { status: 4 })
      .then((exp) => {
        setExperiment(exp);
        history.push('/experiments');
        window.location.reload();
      })
      .catch((err) => {
        setExperimentError(err.message);
      })
      .finally(() => {
        setExperimentLoading(false);
      });
  }, [
    setExperiment,
    setExperimentLoading,
    setExperimentError,
    experimentId,
    setOpen,
    history,
  ]);
  const openModal = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const closeModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.container}
    >
      {open && (
        <ConfirmCancel
          onCancel={closeModal}
          onConfirm={handleCancel}
          onClose={closeModal}
        />
      )}
      <Grid
        container
        justify="flex-end"
        alignItems="center"
        className={classes.content}
      >
        <Typography variant="body1">
          Want to cancel running the experiment?
        </Typography>
        <Button
          disabled={expLoading}
          className={classes.button}
          color="secondary"
          variant="outlined"
          size="large"
          onClick={openModal}
        >
          <Grid container alignItems="center" justify="flex-start">
            <CancelIcon className={classes.buttonIcon} />
            Stop running
          </Grid>
        </Button>
      </Grid>
    </Grid>
  );
};
