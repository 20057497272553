import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, TextField, Paper, Tabs, Tab } from '@material-ui/core';
import { Text } from 'components';

import classnames from 'classnames';
import { Alert } from '@material-ui/lab';
import { predictLongTranscription } from 'services';
import { useAuthenticationContext } from 'context';

import useStyles from './LongTranscription.styles';
import { validateAudioVideoUrl } from 'utils';
import { MessageIcon, UploadIcon } from 'assets';
import { AudioFile } from './AudioFile/AudioFile';
import { useUploadProgress } from 'hooks';

enum TABS {
  URL = 'URL',
  FILE = 'FILE',
}

type LongTranscriptionProps = {
  modelId: string;
  task: number;
  onLongTranscriptionSuccess: (message: string) => void;
};

export const LongTranscription = ({
  modelId,
  task,
  onLongTranscriptionSuccess,
}: LongTranscriptionProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [url, setUrl] = useState('');
  const [awsUrl, setAwsUrl] = useState('');
  const [tab, setTab] = useState<string>(TABS.URL);
  const [transcriptionSent, setTranscriptionSent] = useState(false);
  const [speakersNumber, setSpeakersNumber] = useState<number>();

  const {
    progress,
    isFinished,
    presignedResponse,
    uploadFile,
    resetProgress,
  } = useUploadProgress(task);

  const classes = useStyles();

  const { user } = useAuthenticationContext();

  const [file, setFile] = useState<File | null>(null);

  const [error, setError] = useState<string | null>(null);

  const [loading, setLoading] = useState(false);

  const onUploadClick = () => {
    inputRef?.current?.click();
  };

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    setFile(file);

    setLoading(true);

    uploadFile([file]);
  };

  const deleteHandler = () => {
    if (!file) return;

    if (inputRef.current) {
      inputRef.current.value = '';
    }

    setFile(null);
    setAwsUrl('');
    resetProgress();
  };

  const transcribe = () => {
    if (loading) return;

    let reqURL = '';

    if (tab === TABS.URL) {
      reqURL = url;
    }

    if (tab === TABS.FILE) {
      reqURL = awsUrl;
    }

    if (!validateAudioVideoUrl(reqURL)) {
      setError(
        'Ensure that the provided URL is from Google Drive, YouTube or a direct link file. The URL must be publicly accessible'
      );
      return;
    }
    setError('');
    setLoading(true);

    if (reqURL) {
      predictLongTranscription(
        modelId,
        {
          format: 'mp3',
          url: reqURL,
          ground_truth: '',
          long_transcription: true,
          number_of_speakers: speakersNumber?.toString() ? speakersNumber : -1,
          webhook_url: '',
          result_delivery_email: user?.email || '',
        },
        task,
        {
          'x-api-key': user?.api_keys?.[0]?.key || '',
        }
      )
        .then(() => {
          setTranscriptionSent(true);

          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setError(JSON.stringify(error));
        });
    }
  };

  const handleTabChange = (event: any, value: string) => {
    setTab(value);
    setError('');
  };

  const newTranscriptionHandler = () => {
    setTranscriptionSent(false);
    setTab(TABS.URL);
    setUrl('');
    setLoading(false);
    deleteHandler();
  };

  const speakersNumberChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    const number = parseInt(value);

    if (number < -1) {
      return;
    }
    setSpeakersNumber(number);
  };

  useEffect(() => {
    if (progress === 100) {
      setLoading(false);
      setAwsUrl(presignedResponse?.get_url || '');
    }
  }, [progress, presignedResponse]);

  return (
    <Box className={classes.longTranscriptionWrapper}>
      {transcriptionSent ? (
        <Box>
          <Text variant="h4">Your transcription is on the way!</Text>
          <Box mx="auto" mb="24px">
            <MessageIcon />
          </Box>

          <Box mb="18px">
            <Text variant="paragraph1">
              When ready, you will receive it in your inbox.
            </Text>
          </Box>

          <Button
            variant="contained"
            size="large"
            color="secondary"
            onClick={newTranscriptionHandler}
          >
            New transcription
          </Button>
        </Box>
      ) : (
        <>
          <Text
            variant="paragraph1"
            align="left"
            className={classes.headerText}
          >
            Transcribe any audio or video and receive it in your inbox.
          </Text>

          <Box my="24px">
            <Paper square>
              <Tabs
                value={tab}
                indicatorColor="secondary"
                textColor="secondary"
                onChange={handleTabChange}
                aria-label="disabled tabs example"
                variant="fullWidth"
              >
                <Tab label="Transcribe from URL" value={TABS.URL} />
                <Tab label="Transcribe from file" value={TABS.FILE} />
              </Tabs>
            </Paper>
          </Box>

          <input type="file" ref={inputRef} onChange={onFileChange} hidden />

          {TABS.FILE === tab && (
            <>
              {file ? (
                <Box my="24px">
                  <AudioFile
                    name={file.name}
                    size={file.size}
                    onDelete={deleteHandler}
                    uploadCompleted={isFinished}
                    progress={progress}
                  />
                </Box>
              ) : (
                <Box
                  className={classes.uploader}
                  my="24px"
                  onClick={onUploadClick}
                >
                  <UploadIcon />
                  <Text variant="paragraph1">
                    <span className={classes.uploadText}>Click to upload</span>{' '}
                    an audio or video file
                  </Text>
                  <Text
                    variant="paragraph1"
                    className={classes.maxFileSizeText}
                  >
                    (Max. file size: 4GB)
                  </Text>
                </Box>
              )}
            </>
          )}

          {TABS.URL === tab && (
            <Box
              mb="24px"
              display="flex"
              flexDirection="column"
              gridGap="4px"
              mt="12px"
            >
              <Box mb="4px">
                <Text
                  variant="paragraph1"
                  align="left"
                  className={classnames({
                    [classes.urlInputLabelDisabled]: false,
                  })}
                >
                  Paste your URL here
                </Text>
              </Box>

              <TextField
                className={classes.urlInput}
                type="text"
                label="Audio or Video URL (GDrive, Youtube or public file URL)"
                variant="outlined"
                onChange={(e) => setUrl(e.target.value)}
                fullWidth
                disabled={loading}
                value={url}
              />
            </Box>
          )}

          <Box my="24px">
            <Box mb="8px">
              <Text variant="paragraph1" align="left">
                How many speakers are in the audio/video? (Optional)
              </Text>
            </Box>
            <TextField
              className={classes.urlInput}
              type="number"
              variant="outlined"
              onChange={speakersNumberChangeHandler}
              fullWidth
              disabled={loading}
              value={speakersNumber}
              placeholder="Detect automatically"
            />
          </Box>

          <Button
            onClick={transcribe}
            variant="contained"
            size="large"
            color="secondary"
            disabled={
              loading ||
              (TABS.URL === tab && !url) ||
              (TABS.FILE === tab && progress !== 100)
            }
          >
            {loading ? 'Loading...' : 'Transcribe'}
          </Button>

          {error && (
            <Box mt="12px">
              <Alert severity="warning">
                <Text
                  variant="paragraph1"
                  align="left"
                  className={classes.errorMessage}
                >
                  {error}
                </Text>
              </Alert>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
