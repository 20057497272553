import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  captionValue: {
    maxWidth: '130px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));
