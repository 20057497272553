import { Grid, GridProps } from '@material-ui/core';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import useStyles from './shadedbox.styles';

interface ShadedBoxProps extends GridProps {
  children: ReactNode;
}

export const ShadedBox = ({ children, ...props }: ShadedBoxProps) => {
  const classes = useStyles();

  return (
    <Grid {...props} className={classNames(props.className, classes.container)}>
      {children}
    </Grid>
  );
};
