import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUpload } from 'hooks';
import { getModel, http, updateExperimentKnowledge } from 'services';
import { PresignedUploadResponse } from 'types';
import { makeStringId } from 'utils';
import { getDatasetsByExpId } from 'services/datasets';
import { Doc } from './documents.types';

const MAX_TOTAL_SIZE_MB = 100;
export const ACCEPTED_FILES = ['txt', 'pdf', 'doc', 'docx'];

export const useDocuments = ({
  idModel,
  idExperiment,
}: {
  idModel: string;
  idExperiment: string;
}) => {
  const { handleFetch } = useUpload();
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [changesSaved, setChangesSaved] = useState(false);
  const [datasetFiles, setDatasetFiles] = useState<string[]>([]);
  const [docs, setDocs] = useState<Doc[]>([]);
  const { push } = useHistory();

  const filesToAdd = docs
    .filter((doc) => doc.status === 'Completed')
    .map((doc) => doc.path);
  const filesToRemove = datasetFiles.filter(
    (datesetFile) =>
      !docs.some((doc) => doc.status === 'Active' && doc.name === datesetFile)
  );

  const hasChanges = filesToAdd.length > 0 || filesToRemove.length > 0;
  const canSave = hasChanges && docs.length > 0;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const model = await getModel(idModel);
        const dataset = await getDatasetsByExpId(model.id_experiment);
        const datasetNames = dataset[0].classes.map((c) => c.class_name);

        setDatasetFiles(datasetNames);
        setDocs(
          datasetNames.map((name) => ({
            id: makeStringId(5),
            name: name,
            type: '',
            status: 'Active',
            progress: 0,
            path: '',
          }))
        );
        setLoading(false);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const uploadDocsChange = (fileList?: FileList) => {
    if (!inputRef.current) return;
    const files = fileList ?? inputRef.current.files;
    if (!files) return;

    try {
      let sizeUsed = docs.reduce(
        (acc, doc) =>
          doc.status === 'Completed' && doc.size != null ? acc + doc.size : acc,
        0
      );

      for (const file of Array.from(files)) {
        if (!isValid({ file, sizeUsed })) continue;

        sizeUsed += file.size;
        const docId = makeStringId(5);
        const newDoc: Doc = {
          id: docId,
          name: file.name,
          type: file.type,
          size: file.size,
          status: 'Pending',
          progress: 0,
          path: '',
        };
        addDoc(newDoc);

        http
          .get<PresignedUploadResponse>(
            `upload/knowledge_base?id_experiment=${idExperiment}&filename=${file.name}`
          )
          .then((presignedUrl) => {
            if (presignedUrl && presignedUrl.url) {
              updateDocProperty(docId, 'path', presignedUrl.filename);
              handleFetch({
                url: presignedUrl.url,
                file,
                fields: presignedUrl.s3_fields,
                onProgress: ({ loaded, total }) => {
                  const currentProgress = Math.floor((loaded / total) * 100);
                  updateDocProperty(docId, 'status', 'Uploading');
                  updateDocProperty(docId, 'progress', currentProgress);
                },
                onUploadComplete: () => {
                  updateDocProperty(docId, 'status', 'Completed');
                },
                onFailed: () => {
                  updateDocProperty(docId, 'status', 'Failed');
                },
              });
            }
          })
          .catch((error) => {
            updateDocProperty(docId, 'status', 'Failed');
          });
      }
    } catch (error) {}
  };

  const isValid = ({ file, sizeUsed }: { file: File; sizeUsed: number }) => {
    // Already exists
    if (docs.map((i) => i.name).includes(file.name)) {
      toast.warning(
        `File ${file.name} already exists. Please choose a different file.`,
        {
          position: toast.POSITION.TOP_CENTER,
          toastId: 'already-exists',
          autoClose: 5000,
        }
      );
      return false;
    }

    if (file.size + sizeUsed > MAX_TOTAL_SIZE_MB * 1024 * 1024) {
      toast.warning(
        `Files exceeding the maximum total size of ${MAX_TOTAL_SIZE_MB} mb were omitted.`,
        {
          position: toast.POSITION.TOP_CENTER,
          toastId: 'total-size',
          autoClose: 5000,
        }
      );
      return false;
    }

    const extension = file.name.split('.').pop()?.toLowerCase() || '';
    if (!ACCEPTED_FILES.includes(extension)) {
      toast.warning(
        `${
          file.name
        } is not an allowed file type. Allowed types are: ${ACCEPTED_FILES.map(
          (i) => `.${i}`
        ).join(', ')}.`,
        {
          position: toast.POSITION.TOP_CENTER,
          toastId: 'accepted-files',
          autoClose: 5000,
        }
      );
      return false;
    }

    return true;
  };

  const addDoc = (newDoc: Doc) => {
    setDocs((prev) => [...prev, newDoc]);
  };

  const updateDocProperty = (
    id: string,
    property: string,
    value: string | number
  ) => {
    setDocs((prev) =>
      prev.map((doc) => {
        if (doc.id === id) {
          return { ...doc, [property]: value };
        }
        return doc;
      })
    );
  };

  const deleteDoc = (id: string) => {
    setDocs(docs.filter((doc) => doc.id !== id));
  };

  const confirmChanges = async () => {
    setSaveLoading(true);
    const payload = {
      documents_to_add: filesToAdd,
      documents_to_remove: filesToRemove,
    };
    try {
      await updateExperimentKnowledge(idExperiment, payload);
      setChangesSaved(true);
      setTimeout(() => {
        push(`/building/${idExperiment}?isUpdate=true`);
      }, 1000);
    } catch (error) {
      setSaveLoading(false);
      console.log(error);
    }
  };

  return {
    canSave,
    confirmChanges,
    deleteDoc,
    docs,
    hasChanges,
    changesSaved,
    inputRef,
    loading,
    saveLoading,
    uploadDocsChange,
  };
};
