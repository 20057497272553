import { useEffect, useState } from 'react';
import { Experiment } from 'types';
import { Model } from 'types';

export const useModel = (experiment: Experiment) => {
  const [model, setModel] = useState(experiment.models[0]);

  useEffect(() => {
    if (experiment.id_recommended_model === '') {
      const preprocessing = getPreprocessing(experiment);
      const isRecommended = false;
      const customModel = {
        ...experiment.models[0],
        preprocessing,
        isRecommended,
      };
      setModel(customModel);
    } else {
      const preprocessing = getPreprocessing(experiment);
      //Assumming that if we have and id_recommended_model, is because at least one is recommended
      const filteredModel = experiment.models.filter((m) =>
        isThisModelRecommended(experiment.id_recommended_model, m.id)
      )[0];
      const isRecommended = true;
      let customModel = { ...filteredModel, preprocessing, isRecommended };
      setModel(customModel);
    }
  }, [experiment]);

  return [model, setModel];
};

export const getSelectedModel = (experiment: Experiment, id: string) => {
  const selectedModel = experiment.models.filter((m) => m.id === id)[0];
  const isRecommended = isThisModelRecommended(
    experiment.id_recommended_model,
    id
  );
  const preprocessing = getPreprocessing(experiment);
  const customModel = {
    ...selectedModel,
    isRecommended,
    preprocessing,
  };
  return customModel;
};

const isThisModelRecommended = (
  id_recommended_model: string,
  model_id: string
): boolean => {
  return id_recommended_model === model_id;
};

const getPreprocessing = (experiment: Experiment) => {
  return experiment.preprocessing;
};

export const getCustomModelList = (experiment: Experiment): Model[] => {
  let defaultModelList = experiment.models;
  const updatedModels = defaultModelList.map((model) => {
    return {
      ...model,
      preprocessing: experiment.preprocessing,
      isRecommended: isThisModelRecommended(
        experiment.id_recommended_model,
        model.id
      ),
    };
  });

  return updatedModels;
};
