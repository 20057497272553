import { ExperimentLabel, EXPERIMENT_TASK } from 'types';

export const NEED_HELP_LINKS: ExperimentLabel = {
  [EXPERIMENT_TASK.IMAGE_OBJECT_DETECTION]:
    'https://docs.cogniflow.ai/en/article/how-to-label-images-for-an-object-detection-model-dcfg1y/',
  [EXPERIMENT_TASK.IMAGE_CLASSIFICATION]:
    'https://docs.cogniflow.ai/en/article/how-to-create-an-ai-service-for-image-recognition-t6n68m/',
  [EXPERIMENT_TASK.TEXT_CLASSIFICATION]:
    'https://docs.cogniflow.ai/en/article/how-to-create-an-ai-service-for-sentiment-analysis-text-data-1kjtxhh/',
  [EXPERIMENT_TASK.TEXT_QANDA]:
    'https://docs.cogniflow.ai/en/article/how-to-create-an-ai-question-answering-qa-system-with-no-code-1biz19o/',
  [EXPERIMENT_TASK.AUDIO_CLASSIFICATION]:
    'https://docs.cogniflow.ai/en/article/how-to-create-an-ai-for-sound-classification-1dakrxb/',
};
