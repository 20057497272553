import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  subtitle: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.orange,
  },
  underlined: {
    textDecoration: 'underline',
    color: colors.orange,
  },
  imageContainerResult: {
    backgroundColor: '#FFFF',
    paddingLeft: 20,
    paddingRight: 20,
  },
  messageContainer: {
    padding: '6px 10px',
    borderRadius: 10,
    boxShadow: `0 8px 16px 0 rgba(72, 79, 121, 0.15)`,
    backgroundColor: '#FFFF',
    position: 'relative',
    zIndex: 1,
    marginTop: -30,

    maxWidth: 500,
  },
  badge: {
    backgroundColor: colors.blue10,
    padding: '9px 16px',
    borderRadius: 8,
    marginLeft: 8,
    fontSize: 12,
    color: colors.blue70,
  },
  btnImageClose: {
    position: 'absolute',
  },
  title: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: colors.blue70,
    marginBottom: 16,
  },
  other: {
    alignSelf: 'flex-start',
    paddingRight: 40,
    paddingLef: 20,
    '&:first-child': {
      borderRight: `1px solid ${colors.blue15}`,
    },
  },
  tooltipFont: {
    fontSize: 24,
  },
  box: {
    display: 'flex',

    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px',
  },
  score: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.blue70,
  },
  resultLayout: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  resultWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  scoreWrapper: {
    display: 'flex',
    justifyContent: 'center',
    gap: '12px',
  },
  speech2textBox: {
    display: 'flex',
    flexDirection: 'column',
    '& p': {
      marginBottom: '12px',
    },
  },
}));
