import React from 'react';

import useStyles from './Header.styles';
import { Box, Button } from '@material-ui/core';
import { Text } from 'components';
import { useHistory } from 'react-router-dom';
import { RobotHeaderImg } from 'assets';
import { useAuthenticationContext } from 'context';

export const Header = () => {
  const classes = useStyles();

  const { push } = useHistory();

  const goToNewProject = () => {
    push('/new-project');
  };

  const { user } = useAuthenticationContext();

  return (
    <Box className={classes.header} display="flex">
      <Box className={classes.welcomeMessage}>
        <Box mb="8px">
          <Text variant="paragraph1" align="left" className={classes.title}>
            Welcome, {user?.user_name}!
          </Text>
        </Box>

        <Box mb="16px">
          <Text variant="paragraph1" align="left" className={classes.subtitle}>
            What is Cogniflow?
          </Text>
        </Box>

        <Box>
          <Text
            variant="paragraph1"
            align="left"
            style={{ whiteSpace: 'pre-line', lineHeight: 1.5 }}
            // className={classes.description}
          >
            Cogniflow allows you to create AI projects that will make you more
            productive.
            {'\n'}
            Get started by creating your first project or explore our community
            projects.
          </Text>
        </Box>
      </Box>

      <Box className={classes.headerImage}>
        <img src={RobotHeaderImg} alt="header robot" />
      </Box>

      <Box className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={goToNewProject}
        >
          New Project
        </Button>
      </Box>
    </Box>
  );
};
