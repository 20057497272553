import React from 'react';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import {
  Box,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { ActionButton } from 'components';
import { ChangePassword } from './ChangePassword/ChangePassword';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { useProfile, validationSchema } from './useProfile';

import useStyle from './profile.styles';

export const Profile = () => {
  const classes = useStyle();
  const {
    user,
    initialValues,
    formikRef,
    onSubmitHandler,
    setProfileUpdated,
    profileUpdated,
    isLoading,
    error,
    setError,
  } = useProfile();

  if (!user) return null;

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
        innerRef={formikRef}
      >
        {({ values, setFieldValue }) => (
          <Form className={classes.form}>
            <Typography variant="h2" align="left">
              My Profile
            </Typography>

            <Field
              component={TextField}
              name="email"
              label="Email"
              type="email"
              variant="outlined"
              disabled={true}
            />
            <Field
              component={TextField}
              name="username"
              label="Username"
              type="text"
              variant="outlined"
              disabled={isLoading}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.receive_newsletter}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('receive_newsletter', event.target.checked);
                  }}
                  name="receive_newsletter"
                />
              }
              label="Receive Cogniflow's newsletter"
            />

            {error && (
              <Alert onClose={() => setError('')} severity="error">
                {error}
              </Alert>
            )}

            <Box>
              <ActionButton
                variant="contained"
                color="secondary"
                size="large"
                type="submit"
                loading={isLoading}
              >
                Update profile
              </ActionButton>
            </Box>
          </Form>
        )}
      </Formik>

      <Divider />

      <ChangePassword />

      <Snackbar
        open={profileUpdated}
        onClose={() => setProfileUpdated(false)}
        autoHideDuration={3000}
      >
        <MuiAlert severity={'success'} variant="filled">
          Profile updated successfully
        </MuiAlert>
      </Snackbar>
    </Container>
  );
};
