import React from 'react';
import { ExperimentStatusTag } from 'components/ExperimentStatusTag';
import { Model } from 'types';

interface Props {
  experimentId: string;
  modelId: string;
  model: Model;
}

export const CustomTag = React.memo(({ model }: Props) => {
  return (
    <div>
      <ExperimentStatusTag status={model.status} progress={model.progress} />
    </div>
  );
});
