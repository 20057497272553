import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  detailBox: {
    paddingTop: '18px',
  },

  detailElements: {
    /*paddingRight: '32px' */
  },
}));
