import React, { useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import useStyles from './audioclassification.styles';
import { PredictMessage } from 'components/PredcitMessage';
import { AntTab, AntTabs } from 'components/Tabs';
import { CodeBlock } from 'components/CodeBlock';

type AudioClassificationProps = {
  audioResponseData: any;
  showJsonTab?: boolean;
};

export const AudioClassification = ({
  audioResponseData,
  showJsonTab = true,
}: AudioClassificationProps) => {
  const classes = useStyles();
  const [tab, setTab] = useState(0);

  return (
    <Box className={classes.resultWrapper}>
      <Box className={classes.text}>
        <Typography className={classes.title}>Results</Typography>
        <Typography variant="body1" className={classes.subtitle}>
          These are the results according to the audio conditions.
        </Typography>
      </Box>

      {showJsonTab && (
        <AntTabs
          value={tab}
          onChange={(e, newValue) => {
            setTab(newValue);
          }}
          centered
        >
          <AntTab label="Preview" />
          <AntTab label="JSON" />
        </AntTabs>
      )}

      <Grid className={classes.predictionWrapper}>
        {tab === 0 ? (
          <PredictMessage prediction={audioResponseData.prediction} />
        ) : (
          <CodeBlock
            code={audioResponseData.prediction}
            language="javascript"
          />
        )}
      </Grid>
    </Box>
  );
};
