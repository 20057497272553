import React from 'react';
import classNames from 'classnames';

import { Box } from '@material-ui/core';

import { Text } from 'components';

import { LogoIco } from 'assets';

import useStyle from './application.styles';

export const Footer = () => {
  const classes = useStyle();

  return (
    <Box className={classes.footerWrapper}>
      <Box display="flex" alignItems="center" className={classes.footerBody}>
        <LogoIco className={classes.footerLogo} />
        <Text className={classes.footerText}>Made with</Text>
        <a
          href="https://www.cogniflow.ai/?utm_source=web_app"
          target="_blank"
          rel="noreferrer"
          className={classNames(classes.footerText, classes.footerLink)}
        >
          Cogniflow
        </a>
      </Box>
    </Box>
  );
};
