import { OptionCard } from 'components/OptionCard';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ImageExpLogo, TextExpLogo } from 'assets';
import { Grid, Link } from '@material-ui/core';
import useStyles from './step2.styles';
import { EXPERIMENT_TASK, PROJECT_TYPE } from 'types';
import { StyledTextLink } from 'components/StyledTextLink';

type URLParams = {
  type: string;
};

export const Step2 = () => {
  const { type }: URLParams = useParams();
  const classes = useStyles();

  const taskCards = useMemo(
    () => ({
      [PROJECT_TYPE.TEXT]: [
        {
          title: 'Text Classification',
          description:
            'Create an experiment that uses text entries and their labels as input to learn how to categorize them',
          icon: TextExpLogo,
          logoImgName: 'imgTextPattern.png',
          redirectTo: `/create/${type}/${EXPERIMENT_TASK.TEXT_CLASSIFICATION}`,
          buttonLabel: 'Create experiment',
          helpLink:
            'https://docs.cogniflow.ai/en/article/how-to-create-an-ai-service-for-sentiment-analysis-text-data-1kjtxhh',
          readyToTrain: true,
          seeSamplesLinkText: 'See sample text experiments',
        },
        {
          title: 'Ask questions to your documents',
          description:
            'Create a knowledge base from unlabeled text documents that you can use later to ask open questions about topics in it',
          icon: TextExpLogo,
          logoImgName: 'imgTextPattern.png',
          redirectTo: `/create/${type}/${EXPERIMENT_TASK.TEXT_QANDA}`,
          buttonLabel: 'Create experiment',
          task: EXPERIMENT_TASK.TEXT_QANDA,
          readyToTrain: true,
          helpLink:
            'https://docs.cogniflow.ai/en/article/how-to-create-an-ai-question-answering-qa-system-with-no-code-1biz19o',
          seeSamplesLinkText: 'See sample text experiments',
          isNew: true,
        },

        {
          title: 'Smart extractor',
          description:
            'Extract specific information from the texts by defining the entities or attributes to extract using natural language',
          icon: TextExpLogo,
          logoImgName: 'imgTextPattern.png',
          redirectTo: `/create/${type}/${EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR}`,
          buttonLabel: 'Create experiment',
          task: EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR,
          readyToTrain: true,
          helpLink:
            'https://docs.cogniflow.ai/en/article/create-a-custom-extractor-7pydjq',
          seeSamplesLinkText: 'see sample text extractor experiments',
        },
      ],
      [PROJECT_TYPE.IMAGE]: [
        {
          title: 'Image classification',
          description:
            'Create an experiment that uses photos, images, or graphs as input to learn how to classify them',
          icon: ImageExpLogo,
          logoImgName: 'imgTextPattern.png',
          redirectTo: `/create/${type}/${EXPERIMENT_TASK.IMAGE_CLASSIFICATION}`,
          buttonLabel: 'Create experiment',
          helpLink:
            'https://docs.cogniflow.ai/en/article/how-to-create-an-ai-service-for-image-recognition-t6n68m',
          seeSamplesLinkText: 'See sample image based experiments',
          readyToTrain: true,
        },
        {
          title: 'Object detection',
          description:
            'Create an experiment that uses photos, images, or graphs as input to learn how to locate and categorize different types of objects in them.',
          icon: ImageExpLogo,
          logoImgName: 'imgTextPattern.png',
          // redirectTo: `mailto:info@cogniflow.ai?subject=Looking for custom object detection solution&body=Hi, my name is #NAME#.%0D%0A%0D%0AI currently looking for a custom object detection solution to solve my business problem which is about  #PROBLEM#.%0D%0A%0D%0AI'm available next week on #AVAILABILITY# to discuss further about it.%0D%0A%0D%0AThank you, #NAME#`,
          redirectTo: `/create/${type}/${EXPERIMENT_TASK.IMAGE_OBJECT_DETECTION}`,
          buttonLabel: 'Create experiment',
          task: EXPERIMENT_TASK.IMAGE_OBJECT_DETECTION,
          helpLink: `/experiment/c3ffee01-2351-45af-cc2a-dd1084bbc341`,
          seeSamplesLinkText: `Our pretrained object detection model.`,
          readyToTrain: false,
          needInspirationText: 'For common objects, you can try',
        },

        {
          title: 'Smart extractor',
          description:
            'Extract specific information from the text in images or PDF documents by defining the entities or attributes to extract using natural language',
          icon: ImageExpLogo,
          logoImgName: 'imgTextPattern.png',
          redirectTo: `/create/${type}/${EXPERIMENT_TASK.IMAGE_CUSTOM_ENTITY_EXTRACTOR}`,
          buttonLabel: 'Create experiment',
          helpLink:
            'https://docs.cogniflow.ai/en/article/create-a-custom-extractor-7pydjq',
          seeSamplesLinkText: 'see sample image extractor experiments',
          readyToTrain: true,
          task: EXPERIMENT_TASK.IMAGE_CUSTOM_ENTITY_EXTRACTOR,
        },
      ],
      [PROJECT_TYPE.AUDIO]: [],
    }),
    [type]
  );

  return (
    <Grid container justify="space-evenly" className={classes.container}>
      {(taskCards as any)[type].map((card: any, idx: number) => (
        <OptionCard
          key={idx}
          Icon={card.icon}
          logoImgName={card.logoImgName}
          title={card.title}
          description={card.description}
          buttonLabel={card.buttonLabel}
          redirectTo={card.redirectTo}
          readyToTrain={card.readyToTrain}
          isNew={card.isNew}
          task={card.task}
          type={type}
        >
          {card.needInspirationText
            ? card.needInspirationText
            : 'Need inspiration'}
          <Link
            className={classes.linkDecoration}
            rel="noopener"
            href={card.helpLink}
            target="_blank"
            component="a"
          >
            <StyledTextLink size="14px">
              {card.seeSamplesLinkText}
            </StyledTextLink>
          </Link>
        </OptionCard>
      ))}
    </Grid>
  );
};
