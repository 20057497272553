import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';
export default makeStyles(() => ({
  configurationItemContainer: {
    width: '142px',
    paddingBottom: '20px',
  },

  containerSubtitleTitle: {
    fontFamily: 'Poppins',
    fontSize: '8px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'bold',
    lineHeight: 1.25,
    letterSpacing: '0.6px',
    color: colors.blue50,
    marginBottom: '3px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  containerNormalSubtitle: {
    fontFamily: 'PTSerif',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'underline',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.blue70,
  },
  labelTitle: {
    flexWrap: 'nowrap',
  },
  helpIcon: {
    flexShrink: 0,
  },
}));
