import React from 'react';
import { Box } from '@material-ui/core';
import classname from 'classnames';
import useStyles from './CheckCircle.style';
import { CheckIcon } from 'assets';

type CheckCircleProps = {
  checked?: boolean;
};

export const CheckCircle = ({ checked = false }: CheckCircleProps) => {
  const classes = useStyles();
  return (
    <Box
      className={classname({
        [classes.checkCircle]: true,
        [classes.checked]: checked,
      })}
    >
      {checked && (
        <Box className={classes.icon}>
          <CheckIcon />
        </Box>
      )}
    </Box>
  );
};
