import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  searchInput: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));
