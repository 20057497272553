import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

const flexYCenter = {
  display: 'flex',
  alignItems: 'center',
};

export default makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  subtitle: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.blue70,
  },
  categoriesHeader: {
    backgroundColor: colors.blue10,
    padding: '24px 32px',
  },
  categories: {
    backgroundColor: 'white',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    overflowY: 'auto',
  },
  categoriesAll: {
    padding: '2% 3%',
    borderBottom: '2px solid #eceffa',
  },
  categoriesList: {
    padding: '2% 3%',
  },
  categoriesTitle: {
    textAlign: 'left',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  categoriesSubtitle: {
    textAlign: 'left',
    fontSize: '12px',
  },
  checkboxLabel: {
    ...flexYCenter,
    '& p': {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 500,
      textAlign: 'start',
    },
  },
  download: {
    cursor: 'pointer',
    color: theme.palette.secondary.main,
  },
}));
