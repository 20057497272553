import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  root: {
    height: '100%',
    position: 'relative',
    background: 'var(--color-background)',
    '& *': {
      fontSize: '0.9rem',
      fontFamily: 'var(--fontFamily)',
    },
    '& h1, & h2, & h3, & h4, & h5, & h6': {
      fontSize: '1rem',
    },
    '& p, & h1, & h2, & h3, & h4, & h5, & h6': {
      marginTop: 0,
    },
    '& p:last-child, & h1:last-child, & h2:last-child, & h3:last-child, & h4:last-child, & h5:last-child, & h6:last-child ': {
      marginBottom: 0,
    },
  },
  header: {
    height: 60,
    background: 'var(--color-btn)',
    display: 'flex',
    alignItems: 'center',
    padding: '0 24px',
    '& *': {
      color: '#fff',
      fontSize: '19px !important',
    },
  },
  pinnedMessage: {
    position: 'relative',
    padding: '12px 40px',
    boxShadow: 'rgba(72, 79, 101, 0.18) 0px 1px 8px 0px',
  },
  chat: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
  },

  userMessage: {
    width: 'max-content',
    marginTop: 10,
    fontSize: '0.8rem',
    backgroundColor: 'rgb(72, 79, 121)',
    padding: '8px 12px',
    borderRadius: '12px 0px 12px 12px',
    maxWidth: '100%',
    boxShadow: 'rgba(72, 79, 101, 0.18) 0px 1px 8px 0px',
    color: '#fff',
    alignSelf: 'flex-end',
    marginBottom: 10,
  },
  bottom: {
    width: '100%',
    padding: 14,
    position: 'absolute',
    bottom: 0,
    boxSizing: 'border-box',
  },
  input: {
    width: '100%',
    padding: '8px 16px',
    border: '1px solid #e2e8f0',
    borderRadius: 6,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    '& *': {
      color: '#b1bac5',
    },
  },
  footer: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& *': {
      color: '#888d8f',
      fontSize: '11px',
      fontWeight: 'bold',
    },
  },
}));
