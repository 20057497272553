import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { ControlledModal } from 'components/GenericSimpleModal/ControlledModal';
import { StopLogo2 } from 'assets';
import useStyles from '../step4.styles';

type LogData = {
  message: string;
};

type InvalidCsvFileModalProps = {
  open: boolean;
  closeHandler(): void;
  logs: LogData[];
  editColumnsHandler(): void;
};

export const InvalidCsvFileModal = ({
  open,
  closeHandler,
  logs,
}: InvalidCsvFileModalProps) => {
  const classes = useStyles();

  return (
    <ControlledModal isOpen={open} closeHandler={closeHandler} size="sm">
      <Grid container justify="center">
        <StopLogo2 />
      </Grid>
      <Typography variant="h3" align="center">
        Looks like you are trying to upload an invalid Zip file :/
      </Typography>
      <Grid className={classes.infoContainer}>
        <Grid item container lg={12} className={classes.algoDetailContainer}>
          <Grid
            container
            className={classes.algoDetail}
            justify="space-between"
            alignItems="center"
          >
            {logs.map(({ message }, index) => (
              <Grid className={classes.logContainer} key={message}>
                <Typography className={classes.logPos}>-</Typography>

                <Typography className={classes.logMessage}>
                  {message}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Box display="flex" justifyContent="flex-end"></Box>
    </ControlledModal>
  );
};
