import React from 'react';
import { OutLinedContainer } from '../OutLinedContainer';
import { SubtitleDetail } from '../SubtitleDetail';
import makeStyles from './pagecontenttable.style';

interface ILinkItem {
  text: string;
  notUnderlined?: boolean;
  redirect?: string;
}

export interface IPageContentTable {
  linksList: ILinkItem[];
}

export const PageContentTable = ({ linksList }: IPageContentTable) => {
  const classes = makeStyles();

  return (
    <OutLinedContainer>
      <div className={classes.tableContainer}>
        <ul className={classes.linksWrapper}>
          {linksList.map(({ text, notUnderlined, redirect }, key) => (
            <a href={redirect ? redirect : '#'} key={key}>
              <li className={classes.tableItem}>
                <SubtitleDetail text={text} notUnderlined={notUnderlined} />
              </li>
            </a>
          ))}
        </ul>
      </div>
    </OutLinedContainer>
  );
};
