import React, { useEffect, useState } from 'react';
import { Box, Modal } from '@material-ui/core';
import { DragAndDrop, ProgressBar, SimpleMessage, Text } from 'components';
import Lottie from 'lottie-react';
import Uploading from 'animations/uploading.json';

import useStyles from './ChatbotTraining.styles';
import { FileItem } from './FileItem';

export interface ChatbotTrainingProps {
  files: File[];
  progress: number;
  finished: boolean;
  started: boolean;
  setBuildDisabledHandler(value: boolean): void;
  setFilesHandler(files: File[]): void;
  deleteFileHandler(filename: string): void;
  task: number;
}

export const ChatbotTraining = ({
  files,
  progress,
  finished,
  started,
  task,
  setFilesHandler,
  setBuildDisabledHandler,
  deleteFileHandler,
}: ChatbotTrainingProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const MAX_KB_SIZE = 100 * 1024 * 1024;

  const [error, setError] = useState('');

  const onFileDrop = (incomingFiles: File[]) => {
    const prevFilesSize = files.reduce(
      (acc, { size: cSize }) => acc + cSize,
      0
    );

    const filesSize = incomingFiles.reduce(
      (acc, { size: cSize }) => acc + cSize,
      0
    );

    if (prevFilesSize + filesSize > MAX_KB_SIZE) {
      setError('Maximum knowledge base size is 100MB');
      return;
    }

    setError('');

    setFilesHandler(incomingFiles);
  };

  const onDelete = (fileName: string) => {
    deleteFileHandler(fileName);
  };

  useEffect(() => {
    setBuildDisabledHandler(files.length === 0);
  }, [setBuildDisabledHandler, files]);

  useEffect(() => {
    if (started) {
      setOpen(true);
    }

    // if (finished) {
    //   setOpen(false);
    //   push('/building');
    // }
  }, [started, finished]);

  return (
    <Box>
      <Box mb="24px">
        <DragAndDrop
          onDrop={onFileDrop}
          accept=".pdf, .docx, .doc, .txt"
          task={task}
        />
      </Box>

      {error && (
        <Box className={classes.error}>
          <SimpleMessage type="error" icon={true}>
            {error}
          </SimpleMessage>
        </Box>
        // <Text variant="paragraph1" align="left" className={classes.error}>
        // </Text>
      )}

      <Box display="flex" flexDirection="column" gridGap="4px">
        {files.map(({ name, size, type }, index) => (
          <FileItem
            name={name || ''}
            size={size}
            type={type}
            onDelete={onDelete}
            key={`name-${index}`}
          />
        ))}
      </Box>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        className={classes.uploadingModal}
      >
        <Box className={classes.modalContentWrapper}>
          <Box>
            <Text variant="h3" align="center">
              Uploading documents...
            </Text>
          </Box>

          <Box
            width="100%"
            height="200px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Lottie animationData={Uploading} />
          </Box>
          <ProgressBar progress={progress} />
        </Box>
      </Modal>
    </Box>
  );
};
