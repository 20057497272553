import { makeStyles } from '@material-ui/core';
import { theme } from 'material/theme';

export default makeStyles(() => ({
  title: {},
  subtitle: {
    fontWeight: 700,
    marginBottom: '8px',
  },

  wrapper: {
    backgroundColor: '#fff',
    padding: '32px',
    borderRadius: '16px',
    width: '100%',
    // boxShadow: '0px 8px 16px 0px rgba(72, 79, 121, 0.15)',
    // boxShadow: '0 8px 16px 0 rgba(72, 79, 121, 0.15)',
    boxSizing: 'border-box',
  },

  button: {
    '& .MuiButton-label': {
      fontSize: '12px',
    },
  },

  buttonsContainer: {
    display: 'flex',
    gridGap: '24px',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      '& button': {
        width: '100%',
      },
    },
  },
}));
