import React, { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { experimentLoading } from 'store/experiments';
import { breadcrumbsState } from 'store/breadcrumbs';
import { ViewExperimentHeader } from 'pages/ViewExperiment/ViewExperimentHeader';
import { ViewExperimentBody } from 'pages/ViewExperiment/ViewExperimentBody';
import { MainLayout } from 'components';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { useBreadcrumbInitialPath } from 'components/Breadcrumbs/useBreadcrumbInitialPath';
import { PROJECT_VISIBILITY } from 'types';

export const ViewExperimentHome = ({ experiment }: any) => {
  const loading = useRecoilValue(experimentLoading);
  const setBreadcrumbs = useSetRecoilState(breadcrumbsState);

  useEffect(() => {
    if (experiment) {
      setBreadcrumbs(['My Experiments', experiment.title]);
    }
  }, [setBreadcrumbs, experiment]);

  const { text } = useBreadcrumbInitialPath(experiment);

  const breadcrumbLink = [
    PROJECT_VISIBILITY.PRIVATE,
    PROJECT_VISIBILITY.COMPANY,
  ].includes(experiment?.visibility || PROJECT_VISIBILITY.PRIVATE)
    ? '/projects/dashboard'
    : '/projects/community';

  return (
    <MainLayout>
      {loading && 'Loading'}
      {experiment && (
        <>
          <Breadcrumbs
            paths={[
              { text: text, link: breadcrumbLink },
              {
                text: experiment.title,
                link: `/experiment/${experiment.id}`,
              },
            ]}
          />
          <ViewExperimentHeader />
          <ViewExperimentBody experiment={experiment} />
        </>
      )}
    </MainLayout>
  );
};
