import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  headerSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    paddingTop: '12px',
    top: 0,
    left: 0,
    '& svg': {
      height: '60px',
      '& .cls-1': {
        fill: colors.blue90,
        stroke: 'none',
      },
    },
    marginBottom: '24px',
  },
  subtitle: {
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'bold',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: colors.blue70,
    margin: '0',
  },
  createAccountButton: {
    marginTop: '30px',
  },
  userFormTitle: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.blue70,
    alignSelf: 'flex-start',
  },
  fieldContainer: {
    '& > div': {
      height: '48px',
    },
    marginTop: '12px',
    marginBottom: '12px',
    height: '48px',
  },
  dropdownContainer: {
    marginTop: '12px',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 40px 10px 40px',
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& label': {
      fontFamily: 'Poppins',
      fontSize: '14px',
    },
    // padding: '12px',
  },
  footerSection: {
    height: '88px',
    alignSelf: 'end',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',
  },
  errorMessage: {
    color: colors.errorRed,
    margin: '0',
    fontSize: '10px',
    marginTop: '5px',
    fontFamily: 'Poppins',
    fontWeight: 600,
    backgroundColor: 'rgba(255, 67, 67, 0.1)',
    borderRadius: '2px',
  },
  termsAndConditions: {
    textAlign: 'left',
    fontSize: '12px',
    fontFamily: 'Poppins',
  },
  termsLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  skip: {
    marginTop: '15px',
    fontFamily: 'Poppins',
  },
}));
