import React from 'react';
import { Dialog, Grid, IconButton, Typography } from '@material-ui/core';
import { ActionButton } from 'components/ActionButton';
import { IconClose, StopLogo2 } from 'assets';
import useStyles from './errormessagemodal.styles';

interface Props {
  onConfirm: () => void;
  onCancel: () => void;
  onClose: () => void;
  message: string;
  type: 'dataset' | 'validation';
}

export const ErrorMessageModal = ({
  onClose,
  onConfirm,
  onCancel,
  message,
  type = 'dataset',
}: Props) => {
  const classes = useStyles();
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open
      classes={{
        paper: classes.paper,
      }}
    >
      <IconButton onClick={onClose} className={classes.close}>
        <IconClose />
      </IconButton>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="flex-start"
      >
        <StopLogo2 />
        {type === 'dataset' ? (
          <>
            <Typography className={classes.title} variant="h3" align="center">
              Looks like there was an error
            </Typography>
            <Typography
              className={classes.subtitle}
              variant="body1"
              align="center"
            >
              Datasets might not be created correctly or, if you also uploaded a
              validation set, they might not match. If you need help you can
              learn how to make datasets or download an example.
            </Typography>
            <Typography
              className={classes.subtitle}
              variant="body1"
              align="center"
            >
              <b>{message}</b>
            </Typography>
            <ActionButton
              className={classes.confirmButton}
              size="large"
              color="secondary"
              fullWidth
              variant="contained"
              onClick={onConfirm}
            >
              Return and try again
            </ActionButton>
          </>
        ) : (
          <>
            <Typography className={classes.title} variant="h3" align="center">
              Looks like there was some validation issues
            </Typography>
            <Typography
              className={classes.subtitle}
              variant="body1"
              align="center"
            >
              <b>{message}</b>
            </Typography>
          </>
        )}
      </Grid>
    </Dialog>
  );
};
