import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Collapse, Grid, IconButton } from '@material-ui/core';
import useStyles from './ValidationDataset.styles';
import { SheetIcon } from 'assets';
import { Dropzone, EditCsvColumnModal, StyledTextLink, Text } from 'components';
import { Chevron } from 'components/Icons/Chevron';
import { ExperimentLabel, PROJECT_TASK, Payload } from 'types';
import { useUploadProgress } from 'hooks';
import { DropEvent, FileRejection } from 'react-dropzone';
import { useColumnsForCSV } from '../../hooks';
import { InvalidCsvFileModal } from '../../DefaultTraining/Step4Components';

type UploadFn = <T extends File>(
  acceptedFiles: T[],
  fileRejections: FileRejection[],
  event: DropEvent
) => void;

export interface ValidationDatasetProps {
  task: number;
  setProjectProps(payload: Payload): void;
}

export const ValidationDataset: FC<ValidationDatasetProps> = ({
  task,
  setProjectProps,
}: ValidationDatasetProps) => {
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const acceptedFilesMap: ExperimentLabel = {
    [PROJECT_TASK.CLASSIFIER_TEXT]: '.zip, .csv',
    [PROJECT_TASK.CHATBOT]: '.txt, .zip, .csv, .pdf, .docx, .doc',
  };

  const {
    progress,
    uploadFile,
    fileName,
    isFinished,
    presignedResponse,
  } = useUploadProgress(task);

  const datasetFile = useRef<File>();

  const {
    invalidCsvErrors,
    editValidationCols,
    setUploadingValidationHandler,
    getCsvColNamesOptions,
    setEditValidationCols,
    isValidCsvFile,
    saveCols,
  } = useColumnsForCSV({
    datasetFile: datasetFile.current,
    task,
    uploadValFile: uploadFile,
    uploadFile: () => {},
  });

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const MAX_FILE_SIZE = task === PROJECT_TASK.CHATBOT ? '100MB' : '2GB';

  const onDropValidationDatasetHandler = useCallback(
    async (files: File[]) => {
      const data = await isValidCsvFile(files[0]);
      const cols = await getCsvColNamesOptions(files[0]);

      setUploadingValidationHandler(true);

      setEditValidationCols((s) => ({ ...s, cols }));
      datasetFile.current = files[0];

      if (data?.valid && data.file) {
        setEditValidationCols((s) => ({ ...s, modalOpen: true }));
      }
    },
    [
      isValidCsvFile,
      getCsvColNamesOptions,
      setUploadingValidationHandler,
      setEditValidationCols,
    ]
  );

  const onDropValidationKBFiles: UploadFn = () => {};

  const dropValidationFnMap: { [key: string]: UploadFn } = {
    [PROJECT_TASK.CLASSIFIER_TEXT]: onDropValidationDatasetHandler,
    [PROJECT_TASK.CHATBOT]: onDropValidationKBFiles,
  };

  const closeValidationEditColumnsModal = () => {
    setEditValidationCols((s) => ({
      ...s,
      modalOpen: false,
    }));
  };

  const closeErrorModalHandler = () => {
    setErrorModalOpen(false);
  };

  const openValidationEditColumnsModal = () => {
    setEditValidationCols((s) => ({
      ...s,
      modalOpen: true,
    }));
  };

  useEffect(() => {
    if (isFinished === true && presignedResponse?.filename) {
      setProjectProps({
        validation_url: presignedResponse.filename,
      });
    }
  }, [isFinished, presignedResponse, setProjectProps]);

  useEffect(() => {
    setErrorModalOpen(!!invalidCsvErrors.logs.length);
  }, [invalidCsvErrors, setErrorModalOpen]);

  return (
    <Box className={classes.validationDatasetWrapper}>
      <Box className={classes.section}>
        <Box display="flex" gridGap="8px" alignItems="center">
          <SheetIcon className={classes.icon} />
          <Text variant="paragraph1">Validation Dataset</Text>
          <Text className={classes.defaultText} variant="button">
            Optional
          </Text>
        </Box>

        <Box className={classes.collapseWrapper}>
          <IconButton
            disableRipple
            onClick={handleCollapse}
            style={{ padding: 0 }}
          >
            <Chevron direction={!collapsed ? 'down' : 'up'} />
          </IconButton>
        </Box>
      </Box>
      <Collapse in={collapsed} className={classes.collapsibleContainer}>
        <Box
          display="flex"
          flexDirection="column"
          px="32px"
          pb="32px"
          gridGap="12px"
          mt="24px"
        >
          <Grid item container justify="space-between" alignItems="center">
            <Text className={classes.valdiationTitle} variant="h3">
              Upload a custom validation dataset
            </Text>
            <Text variant="body1" className={classes.optionalTitle}>
              Optional
            </Text>
          </Grid>
          <Dropzone
            onDrop={dropValidationFnMap[task] || uploadFile}
            percent={progress}
            draggingLabel="Drop your file"
            filename={fileName}
            uploadType="validation_url"
            maxFileSize={MAX_FILE_SIZE}
            accept={acceptedFilesMap[task] || '.zip'}
          >
            <Grid container direction="column" justify="center">
              <StyledTextLink isLinkColor={false}>
                <StyledTextLink underlined component={'span'}>
                  Browse your files
                </StyledTextLink>{' '}
                or drag and drop your dataset zip file here{' '}
              </StyledTextLink>
              <Text className={classes.uploadTitle}>
                MAX. UPLOAD {MAX_FILE_SIZE}
              </Text>
            </Grid>
          </Dropzone>
        </Box>
      </Collapse>

      <EditCsvColumnModal
        options={editValidationCols.cols}
        open={editValidationCols.modalOpen}
        closeHandler={closeValidationEditColumnsModal}
        onSaveHandler={saveCols}
      />

      <InvalidCsvFileModal
        logs={invalidCsvErrors.logs}
        open={errorModalOpen}
        closeHandler={closeErrorModalHandler}
        editColumnsHandler={openValidationEditColumnsModal}
      />
    </Box>
  );
};
