import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { Grid, Typography } from '@material-ui/core';
import cls from 'classnames';
import useStyles from './odresultspanel.styles';

import { CategoryStats } from 'components/CategoryStats';
import { ObjectDetectionResponse, PredictResponse } from 'types';
import { countObjectListKey, downloadFile, twoDecimalsFixed } from 'utils';
import { DownloadIcon } from 'assets';
import { json2csv } from 'json-2-csv';
import { useObjectDetectionContext } from '../ObjectDetectionContext';

const FILE_NAME = 'object_response';

type ChecktateProps = { [key: string]: boolean };

type ODResultsProps = {
  prediction: PredictResponse | null;
  onChecksChange(state: ChecktateProps): void;
  defaultChecked?: boolean;
  colors?: { [key: string]: string };
};

export const ResultsPanel = ({
  prediction,
  onChecksChange,
  defaultChecked,
  colors,
}: ODResultsProps) => {
  const classes = useStyles();
  const [selectedObjectsCount, setSelectedObjectsCount] = useState(0);
  const countsRef = useRef({});
  const mappedCounts = useRef(false);

  const {
    categoryChecks,
    setCategoryChecksHandler,
    options: { boxes },
    selectAll,
    selectAllHandler,
  } = useObjectDetectionContext();

  const counts = useMemo(
    () =>
      countObjectListKey(
        (boxes as ObjectDetectionResponse[]) || [],
        'category'
      ),
    [boxes]
  );

  useEffect(() => {
    if (!!Object.keys(counts).length && !mappedCounts.current) {
      countsRef.current = counts;
      mappedCounts.current = true;
    }
  }, [counts]);

  const categoriesCount = Object.keys(counts).reduce(
    (prev, curr) => counts[curr] + prev,
    0
  );

  const changeAllChecksHandler = useCallback(
    (value) => {
      setCategoryChecksHandler(
        Object.keys(countsRef.current).reduce(
          (prev, curr) => ({ ...prev, [curr]: value }),
          {}
        )
      );
    },
    [setCategoryChecksHandler]
  );

  const getCategoryPercentage = (categoryAmount: number): number =>
    +twoDecimalsFixed((categoryAmount / categoriesCount) * 100);

  const onSelectAllChange = (state: ChecktateProps) => {
    const [checked] = Object.values(state);
    onChecksChange({
      selectall: checked,
    });
    selectAllHandler(checked);
    changeAllChecksHandler(checked);
  };

  const onChecksChangeHandler = (state: ChecktateProps) => {
    const [[checkName, checkValue]] = Object.entries(state);

    const checksUpdated = {
      ...categoryChecks,
      [checkName as string]: checkValue,
    };

    setCategoryChecksHandler(checksUpdated);
    onChecksChange(state);
  };

  useEffect(() => {
    selectAllHandler(Object.values(categoryChecks).every((el) => !!el));
  }, [categoryChecks, selectAllHandler]);

  useEffect(() => {
    const count = Object.entries(categoryChecks).reduce(
      (sum, [key, value]) =>
        counts[key] ? sum + (value ? counts[key] : 0) : sum,
      0
    );
    setSelectedObjectsCount(count);
  }, [categoryChecks, counts]);

  const saveResults = () => {
    if (!prediction) return;

    const orderedResult = (
      (prediction.result as ObjectDetectionResponse[]) || []
    ).map((item) => ({
      category: item.category,
      confidence_score: item.confidence_score,
      x_left_top: item.x_left_top,
      y_left_top: item.y_left_top,
      x_right_bottom: item.x_right_bottom,
      y_right_bottom: item.y_right_bottom,
    }));

    json2csv(orderedResult, function (err, csv) {
      downloadFile(new File([csv || ''], `${FILE_NAME}.csv`));
    });
  };

  return (
    <Grid className={classes.wrapper}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.categoriesHeader}
      >
        <Grid>
          <Typography
            className={cls(classes.subtitle, classes.categoriesTitle)}
          >
            Categories
          </Typography>
          <Typography
            variant="body1"
            className={cls(classes.subtitle, classes.categoriesSubtitle)}
          >
            {Object.keys(counts).length} found
          </Typography>
        </Grid>

        <DownloadIcon onClick={saveResults} className={classes.download} />
      </Grid>
      <Grid className={classes.categories}>
        <div className={classes.categoriesAll}>
          <CategoryStats
            label="Select all"
            amount={selectedObjectsCount}
            percentage={getCategoryPercentage(selectedObjectsCount)}
            onCheckChange={onSelectAllChange}
            defaultChecked={selectAll}
            color="#ff6a61"
          />
        </div>
        <div className={classes.categoriesList}>
          {Object.keys(categoryChecks || {}).map(
            (category, idx) =>
              getCategoryPercentage(counts[category]) > 0 && (
                <CategoryStats
                  key={idx}
                  label={category}
                  amount={counts[category]}
                  percentage={getCategoryPercentage(counts[category])}
                  onCheckChange={onChecksChangeHandler}
                  defaultChecked={
                    (categoryChecks as { [key: string]: boolean })[category]
                  }
                  color={colors?.[category]}
                />
              )
          )}
        </div>
      </Grid>
    </Grid>
  );
};
