import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  container: {
    marginTop: 32,
  },
  title: {
    maxWidth: 552,
    marginBottom: 8,
  },
  info: {
    width: '100%',
    maxWidth: 744,
    padding: 24,
    backgroundColor: colors.blue10,
    position: 'relative',
    marginTop: 32,
  },
  button: {
    marginTop: 32,
  },
  img: {
    position: 'absolute',
    left: 0,
    top: -42,
  },
  infoTitle: {
    marginLeft: 40,
  },
  listContainer: {
    textAlign: 'left',
  },
  needHelp: {
    marginTop: 24,
    marginBottom: 32,
  },
  advanceOptionContainer: {
    width: 744,
    padding: 24,
    borderRadius: 8,
    boxShadow: '0 16px 24px 0 rgba(72, 79, 121, 0.15);',
    marginTop: 24,
    backgroundColor: '#ffff',
  },
  titleContainer: {
    //	marginBottom: 24,
  },
  advanceTitleCollpasible: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.blue70,
  },
  advanceSubTitleCollpasible: {
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: colors.blue70,
  },
  collapsibleContainer: {
    width: '100%',
  },
  subTitleHeader: {
    paddingTop: 24,
    paddingBottom: 16,
  },
  itemContainer: {
    padding: 24,
    borderRadius: 8,
    border: `solid 1px ${colors.blue15}`,
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    // maxWidth: '500px',
    '& label .MuiFormControlLabel-label': {
      fontSize: '14px',
      fontFamily: 'Poppins',
      textAlign: 'left',
    },
  },
  field: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  sectionTitle: {
    '& legend': {
      textAlign: 'left',
      fontSize: '14px',
      fontFamily: 'Poppins',
      fontWeight: 'bold',
    },
    // backgroundColor: 'blue',
  },

  actionGroup: {
    paddingTop: 24,
    paddingBottom: 24,
  },
  applyBtn: {
    marginLeft: 24,
  },
  defaultText: {
    backgroundColor: colors.blue10,
    padding: '9px 16px',
    borderRadius: 8,
    marginLeft: 8,
    fontSize: 12,
    color: colors.blue70,
  },

  sectionSubtitle: {
    fontFamily: 'Poppins',
  },
}));
