import React, { useState } from 'react';
import useStyle from './signupform.styles';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { Box, Button, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { signupValidationSchema } from './signupValidationSchema';
import { http } from 'services/http';

import GoogleButton from 'react-google-button';

import { useGoogleLogin } from '@react-oauth/google';
import { googleAuthentication } from 'services';
import { useAuthenticationContext } from 'context';
import {
  HorizontalDivider,
  SimpleMessage,
  SignupFormCard,
  Text,
} from 'components';
import { colors } from 'material/colors';
import classNames from 'classnames';

type SignupValues = {
  email: string;
  username: string;
  password: string;
};

type SignupFormProps = {
  match: any;
};

type ProfileData = {
  email: string;
};

export const SignupForm = ({ match: { path } }: SignupFormProps) => {
  const classes = useStyle();
  const { push } = useHistory();

  const { activateAccount, authenticateUser } = useAuthenticationContext();

  const [error, setError] = useState('');

  const signupSuccessHandler = (
    data: ProfileData,
    to: string,
    from: string
  ) => {
    if ((window as any).tolt_referral) {
      (window as any).tolt?.signup(data.email);
    }

    localStorage.setItem('profile-data', JSON.stringify(data || ''));

    (window as any).lintrk?.('track', { conversion_id: 14304484 });

    push(`${path}/${to}`, { from });
  };

  const onSubmitHandler = ({ email, username, password }: SignupValues) => {
    http
      .post('signup', JSON.stringify({ email, user_name: username, password }))
      .then(() => {
        signupSuccessHandler({ email }, 'verify', 'signup');
      })
      .catch(({ message }: any) => {
        setError(message);
      });
  };

  const continueWithGoogleHandler = useGoogleLogin({
    onSuccess: (response) =>
      googleAuthentication(response)
        .then(({ email, token }: any) => {
          activateAccount?.({ email, token })
            .then(({ access_token: accessToken }) => {
              authenticateUser?.({
                email,
                accessToken,
              });

              signupSuccessHandler({ email }, 'profile', 'third-party');
            })
            .catch((err) => {
              console.log(
                'Something went wrong while activating the user',
                err
              );
            });
        })
        .catch((err) => {
          setError(err.message);
        }),
    onError: (error) => console.log(error),
    flow: 'auth-code',
  });

  return (
    <SignupFormCard>
      <Formik
        validationSchema={signupValidationSchema}
        initialValues={{
          username: '',
          email: '',
          password: '',
        }}
        onSubmit={onSubmitHandler}
      >
        {({ setFieldValue, values }) => (
          <Form className={classes.container}>
            <span className={classes.userFormTitle}>
              Boost your productivity with AI
            </span>

            <Box className={classes.signupwith}>
              <GoogleButton
                style={{
                  width: '100%',
                  boxShadow: 'none',
                  border: `1px solid ${colors.orange}`,
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                type="light"
                onClick={() => continueWithGoogleHandler()}
                label="Sign up with Google"
                disabled={false}
                className={classes.signupButton}
              />
            </Box>

            <HorizontalDivider text="OR" />

            <Field
              className={classNames(classes.emailField, classes.inputField)}
              disabled={false}
              component={TextField}
              name="email"
              label="Email"
              type="email"
              onBlur={() => {
                if (values.username) return;

                const email = values.email || '';
                const [suggested] = email?.split('@');

                if (!suggested) return;

                setFieldValue('username', suggested, true);
              }}
            />
            <Field
              className={classes.inputField}
              component={TextField}
              name="username"
              label="Username"
              type="text"
              disabled={false}
            />

            <Field
              className={classes.inputField}
              component={TextField}
              name="password"
              label="Password"
              type="password"
              disabled={false}
            />
            {error && (
              <Grid className={classes.error}>
                <SimpleMessage type="error" icon={true}>
                  <Text variant="paragraph1">{error}</Text>
                </SimpleMessage>
              </Grid>
            )}
            <p className={classes.termsAndConditions}>
              By proceeding, I agree to Cogniflow{' '}
              <a
                href="https://www.cogniflow.ai/terms"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.termsLink}
              >
                Terms of Services
              </a>{' '}
              and acknowledge the{' '}
              <a
                href="https://www.cogniflow.ai/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.termsLink}
              >
                Privacy Policy
              </a>
            </p>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              type="submit"
              style={{
                marginTop: error ? '0' : '5px',
              }}
            >
              Create account
            </Button>
          </Form>
        )}
      </Formik>
    </SignupFormCard>
  );
};
