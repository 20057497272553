import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  partitionContainer: {
    marginTop: 34,
    marginBottom: 32,
    padding: '0 0 40px',
    borderRadius: '8px 8px 0px 0px',
    backgroundcolor: '#FFF',
  },
  partitionDownload: {
    padding: '32px 24px 32px 40px',
    borderRadius: 8,
    backgroundColor: colors.blue10,
  },
  partitionTitle: {
    marginBottom: 32,
  },
  contentTitle: {
    color: colors.blue70,
  },
  contentType: {
    color: colors.blue70,
  },
  contentItem: {
    borderBottom: `1px solid ${colors.blue15}`,
    height: 48,
  },
  percent: {
    color: colors.blue70,
  },
  total: {
    backgroundColor: colors.blue10,
    padding: '9px 16px',
    borderRadius: 8,
    marginLeft: 8,
    fontSize: 12,
    color: colors.blue70,
  },
  count: {
    backgroundColor: colors.blue10,
    padding: '10px',
    borderRadius: 8,
    fontSize: 12,
    color: colors.blue70,
    margin: 5,
  },
  progressBar: { width: 128, marginLeft: 10 },
  countHeader: { width: 55 },
  countDetail: { width: 200 },
  fileTitle: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.blue70,
  },
  fileSizeTitle: {
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: colors.blue50,
  },
  expireLinkTitle: {
    fontFamily: 'PTSerif',
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    color: colors.blue70,
  },
  contentContainer: {
    padding: '30px 40px 14px 40px',
  },
  infoContainer: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  downloadIcon: {
    color: colors.orange,
  },
}));
