import React, { useState, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Box,
} from '@material-ui/core';
import { CodeEditor } from './CodeEditor';
import { getExperimentCodeList, getUrl } from './codeExported';
import useStyles from './codetab.styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { TextWithClipboard } from 'components/TextWithClipboard/TextWithClipboard';
import { Integrations, Integration } from './Integrations/Integrations';
import { ActionButton, AuthRoute } from 'components';

import { useAuthenticationContext } from 'context';
import { useRecoilValue } from 'recoil';
import { currentModelState } from 'store/models';
import { useQueryParams } from 'hooks';
import { useHistory } from 'react-router-dom';
import { ApiIntegration } from './APIIntegration';
import { IntegrationsWrapper } from './IntegrationsWrapper/IntegrationsWrapper';
import { WhatsappIntegration } from './WhatsappIntegration';
import { Application, Model } from 'types';

type QueryParams = {
  app: string;
};

interface Props {
  type: number;
  task: number;
  application: Application | null;
  model: Model;
}

const INTEGRATION_APPS = {
  WHATSAPP: 'whatsapp',
  COGNI_API: 'api',
};

export const CodeTab = React.memo(
  ({ type, task, application, model }: Props) => {
    const { push } = useHistory();

    const { app } = useQueryParams<QueryParams>();

    const onIntegrationClick = (item: Integration) => {
      push(`?app=${item.param}`);
    };

    const integrationAppsMap = {
      [INTEGRATION_APPS.WHATSAPP]: <WhatsappIntegration modelId={model.id} />,
      [INTEGRATION_APPS.COGNI_API]: <ApiIntegration task={task} type={type} />,
    };

    return (
      <>
        {integrationAppsMap[app] ? (
          <>
            {
              <IntegrationsWrapper>
                {integrationAppsMap[app]}
              </IntegrationsWrapper>
            }
          </>
        ) : (
          <Integrations onClick={onIntegrationClick} task={task} type={type} />
        )}
      </>
    );
  }
);
