import { useState, useRef, useMemo } from 'react';

import { FormikProps } from 'formik';
import * as Yup from 'yup';

import { useAuthenticationContext } from 'context';
import { updateUser } from 'services/user';
import { FetchError } from 'utils';

interface FormProps {
  email: string;
  username?: string;
  receive_newsletter?: boolean;
}

export const useProfile = () => {
  const { user, setUserProp } = useAuthenticationContext();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [profileUpdated, setUpdated] = useState(false);

  const formikRef = useRef<FormikProps<FormProps>>(null);

  const initialValues = useMemo<FormProps>(
    () => ({
      email: user?.email ?? '',
      username: user?.user_name ?? '',
      receive_newsletter: user?.receive_newsletter ?? false,
    }),
    [user]
  );

  const onSubmitHandler = async ({ username, receive_newsletter }: any) => {
    setLoading(true);
    setError('');
    try {
      await updateUser({
        user_name: username,
        receive_newsletter: receive_newsletter,
      });
      setUserProp?.({ user_name: username });
      setLoading(false);
      setUpdated(true);
    } catch (error) {
      setLoading(false);
      if (error instanceof FetchError) setError(error.message);
    }
  };

  const setProfileUpdated = (value: boolean) => {
    setUpdated(value);
  };

  return {
    user,
    initialValues,
    formikRef,
    onSubmitHandler,
    setProfileUpdated,
    profileUpdated,
    isLoading,
    error,
    setError,
  };
};

export const validationSchema = Yup.object().shape({
  email: Yup.string().required('You must specify an email'),
  username: Yup.string().required('You must specify an username').max(254),
});
