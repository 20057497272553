import { Box, Button, Grid, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { Chevron } from 'components/Icons/Chevron';
import { PROJECT_TASK, STATUS_FINISHED } from 'types';
import { useHistory, useParams } from 'react-router-dom';
import useStyle from './detailactionbox.styles';
import { useExperimentContext } from 'context';

interface Props {
  setChevron: Function;
  status: number;
  isOpen: boolean;
  task?: number;
}

export const DetailActionBox = React.memo(
  ({ setChevron, status, isOpen, task }: Props) => {
    const { id: experimentId }: { id: string } = useParams();
    const classes = useStyle();
    const { push } = useHistory();
    const { model: currentModel } = useExperimentContext();

    const handleUseThisModel = useCallback(() => {
      const redirectionTab = task === PROJECT_TASK.CHATBOT ? 'chat' : 'test';

      push(
        `/experiment/${experimentId}/model/${currentModel?.id}/${redirectionTab}`
      );
    }, [push, currentModel, experimentId]);

    return (
      <Grid item className={classes.detailBox}>
        <Grid
          container
          wrap="nowrap"
          justify="flex-end"
          alignItems="center"
          className={classes.detailElements}
        >
          <Typography variant="body1">View model details</Typography>
          <Box m={1} onClick={() => setChevron((state: boolean) => !state)}>
            <Chevron direction={isOpen ? 'up' : 'down'} />
          </Box>
          {status === STATUS_FINISHED ? (
            <Button
              variant="contained"
              size="large"
              color="secondary"
              onClick={handleUseThisModel}
            >
              Use this model!
            </Button>
          ) : null}
        </Grid>
      </Grid>
    );
  }
);
