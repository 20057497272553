import React, { useEffect, useState } from 'react';
import { TaskWrapper } from '../TaskWrapper';
import { OCRImagePanel } from './OCRImagePanel';
import { OCRResultsPanel } from './OCRResultsPanel';
import { PredictResponse, OCRControlData } from 'types';
import { SimpleDialog } from 'components/SimpleDialog';
import useStyles from './ocr.styles';
import { IconClose } from 'assets';
import { useLoadImage } from 'hooks';

import { convertBase64ToFile } from 'services';
import { OCRProvider } from './OCRContext';

type OCRProps = {
  prediction: PredictResponse | null;
  imgUrl: string;
  showJsonTab?: boolean;
};

enum CANVAS_SIZE {
  SM_WIDTH = 300,
  SM_HEIGHT = 300,
  LG_WIDTH = 700,
  LG_HEIGHT = 700,
}

export const OCR = ({ prediction, imgUrl, showJsonTab = true }: OCRProps) => {
  const classes = useStyles();

  const [imageToLoad, setImageToLoad] = useState('');

  const { image } = useLoadImage(imageToLoad);

  const [modalOpen, setModalOpen] = useState(false);

  const [OCRConfig, setOCRConfig] = useState({
    showTextLocation: true,
    text: '',
  });

  const onOCRControlChange = (data: OCRControlData) => {
    setOCRConfig((s) => ({ ...s, ...data }));
  };

  const onMaximize = () => {
    setModalOpen(true);
  };

  const onCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (!imgUrl) return;

    const pdfImg = prediction?.image?.base64 ? prediction?.image : null;

    prediction?.image && prediction?.image?.base64
      ? setImageToLoad(
          window.URL.createObjectURL(convertBase64ToFile(pdfImg?.base64 || ''))
        )
      : setImageToLoad(imgUrl);
  }, [setImageToLoad, imgUrl, prediction]);

  return (
    <OCRProvider>
      <TaskWrapper
        imagePanel={
          prediction &&
          image && (
            <OCRImagePanel
              prediction={prediction}
              image={image}
              onOCRControlChange={onOCRControlChange}
              onMaximize={onMaximize}
              width={CANVAS_SIZE.SM_WIDTH}
              height={CANVAS_SIZE.SM_WIDTH}
              showControls
            />
          )
        }
        resultsPanel={
          <OCRResultsPanel prediction={prediction} OCRConfig={OCRConfig} />
        }
        prediction={prediction}
        showJsonTab={showJsonTab}
      />

      <SimpleDialog
        maxW="xl"
        handleClose={onCloseModal}
        header={
          <IconClose className={classes.closeIcon} onClick={onCloseModal} />
        }
        open={modalOpen}
      >
        {image && (
          <OCRImagePanel
            prediction={prediction}
            image={image}
            onOCRControlChange={onOCRControlChange}
            onMaximize={onMaximize}
            width={CANVAS_SIZE.LG_WIDTH}
            height={CANVAS_SIZE.LG_WIDTH}
            showControls
          />
        )}
      </SimpleDialog>
    </OCRProvider>
  );
};
