import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  bar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: '18px',
    },
  },
  searchInput: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  tags: {
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'left',
      marginLeft: '0',
    },
  },
}));
