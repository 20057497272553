import { Typography } from '@material-ui/core';
import React from 'react';
import makeStyles from './subtitledetail.style';
import cn from 'classnames';

export interface ISubtitleDetailProps {
  text: string;
  notUnderlined?: boolean;
}

export const SubtitleDetail = ({
  text,
  notUnderlined,
  ...props
}: ISubtitleDetailProps) => {
  const classes = makeStyles();

  return (
    <Typography
      {...props}
      align="left"
      className={cn(classes.containerUnderlinedSubtitle, {
        [classes.containerNormalSubtitle]: notUnderlined,
      })}
    >
      {text}
    </Typography>
  );
};
