import React, { FC, useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import useStyles from './PaymentConfirmation.styles';
import { useQueryParams } from 'hooks';
import { confirmPayment } from 'services';

import { useHistory } from 'react-router-dom';
import { useAlertContext, useAuthenticationContext } from 'context';
import { ALERT_TYPES } from 'types';
import { Text, TypingLoader } from 'components';

type QueryProps = {
  session: string;
};

export interface PaymentConfirmationProps {}

export const PaymentConfirmation: FC<PaymentConfirmationProps> = () => {
  const classes = useStyles();

  const { push } = useHistory();

  const { session } = useQueryParams<QueryProps>();

  const { setAlertHandler } = useAlertContext();

  const { setUserProp } = useAuthenticationContext();

  const [failed, setFailed] = useState(false);

  useEffect(() => {
    confirmPayment(session)
      .then((response) => {
        setUserProp?.(response);
        (window as any).lintrk?.('track', { conversion_id: 14509884 });
        setAlertHandler({
          showing: true,
          message: `🎉 Your plan has been successfully upgraded to ${response.commercial_plan.name}`,
          type: ALERT_TYPES.FULLWIDTH_BAR,
          resetTime: 10000,
          severity: 'success',
        });
        push('/');
      })
      .catch(() => {
        setFailed(true);
      });
  }, [session, push, setAlertHandler, setUserProp]);

  const contactUs = () => {
    window.location.href = `mailto:info@cogniflow.ai`;
  };

  return (
    <Grid className={classes.payment}>
      <Box display="flex" flexDirection="column" gridGap="32px">
        {failed ? (
          <Text variant="paragraph1" className={classes.desc}>
            Ups! something went wrong and the payment process could not be
            completed automatically.🙁 <br />
            <br /> Please contact us at{' '}
            <Text variant="link" className={classes.link} onClick={contactUs}>
              support@cogniflow.ai
            </Text>{' '}
            indicating your email (
            <b>the email you used to register your Cogniflow account</b>) and
            once we can confirm your payment we will manually upgrade your plan.
          </Text>
        ) : (
          <>
            <Text variant="h3">Processing payment</Text>
            <TypingLoader />

            <Text variant="paragraph1" className={classes.desc}>
              We are processing your payment, <br /> Please keep this window
              open until we finish.
            </Text>
          </>
        )}
      </Box>
    </Grid>
  );
};
