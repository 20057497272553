import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  container: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 80,
    backgroundColor: colors.blue10,
  },
  content: {
    maxWidth: 1128,
    width: '100%',
  },
  button: {
    marginLeft: 24,
    borderRadius: 8,
    padding: 0,
    width: 180,
  },
  buttonIcon: {
    marginRight: 8,
    marginLeft: 16,
  },
}));
