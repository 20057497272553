import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import {
  OCRResponse,
  PredictResponse,
  OCRControlData,
  OCRBoxProps,
} from 'types';

import {
  drawBoxesResponse,
  rectContainsPoint,
  drawInCanvas,
  clearCanvas,
} from 'utils';
import { Box } from '@material-ui/core';
import { ImageControls } from 'components/ImageControls';
import { useOCRContext } from '../OCRContext';

type OCRImagePanelProps = {
  prediction: PredictResponse | null;
  image: HTMLImageElement;
  onOCRControlChange(data: OCRControlData): void;
  onMaximize(): void;
  width: number;
  height: number;
  showControls?: boolean;
};

type ImgOriginProps = {
  sX: number;
  sY: number;
};

export const OCRImagePanel = ({
  prediction,
  onOCRControlChange,
  image,
  onMaximize,
  width,
  height,
  showControls = false,
}: OCRImagePanelProps) => {
  const canvasEl = useRef<HTMLCanvasElement>(null);

  const { OCROptions, updateOCROptions } = useOCRContext();

  const [imgOrigin, setImgOrigin] = useState<{ sX: number; sY: number } | null>(
    null
  );

  const rectData = useRef<{ text: string; confidence: number }>({
    text: '',
    confidence: 0,
  });

  const staticBoxes = useRef<OCRBoxProps[] | null>(null);

  const setImageOrigin = (sX: number, sY: number) => {
    setImgOrigin({ sX, sY });
  };

  const onMouseMoveHanlder = useCallback(
    (event: MouseEvent) => {
      if (!staticBoxes.current || !canvasEl.current || !imgOrigin) return;

      const cRect = canvasEl.current.getBoundingClientRect();
      const { sX, sY } = imgOrigin;

      const scale = Math.min(
        canvasEl.current.width / image.width,
        canvasEl.current.height / image.height
      );

      const clientX = Math.round(event.clientX - cRect.left);
      const clientY = Math.round(event.clientY - cRect.top);

      const isMouseOutOfImage =
        clientX - sX <= 0 ||
        clientX - sX >= image.width ||
        clientY - sY <= 0 ||
        clientY - sY >= image.height; // Boundaries in canvas (Trigger logic only when mouse is over the image)

      if (isMouseOutOfImage) return;

      staticBoxes.current.forEach(
        ({ x1, y1, x2, y2, text, confidence, mouseOn }, idx: number) => {
          if (!staticBoxes.current) return;

          const w = (x2 - x1) * scale * image.width;
          const h = (y2 - y1) * scale * image.height;
          const x = x1 * image.width * scale + sX;
          const y = y1 * image.height * scale + sY;

          if (
            rectData.current.text !== text &&
            rectData.current.confidence !== confidence &&
            rectContainsPoint({
              rectX: x,
              rectY: y,
              rectW: w,
              rectH: h,
              pointX: clientX,
              pointY: clientY,
            })
          ) {
            onOCRControlChange({
              text,
              confidence: confidence,
            });
            rectData.current.text = text || '';
            rectData.current.confidence = confidence;
            staticBoxes.current[idx].mouseOn = true;
          }

          if (
            mouseOn &&
            !rectContainsPoint({
              rectX: x,
              rectY: y,
              rectW: w,
              rectH: h,
              pointX: clientX,
              pointY: clientY,
            })
          ) {
            onOCRControlChange({
              text: '',
            });
            rectData.current.text = '';
            rectData.current.confidence = 0;
            staticBoxes.current[idx].mouseOn = false;
          }
        }
      );
    },
    [imgOrigin, image, onOCRControlChange]
  );

  const fillCanvasWithResponse = useCallback(
    (imageOnly = false) => {
      if (!canvasEl.current) return;

      clearCanvas(canvasEl.current);

      if (!OCROptions.boxes.length) {
        drawInCanvas(canvasEl.current, image);
        return;
      }

      drawInCanvas(canvasEl.current, image);
      !imageOnly &&
        drawBoxesResponse({
          canvas: canvasEl.current as HTMLCanvasElement,
          image: image,
          boxes: OCROptions.boxes,
          drawLabels: false,
          cb: ({ sX, sY }: ImgOriginProps) => {
            setImageOrigin(sX, sY);
          },
        });
    },
    [image, OCROptions]
  );

  const mappedBoxes = useMemo(
    () =>
      (prediction?.result as OCRResponse[]).map((box) => {
        return {
          x1: box.x_left_top,
          y1: box.y_left_top,
          x2: box.x_right_bottom,
          y2: box.y_right_bottom,
          text: box.text,
          confidence: box.confidence_score,
          color: '#4db253',
          mouseOn: false,
        };
      }),

    [prediction]
  );

  useEffect(() => {
    const canvas = canvasEl.current;

    if (!canvas || !OCROptions.boxes.length || !imgOrigin) return;

    canvas.addEventListener('mousemove', onMouseMoveHanlder);

    return () => {
      canvas?.removeEventListener?.('mousemove', onMouseMoveHanlder);
    };
  }, [OCROptions, imgOrigin, onMouseMoveHanlder]);

  useEffect(() => {
    if (!prediction?.result) return;

    const initialBoxesFiltered = mappedBoxes.filter(
      (box) => box.confidence >= OCROptions.confidence
    );

    updateOCROptions({ boxes: initialBoxesFiltered as OCRBoxProps[] });
    staticBoxes.current = initialBoxesFiltered;

    // eslint-disable-next-line
  }, [prediction, mappedBoxes, updateOCROptions]);

  useEffect(() => {
    fillCanvasWithResponse(!OCROptions.showTextLocation);
  }, [fillCanvasWithResponse, OCROptions]);

  const showTextLocationHandler = (value: boolean) => {
    canvasEl.current && clearCanvas(canvasEl.current);

    fillCanvasWithResponse(!value);
    onOCRControlChange({ showTextLocation: value });
    updateOCROptions({ showTextLocation: value });
  };

  const onSliderChange = (value: number) => {
    const resultFiltered = mappedBoxes.filter((box) => box.confidence >= value);
    staticBoxes.current = resultFiltered;
    updateOCROptions({ boxes: resultFiltered, confidence: value });
  };

  return (
    <Box>
      {showControls && (
        <ImageControls
          showTextLocation={OCROptions.showTextLocation}
          onMaximizeImageHandler={onMaximize}
          showTextLocationHandler={showTextLocationHandler}
          maximizeImage
          slider={OCROptions.showTextLocation ? 'Confidence threshold' : ''}
          OCRConfidence={OCROptions.confidence}
          silderTooltipText="This is the minimum confidence value for a text to display its bounding box"
          onSliderConfidenceChange={onSliderChange}
          // initialSliderValue={INITIAL_CONFIDENCE_THRESHOLD * 100}
        />
      )}
      <canvas ref={canvasEl} width={width} height={height} />
    </Box>
  );
};
