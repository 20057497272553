import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  container: {
    width: '100%',
    maxWidth: 576,
    marginTop: 48,
  },
  field: {
    marginTop: 32,
    backgroundColor: '#ffff',
  },
  form: {
    width: '100%',
    maxWidth: 552,
  },
  button: {
    marginTop: 32,
  },
  imageTask: {
    marginTop: '12px',
  },
}));
