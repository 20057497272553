import React, { useState, ChangeEvent, useEffect } from 'react';
import { Grid, Checkbox, Typography, LinearProgress } from '@material-ui/core';
import useStyles from './categorystats.styles';

type StateProps = {
  [key: string]: boolean;
};

type CategoryStatsProps = {
  label: string;
  percentage: number;
  amount: number;
  onCheckChange(state: StateProps): void;
  defaultChecked?: boolean;
  color?: string;
};

export const CategoryStats = ({
  label,
  amount,
  percentage,
  onCheckChange,
  defaultChecked,
  color,
}: CategoryStatsProps) => {
  const classes = useStyles({
    progressColor: color || '#c2c3c7',
  });
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    setChecked(!!defaultChecked);
  }, [defaultChecked]);

  const onCheckClick = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    onCheckChange({ [name]: checked });
    setChecked(checked);
  };

  return (
    <Grid className={classes.statsWrapper}>
      <Grid className={classes.checkboxLabel}>
        <Checkbox checked={checked} name={label} onChange={onCheckClick} />
        <Typography>{label}</Typography>
      </Grid>

      <Grid className={classes.percentageWrapper}>
        <Grid className={classes.amount}>
          <Typography>{amount}</Typography>
        </Grid>
        <Typography className={classes.percentageNumber}>
          {percentage}%
        </Typography>
        <Grid className={classes.percentage}>
          <LinearProgress
            value={percentage as number}
            variant="determinate"
            className={classes.progress}
          ></LinearProgress>
        </Grid>
      </Grid>
    </Grid>
  );
};
