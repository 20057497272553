import React from 'react';
import { getArraySum, twoDecimalsRound } from 'utils';
import { ConfusionMatrix } from '..';
import { Square } from '../Square';

interface IConfusionMatrixNormalizedProps {
  labels: string[];
  matrix: Array<Array<number>>;
}

export const ConfusionMatrixNormalized = ({
  labels,
  matrix,
}: IConfusionMatrixNormalizedProps) => {
  return (
    <ConfusionMatrix
      matrix={matrix}
      labels={labels}
      renderContent={(row, key, size, styles) => {
        const max = getArraySum(row);
        return (
          <div className={styles} key={key}>
            {row.map((col, idx) => (
              <Square
                key={idx}
                value={twoDecimalsRound(col / max)}
                colorPercentage={twoDecimalsRound(col / max)}
                tooltipText={`Actual ${labels[key]} Prediction ${labels[idx]}`}
                size={size}
              />
            ))}
          </div>
        );
      }}
    />
  );
};
