import React from 'react';
import { Box } from '@material-ui/core';

import useStyles from './FileItem.style';
import { DeleteIcon, DocumentIcon } from 'assets';
import { Text } from 'components';

type FileItemProps = {
  name: string;
  size: number;
  type: string;
  onDelete: (name: string) => void;
};

export const FileItem = ({ name, size, type, onDelete }: FileItemProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.fileItemWrapper}>
      <Box display="flex" alignItems="center" gridGap="6px">
        <DocumentIcon className={classes.docIcon} />
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Text align="left" className={classes.fileName} variant="paragraph1">
            {name}
          </Text>
          <Box display="flex" alignItems="center" gridGap="4px">
            <Text variant="paragraph1" className={classes.fileText}>
              {`${(size / 1024 / 1024).toFixed(2)} MB`} -
            </Text>
            <Text variant="paragraph1" className={classes.fileText}>
              {type}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <DeleteIcon
          className={classes.deleteIcon}
          onClick={() => {
            onDelete(name);
          }}
        />
      </Box>
    </Box>
  );
};
