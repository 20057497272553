import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  messageContainer: {
    padding: '38px',
    borderRadius: 10,
    boxShadow: `0 8px 16px 0 rgba(72, 79, 121, 0.15)`,
    backgroundColor: '#FFFF',
  },
  badge: {
    backgroundColor: colors.blue10,
    padding: '9px 16px',
    borderRadius: 8,
    marginLeft: 8,
    fontSize: 12,
    color: colors.blue70,
  },
  subtitle: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.blue70,
  },
  headerBodyWrapper: {
    flexDirection: 'column',
  },
  scoreContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '12px',
    marginTop: '12px',
  },
  tooltipFont: {
    fontSize: 24,
  },
}));
