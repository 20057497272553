import React from 'react';
import useStyle from './textwithtooltip.style';
import { Tooltip, Typography } from '@material-ui/core';

interface Props {
  text: string;
  description: string;
}
export const TextWithTooltip = ({ text, description }: Props) => {
  const classes = useStyle();

  return (
    <Tooltip title={description}>
      <Typography
        display="inline"
        className={classes.containerUnderlinedSubtitle}
      >
        {text}
      </Typography>
    </Tooltip>
  );
};
