import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import { AudioWave } from '../AudioCapture/AudioWave';
import useStyles from './audio.styles';
import { PredictResponse } from 'types';

interface Props {
  isLoading: boolean;
  prediction: PredictResponse | null;
  audio: string;
}

export const AudioResult = ({ isLoading, prediction, audio = '' }: Props) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" alignItems="center" xs={12}>
      {isLoading && (
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.loadingContainer}
        >
          <CircularProgress color="primary" />
        </Grid>
      )}
      {isLoading || (
        <Grid container xs={12}>
          {prediction && (
            <Grid container xs={12}>
              <AudioWave audioFile={audio} />
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};
