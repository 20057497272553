import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  whatsNew: {
    backgroundColor: 'white',
    borderRadius: '24px',
    boxShadow: '0px 32px 40px 0px rgba(72, 79, 121, 0.20)',
    height: '240px',
    border: '1px solid #D8DDEB',
    boxSizing: 'border-box',
    cursor: 'pointer',

    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      padding: '20px',
      '& > div': {
        padding: '0',
      },
    },

    '&:hover': {
      boxShadow: 'none',
      transition: '0.2s ease-in',
    },
  },

  title: {
    fontWeight: 600,
    fontSize: '16px',
    marginBottom: '8px',
  },
  description: {
    whiteSpace: 'break-spaces',
    lineHeight: '22px',
  },

  cardImages: {
    // padding: '32px 0 10px 0',
    '& img': {
      height: '180px',
      [theme.breakpoints.down('md')]: {
        height: '150px',
      },
    },

    [theme.breakpoints.down('sm')]: {
      // padding: '32px',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  carousel: {
    height: '100%',
    '& ul': {
      height: '100%',
    },
  },
}));
