import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  container: {
    borderRadius: '16px',
    height: '400px',
    width: '448px',
    backgroundColor: 'white',
    position: 'relative',
    boxShadow: '0 8px 16px 0 rgba(72, 79, 121, 0.15)',
  },
  headerElement: {
    top: '0',
    position: 'absolute',
    width: '447px',
    height: '126px',
    backgroundColor: colors.blue10,
    // background:
    // 'linear-gradient(to right, rgba(255, 106, 97, 0.9), rgba(255, 160, 122, 0.9))',
  },
  headerElementContrapart: {
    position: 'absolute',
    clipPath: 'ellipse(81% 100% at 50% 0%)',
    backgroundColor: 'white',
    top: '100px',
    width: '447px',
    height: '126px',
    transform: 'rotate(180deg)',
  },
  content: {
    position: 'relative',
    marginTop: '45px',
  },
  title: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'bold',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: colors.blue70,
    margin: '0',
    padding: '15px 0',
  },
  bodyText: {
    padding: '0 48px',
  },
  link: {
    color: colors.orange,
    fontWeight: 'bold',
  },
}));
