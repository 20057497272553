import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  containerTitle: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'bold',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: colors.blue70,
    margin: '0',
  },
  textContentSpacing: {
    margin: '20px 0',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.blue10,
    '& p': {
      fontSize: '12px',
      fontWeight: 'bold',
    },
  },
  tableRow: {
    display: 'flex',
    '&:nth-last-child(3)': {
      borderBottom: `1px solid ${colors.blue70}`,
    },
    '&:nth-last-child(-n+2) div': {
      backgroundColor: '#f7f9ff',
    },
    '& > div': {
      display: 'flex',
      padding: '12px 16px',
    },
    '& p': {
      fontFamily: 'Poppins',
    },
    '& div': {
      display: 'flex',
      alignItems: 'center',
    },
    '&:nth-child(odd)': {
      backgroundColor: '#f7f9ff',
    },
    '& > div:nth-child(1)': {
      flex: '1 1 45%',
    },
    '& > div:nth-child(2)': {
      flex: '1 1 10%',
    },
    '& > div:nth-child(3)': {
      display: 'flex',
      justifyContent: 'space-between',
      flex: '1 1 45%',
    },
    '& > div:nth-child(3) div': {
      flexBasis: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  lightTitle: {
    '&&': {
      fontWeight: 'normal',
    },
  },
}));
