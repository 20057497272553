import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  algoDetailContainer: {
    borderRadius: '8px',
    border: `solid 1px  ${colors.blue15}`,
    height: '100%',
  },
}));
