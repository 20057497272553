import { atom } from 'recoil';

const initialEditValues = {
  title: '',
  description: {},
};

export const editExperimentState = atom<boolean>({
  key: 'editExperimentState',
  default: false,
});

export const editValuesState = atom({
  key: 'editValuesState',
  default: initialEditValues,
});
