import { Box, Grid, Typography, Tooltip } from '@material-ui/core';
import { HelpIcon } from 'assets';
import React from 'react';
import { PredictResponse } from 'types';
import useStyles from './responsebox.styles';

interface ResponseBoxProps {
  textResponse: PredictResponse;
  renderHeader(): void;
  renderBody(): void;
  closeModalResultHandler?(): void;
  task?: number;
}

export const ResponseBox = ({
  textResponse,
  renderHeader,
  renderBody,
  task,
}: ResponseBoxProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.messageContainer}>
      {textResponse.confidence_score === -1 && (
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.headerBodyWrapper}
        >
          {renderHeader()}
          {renderBody()}
        </Grid>
      )}
      {textResponse.confidence_score !== -1 && (
        <Grid container justify="center" alignItems="center">
          <Grid
            item
            container
            justify="center"
            alignItems="center"
            wrap="nowrap"
            className={classes.headerBodyWrapper}
          >
            {renderHeader()}
            {renderBody()}

            {textResponse.confidence_score && (
              <Grid className={classes.scoreContainer}>
                <Typography variant="body1" className={classes.subtitle}>
                  Score:{' '}
                  {textResponse
                    ? Math.floor(textResponse?.confidence_score * 100) / 100
                    : 0}
                </Typography>
                <Tooltip
                  className={classes.tooltipFont}
                  title="Perdiction Score: A value between 0 and 1. The higher the value the better"
                >
                  <HelpIcon />
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
