import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';
import { ICogniflowTheme } from 'material/theme';

export default makeStyles<ICogniflowTheme>(() => ({
  validationDatasetWrapper: {
    border: `1px solid ${colors.blue10}`,
    padding: '4px 12px',
    borderRadius: '4px',
    boxSizing: 'border-box',
  },

  section: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
  },

  defaultText: {
    backgroundColor: colors.blue10,
    padding: '4px 16px',
    borderRadius: 8,
    marginLeft: 8,
    fontSize: 12,
    color: colors.blue70,
  },

  icon: {
    '& path': {
      fill: colors.blue50,
    },
  },

  editIcon: {
    '& path': {
      fill: colors.orange,
    },
  },

  editIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },

  collapsibleContainer: {},

  valdiationTitle: {
    fontSize: 16,
  },
}));
