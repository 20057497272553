import React from 'react';
import { AuthRoute } from 'components';
import { Switch } from 'react-router-dom';
import { CreateExperimentStep } from './CreateExperimentStep';
import { ExperimentCreationProvider } from 'context';

export const CreateExperiment = ({ match: { path } }: any) => {
  return (
    <ExperimentCreationProvider>
      <Switch>
        <AuthRoute exact path={path}>
          <CreateExperimentStep step={1} />
        </AuthRoute>
        <AuthRoute exact path={`${path}/:type`}>
          <CreateExperimentStep step={2} />
        </AuthRoute>
        <AuthRoute exact path={`${path}/:type/:task`}>
          <CreateExperimentStep step={3} />
        </AuthRoute>
        <AuthRoute path={`${path}/:type/:task?/upload`}>
          <CreateExperimentStep step={4} />
        </AuthRoute>
        <AuthRoute path={`${path}/:type/:task?/ready`}>
          <CreateExperimentStep step={5} />
        </AuthRoute>
      </Switch>
    </ExperimentCreationProvider>
  );
};
