import React, { useMemo, useState } from 'react';
import { TaskWrapper } from '../TaskWrapper';

import { PredictResponse } from 'types';
import { Grid, Box } from '@material-ui/core';
import { ExtractorResult, GenericSimpleModal, Text } from 'components';

import useStyles from './ImageCustomExtractor.styles';
import { GenericModal } from 'components/GenericModal';

type Props = {
  imgUrl: string;
  prediction: PredictResponse | null;
  task: number;
};

export const ImageCustomExtractor = ({ imgUrl, prediction, task }: Props) => {
  const classes = useStyles();

  const wereEntitiesFound = useMemo(() => !!prediction?.result.length, [
    prediction,
  ]);

  const [open, setOpen] = useState(false);

  const image = prediction?.image?.base64
    ? `data:image/png;base64,${prediction?.image?.base64}`
    : imgUrl;

  const openModalHandler = () => {
    setOpen(true);
  };

  const closeModalHandler = () => {
    setOpen(false);
  };

  return (
    <TaskWrapper
      isApplication={false}
      task={task}
      imagePanel={
        <>
          <Box className={classes.imgContainer} onClick={openModalHandler}>
            <img src={image} alt="Result" className={classes.imageCaptured} />
          </Box>
          <GenericSimpleModal
            size="lg"
            isOpen={open}
            closeHandler={closeModalHandler}
          >
            <img src={image} alt="Result" className={classes.imageCaptured} />
          </GenericSimpleModal>
        </>
      }
      resultsPanel={
        <Grid className={classes.badge}>
          <Text variant="paragraph1" className={classes.subtitle}>
            Entities
          </Text>
          {wereEntitiesFound && prediction ? (
            <ExtractorResult response={prediction} />
          ) : (
            'I could not find anything'
          )}
        </Grid>
      }
      prediction={prediction}
    />
  );
};
