import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '410px',
    height: '344px',
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: '16px',
    boxShadow: '0 16px 24px 0 rgba(72, 79, 121, 0.15)',
  },
  subtitle: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.blue70,
    alignSelf: 'center',
    padding: '40px 0 0 0',
  },
}));
