import React from 'react';
import { Text } from 'components';
import { Box, Button } from '@material-ui/core';

import { EntityForm } from '../EntityForm';
import useStyles from './EmptyEntities.styles';

type EntityProps = {
  name: string;
  description: string;
  output_format: string;
  input_format: string;
  color: string;
};

type EmptyEntitiesProps = {
  entityExists(entityName: string): boolean;
  addEntityHandler: (props: EntityProps) => void;
  addFromCsvHandler?: () => void;
  showActions?: boolean;
};

export const EmptyEntities = ({
  entityExists,
  addEntityHandler,
  addFromCsvHandler,
  showActions = true,
}: EmptyEntitiesProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Box mb="32px">
        <Text variant="h3" align="left">
          Entities
        </Text>
      </Box>

      <Box>
        <Text variant="paragraph1" className={classes.subtitle}>
          No entities added
        </Text>
        {showActions && (
          <>
            <Text variant="paragraph1">
              No entities are defined to extract. Add each entity manually or
              from a CSV file.
            </Text>

            <Box mt="24px" className={classes.buttonsContainer}>
              <EntityForm
                entityExists={entityExists}
                addEntityHandler={addEntityHandler}
                openModalElement={
                  <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                  >
                    Add manually
                  </Button>
                }
              />
              <Button
                size="large"
                variant="outlined"
                color="secondary"
                className={classes.button}
                onClick={addFromCsvHandler}
              >
                Add from CSV file
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
