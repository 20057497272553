import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  optionTitle: {
    fontSize: '14px',
  },
  descriptionTitle: {
    fontSize: '12px',
    color: colors.blue20,
    textAlign: 'justify',
  },

  subtitle: {
    fontWeight: 'bold',
  },

  link: {
    '& a': {
      color: colors.orange,
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },

  modelsWrapper: {
    borderBottom: `1px solid ${colors.blue15}`,
    paddingBottom: '32px',
  },

  extractorWrapper: {
    borderRadius: '16px',
    boxShadow: '0px 8px 16px 0px rgba(72, 79, 121, 0.15)',
  },

  defineEntities: {
    margin: '32px 0 16px 0',
  },

  saveButton: {
    padding: '0 50px',
  },
}));
