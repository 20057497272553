import React from 'react';
import { Grid, Typography, Tooltip } from '@material-ui/core';
import { PredictResponse } from 'types';
import { HelpIcon } from 'assets';
import useStyles from './predictmessage.styles';
import { useRecoilValue } from 'recoil';
import { isSpeech2TextTask } from 'store/experiments';
import { ShadedBox } from 'components/ShadedBox';

interface Props {
  prediction: PredictResponse | null;
}

export const PredictMessage = ({ prediction }: Props) => {
  const classes = useStyles();
  const isSpeech2TextResult = useRecoilValue(isSpeech2TextTask);
  const label = isSpeech2TextResult
    ? 'This is what I understand:'
    : 'I think this is:';

  return (
    prediction && (
      <ShadedBox className={classes.box}>
        {prediction?.confidence_score &&
          (prediction.confidence_score === -1 || isSpeech2TextResult) && (
            <>
              <Typography variant="body1" className={classes.subtitle}>
                {label}
              </Typography>
              <Typography variant="button" className={classes.badge}>
                {prediction ? prediction?.result : ' Result'}
              </Typography>
            </>
          )}
        {prediction?.confidence_score && prediction.confidence_score !== -1 && (
          <Grid className={classes.resultLayout}>
            <Grid className={classes.resultWrapper}>
              <Typography variant="body1" className={classes.subtitle}>
                {label}
              </Typography>
              <Typography variant="button" className={classes.badge}>
                {prediction ? prediction?.result : ' Result'}
              </Typography>
            </Grid>

            <Grid className={classes.scoreWrapper}>
              <Typography variant="body1" className={classes.score}>
                Score:{' '}
                {prediction
                  ? Math.floor(prediction?.confidence_score * 100) / 100
                  : 0}
              </Typography>
              <Tooltip
                className={classes.tooltipFont}
                title="Perdiction Score: A value between 0 and 1. The higher the value the better"
              >
                <HelpIcon />
              </Tooltip>
            </Grid>
          </Grid>
        )}

        {prediction?.wer && (
          <Grid className={classes.speech2textBox}>
            {prediction?.result ? (
              <>
                <Typography variant="body1" className={classes.subtitle}>
                  {label}
                </Typography>
                <Typography variant="button" className={classes.badge}>
                  {prediction ? prediction?.result : ' Result'}
                </Typography>
              </>
            ) : (
              <Typography variant="body1">
                I’m sorry, I could not recognize any speech in the audio.
              </Typography>
            )}
          </Grid>
        )}
      </ShadedBox>
    )
  );
};
