import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  audioFileWrapper: {
    border: `1px solid ${colors.blue15}`,
    padding: '12px',
    borderRadius: '4px',
  },

  progressWrapper: {
    width: '100%',
    backgroundColor: colors.blue15,
    borderRadius: '4px',
    height: '6px',
  },

  progress: ({ progress }: { progress: number }) => ({
    backgroundColor: progress === 100 ? colors.greenSuccess : colors.orange,
    borderRadius: '4px',
    height: '6px',
    transition: 'width 0.3s ease-in-out',
    width: `${progress}%`,
  }),

  fileSizeText: {
    fontSize: '12px',
    color: colors.blue50,
  },

  progressNum: {
    fontSize: '12px',
    color: colors.blue50,
    '& p': {
      fontSize: '12px',
      textAlign: 'right',
    },
    marginRight: '6px',
    width: '35px',
  },
}));
