import { makeStyles } from '@material-ui/core';
import { colors } from 'material';

export default makeStyles((theme) => ({
  projectsAmount: {
    color: colors.orange,
    fontSize: '36px',
    fontWeight: 300,
  },

  projectsText: {
    fontSize: '16px',
    fontWeight: 700,
  },

  container: {
    [theme.breakpoints.down('sm')]: {},
  },

  informativeSectionWrapper: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '18px',
      '& > div': {
        width: '100%',
      },
    },
  },
}));
