import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  container: {
    marginTop: 32,
  },
  title: {
    maxWidth: 552,
    marginBottom: 8,
  },
  info: {
    width: '100%',
    maxWidth: 744,
    padding: 24,
    backgroundColor: colors.blue10,
    position: 'relative',
    marginTop: 32,
  },
  img: {
    position: 'absolute',
    left: 0,
    top: -42,
  },
  infoTitle: {
    marginLeft: 40,
  },
  listContainer: {
    textAlign: 'left',
  },
}));
