import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Collapse, Grid, Typography, IconButton } from '@material-ui/core';
import {
  Dropzone,
  EditCsvColumnModal,
  ActionButton,
  StyledTextLink,
} from 'components';

import { DropEvent, FileRejection } from 'react-dropzone';
import { Chevron } from 'components/Icons/Chevron';
import { InvalidCsvFileModal, Helpers } from '../../Step4Components';
import { useHistory } from 'react-router-dom';
import { useUploadProgress } from 'hooks';
import {
  ExperimentLabel,
  EXPERIMENT_TASK,
  ExperimentValues,
  Payload,
} from 'types';
import { getFilename } from 'services/files';

import { useCSVColumns } from '../../hooks';

import useStyles from './DefaultStep.styles';
import { StepTaskComponent } from '../StepTaskComponent';
import { zipFiles } from 'utils';

type UploadFn = <T extends File>(
  acceptedFiles: T[],
  fileRejections: FileRejection[],
  event: DropEvent
) => void;

const acceptedFilesMap: ExperimentLabel = {
  [EXPERIMENT_TASK.TEXT_CLASSIFICATION]: '.zip, .csv',
  [EXPERIMENT_TASK.TEXT_QANDA]: '.txt, .zip, .csv, .pdf, .docx, .doc',
};

type DefaultStepProps = {
  type: string;
  task: string;
  expValues: ExperimentValues;
  showAdvancedOptions?: boolean;
  setExpProps(payload: Payload): void;
};

export const DefaultStep = ({
  type,
  task,
  expValues,
  showAdvancedOptions = true,
  setExpProps,
}: DefaultStepProps) => {
  const [csvCols, setCsvCols] = useState<string[]>([]);
  const [collapseStatus, setCollapseStatus] = useState<boolean>(false);
  const { push } = useHistory();
  const datasetFile = useRef<File>();

  const classes = useStyles();

  const MAX_FILE_SIZE =
    parseInt(task) === EXPERIMENT_TASK.TEXT_QANDA ? '100MB' : '2GB';

  useEffect(() => {
    if (expValues.validation_url) {
      setCollapseStatus(true);
    }
  }, [expValues.validation_url]);
  // disable eslint until figuring out how to handle error
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    progress,
    uploadFile,
    presignedResponse,
    // error,
    fileName,
    isFinished,
    isStarted,
  } = useUploadProgress(task);

  const {
    progress: progresVal,
    uploadFile: uploadValFile,
    presignedResponse: presignedResponseVal,
    isFinished: isFinishedVal,
    fileName: validationFileName,
    // eslint-disable-next-line
    error: errorVal,
  } = useUploadProgress(task);

  const {
    invalidCsvErrors,
    openEditColsModal,
    editValidationCols,
    uploadingValidation,
    setUploadingValidationHandler,
    setOpenEditColsModalHandler,
    getCsvColNamesOptions,
    setEditValidationCols,
    isValidCsvFile,
    saveCols,
  } = useCSVColumns({
    datasetFile: datasetFile.current,
    task,
    uploadValFile,
    uploadFile,
  });

  useEffect(() => {
    if (isFinished === true && presignedResponse?.filename) {
      setExpProps({
        train_url: presignedResponse.filename,
      });
    }
    if (isStarted) {
      setExpProps({
        train_url: '',
      });
    }
  }, [progress, presignedResponse, setExpProps, isFinished, isStarted]);

  useEffect(() => {
    if (isFinishedVal === true && presignedResponseVal?.filename) {
      setExpProps({
        validation_url: presignedResponseVal.filename,
      });
    }
  }, [progresVal, presignedResponseVal, setExpProps, isFinishedVal]);

  const handleSubmit = useCallback(async () => {
    push(`/create/${type}/${task}/ready`);
  }, [type, push, task]);

  const toggleCollapse = useCallback(
    () => setCollapseStatus((collapseStatus) => !collapseStatus),
    [setCollapseStatus]
  );

  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const closeErrorModalHandler = () => {
    setErrorModalOpen(false);
  };

  useEffect(() => {
    setErrorModalOpen(!!invalidCsvErrors.logs.length);
  }, [invalidCsvErrors, setErrorModalOpen]);

  const onDropDatasetHandler = useCallback(
    async (files: File[]) => {
      const data = await isValidCsvFile(files[0]);
      const csvCols = await getCsvColNamesOptions(files[0]);

      setUploadingValidationHandler(false);

      setCsvCols(csvCols);
      datasetFile.current = files[0];

      if (data?.valid && data.file) {
        setOpenEditColsModalHandler(true);
      }
    },
    [
      isValidCsvFile,
      getCsvColNamesOptions,
      setOpenEditColsModalHandler,
      setUploadingValidationHandler,
    ]
  );

  const onDropValidationDatasetHandler = useCallback(
    async (files: File[]) => {
      const data = await isValidCsvFile(files[0]);
      const cols = await getCsvColNamesOptions(files[0]);

      setUploadingValidationHandler(true);

      setEditValidationCols((s) => ({ ...s, cols }));
      datasetFile.current = files[0];

      if (data?.valid && data.file) {
        setEditValidationCols((s) => ({ ...s, modalOpen: true }));
      }
    },
    [
      isValidCsvFile,
      getCsvColNamesOptions,
      setUploadingValidationHandler,
      setEditValidationCols,
    ]
  );

  const onDropKBFiles = useCallback(
    async (files: File[]) => {
      if (files[0].name.split('.')[1] === 'zip') {
        uploadFile(files);
        return;
      }

      const zip = await zipFiles(files, 'kbs.zip');

      uploadFile([zip]);
    },
    [uploadFile]
  );

  const onDropValidationKBFiles = useCallback(
    async (files: File[]) => {
      if (files[0].name.split('.')[1] === 'zip') {
        uploadValFile(files);
        return;
      }

      const zip = await zipFiles(files, 'validation_kbs.zip');

      uploadValFile([zip]);
    },
    [uploadValFile]
  );

  const openEditColumnsModal = () => {
    setOpenEditColsModalHandler(true);
  };

  const closeEditColumnsModal = () => {
    setOpenEditColsModalHandler(false);
  };

  const openValidationEditColumnsModal = () => {
    setEditValidationCols((s) => ({
      ...s,
      modalOpen: true,
    }));
  };

  const closeValidationEditColumnsModal = () => {
    setEditValidationCols((s) => ({
      ...s,
      modalOpen: false,
    }));
  };

  const dropFnMap: { [key: string]: UploadFn } = {
    [EXPERIMENT_TASK.TEXT_CLASSIFICATION]: onDropDatasetHandler,
    [EXPERIMENT_TASK.TEXT_QANDA]: onDropKBFiles,
  };

  const dropValidationFnMap: { [key: string]: UploadFn } = {
    [EXPERIMENT_TASK.TEXT_CLASSIFICATION]: onDropValidationDatasetHandler,
    [EXPERIMENT_TASK.TEXT_QANDA]: onDropValidationKBFiles,
  };

  return (
    <StepTaskComponent task={task} type={type}>
      <Helpers task={task} type={type} />

      <Grid
        container
        direction="column"
        alignItems="center"
        justify="flex-start"
        className={classes.dropzoneContainer}
      >
        <Dropzone
          onDrop={dropFnMap[task] || uploadFile}
          percent={progress}
          draggingLabel="Drop your file"
          filename={fileName || getFilename(presignedResponse?.filename || '')}
          uploadType="train_url"
          accept={acceptedFilesMap[task] || '.zip'}
          maxFileSize={MAX_FILE_SIZE}
        >
          <Grid container direction="column" justify="center">
            <StyledTextLink isLinkColor={false}>
              <StyledTextLink underlined component={'span'}>
                Browse your files
              </StyledTextLink>{' '}
              or drag and drop your dataset zip file here{' '}
            </StyledTextLink>
            <Typography className={classes.uploadTitle}>
              MAX. UPLOAD {MAX_FILE_SIZE}
            </Typography>
          </Grid>
        </Dropzone>
      </Grid>

      {showAdvancedOptions && (
        <Grid
          container
          direction="column"
          className={classes.advanceOptionContainer}
          alignItems="center"
          justify="flex-start"
        >
          <Grid
            item
            container
            justify="space-between"
            className={classes.titleContainer}
            alignItems="center"
          >
            <Typography
              variant="body1"
              className={classes.advanceTitleCollpasible}
            >
              Advanced Options
            </Typography>
            <IconButton disableRipple onClick={toggleCollapse}>
              <Chevron direction={!collapseStatus ? 'down' : 'up'} />
            </IconButton>
          </Grid>
          <Collapse
            in={collapseStatus}
            className={classes.collapsibleContainer}
          >
            <Grid item container justify="space-between">
              <Typography className={classes.valdiationTitle} variant="h3">
                Upload a custom validation dataset
              </Typography>
              <Typography variant="body1" className={classes.optionalTitle}>
                Optional
              </Typography>
            </Grid>
            <Dropzone
              onDrop={dropValidationFnMap[task] || uploadValFile}
              percent={progresVal}
              draggingLabel="Drop your file"
              filename={validationFileName}
              uploadType="validation_url"
              maxFileSize={MAX_FILE_SIZE}
            >
              <Grid container direction="column" justify="center">
                <StyledTextLink isLinkColor={false}>
                  <StyledTextLink underlined component={'span'}>
                    Browse your files
                  </StyledTextLink>{' '}
                  or drag and drop your dataset zip file here{' '}
                </StyledTextLink>
                <Typography className={classes.uploadTitle}>
                  MAX. UPLOAD {MAX_FILE_SIZE}
                </Typography>
              </Grid>
            </Dropzone>
          </Collapse>
        </Grid>
      )}

      <EditCsvColumnModal
        options={csvCols}
        open={openEditColsModal}
        closeHandler={closeEditColumnsModal}
        onSaveHandler={saveCols}
      />

      <EditCsvColumnModal
        options={editValidationCols.cols}
        open={editValidationCols.modalOpen}
        closeHandler={closeValidationEditColumnsModal}
        onSaveHandler={saveCols}
      />

      <InvalidCsvFileModal
        logs={invalidCsvErrors.logs}
        open={errorModalOpen}
        closeHandler={closeErrorModalHandler}
        editColumnsHandler={
          uploadingValidation
            ? openValidationEditColumnsModal
            : openEditColumnsModal
        }
      />

      <ActionButton
        size="large"
        className={classes.button}
        variant="contained"
        color="secondary"
        disabled={(!isStarted && !expValues.train_url) || !expValues.train_url}
        onClick={handleSubmit}
      >
        Next step
      </ActionButton>
    </StepTaskComponent>
  );
};
