import React, { useState, useRef } from 'react';
import useStyles from './DragDropContainer.styles';

interface DragDropContainerProps {
  onDrop(files: FileList): void;
  DraggingElement?: React.FC; // Define prop type for DraggingElement as a functional component
  children?: React.ReactNode; // Allow children as ReactNode type
  className?: string; // Optional className prop
  style?: React.CSSProperties; // Optional style prop
}

const DragDropContainer = ({
  DraggingElement,
  onDrop,
  className,
  style,
  children,
}: DragDropContainerProps) => {
  const classes = useStyles();
  const [dragging, setDragging] = useState(false);
  const dropZoneRef = useRef<HTMLDivElement>(null);

  const handleDragOver = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragEnter = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    setDragging(false);

    const files = e.dataTransfer.files;
    onDrop?.(files);
  };

  return (
    <div
      ref={dropZoneRef}
      className={`${classes.root} ${className}`}
      style={style}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {dragging && DraggingElement && <DraggingElement />}
      {children}
    </div>
  );
};

export default DragDropContainer;
