import React, { useRef, useState } from 'react';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { FormikProps } from 'formik';
import * as Yup from 'yup';

import { Box, Button, Snackbar, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import MuiAlert from '@material-ui/lab/Alert';

import { changePassword } from 'services/auth';
import { FetchError } from 'utils';

import useStyle from './change-password.styles';

interface FormProps {
  newPassword: string;
  confirmNewPassword: string;
}

export const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('You must specify a password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Must contain at least 8 characters including  upper/lowercase letters and numbers'
    )
    .max(254),
  confirmNewPassword: Yup.string()
    .required('You must specify a password')
    .oneOf([Yup.ref('newPassword')], 'Password must match')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Must contain at least 8 characters including  upper/lowercase letters and numbers'
    )
    .max(254),
});

export const ChangePassword = () => {
  const classes = useStyle();
  const formikRef = useRef<FormikProps<FormProps>>(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const onSubmitHandler = async (
    { newPassword }: FormProps,
    { resetForm }: any
  ) => {
    setLoading(true);
    setSuccess(false);
    setError('');
    try {
      await changePassword({ password: newPassword });
      setLoading(false);
      setSuccess(true);
      resetForm();
    } catch (error) {
      setLoading(false);
      if (error instanceof FetchError) setError(error.message);
    }
  };

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          newPassword: '',
          confirmNewPassword: '',
        }}
        onSubmit={onSubmitHandler}
        innerRef={formikRef}
      >
        {({ values, setFieldValue }) => (
          <Form className={classes.form}>
            <Typography variant="h4" align="left">
              Password
            </Typography>

            <Field
              component={TextField}
              name="newPassword"
              label="Enter new password"
              type="password"
              variant="outlined"
              disabled={isLoading}
            />
            <Field
              component={TextField}
              name="confirmNewPassword"
              label="Re-type new password"
              type="password"
              variant="outlined"
              disabled={isLoading}
            />

            {error && (
              <Alert onClose={() => setError('')} severity="error">
                {error}
              </Alert>
            )}

            <Box>
              <Button
                variant="outlined"
                size="large"
                type="submit"
                disabled={
                  isLoading || Object.values(values).some((val) => val === '')
                }
              >
                Change password
              </Button>
            </Box>
          </Form>
        )}
      </Formik>

      <Snackbar
        open={success}
        onClose={() => setSuccess(false)}
        autoHideDuration={3000}
      >
        <MuiAlert severity={'success'} variant="filled">
          Password changed successfully
        </MuiAlert>
      </Snackbar>
    </>
  );
};
