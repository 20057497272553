import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px',
  },
  userFormTitle: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.blue70,
    alignSelf: 'flex-start',
  },
  fieldContainer: {
    marginTop: '12px',
    marginBottom: '12px',
  },
  forgotPasswordLink: {
    fontFamily: 'PTSerif',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: colors.orange,
    alignSelf: 'flex-end',
    marginBottom: '24px',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  filledError: {
    backgroundColor: `${colors.orange}!important`,
    fontWeight: 'bold!important' as 'bold',
    color: '#fff',
    fontFamily: 'Poppins',
    fontSize: 12,
  },
}));
