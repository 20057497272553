import React, { useEffect, useState, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Grid, Typography, Container, Box } from '@material-ui/core';
import { TestDropzone } from 'components';
import { useSetRecoilState } from 'recoil';
import { AudioDrag } from 'assets';
import { AudioCapture } from 'components/AudioCapture';
import { AudioResult } from 'components/AudioResult';
import { codeAudioState } from 'store/models';
import { predictAudio } from 'services/predict';
import {
  AudioRequest,
  EXPERIMENT_TASK,
  Experiment,
  ExperimentTest,
} from 'types';
import { Alert } from '@material-ui/lab';
import {
  useAuthenticationContext,
  usePredictionContext,
  useUserPlanContext,
  useApplicationContext,
} from 'context';
import { FetchError } from 'utils/errors';
import { AudioClassification } from './AudioClassification';
import { toBase64File } from 'services/models';

import { Text } from 'components';
import { useHistory } from 'react-router-dom';

import useStyles from './audiotest.styles';

interface Props extends ExperimentTest {
  modelId: string;
  experiment?: Experiment;
  task: number;
  title?: string;
}

export const AudioTest = React.memo(
  ({
    modelId,
    experiment,
    task,
    fileInstruction,
    title,
    subtitle = null,
    submitText = null,
    application,
    isApplication = false,
  }: Props) => {
    const { application: applicationState } = useApplicationContext();
    const classes = useStyles({ ...applicationState?.config });
    const { user, userApiKey } = useAuthenticationContext();
    const {
      openPlanPredictionExceededModal,
      validatePredictingLimitReached,
    } = useUserPlanContext();
    const setCodeAudioParam = useSetRecoilState(codeAudioState);

    const {
      setShowAudioResultHandler: setShowResult,
      showAudioResult: showResult,
      audioPredResp,
      setAudioPredRespHandler: setAudioPredResp,
      loading: isLoading,
      setLoadingHandler: setIsLoading,
      audioRecorded,
      setAudioRecordedHandler: setAudioRecorded,
    } = usePredictionContext();

    const [error, setError] = useState('');

    const audioResponseData: any = useMemo(
      () => ({
        audio: audioRecorded?.file || '',
        isLoading,
        prediction: audioPredResp,
      }),
      [audioRecorded, audioPredResp, isLoading]
    );
    const resultIsReady = useMemo(() => showResult && !isLoading, [
      showResult,
      isLoading,
    ]);
    const webhookUrl = useMemo(() => application?.config?.webhookUrl, [
      application,
    ]);

    const { push } = useHistory();

    useEffect(() => {
      if (audioPredResp) return;

      const getPredictionResult = async () => {
        try {
          if (!isApplication && (await validatePredictingLimitReached())) {
            openPlanPredictionExceededModal(isApplication);
            return;
          }

          if (!audioRecorded) return;

          const format = 'ogg';
          const audio = audioRecorded?.base64encode;
          const payload: AudioRequest = {
            format: format,
            base64_audio: audio || '',
          };
          setShowResult(true);
          setIsLoading(true);

          const response = await predictAudio(modelId, payload, task, {
            'x-api-key': isApplication ? '' : userApiKey,
          });

          setAudioPredResp(response);
          setTimeout(() => {
            setIsLoading(false);
          }, 800);
          setError('');

          setCodeAudioParam({
            modelId: modelId || '',
            base64: audio || 'PasteBase64CodeHere',
            format: format,
          });
          setShowResult(true);

          // Send the result to the webhook
          if (isApplication && webhookUrl)
            fetch(webhookUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ response, payload }),
            }).catch(() => {});
        } catch (error) {
          if (isApplication) {
            setError(
              'The model is not currently available. Please reach out using a different channel or check back later. Thanks.'
            );
          } else {
            switch ((error as FetchError).statusCode) {
              case 401:
                openPlanPredictionExceededModal(isApplication);
                break;
              default:
                if (!(error as FetchError).statusCode) {
                  setError(
                    'Ups! Something went wrong 😔 please try again in a few minutes'
                  );
                  break;
                }
                setError((error as FetchError).message);
                break;
            }
          }
          setShowResult(false);
        }
      };

      getPredictionResult();
      // eslint-disable-next-line
    }, [
      audioRecorded,
      setIsLoading,
      setCodeAudioParam,
      modelId,
      setAudioRecorded,
      user,
      userApiKey,
      task,
      validatePredictingLimitReached,
      openPlanPredictionExceededModal,
      webhookUrl,
      isApplication,
      setAudioPredResp,
      setShowResult,
    ]);

    const uploadFile = useCallback(
      async (files: File[]) => {
        setAudioPredResp(null);
        try {
          const [file] = files;

          const format = file.type.split('/')[1];
          const urlObj = URL.createObjectURL(file);
          const base64file = (await toBase64File(file)) as string;
          const base64stripped = base64file.split(',')[1];

          setAudioRecorded({
            base64encode: base64stripped,
            file: urlObj,
          });
          setCodeAudioParam({
            modelId: modelId,
            base64: base64stripped,
            format,
          });
        } catch {
          setError(
            'Ups! Something went wrong 😔 please try again in a few minutes'
          );
        }
      },
      [modelId, setAudioRecorded, setCodeAudioParam, setAudioPredResp]
    );

    const onRecordClickHandler = () => {
      setError('');
    };

    const goToLongTranscriptionDocs = () => {
      push('long-transcription');
      // openLink({ url: LONG_TRANSCRIPTION_DOCS, newTab: true });
    };

    return (
      <>
        {task === EXPERIMENT_TASK.AUDIO_SPEECH2TEXT && !isApplication && (
          <Text variant="paragraph1" className={classes.longTranscriptionText}>
            Try our new{' '}
            <span
              className={classes.longTranscriptionLink}
              onClick={goToLongTranscriptionDocs}
            >
              long transcription feature
            </span>
          </Text>
          // <Text variant="paragraph1" className={classes.longTranscriptionText}>
          //   Do you want to transcribe long audios? Read our{' '}
          //   <span
          //     className={classes.longTranscriptionLink}
          //     onClick={goToLongTranscriptionDocs}
          //   >
          //     tutorial
          //   </span>{' '}
          //   for long audio transcriptions.
          // </Text>
        )}

        <Container
          maxWidth={resultIsReady ? 'md' : 'sm'}
          className={classes.testWrapper}
          style={{
            gridTemplateColumns: resultIsReady ? 'repeat(2, 1fr)' : '1fr',
          }}
        >
          <Box className={classNames(classes.inputWrapper, classes.card)}>
            <Typography className={classes.title}>
              {title ?? 'Test this model'}
            </Typography>
            <Typography variant="body1" className={classes.subtitle}>
              {subtitle ? (
                <>{subtitle}</>
              ) : (
                <>
                  <Typography variant="inherit" className={classes.underlined}>
                    <label
                      htmlFor="dropZoneInput"
                      className={classes.cursorPointer}
                    >
                      Browse your files
                    </label>
                  </Typography>{' '}
                  {fileInstruction ??
                    'or drag and drop an audio file to test this model in real-time.'}
                </>
              )}
            </Typography>

            <Box
              style={{
                display: resultIsReady ? 'none' : 'initial',
                width: '100%',
              }}
            >
              <TestDropzone
                dropzoneOptions={{ accept: 'audio/*,video/ogg' }}
                onDrop={uploadFile}
                percent={100}
                loading={isLoading}
                customClass={classes.background}
              >
                <Grid>
                  <AudioDrag />
                  <Typography variant="body1" className={classes.dragDropTitle}>
                    Drag and drop here
                  </Typography>
                  {experiment?.config.pre_trained_score_value && (
                    <Typography
                      variant="body1"
                      className={classes.dragDropWarn}
                    >
                      Only the first 30 seconds of the audio file will be
                      transcribed
                    </Typography>
                  )}
                </Grid>
              </TestDropzone>
            </Box>

            {resultIsReady && (
              <Box className={classes.messageContainer}>
                <AudioResult
                  audio={audioResponseData.audio}
                  prediction={audioResponseData.prediction}
                  isLoading={false}
                />
              </Box>
            )}

            <AudioCapture
              onRecordClick={onRecordClickHandler}
              submitText={submitText}
              colorButton={application?.config?.colorButton}
            />

            {error && (
              <Alert
                severity="error"
                onClose={() => onRecordClickHandler()}
                className={classes.alertWrapper}
              >
                {error}
              </Alert>
            )}
          </Box>

          {resultIsReady && (
            <Box className={classes.card}>
              <AudioClassification
                audioResponseData={audioResponseData}
                showJsonTab={!isApplication}
              />
            </Box>
          )}
        </Container>
      </>
    );
  }
);
