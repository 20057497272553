import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  modelMetricsViewWrapper: {
    flexWrap: 'nowrap',
    gap: '20px',
  },
  matrixType: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    justifyContent: 'flex-end',
  },
  flex: {
    display: 'flex',
  },
  pageContent: {},
  modelDetailsTitle: {
    marginTop: '64px',
    marginBottom: '24px',
  },
  modelDetails: {
    flex: '1 1 70%',
  },
  contentTable: {
    flex: '1 1 30%',
  },
  detailsAndTableContent: {
    display: 'flex',
    gap: '24px',
  },
  containerTitle: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'bold',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: colors.blue70,
    margin: '0',
  },
  detailsAndContentBoxWrapper: {},
  contentSection: {
    margin: '40px 0',
    '&:nth-child(1)': {
      marginTop: '24px',
    },
  },
  textContentSpacing: {
    margin: '20px 0',
  },
}));
