import {
  CircularProgress,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import {
  EXPERIMENT_TYPE_IMAGE,
  EXPERIMENT_TYPE_TEXT,
  EXPERIMENT_TYPE_AUDIO,
  AdvancedConfig,
  TextConfig,
  ImageConfig,
  AudioConfig,
  EXPERIMENT_TASK,
  PROJECT_TYPE,
} from 'types';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ActionButton } from 'components/ActionButton';
import { createExperiment } from 'services/experiments';

import { AccordionAdvancedConfig } from './AccordionConfig';
import { ErrorMessageModal } from './ErrorMessageModal';
import useStyles from './step5.styles';
import { useAuthenticationContext, useExpCreationContext } from 'context';
import { algo_mla_state } from './AccordionConfig/state';
import {
  audioInitialConfig,
  imageInitialConfig,
  textInitialConfig,
} from 'core';
import { me } from 'services';

interface Params {
  type: 'text' | 'image' | 'audio';
  task: string;
}

const extractorTasks = [
  EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR,
  EXPERIMENT_TASK.IMAGE_CUSTOM_ENTITY_EXTRACTOR,
];

const initialConfig: { [key: string]: AdvancedConfig } = {
  [PROJECT_TYPE.TEXT]: textInitialConfig as TextConfig,
  [PROJECT_TYPE.IMAGE]: imageInitialConfig as ImageConfig,
  [PROJECT_TYPE.AUDIO]: audioInitialConfig as AudioConfig,
};

export const Step5 = memo(() => {
  const classes = useStyles();
  const { user } = useAuthenticationContext();
  const { type, task } = useParams<Params>();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [modalType, setModalType] = useState<
    'dataset' | 'validation' | 'openai_api_key'
  >('dataset');
  const [sendEmail, setSendEmail] = useState(true);
  const [config, setConfig] = useState<AdvancedConfig>(initialConfig[type]);
  const algoMlaState = useRecoilValue(algo_mla_state);

  const taskNum = parseInt(task);

  /*
  // disable eslint until finishing set settings feature
  // eslint-disable-next-line @typescript-eslint/no-unused-vars*/

  const setConfigHandler = useCallback((values: AdvancedConfig) => {
    setConfig(values);
  }, []);

  useEffect(() => {
    if (message !== '') {
      setOpen(true);
    }
  }, [message, setOpen]);

  const configAccesor = useCallback(
    (config: any, key: string) => config[key] === true,
    []
  );

  const isVectorizer = useCallback((key: string, type: string) => {
    const mustContain =
      type === 'audio'
        ? ['vectorization_', 'cnn_']
        : type === 'image'
        ? ['cnn_']
        : ['vectorization_'];
    if (type === 'audio') {
      return key.includes(mustContain[0]) || key.includes(mustContain[1]);
    }
    return key.includes(mustContain[0]);
  }, []);

  const isClasifier = useCallback((key: string) => {
    let mustContain = ['algo_'];
    return key.includes(mustContain[0]);
  }, []);

  const isValidVectorizer = useCallback(
    (config: any, type: string) => {
      return Object.keys(config).some(
        (e) => isVectorizer(e, type) && configAccesor(config, e)
      );
    },
    [isVectorizer, configAccesor]
  );

  const isValidClasifier = useCallback(
    (config: any, type: string) => {
      const totalOfClassifierSelected = Object.keys(config).filter(
        (e) => isClasifier(e) && configAccesor(config, e)
      ).length;
      if (type === 'audio') {
        if (totalOfClassifierSelected > 0) {
          if (
            totalOfClassifierSelected === 1 &&
            config['algo_mla'] &&
            algoMlaState
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      }

      return Object.keys(config).some(
        (e) => isClasifier(e) && configAccesor(config, e)
      );
    },
    [isClasifier, configAccesor, algoMlaState]
  );

  const { expValues } = useExpCreationContext();

  const isValidSelection = useCallback(
    () =>
      isValidClasifier({ ...config }, type) &&
      isValidVectorizer({ ...config }, type),
    [config, type, isValidClasifier, isValidVectorizer]
  );

  const exp_type = useMemo(() => {
    if (type === 'image') {
      return EXPERIMENT_TYPE_IMAGE;
    }
    if (type === 'audio') {
      return EXPERIMENT_TYPE_AUDIO;
    }
    return EXPERIMENT_TYPE_TEXT;
  }, [type]);

  const { push } = useHistory();

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);

      if (
        isValidSelection() ||
        taskNum === EXPERIMENT_TASK.TEXT_QANDA ||
        taskNum === EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR
      ) {
        const newConfig = extractorTasks.includes(parseInt(task))
          ? expValues.config
          : config;

        const updatedUser = await me();
        if (
          !updatedUser.can_use_openai_models &&
          (newConfig['llm_gpt3_5_turbo'] ||
            newConfig['llm_gpt3_5_turbo_16k'] ||
            newConfig['llm_gpt4'])
        ) {
          setOpen(true);
          setModalType('openai_api_key');
          setLoading(false);
          return;
        }

        const exp = await createExperiment({
          ...expValues,
          type: exp_type,
          send_notification:
            taskNum !== EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR
              ? sendEmail
              : false,
          config: newConfig,
          task: parseInt(task) || 0,
        });

        setLoading(false);
        push(`/experiment/${exp.id}`);
        window.location.reload();
      } else {
        setOpen(true);
        setMessage(
          'You need to specify at least one classifier and one vectorizer to create the experiment.'
        );
        setModalType('validation');
        setLoading(false);
      }
    } catch (err) {
      setMessage((err as Error).message);
      setOpen(true);
      setLoading(false);
      setModalType('dataset');
    }
  }, [
    push,
    expValues,
    exp_type,
    isValidSelection,
    sendEmail,
    task,
    config,
    taskNum,
  ]);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleCancel = useCallback(() => {
    setOpen(false);
    push(`/create/${type}/upload`);
  }, [setOpen, type, push]);

  const handleSetMail = useCallback(
    (e) => {
      setSendEmail(!sendEmail);
    },
    [setSendEmail, sendEmail]
  );

  return (
    <Grid
      container
      direction="column"
      className={classes.container}
      alignItems="center"
    >
      <Grid
        container
        alignItems="center"
        direction="column"
        justify="center"
        className={classes.headerInfo}
      >
        <Typography variant="h3" className={classes.title}>
          The experiment is ready to run!
        </Typography>
        <Typography variant="body1">
          Running the experiment trains your ML models. Depending on the size of
          your sets, experiment configuration, and place in waiting queue, this
          process may take several hours.
        </Typography>
      </Grid>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="flex-start"
        className={classes.configContainer}
      >
        <AccordionAdvancedConfig
          type={type}
          task={task}
          config={config as AdvancedConfig}
          setConfigHandler={setConfigHandler}
        />
        {/**<Accordion title="Configuration">Here goes the experiment custom config</Accordion> */}
      </Grid>
      {taskNum !== EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR && (
        <Grid item className={classes.sendEmailContainer}>
          <FormControlLabel
            control={
              <Checkbox
                checked={sendEmail}
                name="email_notify"
                onChange={handleSetMail}
              />
            }
            label={
              <Typography variant="body1">
                Send me an email to{' '}
                <span className={classes.emailToSend}>
                  {user ? user.email : null}
                </span>{' '}
                when it is finished.
              </Typography>
            }
          />
        </Grid>
      )}
      <ActionButton
        size="large"
        className={!loading ? classes.button : classes.buttonLoading}
        variant="contained"
        color="secondary"
        onClick={handleSubmit}
        disabled={loading}
      >
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          'Create and run experiment'
        )}
      </ActionButton>

      {open && (
        <ErrorMessageModal
          message={message}
          onConfirm={handleCancel}
          onClose={closeModal}
          type={modalType}
        />
      )}
    </Grid>
  );
});
