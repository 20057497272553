import * as React from 'react';

export const useIntervalTimer = (seconds: number = 0) => {
  const [timer, setTimer] = React.useState(0);
  const [isActive, setActive] = React.useState(false);
  const timerRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);

  React.useEffect(() => {
    if (timer > seconds) {
      setActive(false);
      setTimer(0);
      timerRef.current && clearInterval(timerRef.current);
    } else {
      if (isActive) {
        timerRef.current = setInterval(() => {
          setTimer((timer) => timer + 1);
        }, 1000);
      }
    }
    return () => {
      timerRef.current && clearInterval(timerRef.current);
    };
  }, [timer, isActive, seconds]);

  const handleStart = React.useCallback(() => {
    if (timer < seconds) {
      setActive(true);
    }
  }, [setActive, seconds, timer]);

  const handleStop = React.useCallback(() => {
    setActive(false);
    if (timerRef.current) {
      clearInterval(timerRef.current);
      setTimer(0);
    }
  }, [setActive, setTimer]);

  return { handleStart, handleStop, timer };
};
