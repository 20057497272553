import React, { useMemo, useRef, useState } from 'react';

import * as Yup from 'yup';
import { Formik, Form, Field, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';

import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { ActionButton } from 'components';

import { Company as CompanyType, CompanyUser as CompanyUserType } from 'types';

import { signUpToCompany } from 'services/auth';

import { FetchError } from 'utils';

import useStyle from '../../company.styles';

interface MemberProps {
  company?: CompanyType | null;
  onSuccess?(payload: { msg: string; user: CompanyUserType }): void;
  onError?(payload: { msg: string }): void;
  updateMode?: boolean;
}

interface FormProps {
  email: string;
}

export const validationSchema = Yup.object().shape({
  email: Yup.string().required('You must specify an email').email().max(254),
});

export const MemberForm = ({
  company,
  onSuccess,
  onError,
  updateMode = false,
}: MemberProps) => {
  const classes = useStyle();
  const formikRef = useRef<FormikProps<FormProps>>(null);
  const [canSave, setCanSave] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState('');
  const initialValues = useMemo<FormProps>(
    () => ({
      email: '',
    }),
    []
  );

  const onSubmitHandler = async ({ email }: FormProps) => {
    if (!company) return;
    setError('');
    setLoading(true);

    try {
      const res = await signUpToCompany({
        id_company: company.id,
        email,
      });
      const newUser: CompanyUserType = {
        id: res.id_user,
        email: res.email,
        user_name: res.user_name,
      };
      onSuccess?.({ msg: 'Member added', user: newUser });
    } catch (error) {
      if (error instanceof FetchError) {
        setError(error.message);
        onError?.({
          msg: error.message,
        });
      }
    }

    setLoading(false);
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmitHandler}
      innerRef={formikRef}
    >
      {({ values, setFieldValue }) => (
        <Form
          onChange={() => setCanSave(true)}
          className={classes.form}
          style={{ padding: 32 }}
        >
          <Typography variant="h3">Add Member</Typography>

          <Field
            component={TextField}
            name="email"
            label="Email"
            type="email"
          />

          {error && (
            <Alert onClose={() => setError('')} severity="error">
              {error}
            </Alert>
          )}

          <ActionButton
            variant="contained"
            color="secondary"
            size="large"
            type="submit"
            disabled={!canSave}
            loading={loading}
          >
            Send invitation
          </ActionButton>
        </Form>
      )}
    </Formik>
  );
};
