import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  tableContainer: {
    padding: 'padding: 24px 72px 25px 24px',
    height: '100%',
  },
  tableItem: {
    '&::marker': {
      fontSize: '10px',
      color: colors.blue70,
    },
  },
  linksWrapper: {
    height: '100%',
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
}));
