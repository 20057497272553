import { makeStyles } from '@material-ui/core';
import { colors } from 'material';
import { ICogniflowTheme } from 'material/theme';

export default makeStyles<ICogniflowTheme>(() => ({
  buildingWrapper: {
    backgroundColor: colors.blue5,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  errorSubtitleText: {
    '& span': {
      fontWeight: 'bold',
      color: colors.orange,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
}));
