import { makeStyles } from '@material-ui/core';
import { scrollbarStyle } from 'material';
import { colors } from 'material/colors';

const HEIGHT = 500;

export default makeStyles(({ breakpoints }) => ({
  card: {
    backgroundColor: '#FFFF',
    boxShadow: '0 8px 16px 0 rgb(72 79 121 / 15%)',
    borderRadius: '10px',
  },

  taskWrapper: {
    padding: 0,
    marginTop: 24,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gridAutoRows: HEIGHT,
    gap: 32,
    [breakpoints.down('sm')]: {
      gridTemplateColumns: 'minmax(0, 1fr)',
    },
  },

  taskWrapperFaceSimilarity: {
    [breakpoints.down('sm')]: {
      gridAutoRows: 'auto !important',
    },
  },

  oneItemTaskWrapper: {
    gridTemplateColumns: '1fr',
    marginTop: '24px',
  },

  oneItemElement: {
    maxWidth: '730px',
    margin: 'auto',
    padding: '35px 0',
  },

  resultWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
  },
  resultBody: {
    overflowY: 'scroll',
    flexGrow: 1,
    ...scrollbarStyle,
  },
  title: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: colors.blue70,
  },
  subtitle: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.blue70,
    maxWidth: '510px',
  },
}));
