import React, { ReactNode } from 'react';
import { Grid } from '@material-ui/core';
import useStyles from './outlinedcontainer.style';

export interface IOutLinedContainer {
  children: ReactNode;
}

export const OutLinedContainer = ({ children }: IOutLinedContainer) => {
  const classes = useStyles();
  return <Grid className={classes.algoDetailContainer}>{children}</Grid>;
};
