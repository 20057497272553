import { makeStyles } from '@material-ui/core';
import { colors } from 'material';

export default makeStyles((theme) => ({
  header: {
    backgroundColor: '#F7F9FF',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      padding: '30px 50px',
    },
    padding: '30px 195px',
  },

  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '30%',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'center',
      padding: '10px 0 0 0',
    },
  },

  welcomeMessage: {
    width: '700px',
    maxWidth: '720px',
    padding: '40px 0',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      width: 'auto',
      padding: '10px 30px',
    },
  },

  title: {
    fontSize: '24px',
    fontWeight: 700,
  },

  subtitle: {
    fontSize: '16px',
    fontWeight: 700,

    '& span': {
      color: colors.orange,
    },
  },

  description: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },

  headerImage: {
    display: 'flex',
    alignItems: 'flex-end',
    position: 'relative',
    bottom: '-30px',
    '& img': {
      height: '190px',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  button: {
    padding: '12px 20px',
  },
}));
