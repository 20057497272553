import React, { ReactNode, useState } from 'react';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import classnames from 'classnames';

import useStyles from './taskwrapper.styles';
import { AntTab, AntTabs } from 'components/Tabs';
import { CodeBlock } from 'components/CodeBlock';
import { PredictResponse } from 'types/predict.types';
import { EXPERIMENT_TASK } from 'types';

type TaskWrapperProps = {
  imagePanel: ReactNode;
  resultsPanel: ReactNode;
  prediction: PredictResponse | null;
  task?: number;
  showJsonTab?: boolean;
  isApplication: boolean;
  subtitle?: string | null;
};

export const TaskWrapper = ({
  imagePanel,
  resultsPanel,
  prediction,
  task,
  showJsonTab = true,
  isApplication = false,
  subtitle = null,
}: TaskWrapperProps) => {
  const classes = useStyles();
  const [tab, setTab] = useState(0);

  const headerTextsMap: { [key: number]: ReactNode } = {
    [EXPERIMENT_TASK.IMAGE_FACE_SIMILARITY]: (
      <Box>
        <Typography className={classes.title}>Test this model</Typography>
        <Typography variant="body1" className={classes.subtitle}>
          Please upload two images to check for similarity.
        </Typography>
      </Box>
    ),
  };

  return (
    <Grid container direction="column" alignItems="center">
      <>
        {!isApplication ? (
          prediction ? (
            <Box>
              <Typography className={classes.title}>Results</Typography>
              <Typography variant="body1" className={classes.subtitle}>
                These are the results according to the image conditions.
              </Typography>
            </Box>
          ) : (
            <>{headerTextsMap[task as number]}</>
          )
        ) : (
          <>
            {subtitle && (
              <Typography variant="body1" className={classes.subtitle}>
                {subtitle}
              </Typography>
            )}
          </>
        )}
      </>
      <Container
        maxWidth="lg"
        className={classnames({
          [classes.taskWrapper]: prediction,
          [classes.taskWrapperFaceSimilarity]:
            (task as number) === EXPERIMENT_TASK.IMAGE_FACE_SIMILARITY,
          [classes.oneItemTaskWrapper]: !prediction,
        })}
      >
        <Box
          className={classnames({
            [classes.card]: true,
            [classes.oneItemElement]: !prediction,
          })}
          sizeWidth="sm"
        >
          {imagePanel}
        </Box>

        {prediction && (
          <Box className={classnames(classes.resultWrapper, classes.card)}>
            {showJsonTab && (
              <AntTabs
                value={tab}
                onChange={(e, newValue) => setTab(newValue)}
                centered
              >
                <AntTab label="Preview" />
                <AntTab label="JSON" />
              </AntTabs>
            )}
            <Box
              className={classes.resultBody}
              style={{ ...(tab === 1 && { padding: 24 }) }}
            >
              {tab === 0 ? (
                resultsPanel
              ) : (
                <CodeBlock
                  code={prediction}
                  language="javascript"
                  style={{ height: '100%' }}
                />
              )}
            </Box>
          </Box>
        )}
      </Container>
    </Grid>
  );
};
