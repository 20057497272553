import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    background: (props: any) => props.colorBackground,
    '& *': {
      fontFamily: (props: any) => `'${props.fontFamily}'`,
    },
  },
  title: {
    maxWidth: 500,
    marginTop: 24,
    marginInline: 'auto',
    fontSize: 28,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#848aa9',
  },
  descriptionBtn: {
    padding: '4px 10px',
    background: '#00000005',
    borderRadius: 6,
    width: 'max-content',
    margin: '12px auto',
    cursor: 'pointer',

    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  descriptionBtnTxt: {
    color: '#848aa9',
    fontSize: 12,
  },
  description: {
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: 560,
    padding: 24,
    fontSize: 14,
    margin: '0 auto',
    background: '#00000005',
    borderRadius: 8,
    color: '#72799d',
    textAlign: 'justify',
    whiteSpace: 'break-spaces',
  },
  testWrapper: {
    margin: '48px 0 75px 0',
  },
  footerWrapper: {
    borderTop: '1px solid #e3e3e3',
  },
  footerBody: {
    maxWidth: 1000,
    margin: '0 auto',
    padding: '8px 14px',
  },
  footerLogo: {
    height: 19,
    marginRight: 10,
  },
  footerText: {
    fontWeight: 'bold',
    color: '#a3a6bb',
  },
  footerLink: {
    marginLeft: 5,
    color: colors.orange,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
