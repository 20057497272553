import { makeStyles } from '@material-ui/core';
import { colors } from 'material';

export default makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0 auto',
    backgroundColor: 'white',
    maxWidth: '900px',
    // height: '500px',
    boxShadow: '0px 8px 16px 0px rgba(72, 79, 121, 0.15)',
    borderRadius: '16px',
    paddingBottom: '40px',
  },
  uploadButton: {
    padding: '12px 24px',
  },

  header: {
    padding: '40px',
  },

  docsWrapper: {
    padding: '0 48px',
    '& > div:not(:first-child)': {
      borderTop: '1px solid #f1f1f1',
    },
    '& > div:nth-child(odd)': {
      //backgroundColor: colors.blue5,
    },
    '& > div:nth-child(even)': {
      //backgroundColor: 'white',
    },
  },
}));
