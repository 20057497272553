import React, { useEffect, useState, ReactNode } from 'react';
import { Checkbox, Grid, Typography } from '@material-ui/core';
import { RobotLearningImage } from 'assets';
import { useAuthenticationContext } from 'context';

import useStyles from './experimentrunning.styles';

export interface Props {
  notifyMe: boolean;
  title?: string;
  onSendNotificationChange(value: boolean, cb: Function): void;
  description?: ReactNode;
  sendNotificationCheck?: boolean;
}

const DEFAULT_TITLE = 'The experiment is waiting';

export const GenericResponseMessageR2R2 = React.memo(
  ({
    notifyMe,
    title = DEFAULT_TITLE,
    onSendNotificationChange,
    description,
    sendNotificationCheck = true,
  }: Props) => {
    const classes = useStyles();
    const { user } = useAuthenticationContext();

    const [sendNotification, setSendNotification] = useState(notifyMe);

    useEffect(() => {
      /**
       * TODO:
       * We need to check why backend subscription messages aren't
       * triggering the respecitve events in the client
       */
      const timeoutID = setTimeout(() => {
        window.location.reload();
      }, 30000);

      return () => {
        clearTimeout(timeoutID);
      };
    }, []);

    const sendNotificationChangeHandler = (ev: any) => {
      const { checked } = ev.target;

      onSendNotificationChange(checked, () => {
        setSendNotification(checked);
      });
    };

    return (
      <div className={classes.experimentrunningContainer}>
        <div className={classes.imageContainer}>
          <RobotLearningImage className={classes.robotImage} />
        </div>
        <Typography className={classes.titleHeader}>{title}</Typography>
        <Typography className={classes.subtitleHeader}>
          {description ||
            'Check back periodically as models are released to use as they are trained.'}
        </Typography>

        {sendNotificationCheck && (
          <Grid
            className={classes.mailMeContainer}
            item
            container
            justify="center"
            alignItems="center"
          >
            <Checkbox
              checked={sendNotification}
              size={'medium'}
              onChange={sendNotificationChangeHandler}
            />
            <Typography>
              Send me an email to {user && user.email} when it is finished
            </Typography>
          </Grid>
        )}
      </div>
    );
  }
);
