import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  modalWrapper: {
    backgroundColor: colors.blue70,
    padding: '16px',
    borderRadius: '8px',
    boxShadow: '0 16px 24px 0 rgba(72, 79, 121, 0.15)',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& p': {
      color: 'white',
      fontFamily: 'Poppins',
      fontSize: '12px',
      textAlign: 'left',
    },
    maxWidth: '500px',
  },
}));
