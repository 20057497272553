import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles((theme) => ({
  imageCaptured: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  imageCover: {
    margin: 0,
  },
  tryAnotherMsg: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    color: colors.blue70,
  },
  background: {
    height: 266,
    width: '100%',
    maxWidth: 728,
    borderRadius: 5,
    border: `2px dashed ${colors.blue15}`,
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    '&:focus': {
      outline: 'none',
    },
    cursor: 'pointer',
  },
  dragDropTitle: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    color: colors.blue20,
  },
  testContainer: {
    marginTop: 24,
    backgroundColor: 'white',
  },
  subtitle: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.blue70,
  },
  underlined: {
    textDecoration: 'underline',
    color: colors.orange,
    cursor: 'pointer',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  imageContainerResult: {
    backgroundColor: '#FFFF',
    paddingLeft: 20,
    paddingRight: 20,
  },
  messageContainer: {
    padding: '6px 10px',
    borderRadius: 10,
    boxShadow: `0 8px 16px 0 rgba(72, 79, 121, 0.15)`,
    backgroundColor: '#FFFF',
    position: 'absolute',
    zIndex: 1,
    marginTop: 245,
    marginLeft: 16,
    marginRight: 16,
    width: 500,
  },
  badge: {
    backgroundColor: colors.blue10,
    padding: '9px 16px',
    borderRadius: 8,
    marginLeft: 8,
    fontSize: 12,
    color: colors.blue70,
  },
  btnImageClose: {
    position: 'absolute',
  },
  title: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: colors.blue70,
    marginBottom: 16,
  },
  other: {
    alignSelf: 'flex-start',
    paddingRight: 40,
    paddingLef: 20,
    '&:first-child': {
      borderRight: `1px solid ${colors.blue15}`,
      [theme.breakpoints.down('sm')]: {
        borderRight: 0,
      },
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
  },
  tooltipFont: {
    fontSize: 24,
  },
  cameraBtnContainer: {
    marginTop: 32,
  },
  alertWrapper: {
    marginTop: '16px',
  },
}));
