import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './infowithcaption.styles';

interface Props {
  caption: string;
  label: string;
}

export const InfoWithCaption = ({ caption, label }: Props) => {
  const classes = useStyles();

  return (
    <Grid container justify="center" alignItems="flex-start" direction="column">
      <Typography variant="caption">{caption}</Typography>
      <Typography variant="body2" align="left" className={classes.captionValue}>
        {label}
      </Typography>
    </Grid>
  );
};
