import React, { ReactNode, useMemo } from 'react';
import {
  ChipButton,
  DatasetsModal,
  ProjectHints,
  UnControlledModal,
} from 'components';
import { Typography, Link, Box } from '@material-ui/core';

import { NEED_HELP_LINKS } from 'constant';
import {
  DatasetProps,
  EXPERIMENT_TASK,
  PROJECT_TASK,
  PROJECT_TYPE,
} from 'types';
import {
  textDatasets,
  imageDatasets,
  audioDatasets,
  qandaDatasets,
} from '../step4Utils';

import useStyles from './Helpers.styles';
import {
  ExtensionRounded,
  InfoOutlined,
  LinkOffOutlined,
  LinkOutlined,
  ShareOutlined,
  SchoolOutlined,
} from '@material-ui/icons';
import { DownloadIcon, LinkExternalIcon } from 'assets';

import { useHistory } from 'react-router-dom';

type HelpersProps = {
  task: number;
  type: number;
};

export const Helpers = ({ type, task }: HelpersProps) => {
  const classes = useStyles();

  const { push } = useHistory();

  const datasets: { [key: string]: DatasetProps[] } = useMemo(
    () => ({
      [PROJECT_TASK.CLASSIFIER_TEXT]: textDatasets,
      [PROJECT_TASK.CLASSIFIER_IMAGE]: imageDatasets,
      [PROJECT_TASK.CLASSIFIER_AUDIO]: audioDatasets,
      [PROJECT_TASK.CHATBOT]: qandaDatasets,
      [PROJECT_TASK.OBJECT_DETECTION]: [],
    }),
    []
  );

  const helpersMap: { [key: string]: ReactNode } = {
    [PROJECT_TYPE.TEXT]: (
      <Typography className={classes.needHelp} variant="body1">
        <Link
          rel="noopener noreferrer"
          target="_blank"
          color="secondary"
          href={NEED_HELP_LINKS[task]}
        >
          Need help?
        </Link>{' '}
        Learn how to make datasets or{' '}
        <DatasetsModal
          datasets={datasets[task] || []}
          button={
            <Link
              rel="noopener noreferrer"
              target="_blank"
              color="secondary"
              className={classes.pointer}
            >
              Download an example
            </Link>
          }
        />
      </Typography>
    ),
    [PROJECT_TYPE.IMAGE]: (
      <Typography className={classes.needHelp} variant="body1">
        <Link
          rel="noopener noreferrer"
          target="_blank"
          color="secondary"
          href={NEED_HELP_LINKS[task]}
        >
          Need help?
        </Link>{' '}
        {EXPERIMENT_TASK.IMAGE_OBJECT_DETECTION !== task && (
          <>
            Learn how to make datasets or{' '}
            <DatasetsModal
              datasets={datasets[task]}
              button={
                <Link
                  rel="noopener noreferrer"
                  target="_blank"
                  color="secondary"
                  className={classes.pointer}
                >
                  Download an example
                </Link>
              }
            />
          </>
        )}
      </Typography>
    ),
    [PROJECT_TYPE.AUDIO]: (
      <Typography className={classes.needHelp} variant="body1">
        <Link
          rel="noopener noreferrer"
          target="_blank"
          color="secondary"
          href={NEED_HELP_LINKS[task]}
        >
          Need help?
        </Link>{' '}
        Learn how to make datasets or{' '}
        <DatasetsModal
          datasets={audioDatasets}
          button={
            <Link
              rel="noopener noreferrer"
              target="_blank"
              color="secondary"
              className={classes.pointer}
            >
              Download an example
            </Link>
          }
        />
      </Typography>
    ),
  };

  const tryOurModelClickHander = () => {
    window.open(
      'https://app.cogniflow.ai/experiment/c3ffee01-2351-45af-cc2a-dd1084bbc341/model/abc1226a-ebd1-ff05-22e3-aff01d32bc77/test',
      '_blank'
    );
  };

  const readODTutorialClickHander = () => {
    window.open(
      'https://docs.cogniflow.ai/en/article/how-to-label-images-and-create-your-dataset-for-an-object-detection-ai-model-dcfg1y/',
      '_blank'
    );
  };

  return (
    <Box
      display="flex"
      justifyContent="space-evenly"
      width="100%"
      gridGap="24px"
    >
      {![PROJECT_TASK.CHATBOT].includes(task) && (
        <UnControlledModal
          size="sm"
          openModalButton={
            <ChipButton title="File requirements" icon={<InfoOutlined />} />
          }
        >
          <ProjectHints task={task} type={type} />
        </UnControlledModal>
      )}

      {PROJECT_TASK.OBJECT_DETECTION != task && (
        <DatasetsModal
          datasets={datasets[task]}
          button={
            <ChipButton title="Dataset examples" icon={<DownloadIcon />} />
          }
        />
      )}

      {PROJECT_TASK.OBJECT_DETECTION === task && (
        <ChipButton
          title="Read tutorial"
          icon={<SchoolOutlined />}
          onClick={readODTutorialClickHander}
        />
      )}

      {PROJECT_TASK.OBJECT_DETECTION === task && (
        <ChipButton
          title="Try our model"
          icon={<LinkExternalIcon />}
          onClick={tryOurModelClickHander}
        />
      )}
    </Box>
  );
};
