import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import useStyles from './TrainingData.styles';
import { PROJECT_TASK, PROJECT_TYPE_URL_MAP } from 'types';
import { ExtractorTraining } from './ExtractorTraining';
import { DefaultTraning } from './DefaultTraining';
import { ActionButton, Text } from 'components';
import { getTexts } from './DefaultTraining/Step4Components/step4Utils';
import { useProjectCreationContext } from 'context';
import { ChatbotTraining } from './ChatbotTraining';
import {
  ProjectConfiguration,
  ValidationDataset,
  ErrorMessageModal,
} from './components';
import {
  useBuildProject,
  useProjectConfiguration,
  useChatbotUpload,
} from './hooks';
import { getExperimentConfig } from 'services';
import fetchBuilder from 'fetch-retry-ts';
import { Helpers } from './DefaultTraining/Step4Components';
import { getTaskNumber } from 'utils';

const excludeAdvancedOptionsFor = [
  PROJECT_TASK.EXTRACTOR,
  PROJECT_TASK.OBJECT_DETECTION,
];

export interface TrainingDataProps {}

export const TrainingData = () => {
  const { taskUrl, typeUrl } = useParams<{
    taskUrl: string;
    typeUrl: string;
  }>();

  const type = PROJECT_TYPE_URL_MAP[typeUrl];

  const task = getTaskNumber(taskUrl, typeUrl);

  const { expValues, setProjectProps } = useProjectCreationContext();

  const classes = useStyles();

  const { title, subtitle } = getTexts({ type, task });

  const [buildDisabled, setBuildDisabled] = useState(true);

  const trainUrl = useRef('');
  const getUrl = useRef<string | undefined>('');

  const {
    config,
    setConfigHandler,
    setShowingTagHandler,
    configChangeHandler,
  } = useProjectConfiguration({
    type,
  });

  const {
    buildProject,
    open,
    errorMessage,
    closeModal,
    modalType,
  } = useBuildProject({
    task,
    type,
    projectParams: expValues,
  });

  const {
    files,
    setFilesHandler,
    deleteFileHandler,
    uploadChatbotDocuments,
    progress: chatbotUploadProgress,
    isFinished: chatbotUploadFinished,
    isStarted: chatbotUploadStarted,
    presignedResponse,
  } = useChatbotUpload({
    task,
  });

  useEffect(() => {
    const getDefaultConfig = async () => {
      const resp = await getExperimentConfig(type, task);

      setConfigHandler(resp);
    };
    getDefaultConfig();
  }, [setConfigHandler, type, task]);

  const setBuildDisabledHandler = useCallback((value: boolean) => {
    setBuildDisabled(value);
  }, []);

  useEffect(() => {
    setProjectProps({
      task,
      type,
    });
  }, [task, type, setProjectProps]);

  useEffect(() => {
    if (presignedResponse?.filename) {
      trainUrl.current = presignedResponse?.filename;
      getUrl.current = presignedResponse?.get_url || '';
    }
  }, [presignedResponse]);

  const trainingDataComponent: Record<string, ReactNode> = {
    [PROJECT_TASK.EXTRACTOR]: (
      <ExtractorTraining
        task={task}
        type={type}
        setExpProps={setProjectProps}
        expValues={expValues}
        setBuildDisabledHandler={setBuildDisabledHandler}
      />
    ),
    [PROJECT_TASK.CHATBOT]: (
      <ChatbotTraining
        setBuildDisabledHandler={setBuildDisabledHandler}
        files={files || []}
        setFilesHandler={setFilesHandler}
        deleteFileHandler={deleteFileHandler}
        progress={chatbotUploadProgress}
        finished={chatbotUploadFinished}
        started={chatbotUploadStarted}
        task={task}
      />
    ),
  };

  const showAdvancedConfig = [
    PROJECT_TASK.CLASSIFIER_AUDIO,
    PROJECT_TASK.CLASSIFIER_TEXT,
    PROJECT_TASK.CLASSIFIER_IMAGE,
  ].includes(task);

  const showValidationDataset = [
    PROJECT_TASK.CLASSIFIER_AUDIO,
    PROJECT_TASK.CLASSIFIER_TEXT,
    PROJECT_TASK.CLASSIFIER_IMAGE,
    PROJECT_TASK.OBJECT_DETECTION,
  ].includes(task);

  const buildProjectHandler = async () => {
    let train_url = '';

    const waitForPresignedUrl = async (): Promise<string> =>
      new Promise((resolve) => {
        const checkPresignedValue = () => {
          if (trainUrl.current) {
            resolve(trainUrl.current);
          } else {
            setTimeout(checkPresignedValue, 500);
          }
        };

        checkPresignedValue();
      });

    if (PROJECT_TASK.CHATBOT === task) {
      await uploadChatbotDocuments();

      train_url = await waitForPresignedUrl();

      setProjectProps({
        train_url,
      });

      const retryOptions = {
        retries: 10,
        retryDelay: 3000,
        retryOn: [403, 419, 503, 504, 404],
      };

      const fetchRetry = fetchBuilder(fetch, retryOptions);

      fetchRetry(getUrl.current || '').then(() => {
        buildProject(train_url);
      });
    } else {
      buildProject(train_url);
    }
  };

  return (
    <Box display="flex" justifyContent="center">
      <Box className={classes.trainingDataWrapper}>
        <Box display="flex" flexDirection="column" gridGap="12px" mb="8px">
          <Text variant="h3" className={classes.title}>
            {title}
          </Text>

          {task !== PROJECT_TASK.EXTRACTOR && (
            <Text
              variant="paragraph1"
              style={{ whiteSpace: 'pre-line', lineHeight: 1.5 }}
            >
              {subtitle}
            </Text>
          )}
        </Box>

        {/* <Box mb="10px">
          {![PROJECT_TASK.CHATBOT].includes(task) && (
            <UnControlledModal
              size="sm"
              openModalButton={
                <Box
                  display="flex"
                  justifyContent="center"
                  alignContent="center"
                >
                  <HelpIconOutlined />
                </Box>
              }
            >
              <ProjectHints task={task} type={type} />
            </UnControlledModal>
          )}
        </Box> */}

        <Box display="flex" flexDirection="column" gridGap="12px" mt="12px">
          {![PROJECT_TASK.EXTRACTOR, PROJECT_TASK.CHATBOT].includes(task) && (
            <Box mb="14px" mt="12px">
              <Helpers task={task} type={type} />
            </Box>
          )}
          {(trainingDataComponent[task] as JSX.Element) || (
            <DefaultTraning
              task={task}
              type={type}
              showAdvancedOptions={!excludeAdvancedOptionsFor.includes(task)}
              expValues={expValues}
              setExpProps={setProjectProps}
              setBuildDisabledHandler={setBuildDisabledHandler}
            />
          )}

          <Box
            display="flex"
            flexDirection="column"
            gridGap="12px"
            mb={showValidationDataset || showAdvancedConfig ? '24px' : '0px'}
          >
            {showValidationDataset && (
              <ValidationDataset
                task={task}
                setProjectProps={setProjectProps}
              />
            )}
            {showAdvancedConfig && (
              <ProjectConfiguration
                task={task}
                type={type}
                config={config}
                setShowingTagHandler={setShowingTagHandler}
                configChangeHandler={configChangeHandler}
                setConfigHandler={setConfigHandler}
              />
            )}
          </Box>
        </Box>

        <Box mt="24px">
          <ActionButton
            size="large"
            className={classes.button}
            variant="contained"
            color="secondary"
            disabled={buildDisabled}
            onClick={buildProjectHandler}
          >
            Build project
          </ActionButton>
        </Box>
      </Box>

      {open && (
        <ErrorMessageModal
          message={errorMessage}
          // onConfirm={handleCancel}
          onClose={closeModal}
          type={modalType}
        />
      )}
    </Box>
  );
};
