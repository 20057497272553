import React, { useEffect, useState } from 'react';
import { ViewExperimentHome } from './ViewExperimentHome';
import { ModelMetricsView } from './ModelMetrics';
import { AuthRoute } from 'components/AuthRoute/AuthRoute';
import { TestExperiment } from 'pages';

import { useParams, Switch } from 'react-router-dom';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { currentExperimentState, experimentError } from 'store/experiments';
import { getExperiment } from 'services/experiments';
import { useExperimentProgress, useExperimentStatus } from 'hooks';
import { ExperimentProvider, PredictionProvider } from 'context';
import { addToRecents } from 'services';

export const ViewExperiment = ({ match: { path } }: any) => {
  const { id }: { id: string } = useParams();
  const [count, setCount] = useState(0);
  const [experiment, setExperiment] = useRecoilState(currentExperimentState);
  const setExperimentError = useSetRecoilState(experimentError);
  const { data: progressUpdate } = useExperimentProgress(id) ?? {};
  const { data: statusUpdate } = useExperimentStatus(id) ?? {};

  useEffect(() => {
    if (progressUpdate) {
      setExperiment((experiment) => {
        if (!experiment) {
          return experiment;
        }

        return {
          ...experiment,
          progress: progressUpdate.experiment_progress,
          models: experiment.models.map((model) => {
            if (model.id === progressUpdate.id_model) {
              return {
                ...model,
                progress: progressUpdate.model_progress,
              };
            } else {
              return model;
            }
          }),
        };
      });
    }
  }, [progressUpdate, setExperiment]);

  useEffect(() => {
    if (statusUpdate) {
      setExperiment((experiment) => {
        if (!experiment) return undefined;

        return {
          ...experiment,
          models: experiment.models.map((model) => {
            if (model.id === statusUpdate.id_model) {
              return {
                ...model,
                status: statusUpdate.model_status,
              };
            } else {
              return model;
            }
          }),
        };
      });

      if (statusUpdate.model_status === 3) {
        setCount((state) => state + 1);
      }
    }
  }, [statusUpdate, setExperiment]);

  useEffect(() => {
    getExperiment(id)
      .then((exp) => {
        setExperiment(exp);
      })
      .catch((err) => {
        setExperimentError(err.message);
      });
  }, [id, setExperiment, setExperimentError, count]);

  useEffect(() => () => setExperiment(undefined), [setExperiment]);

  useEffect(() => {
    addToRecents(experiment);
  }, [experiment]);

  return (
    <ExperimentProvider>
      <Switch>
        <AuthRoute
          exact
          path={`${path}/:modelId?`}
          render={() => <ViewExperimentHome experiment={experiment} />}
        />

        <AuthRoute
          exact
          path={`${path}/model/:modelId/metrics`}
          component={ModelMetricsView}
        />

        <PredictionProvider>
          <AuthRoute
            exact
            path={`${path}/model/:modelId/:tab?`}
            component={TestExperiment}
          />
        </PredictionProvider>
      </Switch>
    </ExperimentProvider>
  );
};
