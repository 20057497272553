import React, { useState, useEffect, useCallback } from 'react';
import { getCommercialPlansWithFeatures } from 'services/commercialPlan';

export const Admin = () => {
  const [commercialPlans, setCommercialPlans] = useState([]);
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    getCommercialPlansWithFeatures().then((res) => {
      const { commercial_plans: commercialPlans, features } = res;
      setCommercialPlans(commercialPlans);
      setFeatures(features);
    });
  }, []);

  const getValue = useCallback((commercialPlan, feature) => {
    const permission = commercialPlan.permissions.find(
      (p) => p.id_feature === feature.id
    );
    return permission?.value || 0;
  }, []);

  return (
    <div>
      <table>
        <thead>
          <tr>
            <td></td>
            {features.map((f) => (
              <td key={f.id}>{f.name}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {commercialPlans.map((cp) => (
            <tr key={cp.id}>
              <td>{cp.name}</td>
              {features.map((f) => {
                const value = getValue(cp, f);
                return (
                  <td style={{ border: '1px solid #ccc' }} key={f.id}>
                    {value > 0 ? value : ''}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
