const tagData: { [key: string]: string } = {
  CARDINAL: 'cardinal value',
  DATE: 'date value',
  EVENT: 'event name',
  FAC: 'building name',
  GPE: 'geo-political entity',
  LANGUAGE: 'language name',
  LAW: 'law name',
  LOC: 'location name',
  MONEY: 'money name',
  NORP: 'affiliation',
  ORDINAL: 'ordinal value',
  ORG: 'organization name',
  PERCENT: 'percent value',
  PERSON: 'person name',
  PRODUCT: 'product name',
  QUANTITY: 'quantity value',
  TIME: 'time value',
  WORK_OF_ART: 'name of work of art',
};

export default tagData;
