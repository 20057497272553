import React from 'react';
import { TaskWrapper } from '../TaskWrapper';
import { PredictMessage } from 'components/PredcitMessage';
import useStyles from './imageclassification.styles';

import { TaskComponentProps } from 'types';
import { Box } from '@material-ui/core';

export const ImageClassification = ({
  imgUrl,
  prediction,
  task,
  showJsonTab = true,
}: TaskComponentProps) => {
  const classes = useStyles();

  return (
    <TaskWrapper
      task={task}
      imagePanel={
        <img
          src={imgUrl}
          alt={prediction?.result as string}
          className={classes.imageCaptured}
        />
      }
      resultsPanel={
        <Box style={{ padding: 20 }}>
          <PredictMessage prediction={prediction} />
        </Box>
      }
      prediction={prediction}
      showJsonTab={showJsonTab}
    />
  );
};
