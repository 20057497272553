import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import React, { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import { GenericModal } from 'components/GenericModal';
import { DataTable } from './DataTable';
import useStyle from './modeldetailmodal.styles';

export const sortingState = atom<number>({
  key: 'sortingStrategyState',
  default: 2,
});

export const ModelSelectModal = () => {
  const classes = useStyle();
  const [sort, setSorting] = useRecoilState(sortingState);
  const handleSorting = useCallback(
    (e) => {
      const value = e.target.value;
      setSorting(value);
    },
    [setSorting]
  );

  return (
    <GenericModal>
      <Grid container item className={classes.genericModalContent}>
        <Grid
          item
          container
          justify="space-between"
          spacing={1}
          className={classes.titleHeder}
        >
          <Typography className={classes.modalTitle}>Trained Models</Typography>
          <FormControl variant="outlined">
            <InputLabel id="select-sort-label">Sort By</InputLabel>
            <Select
              labelId="select-sort-label"
              id="select-sort"
              label="Sortong"
              onChange={handleSorting}
              value={sort}
            >
              <MenuItem value={1}>Accuracy/Asc</MenuItem>
              <MenuItem value={2}>Accuracy/Desc</MenuItem>
              <MenuItem value={3}>Avg. Inf. Time/Asc</MenuItem>
              <MenuItem value={4}>Avg. Inf. Time/Desc</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item container justify="space-between" spacing={1}>
          <DataTable />
        </Grid>
      </Grid>
    </GenericModal>
  );
};
