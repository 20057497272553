import React, { useState, useEffect, useRef } from 'react';
import { TaskWrapper } from '../TaskWrapper';
import { ODImagePanel } from './ODImagePanel';
import { ObjectDetectionResponse, TaskComponentProps } from 'types';
import { ResultsPanel } from './ODResultsPanel';
import { useLoadImage } from 'hooks';
import { SimpleDialog } from 'components/SimpleDialog';
import randomColor from 'randomcolor';
import { IconClose } from 'assets';
import { ObjectDetectionProvider } from './ObjectDetectionContext';

enum CANVAS_SIZE {
  SM_WIDTH = 509,
  SM_HEIGHT = 354,
  LG_WIDTH = 700,
  LG_HEIGHT = 700,
}

type Colors = {
  [key: string]: string;
};

export const ObjectDetection = ({
  prediction,
  imgUrl,
  showJsonTab = true,
}: TaskComponentProps) => {
  const [objectDetectionChecks, setObjectDetectionChecks] = useState<string[]>(
    []
  );

  const [modalOpen, setModalOpen] = useState(false);

  const { image } = useLoadImage(imgUrl);

  const ODCategories = useRef<string[]>([]);
  const [ODAllChecked, setODAllChecked] = useState(true);

  const colors = useRef<Colors>({});

  useEffect(() => {
    if (!prediction?.result) return;

    (prediction.result as ObjectDetectionResponse[]).forEach(({ category }) => {
      if (!colors.current[category]) {
        colors.current[category] = randomColor();
      }
    });
  }, [prediction]);

  const onCloseModal = () => {
    setModalOpen(false);
  };

  const onMaximize = () => {
    setModalOpen(true);
  };

  const onObjectDetectionChecksChange = (state: { [key: string]: boolean }) => {
    const [category] = Object.keys(state);

    if (!ODCategories.current) return;

    if (category === 'selectall') {
      state[category]
        ? setObjectDetectionChecks(ODCategories.current)
        : setObjectDetectionChecks([]);
      setODAllChecked(state[category]);
    }

    if (!state[category]) {
      setObjectDetectionChecks((s) =>
        s.filter((cat: string) => cat !== category)
      );
      return;
    }
    setObjectDetectionChecks((s) => [...s, category]);
  };

  useEffect(() => {
    if (!prediction?.result) return;
    const categories = (prediction.result as ObjectDetectionResponse[]).map(
      ({ category }) => category
    );

    ODCategories.current = categories;

    setObjectDetectionChecks(categories);
    // eslint-disable-next-line
  }, [prediction]);

  return (
    <ObjectDetectionProvider>
      <TaskWrapper
        imagePanel={
          prediction &&
          image && (
            <ODImagePanel
              image={image}
              prediction={prediction}
              boxesToShow={objectDetectionChecks}
              onMaximize={onMaximize}
              showControls
              colors={colors.current}
            />
          )
        }
        resultsPanel={
          prediction && (
            <ResultsPanel
              prediction={prediction}
              onChecksChange={onObjectDetectionChecksChange}
              defaultChecked={ODAllChecked}
              colors={colors.current}
            />
          )
        }
        prediction={prediction}
        showJsonTab={showJsonTab}
      />

      <SimpleDialog
        maxW="xl"
        handleClose={onCloseModal}
        header={
          <IconClose style={{ cursor: 'pointer' }} onClick={onCloseModal} />
        }
        open={modalOpen}
      >
        {image && (
          <ODImagePanel
            image={image}
            prediction={prediction}
            boxesToShow={objectDetectionChecks}
            width={CANVAS_SIZE.LG_WIDTH}
            height={CANVAS_SIZE.LG_HEIGHT}
            showControls
            colors={colors.current}
          />
        )}
      </SimpleDialog>
    </ObjectDetectionProvider>
  );
};
