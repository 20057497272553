import React from 'react';
import MuiBreadcrumbs from '@material-ui/core/Breadcrumbs';
import { BreadcrumbLink } from './BreadcrumbLink';
import { Box } from '@material-ui/core';

interface Path {
  text: string;
  link: string;
}

interface BreadcrumbProps {
  paths: Path[];
}

export const Breadcrumbs = ({ paths }: BreadcrumbProps) => (
  <Box mb="12px" mt="12px">
    <MuiBreadcrumbs separator=">">
      {paths.map((path, i) => (
        <BreadcrumbLink
          key={path.link}
          lastLink={i === paths.length - 1}
          label={path.text}
          link={path.link}
        />
      ))}
    </MuiBreadcrumbs>
  </Box>
);
