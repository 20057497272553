import { formatDistanceStrict } from 'date-fns';
import { stringify } from 'querystring';
import {
  ExpConfig,
  ExperimentConfig,
  ExperimentKnowledgeUpdate,
  ExperimentValues,
  PROJECT_TYPE_LABELS,
} from 'types';
import { getUtcDate } from '../utils/date-handlers';
import { http } from './http';
import {
  ExperimentsQuery,
  Experiment,
  ExperimentsResponse,
  ExperimentPatch,
  ImageConfig,
  TextConfig,
  AudioConfig,
  EXPERIMENT_TASK,
  EXPERIMENT_VISIBILITY_NUM,
} from 'types';

export const getExperiments = (
  query: ExperimentsQuery
): Promise<ExperimentsResponse> =>
  http.get(
    `experiment/page?visibility=${
      EXPERIMENT_VISIBILITY_NUM.PRIVATE
    }&${stringify(query)}`
  );

export const getPublicExperiments = (
  query: ExperimentsQuery
): Promise<ExperimentsResponse> =>
  http.get(`experiment/public/page?${stringify(query)}`);

export const getCompanyExperiments = (
  query: ExperimentsQuery
): Promise<ExperimentsResponse> =>
  http.get(
    `experiment/page?visibility=${
      EXPERIMENT_VISIBILITY_NUM.COMPANY
    }&${stringify(query)}`
  );

export const getCommunityExperiments = (
  query: ExperimentsQuery
): Promise<ExperimentsResponse> =>
  http.get(
    `experiment/page?visibility=${
      EXPERIMENT_VISIBILITY_NUM.COMMUNITY
    }&${stringify(query)}`
  );

export const getExperiment = (id: string): Promise<Experiment> =>
  http.get(`experiment/${id}`);

export const createExperiment = (
  payload: ExperimentValues
): Promise<Experiment> => http.post('experiment', JSON.stringify(payload));

export const updateExperiment = (
  id: string,
  payload: ExperimentPatch
): Promise<Experiment> =>
  http.patch(`experiment/${id}`, JSON.stringify(payload));

export const updateExperimentKnowledge = (
  id: string,
  payload: ExperimentKnowledgeUpdate
) => http.patch(`experiment/${id}/knowledge_base`, JSON.stringify(payload));

export const deleteExperiment = (id: string): Promise<Experiment> =>
  http.delete(`experiment/${id}`);

export const getFriendlyDate = (date: string) => {
  const nDate = getUtcDate(date);
  return formatDistanceStrict(nDate, new Date().getTime());
};

export const getExperimentConfig = (
  type: number,
  task?: number | undefined
): Promise<TextConfig | ImageConfig | AudioConfig> => {
  const taskConfigMap: { [key: string]: string } = {
    [EXPERIMENT_TASK.TEXT_QANDA]: 'generative-qa',
    [EXPERIMENT_TASK.TEXT_CUSTOM_ENTITY_EXTRACTOR]:
      'generative-smart-extraction',
  };

  const typeStringReq = PROJECT_TYPE_LABELS[type].toLocaleLowerCase();

  const taskConfig =
    task && taskConfigMap[task] ? `${taskConfigMap[task]}/config` : 'config';

  return http.get(`experiment/${typeStringReq}/${taskConfig}`);
};

export const updateSendNotification = (
  expId: string,
  sendNotification: boolean
) =>
  http.patch(
    `experiment/${expId}/notification`,
    JSON.stringify({
      send_notification: sendNotification,
    })
  );

export const getExtractorConfig = (): Promise<ExpConfig> => {
  return http.get('experiment/text/generative-smart-extraction/config');
};

export const getAllExperiments = () =>
  Promise.all([
    http.get(`experiment/?visibility=${EXPERIMENT_VISIBILITY_NUM.PRIVATE}`),
    http.get(`experiment/?visibility=${EXPERIMENT_VISIBILITY_NUM.COMMUNITY}`),
    http.get(`experiment/?visibility=${EXPERIMENT_VISIBILITY_NUM.COMPANY}`),
    http.get(`experiment/?visibility=${EXPERIMENT_VISIBILITY_NUM.PUBLIC}`),
  ]);

export const getMyExperiments = (count?: number) =>
  Promise.all([
    http.get(
      `experiment/page?sort=recent&page_num=1&page_size=${count}&visibility=${EXPERIMENT_VISIBILITY_NUM.PRIVATE}`
    ),
    // http.get(`experiment/?visibility=${EXPERIMENT_VISIBILITY_NUM.COMPANY}`),
  ]);

export const getCommunityAnPublicExperiments = (count: number) =>
  Promise.all([
    http.get(
      `experiment/page?sort=recent&page_num=1&page_size=${count}&visibility=${EXPERIMENT_VISIBILITY_NUM.PUBLIC}`
    ),
  ]);

export const updateExperimentConfig = (
  exp_id: string,
  config: ExperimentConfig
): Promise<Experiment> => {
  return http.patch(
    `experiment/config/${exp_id}`,
    JSON.stringify({
      config,
    })
  );
};

type GetDashboardProjectsProps = {
  tasks?: number[];
  visibilities?: number[];
  type?: number;
  sort?: string;
  pageSize?: number;
  pageNum?: number;
  keyword?: string;
};
export const getProjects = ({
  tasks = [],
  visibilities = [],
  type,
  sort = 'recent',
  keyword = '',
  pageNum = 1,
  pageSize = 5,
}: GetDashboardProjectsProps) => {
  const visibility = !!visibilities.length
    ? `&visibilities=${visibilities?.join('&visibilities=')}`
    : '';

  const task = !!tasks.length ? `&tasks=${tasks?.join('&tasks=')}` : '';

  const typeQuery = type ? `&${type}` : '';

  const searchKeyword = keyword ? `&keyword=${keyword}` : '';

  return http.get(
    `experiment/page?sort=${sort}&page_num=${pageNum}&page_size=${pageSize}${visibility}${task}${typeQuery}${searchKeyword}`
  );
};
