import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  root: {
    padding: '8px 12px',
    background: '#2b2b2b',
    borderRadius: 4,

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& *': {
      fontFamily: 'Poppins',
    },
  },
  name: {
    marginBottom: 4,
    textAlign: 'start',
    color: '#333856',
    fontWeight: 500,
    fontSize: 14,
  },
  value: {
    flexGrow: 1,
    overflow: 'auto',
    color: '#6a8759',
    whiteSpace: 'nowrap',
    fontSize: 14,
    textAlign: 'start',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  icon: {
    padding: 0,
    marginLeft: 12,
    color: '#717275',
    transform: 'scale(0.85)',
  },
}));
