import { Typography, Grid } from '@material-ui/core';
import React from 'react';
import { ResponseBox } from './../ResponseBox';
import { PredictResponse, EXPERIMENT_TASK } from 'types';

import useStyles from './classificationresponsebox.styles';

interface ClassificationResponseBoxProps {
  textResponse: PredictResponse;
}

export const ClassificationResponseBox = ({
  textResponse,
}: ClassificationResponseBoxProps) => {
  const classes = useStyles();

  return (
    <ResponseBox
      textResponse={textResponse}
      renderHeader={() => (
        <Grid className={classes.headerWrapper}>
          <Typography variant="body1" className={classes.subtitle}>
            I think this is :
          </Typography>
          <Typography variant="button" className={classes.badge}>
            {textResponse.result}
          </Typography>
        </Grid>
      )}
      renderBody={() => ''}
      task={EXPERIMENT_TASK.TEXT_CLASSIFICATION}
    />
  );
};
