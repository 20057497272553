import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  infoContainer: {},
  containerTitle: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'bold',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: colors.blue70,
    margin: '0 0 16px 0',
  },
  fullSizes: {
    width: '100%',
    height: '100%',
  },

  flexParent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 30px 24px 32px',

    //
    boxSizing: 'border-box',
  },

  numericDetail: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 0 0 16px',
  },
  leftNumericDetail: {
    borderRight: `solid 1px  ${colors.blue15}`,
    padding: '16px 16px 0 0',
  },
  aboveDetailsBox: {
    display: 'flex',
    borderBottom: `solid 1px  ${colors.blue15}`,
    paddingBottom: '16px',
  },
  rightUpBox: {},
  leftUpBox: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '50%',
    '& > div': {
      width: '50%',
    },
  },

  belowDetailsBox: {
    display: 'flex',
  },
  leftDownBox: {
    display: 'flex',
    width: '50%',
    paddingTop: '16px',
    '& > div': {
      width: '50%',
    },
  },
  rightDownBox: {
    display: 'flex',
    width: '50%',
    justifyContent: 'flex-end',
  },
  subtitle: {
    display: 'flex',
  },
}));
