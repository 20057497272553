import { makeStyles } from '@material-ui/core';
import { colors } from 'material';
import { ICogniflowTheme } from 'material/theme';

export default makeStyles<ICogniflowTheme>(() => ({
  wrapper: {
    height: '130px',
    width: '100%',
    backgroundColor: colors.blue50,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '30px',
    boxSizing: 'border-box',
  },

  headerText: {
    color: 'white',
  },

  optionText: {
    fontSize: '12px',
    color: colors.blue15,
    margin: 0,
  },

  optionBtn: {
    cursor: 'pointer',
  },
}));
