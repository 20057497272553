import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
  },
  table: {
    backgroundColor: '#fff',
    borderRadius: 12,
    boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',
  },
}));
