import React, { useState } from 'react';
import { copyTextToClipboard } from 'utils';

import { Box, Button, Dialog, IconButton, Modal } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { Text } from 'components';

import useStyle from './share-model.styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import chatBubbleGif from 'assets/chatbubble.gif';
import iFrameGif from 'assets/iframe.gif';

interface Props {
  code: string;
  widgetCode?: string;
  open: boolean;
  onClose(): void;
  setMessage(params: string): void;
  isQAGenerative?: boolean;
}

export const EmbedModal = ({
  code,
  widgetCode,
  open,
  onClose,
  setMessage,
  isQAGenerative = false,
}: Props) => {
  const classes = useStyle();
  const [showPreview, setShowPreview] = useState(false);
  const [previewImg, setPreviewImg] = useState<string>('');

  const copyToClipboard = (code: string) => {
    copyTextToClipboard(code);
    setMessage('Copied to clipboard!');
  };

  const showPreviewHandler = (img: string) => {
    setPreviewImg(img);
    setShowPreview(true);
  };

  return (
    <>
      <Modal open={open} onClose={onClose} className={classes.modalWrapper}>
        <Box className={classes.embedWrapper} style={{ height: 'max-content' }}>
          <IconButton onClick={onClose} className={classes.modalClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
          <Text
            variant="h4"
            className={classes.title}
            style={{ marginBottom: 24, padding: 0 }}
          >
            Embed on website
          </Text>
          <Box>
            {isQAGenerative && (
              <Text
                variant="paragraph1"
                style={{ marginBottom: 16, padding: 0 }}
              >
                <strong>Option 1:</strong> To add the chatbot in a specific
                location inside your web page, paste the code above in your
                HTML.
                <IconButton
                  onClick={() => showPreviewHandler(iFrameGif)}
                  color="secondary"
                  component="span"
                  size="small"
                >
                  <HelpOutlineIcon fontSize="small" />
                </IconButton>
              </Text>
            )}
            <Box className={classes.embedCodeWrapper}>
              <code className={classes.embedCode}>{code}</code>
            </Box>
            {!isQAGenerative && (
              <Alert severity="info" style={{ marginTop: 12 }}>
                Check that your website url has been allowed as a secure origin
                to embed this app in the customization page.
              </Alert>
            )}
            <Box className={classes.embedButtons} style={{ marginTop: 16 }}>
              <Button
                onClick={() => copyToClipboard(code)}
                variant="contained"
                color="secondary"
                size="small"
              >
                Copy code
              </Button>
            </Box>
          </Box>

          {widgetCode && (
            <Box
              style={{ margin: '24px 0', borderTop: '1px solid #e9e9e9' }}
            ></Box>
          )}

          {widgetCode && (
            <Box>
              <Text
                variant="paragraph1"
                style={{ marginBottom: 16, padding: 0 }}
              >
                <strong>Option 2:</strong> To add a chat bubble to the bottom
                right of your website add this script tag to your html.
                <IconButton
                  onClick={() => showPreviewHandler(chatBubbleGif)}
                  color="secondary"
                  component="span"
                  size="small"
                >
                  <HelpOutlineIcon fontSize="small" />
                </IconButton>
              </Text>
              <Box className={classes.embedCodeWrapper}>
                <code className={classes.embedCode}>{widgetCode}</code>
              </Box>
              <Box className={classes.embedButtons} style={{ marginTop: 16 }}>
                <Button
                  onClick={() => copyToClipboard(widgetCode)}
                  variant="contained"
                  color="secondary"
                  size="small"
                >
                  Copy code
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>

      <Dialog onClose={() => setShowPreview(false)} open={showPreview}>
        <img src={previewImg} />
      </Dialog>
    </>
  );
};
