import React, { useState } from 'react';

import {
  Box,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { Visibility, VisibilityOff, FileCopy } from '@material-ui/icons';

import { copyTextToClipboard } from 'utils';
import MuiAlert from '@material-ui/lab/Alert';

import useStyles from './text-with-clipboard.styles';

interface Clipboard {
  value: string | null | undefined;
  label?: string;
  isPassword?: boolean;
}

export const TextWithClipboard = ({
  value,
  label,
  isPassword = false,
}: Clipboard) => {
  const classes = useStyles();
  const [show, setShow] = useState(!isPassword);
  const [copied, setCopied] = useState(false);

  const onCopyHandler = () => {
    copyTextToClipboard(value || '');
    setCopied(true);
  };

  return (
    <>
      <Box>
        <Typography className={classes.name}>{label}:</Typography>
        <Box className={classes.root}>
          <Typography className={classes.value}>
            {show ? value : Array(value?.length).fill('*')}
          </Typography>
          {isPassword && (
            <IconButton
              onClick={() => setShow((prev) => !prev)}
              edge="end"
              size="small"
              className={classes.icon}
            >
              {show ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          )}
          <Tooltip title="Copy">
            <IconButton
              onClick={onCopyHandler}
              edge="end"
              size="small"
              className={classes.icon}
            >
              <FileCopy />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Snackbar
        open={copied}
        onClose={() => setCopied(false)}
        autoHideDuration={3000}
      >
        <MuiAlert severity="success">Copied to clipboard!</MuiAlert>
      </Snackbar>
    </>
  );
};
