import React, { useEffect, useState } from 'react';
import { SendButton } from 'components';
import {
  has24HoursPassed,
  getInviteTokenCreationDate,
  millisecondsUntilDate,
} from 'utils';
import { resendInvitationForActivation } from 'services';
import { MS } from 'core';
import { useAlertContext } from 'context';

type ResendInvitationProps = {
  email: string;
  expiration: string;
};

export const ResendInvitation = ({
  email,
  expiration,
}: ResendInvitationProps) => {
  const [disabled, setDisabled] = useState(false);
  const [timeoutId, setTimeoutId] = useState<number | null>();
  const { setAlertHandler } = useAlertContext();

  useEffect(() => {
    const tokenCreationDate = getInviteTokenCreationDate(expiration);

    if (has24HoursPassed(new Date(tokenCreationDate.toString()), new Date())) {
      setDisabled(false);
    } else {
      setDisabled(true);

      const id = window.setTimeout(() => {
        setDisabled(false);
      }, millisecondsUntilDate(expiration));

      setTimeoutId(id);
    }

    return () => {
      if (timeoutId) window.clearTimeout(timeoutId);
    };

    // eslint-disable-next-line
  }, [expiration]);

  useEffect(() => {
    return () => {
      if (timeoutId) window.clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  const resendInvitationHandler = (email: string) => {
    resendInvitationForActivation(email).then(() => {
      setDisabled(true);

      const id = window.setTimeout(() => {
        setDisabled(false);
      }, MS.hour * 24);

      setTimeoutId(id);
      setAlertHandler({
        message: 'Invitation sent successfully!',
        showing: true,
        severity: 'success',
      });
    });
  };

  return (
    <SendButton
      tooltip="Resend invitation"
      onSend={() => {
        resendInvitationHandler(email);
      }}
      disabled={disabled}
    />
  );
};
