import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { OCRBoxProps } from 'types';

type OCROptionsProps = {
  boxes: OCRBoxProps[];
  showTextLocation: boolean;
  confidence: number;
};

type OptionTypes = { [key: string]: boolean | number | OCRBoxProps[] };

type OCRProviderProps = {
  children: ReactNode;
};

type OCRContextProps = {
  OCROptions: OCROptionsProps;
  updateOCROptions(options: OptionTypes): void;
};

const INITIAL_CONFIDENCE_THRESHOLD = 0.2;

const OCROptionsInitialValues = {
  showTextLocation: true,
  confidence: INITIAL_CONFIDENCE_THRESHOLD,
  boxes: [],
};

const OCRContext = createContext<OCRContextProps>({
  OCROptions: OCROptionsInitialValues,
  updateOCROptions: () => {},
});

export const useOCRContext = () => useContext(OCRContext);

export const OCRProvider = ({ children }: OCRProviderProps) => {
  const [OCROptions, setOCROptions] = useState(OCROptionsInitialValues);

  const updateOCROptions = useCallback((options: OptionTypes) => {
    setOCROptions((s) => ({
      ...s,
      ...options,
    }));
  }, []);

  return (
    <OCRContext.Provider
      value={{
        OCROptions,
        updateOCROptions,
      }}
    >
      {children}
    </OCRContext.Provider>
  );
};
