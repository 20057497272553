import { http } from './http';
import { Application, ApplicationUpdate } from 'types';

export const getApplication = (id_application: string): Promise<Application> =>
  http.get(`application/${id_application}`);

export const getApplicationByModel = (id_model: string): Promise<Application> =>
  http.get(`application/model/${id_model}`);

export const createApplicationByModel = (
  id_model: string
): Promise<{ id: string }> => http.post(`application/model/${id_model}`);

export const deleteApplication = (id_application: string) =>
  http.delete(`application/${id_application}`);

export const updateApplication = (
  id_application: string,
  payload: ApplicationUpdate
) => http.patch(`application/${id_application}`, JSON.stringify(payload));
