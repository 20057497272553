import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  modelHeader: {
    paddingBottom: '24px',
  },
  modelTitle: {
    /*	marginTop: '32px',
		marginLeft: '32px',*/
  },
  modelNameLabel: {
    fontFamily: 'Poppins',
    fontSize: '8px',
    fontWeight: 600,
    fontStretch: 'normal',
    lineHeight: 1.25,
    letterSpacing: '0.6px',
    color: colors.blue70,
  },
  modelNameTitle: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: colors.blue70,
  },
  chipRecommended: {
    backgroundColor: colors.blue10,
    color: colors.blue90,
    paddingLeft: 8,
  },
  starIcon: {
    fontSize: '10px',
    color: colors.blue90,
  },
  errorLogBox: {
    position: 'absolute',
    zIndex: 1,
    maxWidth: '500px',
    right: '0',
    top: '40px',
    width: '500px',
  },
}));
