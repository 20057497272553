import {
  Grid,
  LinearProgress,
  Tooltip,
  Typography,
  Link,
} from '@material-ui/core';
import React, { useMemo } from 'react';
import { DownloadIcon, HelpIcon } from 'assets';
import { DatasetClass, DatasetResponse } from 'types';
import useStyles from '../dataset.styles';
import moment from 'moment';
const pretty = require('prettysize');

interface Props {
  partitions: DatasetClass[];
  dataset: DatasetResponse;
  title: string;
  type: 'training' | 'validation';
}
export const Partition = ({ partitions, dataset, title, type }: Props) => {
  const total = useMemo(() => {
    return partitions.reduce((prev, curr) => prev + curr.count, 0);
  }, [partitions]);

  const sortedPartitions = useMemo(
    () => partitions.sort((a, b) => (a.class_name > b.class_name ? 1 : -1)),
    [partitions]
  );

  const getTimeDiference = () => {
    const mStartDate = moment(new Date());
    const mEndDate = moment(dataset.expires_at);
    const elapsed = moment.duration(mEndDate.diff(mStartDate));
    const message = `${elapsed.get('days')} days`;
    return message;
  };

  const isExpired = () => {
    const mStartDate = moment(new Date());
    const mEndDate = moment(dataset.expires_at);
    return mStartDate > mEndDate;
  };
  const classes = useStyles();

  return (
    <Grid
      xs={12}
      md={6}
      lg={6}
      container
      item
      direction="column"
      wrap="nowrap"
      className={classes.partitionContainer}
    >
      <Typography variant="h3" className={classes.partitionTitle}>
        {title}
        <Typography className={classes.total} variant="button">
          {total}
        </Typography>
      </Typography>
      <Grid
        container
        item
        justify="space-between"
        md={12}
        className={classes.partitionDownload}
      >
        <Grid item container direction="column" md={4} alignItems="flex-start">
          <Typography className={classes.fileTitle}>
            {type === 'training' ? 'TrainingSet.zip' : 'ValidationSet.zip'}
          </Typography>
          <Typography className={classes.fileSizeTitle}>
            {type === 'training'
              ? `${pretty(dataset.train_size_mb * 1024000, { spaces: 2 })}`
              : `${pretty(dataset.validation_size_mb * 1024000, {
                  spaces: 2,
                })}`}
          </Typography>
        </Grid>
        <Grid item container md={8} justify="flex-end" alignItems="center">
          {!isExpired() && (
            <Typography className={classes.expireLinkTitle}>
              Download link expires in {getTimeDiference()}
            </Typography>
          )}
          {type === 'training' && dataset.download_url_train !== '' && (
            <Link href={dataset.download_url_train} color="inherit">
              <DownloadIcon className={classes.downloadIcon} />
            </Link>
          )}
          {type === 'validation' && dataset.download_url_validation !== '' && (
            <Link href={dataset.download_url_validation} color="inherit">
              <DownloadIcon className={classes.downloadIcon} />
            </Link>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        justify="space-between"
        alignContent="center"
        className={classes.contentContainer}
        direction="row"
        item
        md={12}
      >
        <Grid container item justify="flex-start" md={4} alignItems="center">
          <Typography className={classes.contentTitle} variant="button">
            Content
          </Typography>
        </Grid>
        <Grid container justify="flex-end" item md={8}>
          <Grid container alignContent="center" justify="flex-end">
            {type === 'training' && (
              <Typography className={classes.total} variant="button">
                OK
              </Typography>
            )}

            {type === 'validation' && !dataset.explicit_partition && (
              <Typography className={classes.total} variant="button">
                Automatically generated
              </Typography>
            )}
            {type === 'validation' && !dataset.explicit_partition && (
              <Tooltip title="We have separated 20% of your data for validating the quality of the trained models and for reporting performance metrics.">
                <HelpIcon />
              </Tooltip>
            )}
            {type === 'training' && <HelpIcon />}
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.infoContainer} direction="column">
        {sortedPartitions.map((dsClass) => (
          <Grid
            key={dsClass.class_name}
            className={classes.contentItem}
            container
            justify="space-between"
          >
            <Grid xs={4} md={4} item container alignItems="center">
              <Tooltip title={dsClass.class_name}>
                <Typography noWrap variant="body1">
                  {dsClass.class_name}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid xs={8} md={8} item container justify="flex-end">
              <Grid
                container
                md="auto"
                item
                className={classes.countHeader}
                justify="flex-end"
              >
                <Typography className={classes.count} variant="button">
                  {dsClass.count}
                </Typography>
              </Grid>
              <Grid
                container
                alignItems="center"
                justify="flex-end"
                className={classes.countDetail}
              >
                <Typography className={classes.percent} variant="button">
                  {Math.round(((dsClass.count * 100) / total) * 100) / 100}%
                </Typography>
                <LinearProgress
                  className={classes.progressBar}
                  value={(dsClass.count * 100) / total}
                  variant="determinate"
                  color="primary"
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
