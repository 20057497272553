import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Box, TextField } from '@material-ui/core';
import { ActionButton, Text } from 'components';

import useStyles from './../WhatsappIntegration.styles';
import { updateWhatsappToken } from 'services';
import { toast } from 'react-toastify';
import { ApplicationWhatsapp } from 'types';

type WhatsappTokenStepProps = {
  applicationID: string | undefined;
  whatsappToken: string | undefined;
  onNextStepClickHandler(): void;
  onSubmit(params: ApplicationWhatsapp): void;
};

export const WhatsappTokenStep = ({
  applicationID,
  whatsappToken,
  onNextStepClickHandler,
  onSubmit,
}: WhatsappTokenStepProps) => {
  const classes = useStyles();

  const [token, setToken] = useState<string>('');

  useEffect(() => {
    if (whatsappToken) {
      setToken(whatsappToken || '');
    }
  }, [whatsappToken]);

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setToken(event.target.value);
  };

  const onSaveTokenHandler = useCallback(
    (e) => {
      e.preventDefault();
      if (!token || !applicationID) return;

      updateWhatsappToken(applicationID, token).then((response) => {
        toast.success('Token saved successfully!', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2000,
        });
        onNextStepClickHandler();
        onSubmit(response);
      });
    },
    [whatsappToken, applicationID, token]
  );

  return (
    <Box component="form" onSubmit={onSaveTokenHandler}>
      <Box mb="14px">
        <Text variant="paragraph1" className={classes.stepTitle}>
          Save WhatsApp Access Token
        </Text>
      </Box>

      <Box mb="8px">
        <Text variant="paragraph1" align="left">
          WhatsApp Access Token
        </Text>
      </Box>
      <Box mb="24px">
        <TextField
          name="whatsapp_token"
          variant="outlined"
          fullWidth
          helperText="Paste here the access token that Meta provides"
          type="password"
          value={token}
          onChange={onChangeHandler}
          required
        />
      </Box>

      <ActionButton
        type="submit"
        variant="contained"
        color="secondary"
        size="large"
      >
        Save
      </ActionButton>
    </Box>
  );
};
