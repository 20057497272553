import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ODBoxProps } from 'types';

type ObjectDetectionProviderProps = {
  children: ReactNode;
};

type OptionValues = boolean | number | ODBoxProps[];

type Options = {
  showBoxes: boolean;
  showLabels: boolean;
  showScore: boolean;
  opacity: number;
  boxes: ODBoxProps[];
  confidence: number;
};

type CategoryChecksProps = { [key: string]: boolean };

type ObjectDetectionContextProps = {
  options: Options;
  categoryChecks: {};
  selectAll: boolean;
  updateOptions(options: { [key: string]: OptionValues }): void;
  setCategoryChecksHandler(checks: CategoryChecksProps): void;
  selectAllHandler(value: boolean): void;
};

const initialOptionValues = {
  showBoxes: true,
  showLabels: true,
  showScore: true,
  opacity: 100,
  boxes: [],
  confidence: 15,
};

const ObjectDetectionContext = createContext<ObjectDetectionContextProps>({
  options: initialOptionValues,
  categoryChecks: {},
  selectAll: true,
  updateOptions: () => {},
  setCategoryChecksHandler: () => {},
  selectAllHandler: () => {},
});

export const useObjectDetectionContext = () =>
  useContext(ObjectDetectionContext);

export const ObjectDetectionProvider = ({
  children,
}: ObjectDetectionProviderProps) => {
  const [options, setOptions] = useState<Options>(initialOptionValues);
  const [categoryChecks, setCategoryChecks] = useState<CategoryChecksProps>({});
  const [selectAll, setSelectall] = useState(true);

  const mappedBoxes = useRef(false);

  useEffect(() => {
    if (!options.boxes.length) return;

    if (!mappedBoxes.current) {
      setCategoryChecks(
        options.boxes.reduce(
          (prev, curr: ODBoxProps) => ({
            ...prev,
            [curr.category as string]: true,
          }),
          {} as CategoryChecksProps
        )
      );
      mappedBoxes.current = true;
    }
  }, [options]);

  const updateOptions = useCallback(
    (updatedOptions: { [key: string]: OptionValues }) => {
      setOptions((s) => ({
        ...s,
        ...updatedOptions,
      }));
    },
    []
  );

  const setCategoryChecksHandler = useCallback(
    (checks: CategoryChecksProps) => {
      setCategoryChecks(checks);
    },
    []
  );

  const selectAllHandler = useCallback((value: boolean) => {
    setSelectall(value);
  }, []);

  return (
    <ObjectDetectionContext.Provider
      value={{
        options,
        updateOptions,
        categoryChecks,
        setCategoryChecksHandler,
        selectAllHandler,
        selectAll,
      }}
    >
      {children}
    </ObjectDetectionContext.Provider>
  );
};
