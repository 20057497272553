import { useEffect, useMemo } from 'react';
import { httpPredict } from 'services/http';
import {
  Application,
  EXPERIMENT_TASK,
  ErrorDataProps,
  PredictResponse,
} from 'types';
import { FetchError, urlMap } from 'utils';
import { useAuthenticationContext, usePredictionContext } from 'context';

type useGetImagePredictionProps = {
  image: string;
  task: number;
  id: string;
  imgFormat?: string;
  validatePredictingLimitReached(): Promise<boolean>;
  onPredictionFails?(errorData: ErrorDataProps): void;
  application?: Application;
  isApplication?: boolean;
  onPredictionSuccess?(): void;
  convertToPdf?: string;
  ocrEngine?: string;
};

export const useGetImagePrediction = ({
  image,
  task,
  id,
  imgFormat,
  validatePredictingLimitReached,
  onPredictionFails,
  application,
  isApplication = false,
  onPredictionSuccess,
  convertToPdf,
  ocrEngine,
}: useGetImagePredictionProps) => {
  const {
    imagePrediction: prediction,
    setImagePredictionHandler: setPrediction,
    loading,
    setLoadingHandler: setLoading,
  } = usePredictionContext();

  const { user, userApiKey } = useAuthenticationContext();

  const initialUrl = useMemo(
    () => (urlMap[task] ? urlMap[task] : `image/classification/predict`),
    [task]
  );

  const webhookUrl = useMemo(() => application?.config?.webhookUrl, [
    application,
  ]);

  const cleanPredictionResponse = () => {
    setPrediction(null);
  };

  const setPredictionHandler = (data: any) => {
    setPrediction(data);
  };

  useEffect(() => {
    if (prediction) return;
    const getPredictionResult = async () => {
      try {
        if (!isApplication && (await validatePredictingLimitReached())) {
          onPredictionFails?.({
            status: 401,
            message: 'Plan exceeded',
          });
          return;
        }

        if (!image) return;

        setLoading(true);

        const normalizeBoxes = [
          EXPERIMENT_TASK.IMAGE_OBJECT_DETECTION,
          EXPERIMENT_TASK.IMAGE_OCR,
        ].includes(task);

        const isOCR = EXPERIMENT_TASK.IMAGE_OCR === task;
        const isExtractor =
          EXPERIMENT_TASK.IMAGE_CUSTOM_ENTITY_EXTRACTOR === task;

        const isPDF = imgFormat?.toLocaleLowerCase() === 'pdf';

        const convertPDFToImage = isOCR || isExtractor;
        const convert_pdf_to_image = isExtractor
          ? convertToPdf || 'default'
          : 'yes';
        const ocr_engine = ocrEngine || 'paddleocr';

        const includeOutputImage = (isOCR || isExtractor) && isPDF;

        const pageCountField = isExtractor && isPDF ? { page_count: 10 } : {};

        const url =
          EXPERIMENT_TASK.IMAGE_CUSTOM_ENTITY_EXTRACTOR === task
            ? 'image/smart-extraction/extract'
            : initialUrl;

        const payload = {
          format: imgFormat || 'png',
          base64_image: image,
          ...(includeOutputImage
            ? { include_output_image: 'default' }
            : { include_output_image: 'no' }),
          ...(convertPDFToImage ? { convert_pdf_to_image } : {}),
          ...(isExtractor ? { ocr_engine } : {}),
          ...(normalizeBoxes ? { normalize_boxes: true } : {}),
          ...pageCountField,
        };

        const response = await httpPredict.post<PredictResponse>(
          `${url}/${id}`,
          JSON.stringify(payload),
          { 'x-api-key': isApplication ? '' : userApiKey }
        );

        setLoading(false);
        setPrediction(response);
        onPredictionSuccess?.();

        // Send the result to the webhook
        if (isApplication && webhookUrl)
          fetch(webhookUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ response, payload }),
          }).catch(() => {});
      } catch (error) {
        setLoading(false);
        onPredictionFails?.({
          status: (error as FetchError).statusCode,
          message: (error as FetchError).message,
        });
      }
    };

    getPredictionResult();
    // eslint-disable-next-line
  }, [
    user,
    image,
    userApiKey,
    task,
    id,
    initialUrl,
    imgFormat,
    validatePredictingLimitReached,
    onPredictionFails,
    webhookUrl,
    isApplication,
    onPredictionSuccess,
    setLoading,
    setPrediction,
  ]);

  return {
    prediction,
    loading,
    setPredictionHandler,
    cleanPredictionResponse,
  };
};
