import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  tone: {
    position: 'relative',
    textAlign: 'left',
    pointerEvents: ({ disabled }) => (disabled ? 'none' : 'all'),
  },
  toneLabel: {
    fontSize: '0.75em',
    color: '#a4a9c8',
  },
  toneValue: {
    padding: '3px 14px 12px',
    border: '1px solid',
    color: ({ disabled }) =>
      disabled ? 'rgb(173, 173, 173)' : 'rgb(27, 27, 27)',
    borderColor: ({ disabled }) =>
      disabled ? 'rgb(189, 189, 189)' : '#d8ddeb',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fbfbfb',
    },
  },
  tonePopup: {
    position: 'absolute',
    top: '110%',
    left: 0,
    right: 0,
    zIndex: 10,

    padding: '40px',
    background: '#fff',
    border: '1px solid #eceffa',
    boxShadow: '0px 16px 24px 0px rgba(72, 79, 121, 0.15)',
    borderRadius: '8px',
  },
  toneOptions: {
    marginTop: '32px',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '16px',
  },
  toneOption: {
    padding: '10px 14px',
    border: '1px solid #d8ddeb',
    borderRadius: '8px',
    color: '#484f79',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fbfbfb',
    },
  },
}));
