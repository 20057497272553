import React from 'react';
import { Text } from 'components';
import { Box } from '@material-ui/core';
import { CardsStack } from 'assets';

import { DashboardCardProps } from '../types';
import Carousel from 'react-multi-carousel';
import { responsive } from '../dashboardCards';

import useStyles from './WhatsNew.styles';

export const WhatsNew = ({ news }: DashboardCardProps) => {
  const classes = useStyles();

  const carouselProps = {
    autoplay: news.length > 1,
    infinite: news.length > 1,
    transitionDuration: 400,
    responsive,
  };

  return (
    <Box className={classes.whatsNew}>
      <Carousel className={classes.carousel} {...carouselProps}>
        {news.map(({ title, description, img_url, url }, idx) => (
          <Box
            display="flex"
            justifyContent="space-between"
            height="100%"
            boxSizing="border-box"
            padding="32px"
            onClick={() => {
              window.open(url, '_blank');
            }}
            key={idx}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box>
                <Text
                  variant="paragraph1"
                  align="left"
                  className={classes.title}
                >
                  {title}
                </Text>

                <Text
                  variant="paragraph1"
                  align="left"
                  className={classes.description}
                >
                  {description}
                </Text>
              </Box>
            </Box>
            <Box className={classes.cardImages}>
              <img src={img_url || CardsStack} alt="cards stack img" />
            </Box>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};
