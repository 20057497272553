import React, { useState } from 'react';

import { Box, Container, Typography } from '@material-ui/core';

import { ActionButton } from 'components';
import { CompanyForm } from '../CompanyForm';

import classNames from 'classnames';
import useStyle from '../company.styles';

interface CreateCompanyProps {
  onSuccess?(payload: { msg: string }): void;
  onError?(payload: { msg: string }): void;
}

export const CreateCompany = ({ onSuccess, onError }: CreateCompanyProps) => {
  const classes = useStyle();
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);

  return showCreateForm ? (
    <Container maxWidth="sm" className={classes.wrapper}>
      <Box className={classes.textWrapper}>
        <Typography variant="h2" align="left">
          Organization name
        </Typography>
        <Typography variant="body1" align="left">
          Choose your name to start inviting people to join.
        </Typography>
      </Box>
      <CompanyForm onSuccess={onSuccess} onError={onError} />
    </Container>
  ) : (
    <Container
      maxWidth="sm"
      className={classNames(classes.wrapper, classes.emptyWrapper)}
    >
      <img
        src="https://static.userguiding.com/ext-assets/1651176536305_group_14_2x.png"
        width={200}
        alt="company"
      ></img>
      <Box className={classes.textWrapper}>
        <Typography variant="h3">Organization (for Teams)</Typography>
        <Typography variant="body1">
          Increase your productivity.
          <br />
          Invite users and collaborate to create great AI together.
        </Typography>
      </Box>
      <ActionButton
        onClick={() => setShowCreateForm(true)}
        variant="contained"
        color="secondary"
        size="large"
        loading={false}
      >
        Next
      </ActionButton>
    </Container>
  );
};
