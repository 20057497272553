import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { Text } from 'components';

import useStyles from './SettingsDropdown.styles';
import { ChevronDown } from 'assets';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { CheckCircle } from '../CheckCircle/CheckCircle';

import classnames from 'classnames';

type ModelOptionProps = {
  title: string;
  description: string;
  subtitle?: string;
  link?: string;
  to?: string;
  value: string;
  disabled?: boolean;
  Icon?: React.FunctionComponent<any>;
};

type SettingsDropdownProps = {
  options: ModelOptionProps[];
  initialValue: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  optionStyle?: React.CSSProperties;
};

export const SettingsDropdown = ({
  options,
  onChange,
  initialValue,
  disabled,
  style,
  optionStyle,
}: SettingsDropdownProps) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const [value, setValue] = useState(initialValue);

  const dropdownRef = useOnClickOutside(() => setOpen(false));

  const currentItem = options.find((item) => item.value === value);

  const handleOpen = () => setOpen(!open);

  const onItemSelection = (value: string) => {
    setValue(value);
    onChange(value);
  };

  return (
    <div
      className={classnames({
        [classes.dropdownWrapper]: true,
        [classes.dropdownDisabled]: disabled,
      })}
      onClick={handleOpen}
      ref={dropdownRef}
      style={style}
    >
      <Box className={classes.dropdown}>
        {currentItem?.Icon ? (
          <currentItem.Icon
            style={{ width: '16px', height: '16px', flexShrink: 0 }}
          />
        ) : (
          <CheckCircle checked />
        )}
        <Box>
          <Text
            variant="paragraph1"
            align="left"
            className={classes.optionTitle}
          >
            {currentItem?.title}{' '}
            <span className={classes.subtitle}>{currentItem?.subtitle}</span>
          </Text>
          <Text
            variant="paragraph1"
            align="left"
            className={classes.descriptionTitle}
          >
            {currentItem?.description}
          </Text>
        </Box>

        <Box className={classes.icon}>
          {open ? <ChevronDown /> : <ChevronDown />}
        </Box>

        {open && (
          <Box className={classes.itemsWrapper}>
            {options
              .filter((item) => item.value !== currentItem?.value)
              .map(
                ({
                  value,
                  title,
                  subtitle,
                  description,
                  link,
                  disabled,
                  Icon,
                }) => (
                  <Box
                    className={classnames({
                      [classes.item]: true,
                      [classes.disabled]: disabled,
                    })}
                    onClick={() => {
                      if (disabled) return;
                      onItemSelection(value);
                    }}
                    key={value}
                  >
                    {Icon ? (
                      <Icon
                        style={{ width: '16px', height: '16px', flexShrink: 0 }}
                      />
                    ) : (
                      <CheckCircle />
                    )}
                    <Box style={optionStyle}>
                      <Text
                        variant="paragraph1"
                        align="left"
                        className={classes.optionTitle}
                      >
                        {title}{' '}
                        <span className={classes.subtitle}>{subtitle}</span>
                      </Text>
                      {description && (
                        <Text
                          variant="paragraph1"
                          align="left"
                          className={classes.descriptionTitle}
                        >
                          {description}
                        </Text>
                      )}

                      {link && (
                        <Text
                          variant="paragraph1"
                          align="left"
                          className={classes.link}
                        >
                          <a href={link} target="_blank">
                            Upgrade
                          </a>
                        </Text>
                      )}
                    </Box>
                  </Box>
                )
              )}
          </Box>
        )}
      </Box>
    </div>
  );
};
