import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  avatar: {
    height: 88,
    width: 88,
    padding: 8,
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
  title: {
    marginLeft: 16,
    lineHeight: '1.4',
  },
  statusCaption: {
    marginRight: 16,
  },
  input: {
    lineHeight: '1.4',
    fontFamily: 'Poppins',
    fontSize: 40,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: colors.blue70,
    marginLeft: 16,
  },
  avatarContainer: {
    cursor: 'pointer',
  },
  iconEdit: {
    position: 'relative',
    float: 'right',
    top: -30,
    left: 0,
    cursor: 'pointer',
  },
  errorLogBox: {
    position: 'absolute',
    zIndex: 1,
    maxWidth: '500px',
    right: '0',
    top: '40px',
    width: '500px',
  },
  button: {
    width: '100px',
  },

  expConfigContainer: {
    gap: '12px',
  },
}));
