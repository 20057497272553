import { Grid, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import useStyle from './propertyvaluelabel.style';

interface Props {
  property: string;
  value: ReactElement;
}

export const PropertyValueLabel = ({ property, value }: Props) => {
  const classes = useStyle();
  return (
    <Grid
      item
      container
      md={6}
      alignItems="center"
      justify="flex-start"
      className={classes.preprocessingBox}
    >
      <Typography className={classes.processingItem}>{property}</Typography>
      {value}
    </Grid>
  );
};
