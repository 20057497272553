import React, { useEffect, useMemo, useState } from 'react';

import {
  Typography,
  Container,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core';
import { toast } from 'react-toastify';

import { useAuthenticationContext } from 'context';
import { usePermissions } from 'hooks/usePermissions';

import { ApiKeysTable } from './ApiKeysTable';
import { ActionButton, Text } from 'components';

import useStyle from './apikeys.styles';
import { createCustomAPIKey, deleteAPIKey } from 'services/apiKeys';
import { CustomApiKey } from 'types';
import { FetchError } from 'utils';
import { Alert } from '@material-ui/lab';

interface ApiKey {
  id: string;
  alias: string;
  key: string;
  created_at: string;
  provider: number;
}

const API_KEY_PROVIDERS = [{ name: 'Open AI', value: 1 }];

export const ApiKeys = () => {
  const classes = useStyle();
  const { user, fetchUser } = useAuthenticationContext();
  const { requiresCustomOpenAIAPIKey } = usePermissions();
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);

  const cogniflowApiKeys = useMemo(
    () => (apiKeys || []).filter((apiKey) => apiKey.provider === 0),
    [apiKeys]
  );
  const otherApiKeys = useMemo(() => {
    let arr = (apiKeys || []).filter((apiKey) => apiKey.provider === 1);
    arr = arr.map((item) => ({ ...item, alias: 'OpenAI', key: item.alias }));
    return arr;
  }, [apiKeys]);

  useEffect(() => {
    setApiKeys(user?.api_keys || []);
  }, [user]);

  const [showForm, setShowForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [newKey, setNewKey] = useState('');
  const [provider, setProvider] = useState(1);
  const [apiKeyToDelete, setApiKeyToDelete] = useState<string | null>(null);

  const handleProviderChange = (event: any) => {
    setProvider(event.target.value);
  };

  const handleKeyChange = (event: any) => {
    setNewKey(event.target.value);
  };

  const handleOpenForm = () => {
    setNewKey('');
    setShowForm(true);
  };

  const handleSave = async (e: any) => {
    e.preventDefault();
    try {
      const newApiKey: CustomApiKey = {
        alias:
          API_KEY_PROVIDERS.find(({ value }) => value === provider)?.name || '',
        key: newKey,
        provider: provider,
      };
      await createCustomAPIKey(newApiKey);
      toast.success('Key successfully added', {
        position: toast.POSITION.TOP_CENTER,
        toastId: 'api-create',
        autoClose: 3000,
      });
      fetchUser?.();
    } catch (error) {
      if (error instanceof FetchError)
        toast.error(error.message, {
          position: toast.POSITION.TOP_CENTER,
          toastId: 'api-error',
          autoClose: 3000,
        });
    }
    setShowForm(false);
  };

  const handleDelete = (id: string) => {
    setApiKeyToDelete(id);
    setShowDelete(true);
  };

  const confirmDelete = async () => {
    if (!apiKeyToDelete) return null;

    try {
      await deleteAPIKey(apiKeyToDelete);
      setShowDelete(false);
      toast.success('Key successfully deleted', {
        position: toast.POSITION.TOP_CENTER,
        toastId: 'api-delete',
        autoClose: 3000,
      });
      fetchUser?.();
    } catch (error) {}
  };

  if (!user) return null;

  return (
    <Container maxWidth="md" className={classes.root}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h2" align="left">
          Api Keys
        </Typography>
      </Box>

      <Box>
        <Typography variant="h4" align="left" style={{ marginBottom: 18 }}>
          Cogniflow
        </Typography>

        <ApiKeysTable apiKeys={cogniflowApiKeys} handleDelete={handleDelete} />
      </Box>

      {requiresCustomOpenAIAPIKey && (
        <Box>
          <Text variant="h3" align="left" style={{ marginBottom: 24 }}>
            Others
          </Text>
          <Text variant="h4" align="left">
            OpenAI
          </Text>
          {user?.is_company_commercial_plan ? (
            <>
              <Text
                variant="paragraph1"
                align="left"
                style={{ fontSize: 16, margin: '16px 0' }}
              >
                Integrate a OpenAI API key so you can use features like “Chat
                with your documents” and “Smart Extractor” that require
                connecting with the OpenAI API.
              </Text>
              <Alert severity="info">
                Please make sure the owner has set up an OpenAI API key to
                access the feature mentioned above.
              </Alert>
            </>
          ) : (
            <>
              <Text
                variant="paragraph1"
                align="left"
                style={{ fontSize: 16, margin: '16px 0' }}
              >
                Integrate your own OpenAI API key so you can use features like
                “Chat with your documents” and “Smart Extractor” that require
                connecting with the OpenAI API.
              </Text>
              {otherApiKeys.length > 0 ? (
                <ApiKeysTable
                  apiKeys={otherApiKeys}
                  handleDelete={handleDelete}
                  hideKeys={false}
                />
              ) : (
                <ActionButton
                  onClick={handleOpenForm}
                  variant="contained"
                  color="secondary"
                  size="large"
                  style={{ margin: '12px  0' }}
                >
                  Add OpenAI API key
                </ActionButton>
              )}
            </>
          )}
        </Box>
      )}

      <Dialog open={showForm} onClose={() => setShowForm(false)}>
        <form onSubmit={handleSave} style={{ maxWidth: '100%', width: 500 }}>
          <DialogTitle>Add Api Key</DialogTitle>
          <DialogContent>
            <Box
              display="flex"
              flexDirection="column"
              gridGap="16px"
              paddingBottom="16px"
            >
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="provider-select">Provider</InputLabel>
                <Select
                  id="provider-select"
                  value={provider}
                  onChange={handleProviderChange}
                >
                  {API_KEY_PROVIDERS.map(({ name, value }) => (
                    <MenuItem value={value} key={value}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                value={newKey}
                onChange={handleKeyChange}
                label="Key"
                type="text"
                variant="outlined"
                fullWidth
                required
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              type="button"
              onClick={() => setShowForm(false)}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              autoFocus
              disableElevation
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog open={showDelete} onClose={() => setShowDelete(false)}>
        <DialogTitle>Delete Api Key</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Text variant="paragraph1" align="left" style={{ fontSize: 16 }}>
              Are you certain you want to proceed?
            </Text>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            onClick={() => setShowDelete(false)}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={confirmDelete}
            variant="contained"
            color="secondary"
            autoFocus
            disableElevation
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
