import React, { useEffect, useMemo, useState, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tab,
  Tabs,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import { ActionButton, Text } from 'components';
import { ColorPicker } from 'components/ColorPicker';

import { getEnv } from 'services/env';
import { getApplication, updateApplication } from 'services/application';
import { buildPublicUrl } from 'services/files';
import { Application, ApplicationUpdate, EXPERIMENT_TYPE } from 'types';
import { LANGUAGES_SORT } from 'utils';
import { DEFAULT_VALUES } from 'pages/Application/Application';

import LockIcon from '@material-ui/icons/Lock';
import useStyle from './share-model.styles';
import { EditAvatar } from './EditAvatar';
import { ChatPreview } from './ChatPreview';
import { usePermissions } from 'hooks/usePermissions';

const REACT_APP_BUCKET_NAME = getEnv('REACT_APP_BUCKET_NAME');

interface Props {
  application: Application;
  setApplication: React.Dispatch<React.SetStateAction<Application | null>>;
  open: boolean;
  onClose(): void;
  setMessage(params: string): void;
  isQAGenerative?: boolean;
  isQAGenerativeStructured?: boolean;
}

interface StateProps {
  title: string;
  description: string;
  subtitle: string;
  submitText: string;
  colorBackground: string;
  colorBox: string;
  colorButton: string;
  colorText: string;
  fontFamily: string;
  webhookUrl: string;
  welcomeMessage: string;
  pinnedMessage: string;
  pinnedMessageIsCollapsible: boolean;
  noAnswerMessage: string;
  avatar?: string;
  lang_code?: string;
  hideLogo?: boolean;
  hideDownloadButton?: boolean;
  includeMessageSources?: boolean;
}

export const CustomizationModal = ({
  application,
  setApplication,
  open,
  onClose,
  setMessage,
  isQAGenerative = false,
  isQAGenerativeStructured = false,
}: Props) => {
  const classes = useStyle();
  const { createApplicationValue } = usePermissions();
  const initialValues: StateProps = {
    title: '',
    description: '',
    subtitle: '',
    submitText: '',
    webhookUrl: '',
    welcomeMessage: '',
    pinnedMessage: '',
    noAnswerMessage: '',
    ...DEFAULT_VALUES,
  };
  const [state, setState] = useState<StateProps>(initialValues);
  const [origins, setOrigins] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [displayedAvatar, setDisplayedAvatar] = useState<
    string | undefined | null
  >(null);

  useEffect(() => {
    const newState = { ...initialValues };
    newState['title'] = application.title;
    newState['description'] = application.description;
    Object.entries(application.config || []).forEach(([key, value]) => {
      if (value != null && value !== '') newState[key] = value;
    });
    setState(newState);
    setOrigins(application.config?.origins || []);
    // eslint-disable-next-line
  }, [open, application]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleChangeCheck = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.checked;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const { title, description, ...config } = state;
      if (config.lang_code === 'default') config.lang_code = '';

      const body: ApplicationUpdate = {
        title,
        description,
        config: {
          ...config,
          origins: origins.filter((item) => item),
        },
      };

      await updateApplication(application.id, body);
      const newApplication = await getApplication(application.id);
      setApplication(newApplication);
      setMessage('The application has been successfully updated!');
      onClose();
    } catch (error) {}
    setLoading(false);
  };

  const handleClear = () => {
    setState(initialValues);
    setOrigins([]);
  };

  const handleChangeOrigin = (index: number, newValue: string) => {
    setOrigins((prev) => {
      const newArray = [...prev];
      newArray[index] = newValue;
      return newArray;
    });
  };

  const handleClose = (event: any, reason: any) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  const isText = useMemo(
    () => application.experiment.type === EXPERIMENT_TYPE.TEXT,
    [application]
  );

  return (
    <Modal open={open} onClose={handleClose} className={classes.modalWrapper}>
      <>
        <Box className={classes.customWrapper}>
          <Text variant="h4" className={classes.title}>
            {isQAGenerative ? 'Chat Settings' : 'Web App Customization'}
          </Text>
          <IconButton onClick={onClose} className={classes.modalClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>

          <Box style={{ marginTop: 20, borderBottom: '1px solid #ccc' }}>
            <Tabs
              value={tab}
              indicatorColor="secondary"
              textColor="secondary"
              onChange={(e, i) => setTab(i)}
              variant="fullWidth"
            >
              <Tab label="General" />
              <Tab label="Appearance" />
              <Tab label="Advanced" />
            </Tabs>
          </Box>

          <Box
            className={classes.customizationForm}
            style={{ display: tab === 0 ? 'flex' : 'none' }}
          >
            <Box>
              <TextField
                label={isQAGenerative ? 'Chat title' : 'Title'}
                name="title"
                value={state.title}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                helperText={isQAGenerative ? 'e.g. My Chatbot' : 'e.g. My App'}
              />
            </Box>
            {isQAGenerative && (
              <>
                <Box>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Language</InputLabel>
                    <Select
                      value={state.lang_code}
                      onChange={handleChange}
                      inputProps={{
                        name: 'lang_code',
                      }}
                      label="Language"
                      defaultValue="default"
                    >
                      <MenuItem value="default">
                        <em>Detect automatically</em>
                      </MenuItem>
                      {Object.entries(LANGUAGES_SORT).map(([key, value]) => (
                        <MenuItem value={key} key={key}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <TextField
                    label="Welcome Message"
                    name="welcomeMessage"
                    value={state.welcomeMessage}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    multiline
                    helperText="Press [enter] key to add a new line."
                  />
                </Box>
                <Box>
                  <TextField
                    label="Banner Message"
                    name="pinnedMessage"
                    value={state.pinnedMessage}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    multiline
                    helperText="Press [enter] key to add a new line."
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="pinnedMessageIsCollapsible"
                        checked={state.pinnedMessageIsCollapsible}
                        onChange={handleChangeCheck}
                      />
                    }
                    label="Banner Collapsible"
                  />
                </Box>
                {isQAGenerativeStructured && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="hideDownloadButton"
                        checked={state.hideDownloadButton}
                        onChange={handleChangeCheck}
                      />
                    }
                    label="Hide Download button"
                  />
                )}

                {!isQAGenerativeStructured && (
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="includeMessageSources"
                          checked={state.includeMessageSources}
                          onChange={handleChangeCheck}
                        />
                      }
                      label="Show message sources"
                    />
                  </Box>
                )}

                {/* <Box>
                <Text className={classes.customizationInputText}>
                  No Answer Message:
                </Text>
                <TextField
                  label='Press "enter" to add a new line'
                  name="noAnswerMessage"
                  value={state.noAnswerMessage}
                  onChange={handleChange}
                  variant="filled"
                  size="small"
                  fullWidth
                  multiline
                />
              </Box> */}
              </>
            )}
            {!isQAGenerative && (
              <>
                <Box>
                  <TextField
                    label="Subtitle"
                    name="subtitle"
                    value={state.subtitle}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    helperText="e.g. Test this model"
                  />
                </Box>
                <Box>
                  <TextField
                    label="Description"
                    name="description"
                    value={state.description}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    multiline
                    helperText="Press [enter] key to add a new line."
                  />
                </Box>
                <Box>
                  <TextField
                    label="Button Text"
                    name="submitText"
                    value={state.submitText}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    helperText="e.g. Submit"
                  />
                </Box>
              </>
            )}

            {/* <Text variant="paragraph1">
            Please note that if you leave fields empty when modifying the
            settings of your applications, default text will be used. This
            ensures that your applications continue to function properly.
          </Text> */}
          </Box>

          <Box
            className={classes.customizationForm}
            style={{ display: tab === 1 ? 'flex' : 'none' }}
          >
            {createApplicationValue < 2 && (
              <Box className={classes.customizationLocked}>
                <LockIcon style={{ width: 46, height: 46 }} />
                <Text variant="h4">
                  This is a feature available for higher plans. Upgrade your
                  plan to enable this feature.
                </Text>
                <Link to="/settings/subscription">
                  <ActionButton
                    color="secondary"
                    variant="contained"
                    size="large"
                  >
                    Upgrade your plan
                  </ActionButton>
                </Link>
              </Box>
            )}
            {isQAGenerative && (
              <>
                <Box>
                  <Text className={classes.customizationInputText}>Avatar</Text>
                  <EditAvatar
                    initialValue={
                      state.avatar
                        ? buildPublicUrl(
                            state.avatar,
                            REACT_APP_BUCKET_NAME || 'static'
                          )
                        : ''
                    }
                    name="avatar"
                    onChange={handleChange}
                    setDisplayedImage={(value) => setDisplayedAvatar(value)}
                  />
                </Box>
              </>
            )}
            <Box>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Text Font</InputLabel>
                <Select
                  value={state.fontFamily}
                  onChange={handleChange}
                  inputProps={{
                    name: 'fontFamily',
                  }}
                  label="Text Font"
                >
                  <MenuItem value="Poppins">Poppins</MenuItem>
                  <MenuItem value="Arial">Arial</MenuItem>
                  <MenuItem value="Open Sans">Open Sans</MenuItem>
                  <MenuItem value="Lato">Lato</MenuItem>
                  <MenuItem value="Comic Neue">Comic Neue</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box>
              <Text className={classes.customizationInputText}>
                Color Theme
              </Text>
              <Box display="flex">
                <Box className={classes.customizationInputColor}>
                  <ColorPicker
                    name="colorBackground"
                    value={state.colorBackground}
                    onChange={handleChange}
                  />
                  <Text>Background</Text>
                </Box>
                <Box className={classes.customizationInputColor}>
                  <ColorPicker
                    name="colorButton"
                    value={state.colorButton}
                    onChange={handleChange}
                  />
                  <Text>Button</Text>
                </Box>
                {isText && !isQAGenerative && (
                  <Box className={classes.customizationInputColor}>
                    <ColorPicker
                      name="colorBox"
                      value={state.colorBox}
                      onChange={handleChange}
                    />
                    <Text>Box</Text>
                  </Box>
                )}
                {/* <Box className={classes.customizationInputColor}>
                  <ColorPicker
                    name="colorText"
                    value={state.colorText}
                    onChange={handleChange}
                  />
                  <Text>Text</Text>
                </Box> */}
              </Box>
            </Box>
            <Box position="relative">
              {createApplicationValue >= 2 && createApplicationValue < 3 && (
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  position="absolute"
                  top={-6}
                  bottom={-6}
                  left={-10}
                  right={-10}
                  style={{
                    backgroundColor: 'rgba(231, 235, 248, 0.71)',
                    paddingRight: 8,
                    borderRadius: 8,
                    border: '2px solid #d9dde8',
                  }}
                  zIndex={100}
                >
                  <Link to="/settings/subscription">
                    <ActionButton variant="contained" color="secondary">
                      Upgrade your plan
                    </ActionButton>
                  </Link>
                </Box>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    name="hideLogo"
                    checked={state.hideLogo}
                    onChange={handleChangeCheck}
                  />
                }
                label="Hide Cogniflow logo"
              />
            </Box>
          </Box>

          <Box
            className={classes.customizationForm}
            style={{ display: tab === 2 ? 'flex' : 'none' }}
          >
            {!isQAGenerative && (
              <Box>
                <Text className={classes.customizationInputText}>
                  Allowed Origins
                </Text>
                <Text variant="paragraph1" style={{ marginBottom: 8 }}>
                  Specify the authorized websites to embed your widget securely.
                </Text>
                {origins.map((origin, index) => (
                  <Box display="flex" key={index} style={{ marginBottom: 8 }}>
                    <TextField
                      placeholder="https//www.my-domain.com"
                      value={origin}
                      onChange={(event) =>
                        handleChangeOrigin(index, event.target.value)
                      }
                      variant="outlined"
                      size="small"
                      hiddenLabel
                      fullWidth
                    />
                    <IconButton
                      onClick={() =>
                        setOrigins((prev) =>
                          prev.filter((item, i) => index !== i)
                        )
                      }
                      size="small"
                      style={{ flexShrink: 0, marginLeft: 8 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
                <Button
                  onClick={() => setOrigins((prev) => [...prev, ''])}
                  variant="outlined"
                  startIcon={<AddIcon />}
                >
                  Add
                </Button>
              </Box>
            )}
            <Box>
              <Text className={classes.customizationInputText}>
                Webhook Url
              </Text>
              <Text variant="paragraph1" style={{ marginBottom: 6 }}>
                Use a webhook to notify you when an event happens in your web
                apps and widgets. When the event occurs, an HTTP POST request
                will be made to the URL.
              </Text>
              <TextField
                name="webhookUrl"
                placeholder="https//www.my-webhook.com"
                value={state.webhookUrl}
                onChange={handleChange}
                variant="outlined"
                size="small"
                fullWidth
                hiddenLabel
              />
            </Box>
          </Box>

          <Box className={classes.embedButtons} style={{ paddingRight: 20 }}>
            <Button
              onClick={handleClear}
              variant="outlined"
              color="primary"
              size="large"
              disableElevation
              disabled={loading}
            >
              Reset
            </Button>
            <ActionButton
              onClick={handleSave}
              variant="contained"
              color="secondary"
              size="large"
              loading={loading}
            >
              Save
            </ActionButton>
          </Box>
        </Box>

        {isQAGenerative && (
          <Box
            className={classes.customWrapper}
            style={{ background: 'rgb(233, 237, 251)' }}
          >
            <Text variant="h4" className={classes.title}>
              Preview
            </Text>
            <Box style={{ padding: 24, paddingBottom: 0, flexGrow: 1 }}>
              <Box
                style={{
                  borderRadius: 16,
                  overflow: 'hidden',
                  height: '100%',
                  background: '#fff',
                  boxShadow: '0 2px 24px 0 #ccc',
                }}
              >
                <ChatPreview {...state} avatar={displayedAvatar} />
              </Box>
            </Box>
          </Box>
        )}
      </>
    </Modal>
  );
};
