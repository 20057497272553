import { makeStyles } from '@material-ui/core';
import { colors } from 'material/colors';

export default makeStyles(() => ({
  tabs: {
    marginTop: 24,
  },
  experBody: {
    marginBottom: '100px',
    maxWidth: '100%',
  },
  tryAnotherBtn: {
    marginLeft: '10px',
  },
  tryAnotherMsg: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    color: colors.blue70,
  },
}));
