import React, { useEffect, useState } from 'react';

import {
  Login,
  ActivateAccount,
  ForgotPasswd,
  Reset,
  Experiments,
  ViewExperiment,
  Settings,
  Application,
  Signup,
  PaymentConfirmation,
  Embed,
  Projects,
  CreateProject,
  Admin,
  BuildingProject,
  CreateExperiment,
} from 'pages';

import {
  AlertToast,
  PromotionalPlanModal,
  PlanModals,
  AuthRoute,
} from 'components';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import { useAuthenticationContext } from 'context';
import { confirmAlert } from 'react-confirm-alert';

export default function Routes() {
  const { user } = useAuthenticationContext();
  const [promotionalPlanId, setPromotionalPlanId] = useState<string | null>(
    null
  );
  const [showPromo, setShowPromo] = useState(true);

  useEffect(() => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      let pcode = queryParams.get('pcode') || '';
      if (pcode) localStorage.setItem('showPromo', pcode);

      pcode = localStorage.getItem('showPromo') || '';
      if (user && pcode) {
        setPromotionalPlanId(pcode);
        localStorage.removeItem('showPromo');
      }
    } catch {}
  }, [user]);

  return (
    <Router
      getUserConfirmation={(message, callback) => {
        confirmAlert({
          message,
          buttons: [
            {
              label: 'Yes',
              onClick: () => callback(true),
            },
            {
              label: 'No',
              onClick: () => callback(false),
            },
          ],
        });
      }}
    >
      <div>
        <Switch>
          <AuthRoute exact path="/">
            <Redirect to="/projects/dashboard" />
          </AuthRoute>

          <AuthRoute path="/experiments" component={Experiments} />

          <AuthRoute path="/experiment/:id" component={ViewExperiment} />

          <Route exact path="/login" component={Login} />

          <Route path="/signup" component={Signup} />

          <Route exact path="/activation" component={ActivateAccount} />

          <Route exact path="/forgot" component={ForgotPasswd} />

          <Route exact path="/reset" component={Reset} />

          <AuthRoute path="/create" component={CreateExperiment} />

          <AuthRoute path="/new-project" component={CreateProject} />

          <AuthRoute path="/settings" component={Settings} />

          <AuthRoute path="/projects/:tab" component={Projects} />

          <Route path="/app/:applicationId" component={Application} />

          <Route path="/payment_confirmation" component={PaymentConfirmation} />

          <Route path="/embed" component={Embed} />

          <AuthRoute
            path="/building/:experimentId"
            component={BuildingProject}
          />

          <AuthRoute path="/admin" component={Admin} />
        </Switch>
      </div>

      <PlanModals />
      <PromotionalPlanModal
        open={showPromo}
        onClose={() => setShowPromo(false)}
        planId={promotionalPlanId}
      />
      <AlertToast />
    </Router>
  );
}
