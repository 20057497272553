import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  closeIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  closeIcon: {
    cursor: 'pointer',
  },
}));
