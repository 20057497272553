import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  table: {
    backgroundColor: '#FFFF',
    boxShadow: '0 8px 16px 0 rgb(72 79 121 / 15%)',
    borderRadius: '10px',
  },
  removeUserRoot: {
    padding: 24,
    gap: 16,
  },
}));
