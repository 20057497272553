import { makeStyles } from '@material-ui/core';

export default makeStyles(({ palette, breakpoints }) => ({
  root: {
    display: 'flex',
    padding: '24px 0',
    '& *': {
      fontFamily: 'Poppins',
    },
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  sidebar: {
    marginBottom: 24, // add spacing on mobile
    '& span': {
      fontSize: '14px',
    },
  },
  link: {
    display: 'block',
    overflow: 'hidden',
    textDecoration: 'none',
    borderRadius: 8,
    '& > div': {
      paddingRight: '30px',
    },
  },
  linkActive: {
    background: '#ff5e4015',
    '& *': {
      color: palette.secondary.main,
    },
  },

  contentContainer: {
    width: '100%',
  },
}));
